let template = require('./registration_limit_modal.twig');

export class RegistrationLimitModal {

    constructor(targetData, reg_info) {
        this.dom = $(twig({ data: template }).render({
            reg_info: reg_info,
            add_email: app.appData.layout.attendance.required_email_for_remote_attendee ?? false,
            remote_button: app.appData.layout.attendance.target_limit_checks.registration.remote_button
        }));

        this.dom.find(".remote-btn").on("click", (event) => {
            this.modal.hide();
            targetData.remote = 1;
            app.pages.attendance.dom.trigger('targetInviteClicked', [event, targetData]);
        });

        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false, show: false});
        this.modal.show();
    }
}
