var edit_group_template = require('./edit_group.twig');
import { Dropdown } from '../../components/Dropdown'

export class EditGroup
{
    constructor(config) {
        this.config = config
        this.groupId = config.groupId
        this.members = [];

        this.dom = $(twig({ data: edit_group_template }).render({config: config}))

        let statesDD = new Dropdown(app.appData.states)
        statesDD
            .label('State')
            .draw(this.dom.find("#target_search_state_dd-container"), 'alpha2', 'name', 'state')
        this.dom.find('#target_search_state_dd-container > select').addClass("input-group-text")

        this.initializeTables()
        this.loadMembersTable()

        this.dom.find('#datatable_targets').on('click', '.add', (event) => {
            let targetData = this.targetsDatatable.row($(event.target).closest('tr')).data()
            this.addMember(targetData)

            event.preventDefault()
        })

        this.dom.find('#datatable_members').on('click', '.delete', (event) => {
            let memberData = this.membersDatatable.row($(event.target).closest('tr')).data()
            if(confirm('Are you sure you want to remove ' + memberData.firstname + ' ' + memberData.lastname + '?')) {
                this.deleteMember(memberData.target_id)
            }

            event.preventDefault()
        })

        this.dom.find('#target_search_lastname').on('keyup', () => {
            this.dom.find('#target_filter button').prop('disabled', !this.canSearch())
        });

        this.dom.find('#form_filter_targets').on('submit', (e) => {
            e.preventDefault()

            this.setFilterData()
            this.targetsDatatable.ajax.reload();
        });


        this.dom.find('#back-to-groups').on('click', (e) => {
            e.preventDefault()
            this.dom.trigger('ShowManageGroups', [e])
            this.modal.hide()
        });

        this.dom.on('hidden.bs.modal',  () => {
            this.modal.dispose();
        })

        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false});
        this.modal.show();
    }

    setFilterData() {
        this.filterData = {};
        this.dom.find("#form_filter_targets input, #form_filter_targets select").each((index ,elem) => {
            this.filterData[$(elem).attr('name').replace('target_search_', '')] = $(elem).val();
        });
    }

    canSearch() {
        return this.dom.find('#target_search_lastname').val().trim().length > 1
    }

    deleteMember(targetId) {
        return $.ajax({
            url : `/api/attendance-group/${this.groupId}/members/${targetId}`,
            type : 'DELETE'
        }).then((response) => {
            if(response.success) {
                this.membersDatatable.row('#member-' + targetId).remove().draw()

                displayNotification({
                    message: 'Member deleted.',
                    success: true
                })
            }

            return response
        })
    }

    addMember(memberData) {
        console.log(memberData)
        return $.ajax({
            url: `/api/attendance-group/${this.groupId}/members`,
            type: 'POST',
            data: {
                'target_id': memberData.target_id,
                'address_id': memberData.address_id
            },
            dataType: 'JSON'
        }).then((response) => {
            if(response.success) {
                this.loadMembersTable()
                displayNotification({
                    message: 'Member added.',
                    success: true
                })

                this.dom.find('.add[data-target-id="' + memberData.target_id + '"]').each((index, elem) => {
                    elem.remove()
                })

            } else {
                displayNotification({
                    message: response.message,
                    success: false
                })
            }

            return response
        })
    }

    getMembers() {
        return $.ajax({
            url: `/api/attendance-group/${this.groupId}/members`,
            type: 'GET'
        })
    }

    loadMembersTable() {
        this.getMembers().then((response) => {
            this.members = response.data
            this.membersDatatable.clear()
            this.membersDatatable.rows.add(response.data).draw()
        })
    }

    initializeTables() {
        this.membersDatatable = this.dom.find('#datatable_members').DataTable({
            "data": this.members,
            "rowId": (data) => { return "member-" + data.target_id },
            "displayLength": 10,
            "sort": true,
            "paginate": true,
            "filter": false,
            "paginationType": "full_numbers",
            "columns": [
                {"mDataProp": "customer_id"},
                {"mDataProp": "firstname"},
                {"mDataProp": "lastname"},
                {"mDataProp": "degree"},
                {"mDataProp": "specialty"},
                {"mDataProp": "address1"},
                {"mDataProp": "city"},
                {"mDataProp": "state"},
                {"mDataProp": "email"},
                {
                    "data": null,
                    "render": (data, type, row) => {
                        let html = `<a class="delete mx-1" data-target-id="${row['target_id']}" title='Remove Member'><i class="fas fa-trash"></i></a>`
                        return html;
                    }
                }
            ]
        })

        this.targetsDatatable = this.dom.find("#datatable_targets").DataTable({
            "processing": true,
            "serverSide": true,
            "ordering": false,
            "ajax": {
                "url": "api/attendance/searchtargets",
                "data": (d) => {
                    d.data = this.filterData;
                    d.meeting_id = this.config.meetingData.meeting_id
                }
            },
            "columns": [
                {"mDataProp": "customer_id"},
                {"mDataProp": "firstname"},
                {"mDataProp": "lastname"},
                {"mDataProp": "degree"},
                {"mDataProp": "specialty"},
                {"mDataProp": "address"},
                {"mDataProp": "city"},
                {"mDataProp": "state"},
                {"mDataProp": "email"},
                {
                    data: 'null',
                    render: (data, type, row) => {

                        // check to see if target is already in this groups
                        if(!this.members.find(({target_id}) => target_id == row.target_id))
                        {
                            return '<button type="button" class="btn btn-sm btn-primary add" data-target-id="' + row.target_id + '" data-address-id="' + row.address_id + '" data-name="' +  row.firstname + ' ' +  row.lastname + '"> Add</button>';
                        }
                        else
                        {
                            return '';
                        }
                    }
                }
            ],
            "pagingType": "full_numbers",
            "width" : "100%",
            "dom": '<"row"<"col-6"l><"col-6"<"row">>><t>pri',
            "deferLoading": 1,
            "pageLength": 10,
            "scrollCollapse": true,
            "scrollY": '50vh',
            "language": {
                "loadingRecords": 'Please wait while we fetch your results.',
                "emptyTable":     "No Results",
                "processing": 'Please wait while we fetch your results.'
            },
            "drawCallback": function() {
                app.convertDatatableToCard("datatable_targets");
            }
        });
    }
}
