import {Card} from "./Card";

var template = require('./advertising_card.twig');

export class AdvertisingCard extends Card {
    constructor() {
        super();
        let dependent_ids = app.appData.advertising_options.map((option) => {
            return option.dependent_id
        })

        let data = app.appData.advertising_options.map((option) => {
            option.hide = false;
            if(dependent_ids.includes(option.advertising_option_id)) {
                option.hide = true;
            }
            return option;
        })

        this.dom = $(twig({data: template}).render({advertising_options : data}));

        this.dom.find('.dependent_input').on('click', (event) => {
            let targetZipCode = true;
            if($('#program_type_dd').val()) {
                let projProgTypeConf = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()].configuration;
                let targetZipCodeConf = projProgTypeConf.sb.meeting_request.target_zip_code_required;
                targetZipCode = targetZipCodeConf != undefined ? targetZipCodeConf : targetZipCode;
            }

            let element = $(event.target)
            let dependentElement = this.dom.find('[name=container_'+element.data('dependent-id')+']');

            if(element.is(':checked')
                && targetZipCode) {
                dependentElement.removeClass('d-none').addClass('d-inline')
            } else {
                dependentElement.addClass('d-none').removeClass('d-inline')
            }
        })

        this.dom.on('click', 'input[type="checkbox"]', (event) => {
            let other_element = this.dom.find('[name="advertising_option_other['+$(event.target).data('option')+']"]').parent('span');
            other_element.hide()
            if($(event.target).is(':checked')) {
                other_element.show();
            }
            this.cardFilledOutTriggers();
        })

        this.dom.on('change', 'input[type="text"]', () => {
            this.cardFilledOutTriggers();
        })
    }

    getData() {
        let advertising_data = {};
        this.dom.find('input, select').each((i, e) => {
            let name = $(e).attr('name');
            let type = $(e).attr('type');
            if(type == 'checkbox') {
                if($(e).is(':checked')) {
                    advertising_data[name] = 1
                }
            } else if (type == 'hidden') {
                if($(e).parent().find('[type=text]').val()) {
                    advertising_data[name] = 1
                }
            } else if($(e).val()) {
                advertising_data[name] = $(e).val()
            }
        })

        return advertising_data;
    }

    isFilledOut() {
        let ret = false;
        let invalidCount = 0
        this.dom.find('.card-body').addClass('border border-danger');
        this.dom.find('input[type="checkbox"]').each((i, element) => {
            if($(element).is(':checked')) {
                let targetZipCode = true;
                if($('#program_type_dd').val()) {
                    let projProgTypeConf = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()].configuration;
                    let targetZipCodeConf = projProgTypeConf.sb.meeting_request.target_zip_code_required;
                    targetZipCode = targetZipCodeConf != undefined ? targetZipCodeConf : targetZipCode;
                }

                let dependentElement = this.dom.find('[name=container_'+$(element).data('dependent-id')+']').find('input[type="text"]')
                let validDependent = !dependentElement || !$(dependentElement).prop('required') || $(dependentElement).val()

                if(!validDependent
                    && targetZipCode) {
                    invalidCount++
                    $(dependentElement).addClass('is-invalid');
                } else {
                    ret = true;
                    $(dependentElement).removeClass('is-invalid');
                    this.dom.find('.card-body').removeClass('border border-danger');
                }
            }
        })


        let default_show_ms_views_and_news = app.appData.defaultProjectProgramTypeConfig.sb.meeting_request.show_ms_views_and_news;
        let show_ms_views_and_news = default_show_ms_views_and_news;

        if($('#program_type_dd').val()
            && app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()]
                .configuration.sb.meeting_request.show_ms_views_and_news != undefined){
            show_ms_views_and_news = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()]
                .configuration.sb.meeting_request.show_ms_views_and_news;
        }

        if(show_ms_views_and_news === true){
            $('#container_code_ms').removeClass('d-none');
        } else {
            $('#container_code_ms').addClass('d-none');
        }


        if(invalidCount > 0) {
            ret = false
        }
        return ret;
    }
}
