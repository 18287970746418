import { Page } from './page.js'
import { EmployeeSearch } from "./prospect/EmployeeSearch";

let template = require('./prospect/prospect.twig');
let new_pot_trainee = require('./prospect/prospect_add.twig');


export class Prospect extends Page {
    constructor() {
        super();

        this.dom = $(twig({ data: template }).render({
            products: app.appData.products,
            current_user: app.appData.current_user,
            degrees: app.appData.degrees
        }));

        this.dom.find('#submit_form').first().on('click', (e) => {
            e.preventDefault();
            let form = this.dom.find('form[name=prospect-form]')
            this.submitForm(form);
        })

        this.dom.find('#add_trainee').off().on('click', (e) => {
            e.preventDefault();
            this.addTrainee();
        })

        this.dom.find('#search_employee_button').on('click', (e) => {
            e.preventDefault();
            let employeeSearch = new EmployeeSearch();
            let modal = new bootstrap.Modal(employeeSearch.dom, {backdrop: 'static', keyboard: false})
            modal.show();
        })

        this.dom.on('click', '.remove-card', (e) => {
            e.preventDefault();
            $(e.target).closest(".potential_trainee").remove();
        })
    }

    getProducts(){

        return $.ajax({
            url     : 'api/prospect/products',
            async   : false,
            method  : 'get',
            dataType: 'json'
        });
    }

    submitForm(form){
        let completed = false;
        completed = this.checkRequired();
        if(completed){
            let num_trainee = 0;

            $(".potential_trainee").each(()=>{
                num_trainee++;
            });

            let data = $(form).serialize();

            data = data + '&num_trainee=' + num_trainee;

            $.ajax({
                url     : 'api/prospect/sendform',
                async   : false,
                method  : 'post',
                data    : data,
                success : (data) => {

                    if(data == 'success'){
                        displayNotification({
                            title: 'Form Submitted',
                            success: true
                        });

                        //Reset the form, which gets messy.
                        this.dom.find(".potential_trainee input[type!=checkbox][type!=radio]").each((index, elem) => {
                            $(elem).val('');
                        });
                        $(".additional_trainee").remove();
                        $("#pt1_designation").val('');
                        $("input:checked").prop('checked', false);
                    }
                    else{
                         displayNotification({
                            title: 'Form Failed to Submit',
                            success: false
                        });
                    }
                }
            });
        }
        else{
            displayNotification({
                title: 'Missing Required Information',
                success: false
            });
        }

    }

    checkRequired() {
        let result = true;

        this.dom.find("#prospect-form input[type!=checkbox][type!=radio].required").each((index, elem) => {
            if ($(elem).val()) {
                $(elem).removeClass('is-invalid')
            }
            else
            {
                $(elem).addClass('is-invalid')
                result = false;
            }
        })

        this.dom.find('#prospect-form input[type=radio].required').each((idx, el) => {
            if(this.dom.find('input[name="' + $(el).attr('name') + '"]:checked').length) {
                $('input[name="' + $(el).attr('name') + '"]').parent().removeClass('border border-danger');
            }
            else {
                $('input[name="' + $(el).attr('name') + '"]').parent().addClass('border border-danger');
                result = false;
            }
        });

        this.dom.find('#prospect-form .des-select').each((idx, el) => {
            if($(el).val() != '') {
                $(el).removeClass('is-invalid')
            }
            else {
                $(el).addClass('is-invalid')
                result = false;
            }
        });

        let remove_check = [];
        let add_check = [];
        this.dom.find('input[type=checkbox].required').each((index,elem) => {
            if($(elem).is(':checked')){
                if(remove_check.indexOf($(elem).data('group')) === -1){
                    remove_check.push($(elem).data('group'));
                }
                if(add_check.indexOf($(elem).data('group')) > -1){
                    delete add_check[add_check.indexOf($(elem).data('group'))]
                }
            }
            else{
                if(add_check.indexOf($(elem).data('group')) === -1 && remove_check.indexOf($(elem).data('group')) === -1){
                    add_check.push($(elem).data('group'));

                }
            }
        })

        $.each(remove_check,(index,elem) =>{
            $('#'+elem).removeClass('border border-danger');
        })
        $.each(add_check,(index,elem) =>{
            if(elem){
                $('#'+elem).addClass('border border-danger');
                result = false;
            }
        })

        return result;
    }

    addTrainee(){

        let num_trainee = 0;

        $(".potential_trainee").each(()=>{
            num_trainee++;
        });

        // add for next template
        num_trainee++;

        this.dom.find("#p2p_request_form").append($(twig({data: new_pot_trainee}).render({
            products: app.appData.products,
            current_user: app.appData.current_user,
            degrees: app.appData.degrees,
            pot_train_num: num_trainee,
        })));

        $(this.dom).find("#pt" + num_trainee + "_phone").mask('(999) 999-9999');
        $(this.dom).find("#pt" + num_trainee + "_phone").attr('pattern', '^([0-9]){10}$');

    }


    ready(){

    }

}
