export default class FilterNode {
    
    dependencies = [];
    name = '';
    node = {};
    defaultValue = null;

    constructor(props = {}) {
        const { dependencies, name, node  } = props;
        this.dependencies = dependencies;
        this.name = name;
        this.node = node;
        this.defaultValue = '';
        this.previousValue = '';

    }

    getVal() {
        return this.node.find('select').val();
    }

    setVal(val) {
        this.node.find('select').val(val);
    }

    resetValues() {
        this.setVal(this.defaultValue);
        this.setPreviousValue('');
    }

    getPreviousValue() {
        return this.previousValue;
    }

    setPreviousValue(value) {
        this.previousValue = value;
    }

    setValid() {
        this.node.find('select')?.removeClass('is-invalid');
    }

    setInvalid() {
        this.node.find('select')?.addClass('is-invalid');
    }
}
