import { Page } from '../page.js'

let template = require('./meetingexpenses.twig');
let meetingExpensesSectionTemplate =  require('./meeting_section.twig');
let speakerExpensesSectionTemplate =  require('./speaker_section.twig');
let totalsSectionTemplate =  require('./total_section.twig');

export class MeetingExpenses extends Page{
    constructor() {
        super();
        this.dom = $(template);
    }

    getExpenses() {
        return $.ajax({
            url: '/api/meetingexpenses/' + this.meetingId,
            type: 'GET',
            dataType: 'JSON'
        })
    }

    ready() {
        this.meetingId = window.location.toString().split("/").pop();
        this.dom.find("#meeting-id").html(this.meetingId)

        this.dom.find('#meeting_details_button').off().on('click', () => app.navigate('meeting', [this.meetingId]));

        this.getExpenses().then((response) => {
            this.dom.find('#meeting-expenses-card').html(
                $(twig({data:  meetingExpensesSectionTemplate}).render(
                    {'meetingExpenses': response.data.meeting_expenses}
            )))

            this.dom.find('#speaker-expenses-card').html('')
            for (let speaker of response.data.speaker_expenses) {

                speaker.expenseTotal = speaker.expenses.reduce((t, x) => t + parseInt(x.value), 0)
                this.dom.find('#speaker-expenses-card').append(
                    $(twig({data:  speakerExpensesSectionTemplate}).render(
                        {'speakerData': speaker}
                )))
            }

            this.dom.find('#totals-card').html(
                $(twig({data:  totalsSectionTemplate}).render(
                    {'totals': response.data.total_expenses}
            )))
        })
    }
}
