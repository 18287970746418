import {Card} from "./Card";
import {HubSearch} from "./HubSearch";

var template = require('./spoke_card.twig');

export class SpokeCard extends Card
{
    constructor() {
        super();
        this.from_calendar = false;
        this.dom = $(twig({ data: template }).render());

        this.dom.find('#hub_search_button').on('click', () => {
            if(this.hubSearch) {
                this.hubSearch.modal.dispose();
            }

            this.hubSearch = new HubSearch();

            this.hubSearch.dom.on('HubApplied', () => {
                this.cardFilledOutTriggers();
            });
        })

        this.dom.find('#hub_meeting_id').on('change', () => {
            this.hub_meeting_id = this.dom.find('#hub_meeting_id').val();

            this.dom.find('#hub_meeting_id').val('');
            this.cardFilledOutTriggers();

            this.dom.find('#hub_meeting_id').val(this.hub_meeting_id);
            this.cardFilledOutTriggers();

            this.dom.trigger('HubApplied');
        })
    }

    show() {
        let program_type;
        if(this.from_calendar) {
            this.dom.find('#hub_meeting_id').val(this.hub_meeting_id);
        } else {
            this.hub_meeting_id = null;
        }

        if($('#program_type_dd').val()) {
            program_type = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()];
        }

        if(program_type != undefined
        && program_type.configuration.sb.meeting_request.is_spoke) {
            this.dom.fadeIn(100).promise().then(() => {
                this.cardFilledOutTriggers();
            });

            if(this.dom.find('#hub_meeting_id').val() != ''){
                this.dom.find('#hub_meeting_id').trigger('change');
            }
        }
        else {
            this.clearAndHide();
        }
    }

    canShowNextCard()
    {
        let can_show =
            (this.dom.is(':visible')
            && this.hub_meeting_id)
            ||
            (!this.isSpoke()
            && app.pages['meetingrequest'].formCards.project_card.filled_out);

        return can_show;
    }
}