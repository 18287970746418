import {Card} from "./Card";

var template = require('./submit_request_card.twig');

export class SubmitRequestCard extends Card
{
    constructor() {
        super();

        this.dom = $(twig({data: template}).render());
    }

    // This card is never filled out
    cardFilledOutTriggers() {
        this.dom.trigger($.Event('CardNotFilledOut'));
    }
}