import { Page } from './page.js'
import { Dropdown } from '../components/Dropdown'

let template = require('./marketingcontent/list.twig');
export class MarketingContent extends Page{

    constructor() {
        super();
        this.dom = $(twig({ data: template }).render());
        this.dom.find('#add-link').on('click', () => {
            app.navigate('add_marketingcontent')
        })
    }

    initializeTable() {
        this.marketingContentTable = this.dom.find('#marketing_content_table').DataTable({
            "paging": true,
            "lengthChange": true,
            "searching": true,
            "ordering": true,
            "autoWidth": false,
            "pagingType": "full_numbers",
            "pageLength": 25,
            "dom": '<"row"<"col-6"l><"col-6"<"row"<"col-10"f><"col-2 text-end"<"w-100 float-start"B>>>>><t>ip',
            "buttons": [
                {
                    'className': 'btn btn-success',
                    'text': '<i class="fas fa-file-excel"></i>',
                    'title': 'Marketing Content List',
                    'action': () => {
                        window.location.href = "api/marketingcontent/export";
                    }
                }
            ],
            "columns": [
                {"data": "request_id"},
                {"data": "product"},
                {"data": "program_type"},
                {
                    "data": "action",
                    "mRender": (data) => {
                        return data[0].toUpperCase() + data.substr(1).toLowerCase();
                    }
                },
                {"data": "material_type"},
                {"data": "full_material_title"},
                {"data": "prc_code"},
                {"data": "marketing_lead"},
                {"data": "program_ops_contact"},
                {
                    "data": "files",
                    "render": (data) => {
                        let file_links = [];

                        $.each(data, (idx, file) => {
                            file_links.push("<a href='api/marketingcontent/download?marketing_content_file=1&file_id=" + file['file_id'] + "' class='text-primary file_dl_link'>" + file['display_name'] + "</a><br>");
                        });

                        return file_links.join('');
                    }
                },
                {"data": "submitted_by"},
                {"data": "submitted_datetime"},
                {"data": "completed_by"},
                {"data": "completed_datetime"}
            ],
            "processing": true,
            "language": {
                "emptyTable": "No data available",
                "processing": '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span>'
            },
            "serverSide": true,
            "ajax": {
                "url": "api/marketingcontent",
                "data": (d) => {
                    d.brand_product = this.dom.find('#brand_product').val();
                    d.status = this.dom.find('#status').val();
                }
            },
            "initComplete": () => {
                this.dom.find('#brand_product, #status').on('change', () => {
                    let dataTable = this.dom.find('#marketing_content_table').DataTable();
                    this.dom.find('#marketing_content_table tbody').hide(500);

                    dataTable.ajax.reload(() => {
                        this.dom.find('#marketing_content_table tbody').show(500);
                    });
                });
            },
            "drawCallback": () => {
                this.dom.find("#marketing_content_table tbody tr").off('click').on('click', (e) => {
                    let dataTable = this.dom.find('#marketing_content_table').DataTable();
                    app.navigate('marketing_content_detail', [dataTable.row(e.target).data().request_id]);
                });
            }
        });
    }

    drawBrandProductsDD(data) {
        let productsDD = new Dropdown(data)
		productsDD
            .all(false)
            .label(' ')
            .draw(this.dom.find('#brand_product_container'), 'id', 'name', 'brand_product')
    }

    ready() {
        if(!app.appData.brandsProducts) {
            fetch('api/marketingcontent/init')
            .then(response => { return response.json() })
            .then(data => {
                app.appData = { ... app.appData, ...data };
                this.drawBrandProductsDD(data.brandsProducts);
            })
        }

        if($.fn.dataTable.isDataTable(this.dom.find('#marketing_content_table'))) {
            this.dom.find('#marketing_content_table').DataTable().ajax.reload();
        }
        else {
            this.initializeTable();
        }
    }
}