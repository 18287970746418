import template from './Header.twig'
import {UserProfile} from "../UserProfile";
import {Contact} from "../Contact";

export class Header
{
    constructor() {
        let data = {
            base: app.base,
            ...app.appData
        };

        this.dom = $(twig({data: template}).render(data));
        this.setSupportDialog();
        this.setUserProfile();
        this.showChangePassword();

        this.sideBarCollapsed = true;
        this.dom.find('.sidebar-toggle').on('click', () => this.toggleSideBar())
    }

    setSupportDialog() {
        let contact = new Contact();
        this.dom.find('#contact_dialog').html(contact.dom);

        this.dom.find('#contact_icon').on('click', () => {
            this.dom.find("#facebook_container").hide();
        });
    }

    setUserProfile() {
        let userProfile = new UserProfile();
        this.dom.find('#userbox').append(userProfile.dom);
    }

    showChangePassword() {
        if(!app.checkPerms('changepassword')) {
            this.dom.find('#change-password-container').remove();
        } else {
            this.dom.find('#change-password-button').off().on('click', () => {
                app.navigate('changepassword');
                this.dom.find('#userbox-button').click();
            });
        }
    }

    toggleSideBar() {
        if(this.sideBarCollapsed) {
            $('html').removeClass('sidebar-left-collapsed')
            $('html').addClass('sidebar-left-big-icons')
        } else {
            $('html').addClass('sidebar-left-collapsed')
            $('html').removeClass('sidebar-left-big-icons')
        }

        this.sideBarCollapsed = !this.sideBarCollapsed;
    }
}
