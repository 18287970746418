import template from './denyAttendeeEval.twig'
import {CustomModal} from "../../components/customModal";

export class DenyAttendeeEval {
    constructor(meeting_id, attendee_id, eval_id, answer_set_id) {
        this.meeting_id = meeting_id;
        this.attendee_id = attendee_id;
        this.eval_id = eval_id;
        this.answer_set_id = answer_set_id;

        this.dom = $(twig({data: template}).render({
        }));

        this.dom.find(".message").addClass("d-none");
        this.dom.find("#submit_denial").attr("disabled", false);
        this.dom.find("#loading-ajax").addClass('hide');
        this.dom.find('#submit_denial').on('click', () => this.denyAttendeeEval())

        this.dom.find('#cancel').on('click', () => {
            this.close()
        })

        this.denialModal = new CustomModal({
            title: "Decline Note",
            content: this.dom,
            buttons: []
        });
    }

    denyAttendeeEval(){
        let reason = this.dom.find("#denial_reason").val().trim();

        if (reason.length > 1) {
            this.dom.find("#submit_denial").attr("disabled", true);
            this.dom.find(".loading-ajax").removeClass('hidden');
            $.ajax({
                url: "api/prepostwork/meeting/" + this.meeting_id + "/deny",
                type: "GET",
                async: false,
                data: {
                    attendee_id: this.attendee_id,
                    eval_id: this.eval_id,
                    answer_set_id: this.answer_set_id,
                    denial_reason: reason
                },
                success: () => {
                    this.dom.find("#submit_denial").attr("disabled", false);
                    this.close();

                    let currentAccordion = $("input[name='answer_set_id'][value="+this.answer_set_id +"]").closest(".accordion_body");
                    $(currentAccordion).find(".denial_message").html("Patient Model Information Denied").removeClass("hidden").show().delay(50000).fadeOut();

                    let form = $(currentAccordion).find("form[name='evaluation']");
                    this.setApprovalStatus(form, this.eval_id, this.answer_set_id);
                }
            });
        }else {
            this.dom.find(".message").text("Please add  Decline Reason").removeClass("d-none");
        }
    }

    close() {
        this.dom.find("#denial_reason").val("");
        this.dom.find(".message").text("").addClass("d-none");
        this.denialModal.hide();
    }

    setApprovalStatus(form, evalId, answerSetId){
        $.ajax({
            url: "api/prepostwork/getapprovalstatus",
            type: "GET",
            async: false,
            data: {
                attendeeId: $("#attendees").val(),
                evalId: evalId,
                answerSetId: answerSetId
            },
            success: (data) => {
                if(data && data.approved === '1'){
                    form.closest(".accordion_header").find(".approved").show();
                    form.closest(".accordion_header").find(".denied").hide();
                    form.closest(".accordion_header").find(".pending").hide();
                    form.closest(".accordion_body").find(".action_btn_wrap").hide();
                }else if(data && data.denied === '1'){
                    form.closest(".accordion_header").find(".denied").show();
                    form.closest(".accordion_header").find(".approved").hide();
                    form.closest(".accordion_header").find(".pending").hide();
                    form.closest(".accordion_body").find(".action_btn_wrap").hide();
                }else{
                    form.closest(".accordion_header").find(".pending").show();
                    form.closest(".accordion_header").find(".denied").hide();
                    form.closest(".accordion_header").find(".approved").hide();
                }
            }
        });
    }
}
