let template = require('./speaker_meetings.twig')

export class SpeakerMeetings
{
    constructor(speaker_id, meeting_id) {

        this.dom = $(twig({ data: template }).render());

        this.meetingsTable = this.dom.find('#meetings').DataTable({
            paginate: true,
            pageLength: 10,
            sort: true,
            serverSide: false,
            deferRender: true,
            sorting: [[ 0, "asc" ]],
            ajax: {
                url: 'api/speakers/' + speaker_id + '/adjacent_meetings',
                method: 'get',
                data: {
                    meeting_id: meeting_id
                },
                dataSrc: json => json
            },
            columns: [
                {
                    data: 'meeting_id',
                    render : (data, type, row) => {
                        if(row.same_day_meeting == 1) {
                            return '<span class="text-success">'+row.meeting_id+'</span>'
                        }
                        else {
                            return '<span class="text-primary">'+row.meeting_id+'</span>'
                        }
                    }
                },
                { data: 'meeting_datetime' },
                { data: 'status_name' }
            ],
            language: {
                emptyTable: 'No meetings to show'
            }
        });

        this.dom.find('#meetings tbody').off('click')
        .on('click', 'tr', (elem) => {
            let data = this.meetingsTable.row($(elem.target).parents('tr')).data();
            window.open('meeting/'+data.meeting_id, '_blank');
        });
    }
}
