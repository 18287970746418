import {Card} from "./Card";
import {CustomModal} from "../../components/customModal";

var template = require('./project_card.twig');
var live_program_warning_template = require('./live_program_warning_modal.twig');
var programtype_attestation_modal = require('./programtype_attestation_modal.twig');
export class ProjectCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({data: template}).render(app.appData.layout.meeting_request));

        this.project_dd = this.dom.find("#project_dd");
        this.program_type_dd = this.dom.find('#program_type_dd');
        this.program_type_option_dd = this.dom.find('#program_type_option_dd');
        this.meeting_type_dd = this.dom.find('#meeting_type_dd');
        this.event_type_dd = this.dom.find('#event_type_dd');
        this.rationale_type_dd = this.dom.find('#rationale_type_dd');
        this.service_type_dd = this.dom.find('#service_type_dd');

        this.account_name = this.dom.find('#account_name');

        this.drawProjectDD();
        this.drawProgramTypeDD();
        this.drawMeetingTypeDD();
        this.drawSESessionDD();
        this.drawServiceTypeDD();

        this.show_event_type_dd = typeof app.appData.layout.meeting_request.show_event_type_dd != 'undefined' && app.appData.layout.meeting_request.show_event_type_dd;
        if(this.show_event_type_dd)
        {
            this.drawEventTypeDD();
        }
    }

    drawProjectDD() {
        this.project_dd.empty().append("<option></option>");
        this.rationale_type_dd.val('').empty();
        this.dom.find('#rationale_dd_container').hide();

        let data = app.format.sortObjectByKey(app.appData.projects, 'project_name', 'sorted_projects' );
        for(let i in data)
        {
            let project = data[i];
            if(project.can_request == 1 && project.active == 1 && project.request_status_id > 0
                && Object.keys(app.appData.projects[project.project_id].program_types).length > 0) {
                this.project_dd.append("<option value='" + project.project_id + "'>" + project.project_name + "</option>");
            }
        }

        this.project_dd.off().on('change', () => {
            this.project_id = this.project_dd.val();
            let project = app.appData.projects[this.project_id];

            this.meeting_type_dd.val('');
            this.event_type_dd.val('');

            this.dom.find('#meeting_type_dd_container').hide();
            this.dom.find('#se_session_container').hide();

            this.rationale_type_dd.val('').empty();
            this.dom.find('#rationale_dd_container').hide();

            this.drawProgramTypeDD(this.project_dd.val());
            this.drawServiceTypeDD(this.project_dd.val());

            if(typeof project !== 'undefined')
            {
                if(project.event_type_id == 0 && this.show_event_type_dd) {
                    this.event_type_id = 0;
                    this.dom.find('#event_type_dd_container').show();
                } else {
                    this.event_type_id = app.appData.projects[this.project_id].event_type_id;
                    this.dom.find('#event_type_dd_container').hide();
                    this.event_type_dd.val(project.event_type_id);
                }
            }

            this.dom.trigger('ProjectUpdated');
        });
    }

    drawProgramTypeDD(project_id) {
        this.program_type_dd.off().empty().append("<option></option>")

        if (project_id)
        {
            let data = [];
            let sorted_pts = Object.entries(
                app.appData.projects[project_id].program_types).sort(
                    (left, right) => left[1].program_name.localeCompare(right[1].program_name)
            );

            for(let i in sorted_pts)
            {
                 let program_type = sorted_pts[i][1];
                 data.push({
                     id: program_type.program_type_id,
                     text: program_type.program_name,
                     ...program_type
                 })
            }

            //console.log(data);
            this.program_type_dd.select2({
                minimumResultsForSearch: -1,
                data: data,
                templateResult: (program_type) => {
                    if(program_type.show_on_sb == "1"  && program_type.active == 'Y')
                    {
                        //console.log(program_type);
                        let lead_time_text = '';
                        let show_lead_time = app.appData.layout.meeting_request.show_lead_time;

                        if(app.appData.layout.meeting_request.useBusinessDays)
                        {
                            lead_time_text = (show_lead_time && parseInt(program_type.lead_time) !== 0)
                                ? ' (' + program_type.lead_time + ' business days lead time)'
                                : '';
                        }
                        else
                        {
                            lead_time_text = (show_lead_time && parseInt(program_type.lead_time) !== 0)
                                ? (program_type.lead_time % 7 == 0
                                    ? ' (' + (program_type.lead_time / 7) + ' weeks lead time)'
                                    : ' (' + program_type.lead_time + ' day lead time)')
                                : '';
                        }

                        let program_type_desc_text = app.appData.layout.meeting_request.show_program_type_description
                            ? ' (' + program_type.description + ')'
                            : '';

                        let out =  $("<span><b>" + program_type.program_name + "</b>" + lead_time_text + program_type_desc_text + "</span>");

                        return out;
                    }
                },
                templateSelection: program_type => {
                    return $('<span>' + program_type.text + '</span>');
                }
            })
        }

        this.program_type_dd.on('change', () => {
            this.program_type_id = this.program_type_dd.val();
            this.drawSESessionDD(this.program_type_id);
            this.rationale_type_dd.val('').empty();
            this.dom.find('#rationale_dd_container').hide();

            let program_type = app.appData.projects[this.project_id].program_types[this.program_type_id];

            let show_av = false;
            let default_time = false;
            let default_timezone = false;
            let default_show_rationale_question = false;
            let show_congress = false;

            if(typeof program_type !== 'undefined')
            {
                this.drawProgramTypeOptionDD(app.appData.programTypes[this.program_type_id].program_key);
                this.updateInjectorExperiences(app.appData.projects[this.project_id].program_types[this.program_type_id].program_type_id);
                let ppConfig = program_type.configuration.sb.meeting_request;

                show_av = !!ppConfig.show_av;
                default_time = ppConfig.start_time_default;
                default_timezone = ppConfig.start_timezone_default;
                show_congress = ppConfig.show_congress;
                default_show_rationale_question = app.appData.defaultProjectProgramTypeConfig.sb.meeting_request.show_rationale_question;

                let show_rationale_question = default_show_rationale_question;

                if(typeof ppConfig.show_rationale_question !== 'undefined'){
                    show_rationale_question = ppConfig.show_rationale_question;
                }

                if(show_rationale_question === true){
                    this.drawRationaleTypeDD();
                }

                this.handleEndDateTime(ppConfig);

                if(typeof ppConfig.program_submission_acknowledgement !== 'undefined'
                    && ppConfig.program_submission_acknowledgement == true) {
                    let acknowledge_modal = $(twig({data: live_program_warning_template}).render({
                        prog_type: program_type.program_key,
                        company_key: app.appData.companyKey
                    }));
                    acknowledge_modal.find('.no-acknowledgement').on('click', () => this.program_type_dd.val('').trigger('change'))

                    let modal = new bootstrap.Modal(acknowledge_modal, {
                        'backdrop': 'static',
                        'keyboard': false
                    });
                    modal.show();
                }

                if( this.filled_out && ppConfig.is_spoke) {
                    this.dom.trigger('SpokeMeetingSelected')
                }

                if(typeof ppConfig.manual_service_type_selection !== 'undefined'
                    && ppConfig.manual_service_type_selection) {
                    this.dom.find('#service_type_dd_container').show();
                } else {
                    this.dom.find('#service_type_dd_container').hide();
                }

                if(typeof ppConfig.show_account_name !== 'undefined'
                    && ppConfig.show_account_name) {
                    this.dom.find('#account_name_container').show();
                } else {
                    this.dom.find('#account_name_container').hide();
                }

                //this is 💩
                if(typeof ppConfig.program_type_attestation !== 'undefined'
                    && ppConfig.program_type_attestation) {
                    let modalData = programtype_attestation_modal;
                    if(typeof app.appData.opco_config.sb.meeting_request.attestation_info !== 'undefined') {
                        modalData = app.appData.opco_config.sb.meeting_request.attestation_info;
                    }
                    let programTypeAttestation = new CustomModal({
                        content: $(twig({data: modalData}).render()),
                        title: 'Attestation Required',
                        static: true,
                        buttons: [
                            {
                                text: 'I attest',
                                close: true,
                                class: 'btn-primary'
                            }, {
                                text: 'I do not attest',
                                //close:true,
                                class: 'btn-danger',
                                id: 'no-attesty',
                                action: () => {
                                    alert('If you cannot attest to the above criteria, the program cannot be submitted.');
                                    this.dom.find('#project_dd').val('').trigger('change');
                                    programTypeAttestation.close();
                                }
                            }
                        ]
                    });

                    programTypeAttestation.open();
                }

            } else {
                show_av = app.appData.defaultProjectProgramTypeConfig.sb.meeting_request.show_av;
                default_time = app.appData.defaultProjectProgramTypeConfig.sb.meeting_request.start_time_default;
                default_timezone = app.appData.defaultProjectProgramTypeConfig.sb.meeting_request.start_timezone_default;
                show_congress = app.appData.defaultProjectProgramTypeConfig.sb.meeting_request.show_congress;
                this.dom.trigger('HideSpokeCard')
            }

            if(show_av) {
                this.dom.trigger('EnableAV');
            } else {
                this.dom.trigger('DisableAV');
            }

            if(show_congress) {
                this.dom.trigger('EnableCongress');
            } else {
                this.dom.trigger('DisableCongress');
            }

            this.dom.trigger('DrawMeetingDates');
            this.dom.trigger('SetDefaultTime',[default_time]);
            this.dom.trigger('SetDefaultTimeZone',[default_timezone]);
            this.dom.trigger('RefreshTopics');
            this.dom.trigger('RefreshRequiredFields', [this.drawMeetingTypeDD.bind(this, this.program_type_id)]);
            this.dom.trigger('DrawVenueTypes');
            this.dom.trigger('RefreshStrategicBudget');

        });
    }

    drawProgramTypeOptionDD(program_key)
    {
        this.program_type_option_dd.val('').empty();

        if(typeof app.appData.programTypeOptions[program_key] != 'undefined')
        {
            this.dom.find('#program_type_option_dd_container').show();
            this.program_type_option_dd.append($('<option></option>'));
            Object.values(app.appData.programTypeOptions[program_key]).forEach(option => {
                this.program_type_option_dd.append($('<option value="'+option.program_type_option_id+'">'+option.name+'</option>'));
            })

            this.program_type_option_dd.off().on('change', () => {
                this.cardFilledOutTriggers();
            });
        }
        else
        {
            this.dom.find('#program_type_option_dd_container').hide();
        }
    }

    updateInjectorExperiences(program_id) {
        if (program_id) {
            $.ajax({
                url: "api/getInjectorsExp",
                method: 'get',
                data: {
                    program_id: program_id
                }
            }).done((response) => {
                if (response.success) {
                    let dropdown = $('#injector_experience_id');
                    dropdown.empty();
                    dropdown.append('<option value=""></option>');
                    response.data.forEach((injector_experience) => {
                        if (injector_experience.active == 1) {
                            dropdown.append("<option value='" + injector_experience.injector_experience_id + "'>"
                                + injector_experience.name + ' - ' + injector_experience.description + "</option>");
                        }
                    });
                }
            });
        }
    }

    drawRationaleTypeDD()
    {
        if(typeof app.appData.programTypeOptions != 'undefined')
        {
            this.dom.find('#rationale_dd_container').show();
            this.rationale_type_dd.append($('<option></option>'));
            app.appData.rationaleTypes.forEach(option => {
                this.rationale_type_dd.append($('<option value="' + option.rationale_id + '">' + option.rationale + '</option>'));
            });

            this.rationale_type_dd.off().on('change', () => {
                this.cardFilledOutTriggers();
            });
        }
        else
        {
            this.dom.find('#rationale_dd_container').hide();
        }
    }

    drawEventTypeDD() {
        this.event_type_dd.append($('<option>'));
        Object.entries(app.appData.eventTypes).forEach((eventType) => {
            let optGroup = $('<optgroup label="'+eventType[0]+'">');
            Object.values(eventType[1]).forEach(eventSubType => {
                optGroup.append($('<option value="'+eventSubType.id+'">'+eventSubType.sub_type+'</option>'))
            })

            this.event_type_dd.append(optGroup);
        })

        this.event_type_dd.off().on('change', () => {
            this.event_type_id = this.event_type_dd.val();
            this.cardFilledOutTriggers()
        })
    }

    drawMeetingTypeDD(program_type_id) {
        this.meeting_type_id = 0;
        if(!this.project_id || (    app.appData.projects[this.project_id]?.meeting_types.length == 0
            && typeof app.appData.programtypeMeetingTypes[program_type_id] == 'undefined'))
        {
            this.dom.find('#meeting_type_dd_container').hide();
            return;
        }

        this.meeting_type_dd.empty().append("<option></option>")

        let option;
        let project_meeting_types = app.appData.projects[this.project_id].meeting_types ?? {};
        let pp_meeting_types = app.appData.programtypeMeetingTypes[program_type_id] ?? {};
        let intersect_meeting_types = {};

        if(Object.values(project_meeting_types).length) {
            if(!Object.values(pp_meeting_types).length) {
                intersect_meeting_types = project_meeting_types;
            } else {
                // do intersect
                Object.entries(project_meeting_types)
                    .filter(entry => typeof pp_meeting_types[entry[0]] !== 'undefined')
                    .forEach(entry => typeof entry.length !== 'undefined' ? intersect_meeting_types[entry[0]] = entry[1] : 0);
            }
        } else {
            intersect_meeting_types = pp_meeting_types;
        }

        let meeting_types = Object.values(intersect_meeting_types).sort((mt1, mt2) => mt1.name.localeCompare(mt2.name));

        Object.entries(meeting_types).forEach(entry => {
            let meeting_type = entry[1];

            option = $("<option></option>")
                .val(meeting_type.id)
                .text(meeting_type.name)
                .attr({
                    "data-out_office": meeting_type.out_office,
                    "data-meal": meeting_type.meal,
                    "data-catering": meeting_type.catering,
                });
            this.meeting_type_dd.append(option);
        });

        this.meeting_type_dd.off().on('change', () => {
            let success = true;
            this.meeting_type_id = this.meeting_type_dd.val();
            this.dom.trigger('DrawVenueTypes');

            if(this.dom.find('#meeting_type_dd option:selected').data('out_office') == '1'
                && app.appData.layout.meeting_request.check_oof_program_limit != 'undefined'
                && app.appData.layout.meeting_request.check_oof_program_limit) {
                $.ajax({
                    url     : "api/meetingrequest/outofficeprogramlimit",
                    method  : 'get',
                    async   : false,
                    data    : {
                        project_id: $('#project_dd').val()
                    }
                }).done((data) => {
                    if(data.success == false){
                        success = false;
                        this.meeting_type_dd.val('').trigger('change');
                        displayNotification({
                            message: "Selected project has reached the maximum out of office programs. Please select another meeting type.",
                            type: false
                        })
                    }
                });
            }

            if(success) {
                let program_type = app.appData.projects[this.project_dd.val()].program_types[this.program_type_dd.val()];
                if( this.filled_out && program_type.configuration.sb.meeting_request.is_spoke) {
                    this.dom.trigger('SpokeMeetingSelected')
                } else {
                    this.dom.trigger('NonSpokeMeetingSelected')
                }

                this.cardFilledOutTriggers();
            }
            this.dom.trigger('RefreshTopics');
        });

        this.cardFilledOutTriggers();
        this.dom.find('#meeting_type_dd_container').show();
    }

    drawServiceTypeDD(project_id) {
        let service_types = app.appData.serviceTypes;
        if(project_id) {
            $.ajax({
                url     : "api/meetingrequest/servicetype",
                method  : 'get',
                async   : false,
                data    : {
                    project_id: project_id
                }
            }).done((data) => {
                if(data.length > 0) {
                    service_types = data;
                }
            });
        }
        this.service_type_dd.empty().append('<option>');
        Object.values(service_types).forEach((service_type) => {
            this.service_type_dd.append("<option value='" + service_type.service_id + "'>"
                + service_type.service_name + (service_type.description ? ' (' + service_type.description + ')' : '')
                + "</option>")
        })

        this.service_type_dd.on('change', () => {
            this.cardFilledOutTriggers();
        })
    }

    drawSESessionDD(program_type_id) {
        if(typeof app.appData.programtypeMeetingTypes[program_type_id] == 'undefined') {
            this.dom.find('#se_session_container').hide();
            return;
        }

        let program_type = app.appData.projects[this.project_dd.val()].program_types[program_type_id];
        let show_session = false;
        if(typeof program_type !== 'undefined') {
            show_session = !!program_type.configuration.sb.meeting_request.show_se_session;
        } else {
            show_session = app.appData.defaultProjectProgramTypeConfig.sb.meeting_request.show_se_session;
        }

        if(show_session) {
            this.dom.find('#se_session_container').show();
        } else {
            this.dom.find('#se_session_container').hide();
        }
    }

    setRequiredFields(fields) {
        super.setRequiredFields(fields);

        if(this.project_dd.val()) {
            this.requiredFields.mf_meeting.program_type_id =
                Object.keys(app.appData.projects[this.project_dd.val()].program_types).length
        }

        if(this.program_type_dd.val()) {
            this.requiredFields.mf_meeting.meeting_type_id = (
                typeof app.appData.programtypeMeetingTypes[this.program_type_dd.val()] !== 'undefined'
                && Object.keys(app.appData.programtypeMeetingTypes[this.program_type_dd.val()]).length > 0
            );
        }
    }

    handleEndDateTime(ppConfig){
        let default_show_end_date = app.appData.defaultProjectProgramTypeConfig.sb.meeting_request.show_end_date;
        let default_show_all_day_meeting_checkbox = app.appData.defaultProjectProgramTypeConfig.sb.meeting_request.show_all_day_meeting_checkbox;
        let show_end_date = default_show_end_date;
        let show_all_day_meeting_checkbox = default_show_all_day_meeting_checkbox;

        if(typeof ppConfig.show_end_date !== 'undefined'){
            show_end_date = ppConfig.show_end_date;
        }

        if(show_end_date === true){
            $('#end_date_container').show();
        }

        if(typeof ppConfig.show_all_day_meeting_checkbox !== 'undefined' && ppConfig.show_all_day_meeting_checkbox == true){
            show_all_day_meeting_checkbox = ppConfig.show_all_day_meeting_checkbox;

            $("#time_dd option:nth-child(2)").prop("selected", "selected").change();

            $("#all_day_meeting").on('change', function(){
                if(this.checked)
                {
                    $("#time_dd option:selected").prop("selected", false);
                    $("#time_dd option:nth-child(2)").prop("selected", "selected").change();
                    $('#time_dd').prop('disabled', 'disabled');

                    $("#end_time_dd option:selected").prop("selected", false);
                    $("#end_time_dd option:nth-last-child(1)").prop("selected", "selected").change();
                    $('#end_time_dd').prop('disabled', 'disabled');
                }else{
                    $("#end_time_dd option:selected").prop("selected", false).change();
                    $('#time_dd').removeAttr('disabled');
                    $("#time_dd option:selected").prop("selected", false).removeAttr('disabled').change();
                    $('#end_time_dd').removeAttr('disabled');
                }
            })
        }

        if(show_all_day_meeting_checkbox === true){
            $('#all_day_meeting_container').show();
        }
    }
}
