import {Card} from "./Card";

var template = require('./congress_card.twig');

export class CongressCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({data: template}).render());

        this.dom.find('#pseudo_spec_proj_id').on('click', () => {
            let projProgTypeConf = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()].configuration;
            let showCongressName = projProgTypeConf.sb.meeting_request.show_congress_name ?? false;

            if(showCongressName && this.dom.find('#pseudo_spec_proj_id').is(':checked')) {
                this.dom.find('#congress_name_container').show();
            } else {
                this.dom.find('#congress_name_container').hide();
            }
        })
    }
}
