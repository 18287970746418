
var template = require('./menu.twig');

export class Menu {
	constructor() {
		let menus = app.appData.menus;
		let menuLists = [];
		$.each(menus, (key,label) => {
			let menu = {
				'label' : label,
				'parentClass' : app.currentPage == key?' nav-expanded nav-active ':'',
				'activeClass' : app.currentPage == key?' nav-link-active ':'',
				'key': key,
				'icon': app.appData.iconMappings[key]
			}
			menuLists.push(menu)
		})
		this.dom = $(twig({ data: template }).render({menuLists: menuLists}));

		this.dom.find("#sidebar_logout a").attr('href', $("#logout a").attr('href'))
		$( ".header .logo img" ).clone().appendTo(this.dom.find("#sidebar_company_logo"))

		$(this.dom).find('.nav-link').on('click', (event) => {
			event.preventDefault()
			let link = $(event.target).closest('.nav-link')
			let page = link.attr('data-page')

			$('.nav-link').removeClass('nav-link-active')
			$('.nav-parent').removeClass('nav-active')

			link.addClass('nav-link-active')
			link.closest('.nav-parent').addClass('nav-active')
			$('#sidebar-left').addClass('hide-in-smaller');
			$("#sidebar_logout a").attr('href', $("#logout a").attr('href'));
			$( ".header .logo img" ).clone().appendTo( "#sidebar-left .logo");
			$('#notification_link').removeClass('notification-link-active').addClass('notification-link');

			app.navigate(page)
		})
	}
}