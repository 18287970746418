let template = require('./modal.add_note.twig');
let note_template = require('./note.twig');

export class ModalAddNote
{
    constructor() {
        this.meeting_id = window.location.toString().split("/").pop();

        this.dom = $(template);
        this.dom.find('#submit_note').on('click', () => this.submitNote());

        this.modal = new bootstrap.Modal(this.dom);
        this.modal.show();
    }

    submitNote() {
        let text = this.dom.find('[name=note]').val();

        $.ajax({
            url: 'api/meeting/' + this.meeting_id + '/note',
            method: 'post',
            data: {
                note: text
            }
        }).then(() => {
            this.modal.hide();

            $("#notes_container").prepend($(twig({data: note_template}).render({
                update_time: moment().format('MM/DD/YYYY h:m A'),
                meeting_note: text
            })));

            displayNotification({
                title: 'Note added successfully',
                success: true
            })
        })
    }
}