import { Page } from '../page.js';

import { ProgramTable } from '../../components/ProgramTable'

var template = require('./speakerdetail.twig');
var template_hcp = require('./hcp.twig');
var template_patient = require('./patient.twig');
var brand_template = require('./branddetail.twig');
var speaker_threshold_template = require('./speaker-threshold.twig');
var local_cap_template = require('./localcap.twig');
var note_template = require('./notes.twig');
var travel_template = require('./travels.twig');
var license_template = require('./licenses.twig');

export class SpeakerDetail extends Page {
    constructor() {
        super();
        this.show_payments = true;
        this.extended_bio = false;
        if(typeof app.appData.layout.speaker_detail.hide_payments == 'undefined') {
            app.appData.layout.speaker_detail.hide_payments = { user_type_ids : [] };
        }

        if(typeof app.appData.layout.speaker_detail.show_add_training == 'undefined') {
            app.appData.layout.speaker_detail.show_add_training = { user_type_ids : [] };
        }
        this.show_add_training = app.appData.layout.speaker_detail.show_add_training.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))


        if(typeof app.appData.layout.speaker_detail.show_list_training == 'undefined') {
            app.appData.layout.speaker_detail.show_list_training = { user_type_ids : [] };
        }
        this.show_list_training = app.appData.layout.speaker_detail.show_list_training.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))

        if(typeof app.appData.layout.speaker_detail.show_sow == 'undefined') {
            app.appData.layout.speaker_detail.show_sow = { user_type_ids : [] };
        }
        this.show_sow = app.appData.layout.speaker_detail.show_sow.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))

        if(typeof app.appData.layout.speaker_detail.show_speaker_agreement == 'undefined') {
            app.appData.layout.speaker_detail.show_speaker_agreement = { user_type_ids : [] };
        }
        this.show_speaker_agreement = app.appData.layout.speaker_detail.show_speaker_agreement.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))

        if(typeof app.appData.layout.speaker_detail.show_ytd_card == 'undefined') {
            app.appData.layout.speaker_detail.show_ytd_card = { user_type_ids : [] };
        }
        this.show_ytd_card = app.appData.layout.speaker_detail.show_ytd_card.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))

        if(app.appData.layout.speaker_detail.hide_payments.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))) {
            this.show_payments = false;
        }

        if(app.appData.layout.speaker_detail.extended_bio) {
            this.extended_bio = app.appData.layout.speaker_detail.extended_bio;
        }
        this.dom = $(twig({ data: template }).render({'speaker': app.appData.labels.speaker}));
    }

    loadSpeakerPage(speakerData)
    {
        if(this.detailDom) {
            this.detailDom.detach()
        }

        speakerData.extended_bio = this.extended_bio ? 1 : 0
        if(speakerData.patient_speaker == '1') {
            speakerData.show_list_training = this.show_list_training;
            this.detailDom = $(twig({ data: template_patient }).render(speakerData));
            this.dom.find('#speaker_details_cards').append(this.detailDom);
            this.getSpeechFiles(speakerData.files);
            if(this.show_list_training) {
                this.loadSpeakerTopics(speakerData.topics);
            }
        } else {
            let detail_opt_in = app.appData.layout.speaker_detail.detail_opt_in ?? '';
            this.detailDom = $(twig({ data: template_hcp }).render({
                'show_add_training': this.show_add_training,
                'show_list_training': this.show_list_training,
                'show_payments': this.show_payments,
                'show_sow': this.show_sow,
                'show_speaker_agreement': this.show_speaker_agreement,
                'show_detail': detail_opt_in.length == 0 || speakerData.opt_ins.includes(detail_opt_in),
                'extended_bio': speakerData.extended_bio,
                'languages': app.appData.layout.speaker_detail.languages ? app.appData.layout.speaker_detail.languages : false,
                'show_social_media': app.appData.layout.speaker_detail.show_social_media ? app.appData.layout.speaker_detail.show_social_media : false,
                'no_bio_text': app.appData.layout.speaker_detail.no_bio_text ? app.appData.layout.speaker_detail.no_bio_text : false,
                'show_region1': speakerData.show_region1,
                'speaker': app.appData.labels.speaker,
                'show_credentials': speakerData.show_credentials,
                'show_contracted_years': app.appData.layout.speaker_detail.show_contracted_years ? app.appData.layout.speaker_detail.show_contracted_years : false,
                'show_ytd_card' : this.show_ytd_card,
                'ytd_honoraria': speakerData.ytd_honoraria,
                'honoraria_total': speakerData.honoraria_total,
                'speaker_medical_affiliation' : app.appData.configs.SPEAKER_MEDICAL_AFFILIATION ?? false
            }));

            this.dom.find('#speaker_details_cards').append(this.detailDom);

            if(this.extended_bio) {
                this.loadSpeakerGovernmentInfo(speakerData.information)
                this.loadSpeakerAssistantInfo(speakerData.information)
                this.loadSpeakerNihInfo(speakerData.information)
                this.loadSpeakerPreferredProductInfo(speakerData.information)
                this.loadSpeakerAssociatedSFInfo(speakerData.tlls)
            }
            this.loadSpeakerInfo(speakerData.information);
            this.loadSpeakerTopics(speakerData.topics);
            this.loadSpeakerExpenses(speakerData.expenses);
            this.loadSpeakerAdditionalPayments(speakerData.payment_options)
            if(this.show_list_training) {
                this.loadTrainedTopicHistory(speakerData.training_histories)
            }

            this.dom.find('#add_trained_topic_container [name=training_date]').datepicker({
                orientation: 'bottom',
                endDate: new Date()
            });

            this.dom.find('#add_trained_topic_container [name=topic_id]').select2();

            this.dom.find('#add_trained_topic_container form').on('submit', event => {
                event.preventDefault();
                this.addTrainedTopic();
                this.ready();
            })
        }


        this.loadSpeakerMeetings();
        this.loadSpeakerFiles(speakerData.files);
        this.loadSpeakerAdditionalPayments(speakerData.payment_options)

        this.loadSpeakerThreshold(speakerData);
        this.loadSpeakerBrands(speakerData);

        this.loadSpeakerLocalCap(speakerData.local_cap)
        this.loadSpeakerTravel(speakerData.travel)
        this.loadSpeakerNotes(speakerData.notes)
        this.loadSpeakerLicenses(speakerData.licenses)

        if(app.appData.layout.speaker_detail.show_social_media)
        {
            this.loadSpeakerSocialMediaData(speakerData.information)
        }
    }


    loadSpeakerTravel(travelInfo)
    {
        travelInfo.no_travel_days = travelInfo.days.filter(day => day.willing).length == 0;
        this.travelDom = $(twig({ data: travel_template }).render(travelInfo));
        this.dom.find('#travel_section').append(this.travelDom);

        if(travelInfo.can_edit) {
            this.dom.find('.badge').on('click', event => {
                let willing = parseInt($(event.target).data('willing'))
                let type = $(event.target).data('type')
                let property = $(event.target).data('property')

                $.ajax({
                    url: "api/speakers/"+this.speaker_id+"/travel_preference",
                    method: 'POST',
                    data: {
                        type: type,
                        property: property,
                        willing: 1 - willing,
                    }
                }).then(data => {
                    if(data.success) {
                        if(willing) {
                            $(event.target).removeClass('badge-success').addClass('badge-danger')
                            $(event.target).data('willing', 0)
                        } else {
                            $(event.target).removeClass('badge-danger').addClass('badge-success')
                            $(event.target).data('willing', 1)
                        }

                        displayNotification({
                            message: "Travel preference updated",
                            success: true
                        })
                    }
                })
            })
        }
    }

    loadSpeakerLocalCap(speakerInfo) {
        if(app.appData.layout.speaker_detail.has_local_cap
            && app.appData.layout.speaker_detail.show_caps.user_type_ids
            && app.appData.layout.speaker_detail.show_caps.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))) {
            this.localCapDom = $(twig({ data: local_cap_template }).render({
                local_cap_rdt: (speakerInfo.rdts && speakerInfo.rdts.length > 0) ? speakerInfo.rdts.join(',') : 'N/A',
                local_meeting_count: speakerInfo.meeting_counts
            }));
            $("#speaker_local_caps").html(this.localCapDom);
        }
    }

    loadSpeakerGovernmentInfo(speakerInfo) {
        $('#government_data').hide();
        if(speakerInfo.showGovernmentData) {
            $('#government_data').show();
            $('#speaker_is_fed').text(speakerInfo.is_fed == '1' ? 'Yes' : 'No')
            $('#speaker_is_fed_consent').text(speakerInfo.is_fed_consent == '1' ? 'Yes' : 'No')
            $('#speaker_is_gvmt').text(speakerInfo.is_gvmt == '1' ? 'Yes' : 'No')
            $('#speaker_is_gvmt_consent').text(speakerInfo.is_gvmt_consent == '1' ? 'Yes' : 'No')
            $('#speaker_is_pt_committee').text(speakerInfo.is_pt_cpmmittee == '1' ? 'Yes' : 'No')
            $('#speaker_is_pt_committee_consent').text(speakerInfo.is_pt_cpmmittee_consent == '1' ? 'Yes' : 'No')
        }
    }

    loadSpeakerAssistantInfo(speakerInfo) {
        $('#assistant_data').hide();
        if(speakerInfo.showAssistantInfo) {
            $('#assistant_data').show();
            $('#speaker_contact_method').text(speakerInfo.contact_method)
            $('#speaker_asst_name').text(speakerInfo.asst_name)
            $('#speaker_asst_phone').text(speakerInfo.asst_phone)
            $('#speaker_asst_email').text(speakerInfo.asst_email)
        }
    }

    loadSpeakerPreferredProductInfo(speakerInfo)
    {
        $('#preferred_product').hide();
        if(speakerInfo.isPreferred) {
            $('#preferred_product').show();
            $('#speaker_preferred').text(speakerInfo.preferred)
        }
    }

    loadSpeakerNihInfo(speakerInfo)
    {
        $('#preferred_product').hide();
        if(speakerInfo.showNih) {
            $('#preferred_product').show();
            $('#speaker_is_nih').text(speakerInfo.is_nih == '1' ? 'Yes' : 'No')
        }
    }

    loadSpeakerAssociatedSFInfo(tlls) {
        if(tlls.length > 0) {
            let text  = [];
            $('#associated_sf').show()
            tlls.forEach((tll) => {
                text.push(tll.name+"("+tll.role_name+")")
            })
            $('#speaker_associated_sf').html(text.join("<br />"))
        }
    }

    loadSpeakerInfo(speakerInfo)
    {
        $.each(speakerInfo, (key, info) => {
            speakerInfo[key] = info ? info: 'N/A';
        })

        let url = $('base').attr('href');
        $("#speaker_pic").attr("src", url+"api/speakers/"+this.speaker_id+"/pic");
        $("#speaker_name").text(speakerInfo.firstname + ' ' + speakerInfo.lastname);
        $("#speaker_degree").html(speakerInfo.degree);
        $("#speaker_credentials").html(speakerInfo.credentials);
        $("#speaker_title").html(speakerInfo.title);
        $("#speaker_affiliation").html(speakerInfo.affiliation);
        $("#speaker_specialty").html(speakerInfo.specialty);
        $("#speaker_region1").html(speakerInfo.region1);
        $("#speaker_address1").text(speakerInfo.address1);
        $("#speaker_address2").text(speakerInfo.city + ', ' + speakerInfo.state + ' ' + speakerInfo.zip);
        $("#speaker_phone").text(speakerInfo.phone);
        $("#speaker_mobile").text(speakerInfo.cell);
        $("#speaker_fax").text(speakerInfo.fax);
        $("#speaker_email").html('<a href="mailto:'+speakerInfo.email+'" > '+ (speakerInfo.email ? speakerInfo.email : "") +'</a>');
        $("#speaker_fellowship").text(speakerInfo.fellowship);
        $("#speaker_residency").text(speakerInfo.residency);
        $("#speaker_branded").text(speakerInfo.branded == "1" ? 'Yes' : 'No');
        $("#speaker_languages").text(speakerInfo.languages);
        $("#speaker_webcam_available").text(speakerInfo.webcam_available == "1" ? 'Yes' : 'No');
        $("#speaker_classification").text(speakerInfo.classification);
        $("#speaker_medical_institution").text(speakerInfo.medical_institution);

        if(app.appData.layout.speaker_detail.show_contracted_years)
        {
            $("#speaker_contracted_years").text(speakerInfo.years_contracted ?? 0);
        }
    }

    loadSpeakerAdditionalPayments(payments)
    {
        let payment = payments[0]
        let text = 'N/A'
        if(payment) {
            text = [payment.name +"<br />",
                            payment.address1+"<br />",
                            payment.city +" "+payment.state + ", "+ payment.zip
            ].join('')
        }

        $("#additional_payment_options").html(text)
    }

    loadSpeakerTopics(topics)
    {
        this.topicsTable = $('#trained_topics').DataTable({
            columns     : this.createTableColumns(app.appData.layout.speaker_detail.trained_topics),
            data        : topics,
            language: {
                emptyTable: "No topics to display",
                search: "",
                searchPlaceholder: "Search...",
                processing: "Loading..."
            },
            dom: '<"row"<"col-md-6"l><"col-md-6"<"row"<"col-10"f><"col-2 text-end"<"float-end export"B>>>>><tr>ip',
            "buttons": [ {
                'extend' : 'csvHtml5',
                'className':'btn btn-success',
                'text': '<i class="fas fa-file-excel"></i>',
                'title': 'Program Trained Topics',
                exportOptions: { orthogonal: 'export' }
            }],
            "drawCallback": function() {
                app.convertDatatableToCard("trained_topics");
            }
        })
        $('.export').attr('title', 'Click to download');
    }

    loadSpeakerExpenses(expenses)
    {
        let tableColumns = this.createTableColumns(app.appData.layout.speaker_detail.expenses);
        if(!this.sortcolumn) {
            this.sortcolumn = [tableColumns.map(function(e) { return e.data; }).indexOf('date_requested'), 'desc'];
        }

        this.expensesTable = $('#expenses-table').DataTable({
            columns     : tableColumns,
            data        : expenses,
            language: {
                emptyTable: "No expenses to display",
                search: "",
                searchPlaceholder: "Search...",
                processing: "Loading..."
            },
            destroy: true,
            order : [this.sortcolumn],
            dom: '<"row"<"col-6"l><"col-6"<"row"<"col-10"f><"col-2 text-end"<"float-end export"B>>>>><tr>ip',
            "buttons": [ {
                'extend' : 'csvHtml5',
                'className':'btn btn-success',
                'text': '<i class="fas fa-file-excel"></i>',
                'title': 'Program Expenses',
                exportOptions: { orthogonal: 'export' }
            }],
            "drawCallback": function() {
                app.convertDatatableToCard("expenses-table");
            }
        })
        $('.export').attr('title', 'Click to download');
    }

    loadTrainedTopicHistory(training_histories)
    {
        let tableColumns = this.createTableColumns(app.appData.layout.speaker_detail.trained_topics_history);
        let sortcolumn = [0, 'desc'];

        this.historyTable = $('#trained_topics_history').DataTable({
            columns  : tableColumns,
            data     : training_histories,
            language: {
                emptyTable: "No topics to display",
                search: "",
                searchPlaceholder: "Search...",
                processing: "Loading..."
            },
            destroy: true,
            order : [sortcolumn],
            dom: '<"row"<"col-6"l><"col-6"<"row"<"col-10"f><"col-2 text-end"<"float-end"B>>>>><tr>ip',
            "buttons": [ {
                'extend' : 'csvHtml5',
                'className':'btn btn-success',
                'text': '<i class="fas fa-file-excel"></i>',
                'title': 'Program Topic Training History',
                exportOptions: { orthogonal: 'export' }
            }],
            "drawCallback": function() {
                app.convertDatatableToCard("trained_topics_history");
            }
        })
    }

    loadSpeakerMeetings()
    {
        this.programList = new ProgramTable(this.dom.find('#speaker_programs'), app.appData.layout.speaker_detail.programs);
        this.programList.setCustomFilterData({speaker_id: this.speaker_id, my_programs_only: false, page: 'speaker_detail'});
        this.programList.initializeDataTable();
    }

    loadSpeakerSocialMediaData(speakerInfo)
    {
        if (speakerInfo.socialMedia) {
            let social_media_data = this.dom.find('#social_media_container');
            let socialMediaHTML = ""
            Object.values(speakerInfo.socialMedia).forEach(social_media => {
                socialMediaHTML += '<div class="col-6">' +
                    '<h4 class="text-capitalize">' + social_media.name + '</h4>' +
                    '<span class="detail-field">' + social_media.username + '</span>' +
                    '</span></div>'
            });
            social_media_data.append(socialMediaHTML);
        }
    }

    loadSpeakerBrands(speakerData)
    {
        let config = app.appData.layout.speaker_detail;
        let brands = []
        speakerData.brands.forEach(( brand) => {

            if(app.appData.layout.speaker_detail.show_contracts.user_type_ids
                && app.appData.layout.speaker_detail.show_contracts.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))
            ) {
                config.show_contract = true;
                brand['contract'] = speakerData.contracts.filter((contract) => { return contract.brand_id == brand.brand_id });
                brand['contract'].forEach(contract => {
                    contract.honoraria = speakerData.honoraria.filter((honoraria) => { return contract.contract_id == honoraria.contract_id });
                    contract.start_date = contract.contract_start_date ? moment(contract.contract_start_date).format('MM-DD-YYYY'): 'N/A';
                    contract.end_date = contract.contract_end_date ? moment(contract.contract_end_date).format('MM-DD-YYYY'): 'N/A';

                });
            }

            if(app.appData.layout.speaker_detail.show_ratings.user_type_ids
                && app.appData.layout.speaker_detail.show_ratings.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))
            ) {
                config.show_rating = true;
                let ratings = speakerData.ratings.filter((rating) => rating.brand_id == brand.brand_id);
                brand['ratings'] = ratings.sort((a, b) => b.year - a.year);
                brand['ratings'].forEach(rating => {
                    if(!rating.rep_rating) {
                        rating.att_rating = rating.overall_rating = parseFloat(rating.att_rating).toFixed(2)
                        rating.rep_rating = '0.00';
                    } else if(!rating.att_rating) {
                        rating.rep_rating = rating.overall_rating = parseFloat(rating.rep_rating).toFixed(2);
                        rating.att_rating = '0.00';
                    } else {
                        rating.overall_rating = (rating.rep_rating + rating.att_rating ) / 2;
                    }
                });
            }

            if(app.appData.layout.speaker_detail.show_caps.user_type_ids
                && app.appData.layout.speaker_detail.show_caps.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))
            ) {
                config.show_cap = true;
                let caps = speakerData.cap.filter((cap) => (cap.product_id == 0 && cap.contract_id == 0 && cap.brand_id == brand.brand_id));
                brand['caps'] = caps.sort((a, b) => b.year - a.year);
            }

            brands.push(brand);
        } )

        this.brandDetailDom = $(twig({ data: brand_template }).render({
            brands: brands,
            config: config,
            is_show_honoraria: app.appData.layout.speaker_detail.show_honoraria.user_type_ids
                && app.appData.layout.speaker_detail.show_honoraria.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))
        }));

        this.brandDetailDom.find('a.nav-link').on('click', event => {
            let id = $(event.target).attr('aria-controls')
            this.brandDetailDom.find('#'+id).addClass('')
        })

        $("#brands").html(this.brandDetailDom);
    }

    loadSpeakerThreshold(speakerData) {

        let cap = speakerData.cap.filter((cap) => (cap.product_id == 0 && cap.contract_id == 0 && cap.brand_id == 0));

        if (cap.length > 0) {
            const currentDate = new Date();
            let annualCaps = {}

            cap.forEach(c => {
                if(c.year === currentDate.getFullYear()) {
                    annualCaps[c.year] = c
                }
            })

            this.speakerThresholdDom = $(twig({ data: speaker_threshold_template }).render({annualCaps: annualCaps}))
            $("#speaker_threshold_section").html(this.speakerThresholdDom);
        }
    }

    loadSpeakerFiles(files)
    {
        let bioDownloadHTML = 'No Bio';
        let cvDownloadHTML = 'No CV';
        let sowDownloadHTML = 'No SOW';
        let speakerAgreementDownloadHTML = 'No Speaker Agreement';

        if(files !== undefined && files !== null && files.length > 0) {
            let cv = files.find(element => element.file_code == 'cv');
            if(cv) {
                cvDownloadHTML = '<button class="downloadFile btn btn-info w-100" data-info="' + cv.file_id + '">' +
                    '<i class="fas fa-file-download fa-lg"></i> ('+moment(cv.update_time).format('MM-DD-YYYY')+')</button>';
            }

            let bio = files.find(element => element.file_code == 'bio');
            if(bio) {
                bioDownloadHTML = '<button class="downloadFile btn btn-info w-100" data-info="' + bio.file_id + '">' +
                    '<i class="fas fa-file-download fa-lg"></i> ('+moment(bio.update_time).format('MM-DD-YYYY')+')</button>';
            }

            let sows = files.filter(element => element.file_code == 'sow');
            if(sows.length) {
                sowDownloadHTML = '';
            }

            for(let i in sows) {
                let sow = sows[i];
                sowDownloadHTML += '<div class="col-12 mb-1"><button class="downloadFile btn btn-info" data-info="' + sow.file_id + '">' +
                    '<i class="fas fa-file-download fa-lg"></i> '+sow.display_name+' ('+moment(sow.update_time).format('MM-DD-YYYY')+')</button></div>';
            }

            let speaker_agreements = files.filter(element => element.file_code == 'speaker_agreement');
            if(speaker_agreements.length) {
                speakerAgreementDownloadHTML = '';
            }

            for(let i in speaker_agreements) {
                let speaker_agreement = speaker_agreements[i];
                speakerAgreementDownloadHTML += '<div class="col-12 mb-1">' +
                    '<button class="downloadFile btn btn-info w-100 text-start" data-info="' + speaker_agreement.file_id + '">' +
                    '<i class="fas fa-file-download fa-lg"></i> '+speaker_agreement.display_name+
                    '</button></div>'
            }
        }

        $('#speaker_cv').empty().append(cvDownloadHTML);
        $('#speaker_bio').empty().append(bioDownloadHTML);
        $('#speaker_sow').empty().append(sowDownloadHTML);
        $('#speaker_agreement').empty().append(speakerAgreementDownloadHTML);
    }

    getSpeechFiles(files) {
        let speeches = files.filter(element => element.file_code == 'speech');
        let speechDownloadHTML = ''

        speeches.sort((a, b) => {
            let aDate = new Date(a['update_time']);
            let bDate = new Date(b['update_time']);
            return aDate - bDate;
        });

        if(speeches && speeches.length > 0)
        {
            let speech = speeches.pop();
            speechDownloadHTML += '<div class="col-12"> <a href="#" class="downloadFile detail-field" data-info="' + speech.file_id + '"> '+ speech.display_name +'</a></div>';
        }
        $('#speaker_speech').html(speechDownloadHTML);
    }

    loadSpeakerNotes(notes) {
        if(app.appData.layout.speaker_detail.show_notes.user_type_ids
            && app.appData.layout.speaker_detail.show_notes.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))
        ) {
            this.dom.find("#notes_section").empty();
            this.dom.find("#notes_section").append($(twig({data: note_template}).render({
                notes: notes,
                speaker: app.appData.labels.speaker
            })));
        }
    }

    loadSpeakerLicenses(licenses) {
        if(app.appData.layout.speaker_detail.show_licenses) {

            this.dom.find("#license_section").empty();
            this.dom.find("#license_section").append($(twig({data: license_template}).render({speaker: app.appData.labels.speaker})));

            this.licensesTable = this.dom.find('#speaker_licenses').DataTable({
                columns: [
                    { data: 'sol_designation' },
                    { data: 'number'},
                    { data: 'state' },
                    {
                        data: 'exp_date',
                        render: (data) => {
                           return data != '0000-00-00' ? moment(data).format('MM-DD-YYYY') : ''
                        }
                    },
                    {
                        data: 'primary',
                        render: (data) =>  {
                            return data == 'Y' ? 'Yes' : 'No'
                        }
                    }

                ],
                data: licenses,
                language: {
                    emptyTable: "No licenses to display",
                    search: "",
                    searchPlaceholder: "Search...",
                    processing: "Loading..."
                },
                dom: '<"row"<"col-md-6"l><"col-md-6"<"row"<"col-md-10 search-box"f><"col-2 text-end"<"float-end export"B>>>>><tr>ip',
                "buttons": [ {
                    'extend' : 'csvHtml5',
                    'className':'btn btn-success',
                    'text': '<i class="fas fa-file-excel"></i>',
                    'title': 'Speaker Licenses',
                    exportOptions: { orthogonal: 'export' }
                }],
                "info": $(window).width() < 768 ? false : true,
                "drawCallback": function() {
                    app.convertDatatableToCard("speaker_licenses");
                }
            })
            $('.export').attr('title', 'Click to download');
        }

    }

    getSpeakerData() {
        this.speaker_id = $("#speaker_id").val() || window.location.toString().split("/").pop();
        return $.ajax({
            url: "api/speakers/"+this.speaker_id,
            dataType: 'json',
            method: 'get'
        });
    }

    createTableColumns(columns) {
        let headers = [];
        let i = 0;
        $.each(columns, (key, value) => {
            let label = value;
            if(typeof value === 'object') {
                label = value.display_name
                key = value.field
                if(value.sorting) this.sortcolumn = [i, value.sorting];
            }
            headers.push({
                "data": key,
                "title": label,
                "column": value,
                render : (data, type) => {
                    if(key == 'training_date') {
                        data = (data == '00/00/0000') ? '<span class="text-danger">Not Trained</span>' : data
                    }

                    if ( value.format == 'formatDate' && type === 'sort') {
                        var d = new Date(data);
                        return d.getTime();
                    } else if(key == 'trained_by_type') {
                        switch(data) {
                            case "tll":
                                data = "TLL 1:1 Speaker Training"
                                break
                            case "virtual":
                                data = "Virtual Group Training"
                                break
                            default:
                                data = "Live Speaker Training Summit"
                                break
                        }

                        return data
                    } else {
                       return app.format.column(data, value)
                   }
              }
            });
            i++;
        });
        return headers;
    }

    addTrainedTopic() {
        $.ajax({
            url: 'api/speakers/' + this.speaker_id + '/training',
            method: 'post',
            async: false,
            data: {
                trained_date: this.dom.find('#add_trained_topic_container [name=training_date]').val(),
                training_type: this.dom.find('#add_trained_topic_container [name=training_type]').val(),
                trained_by: this.dom.find('#add_trained_topic_container [name=trained_by]').val(),
                topic_id: this.dom.find('#add_trained_topic_container [name=topic_id]').val()
            }
        })
    }

    ready()
    {
        this.getSpeakerData().then((data) => {
            this.loadSpeakerPage(data);
            $('.yearDropdown').on('change', () => {
                let bloop = $(this)[0];
                let brandIndex = data.brands.findIndex(element => element.brand_id == bloop.name);
                let yearSelect = bloop.options[bloop.selectedIndex].value;
                let hold = data.brands[brandIndex].allRatings;
                let yearIndex = hold.findIndex(element => element.yearavg == yearSelect);
                let ratingInfo = data.brands[brandIndex].allRatings[yearIndex];

                $('#overall'+bloop.name)[0].innerText = ratingInfo['overallavg'] || '0.00';
                $('#rep'+bloop.name)[0].innerText = ratingInfo['repavg'] || '0.00';
                $('#att'+bloop.name)[0].innerText = ratingInfo['attavg'] || '0.00';
            });

            if(data.training_topics) {
                let training_topics = this.dom.find('#add_trained_topic_container [name=topic_id]');
                Object.values(data.training_topics).forEach(topic => {
                    training_topics.append($('<option value="'+topic.topic_id+'">'+topic.topic_name+'</option>'))
                })
                training_topics.select2();
            }

            let trained_by = this.dom.find('#add_trained_topic_container [name=trained_by]');
            Object.values(app.appData.tlls).forEach(tll => {
                let selected = ''
                if(tll.wwid == app.appData.curPosition.wwid) {
                    selected ='selected'
                }
                trained_by.append($('<option value="'+tll.wwid+'" '+selected+'>'+tll.name+'</option>'))
            })
        }).then(() => {
            $('.downloadFile').on('click', (event) => {
                event.preventDefault();
                let fileId = $(event.target).attr('data-info');
                window.open("api/speakers/"+this.speaker_id+"/download/"+fileId);
            });

        })
    }
}
