import {Calendar} from "./Calendar";

var template = require('./speaker_calendar.twig')

export class SpeakerCalendar
{
    constructor(speaker_id) {
        this.dom = $(twig({ data: template }).render({speaker: app.appData.labels.speaker}));

        $.ajax({
            url: 'api/speakers/' + speaker_id + '/availability'
        }).always((response, status) => {
            let availability = status=='success' ? response : false;
            let calendar = new Calendar({
                url : 'api/speakers/' + speaker_id + '/meetings',
                availability: availability
            });

            this.dom.find('.speaker_calendar_container').append(calendar.dom);
        });

        this.modal = new bootstrap.Modal(this.dom);
        this.modal.show();
    }
}