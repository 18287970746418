import { Page } from './page.js'

let template = require('./changepassword.twig');

export class ChangePassword extends Page{
    constructor() {
        super();
        this.dom = $(template);
        this.errmsg = [];
    }

    submitChange(form) {
        let msg_elem = $(this.dom).find('#msg-container').first();
        msg_elem.html('').addClass('d-none');
        if(this.validate()) {
            $.ajax({
                url: "api/password/change",
                type: "post",
                data: $(form).serialize()
            })  .then(data => {
                this.errmsg = data.message;
                this.displayMessage(data.success, msg_elem);
            })
        } else {
            this.displayMessage(false, msg_elem);
        }

        form.reset();
    }

    displayMessage(status, msg_elem) {
        let addClass = 'alert-danger';
        let removeClass = 'alert-success';
        if(status) {
            addClass = 'alert-success';
            removeClass = 'alert-danger';
        }

        msg_elem.addClass(addClass).removeClass(removeClass).removeClass('d-none').html(this.errmsg.join("<br />"));

    }

    validate() {
        this.errmsg = [];
        let ret = true;
        let pat = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');
        let password = $(this.dom).find('#password').first().val();
        let confirm_password = $(this.dom).find('#confirm_password').first().val();
        let current_password = $(this.dom).find('#current_password').first().val();
        if(!password || !confirm_password || !current_password) {
            ret = false;
            this.errmsg.push("All fields are required");
        }

        if(confirm_password != password) {
            ret = false;
            this.errmsg.push("Password and Confirm password do not match");
        }

        if(!pat.test(password)) {
            ret = false;
            this.errmsg.push("Invalid Password");
        }

        return ret;

    }

    ready() {
        $(this.dom).find('#change-password').first().on('submit', (e) => {
            e.preventDefault();
            this.submitChange(e.target);
        })
    }
}