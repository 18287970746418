import RepEvaluation from "@appdev/evaluations-ui/evaluations/rep";
import template from './program_evaluation.twig';

export default class ProgramEvaluation {
    constructor(meetingData) {
        this.dom = $(twig({ data: template }).render({ topics: meetingData.speaker_info }))

        let mode = meetingData.eval.mode == 'eval_view' ? 'view' : 'default';
        let evaluation = new RepEvaluation(mode);
        evaluation.fetch_evaluation_html(this.dom.find('#evals_container'), meetingData.logistics_info.meeting_id);
    }

    ready() {

        this.modal = new bootstrap.Modal(this.dom);
        this.modal.show();

        $(this.dom).on('submit', 'form[name=evaluation]', (event) => {
            event.preventDefault();
            this.modal.hide();
        });


    }
}
