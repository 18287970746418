var employee_search_template = require('./employee_search_modal.twig');

export class EmployeeSearch
{

    reloadDatatable() {
        this.datatable.ajax.reload();
    }

    setFilterData() {
        this.filterData = {};
        this.dom.find("#form_filter_employee input, #form_filter_employee select").each((index ,elem) => {
            this.filterData[$(elem).attr('name').replace('employee_search_', '')] = $(elem).val();
        })
    }

    constructor() {
        this.dom = $(twig({ data: employee_search_template }).render())

        this.datatable = this.dom.find("#datatable_employee").DataTable({
            "ajax": {
                "url": "api/prospect/searchemployees",
                "data": (d) => {
                    d.data = this.filterData;
                }
            },
            "columns": [
                {data: 'firstname'},
                {data: 'lastname'},
                {data: 'city'},
                {data: 'state'},
                {data: 'email'},
                {
                    data: 'wwid',
                    render: (data, type, row) => {
                        return '<button data-firstname="' + row.firstname + '"' +  'data-lastname="' +  row.lastname + '" data-midname="' +  row.midname + '" data-email="' +  row.email + '" data-territory="' +  row.territory + '" class="btn btn-sm btn-primary select-employee">Select</button>';
                    }
                }
            ],
            "pagingType": "full_numbers",
            "width" : "100%",
            "dom": '<"row"<"col-6"l><"col-6"<"row">>><t>rip',
            "pageLength": 10,
            "scrollCollapse": true,
            "scrollY": '50vh',
            "processing":true,
            "language": {
                "loadingRecords": 'Please wait while we fetch your results.',
                "emptyTable":     "Use filters above to search.",
                "processing": 'Please wait while we fetch your results.'
            }
        })

        this.dom.find('#form_filter_employee').on('submit', (e) => {
            e.preventDefault();
            this.setFilterData();
            this.reloadDatatable()
        })

        this.dom.find("#datatable_employee tbody").on('click', '.select-employee', (e) => {
            let button = $(e.target)
            let employeeData = button.data();
            button.remove();

            $('#ar_first_name').val(employeeData.firstname);
            $('#ar_middle_name').val(employeeData.midname);
            $('#ar_last_name').val(employeeData.lastname);
            $('#ar_email').val(employeeData.email);
            $('#ar_territory').val(employeeData.territory);
            $('.attendee-list-message > span').html('Employee information added to form')
            $('.attendee-list-message').addClass('alert-success').removeClass('alert-danger').show().fadeOut(5000);
        })
    }
}
