import {AttendeeList} from "./AttendeeList";

var validate_remote_template = require('./validate_remote_model.twig');

export class ValidateRemote
{
    constructor(newRemote, row, maxPart) {
        this.row = row;
        this.data = row.data();
        this.remote = newRemote;
        this.message = 'By selecting remote ‘Yes’, you attest that this customer will be remote and not with other attendees, the speaker, or any AbbVie reps.';
        this.button = true;

        if(this.remote != '1' ) {
            if(maxPart) {
                this.message = 'The selected customer has reached their max limit for this presentation content and is not eligible to attend the program as a live participant.';
                this.button = false;
            }
        }

        this.dom = $(twig({ data: validate_remote_template }).render({
            remote: this.remote,
            message: this.message,
            button: this.button,
            add_email: this.data.email == '' && (app.appData.layout.attendance.required_email_for_remote_attendee ?? false)
        }));

        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false});

        this.dom.find('#remote_change_form').on('submit', (e) => {
            e.preventDefault();

            this.saveRemoteChange().done((data) => {
                if(data.success)
                {
                    this.data.remote = this.remote;
                    row.data(this.data).draw('page');
                    this.dom.find('#x_close').click();
                    app.appData.attendeeArray[data.data.attendee_id] = data.data;
                    AttendeeList.updateRow(data.data)
                }
            });
        });


        this.dom.find('#remote_change_cancel').on('click', () => {
            this.dom.find('#x_close').click();
        })

        this.dom.on('hidden.bs.modal',  () => {
            this.modal.dispose();
        })

        this.modal.show();
    }

    saveRemoteChange() {
        return $.ajax({
            url     : 'api/attendance/changeremote',
            method  : 'post',
            data    : {
                meeting_id: $('#meeting_id').val(),
                attendee_id: this.data.attendee_id,
                remote: this.remote,
                email: this.dom.find('#email').val() ?? ''
            }
        })
    }

}