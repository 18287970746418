var questions_modal_template = require('./questions_modal.twig');

export class AttendeeQuestions
{
    constructor(type, meetingPhase, data) {
        this.type = type;
        this.meetingPhase = meetingPhase
        this.attendeeData = data.attendeeData
        this.meetingData = data.meetingData

        this.questions = this.getQuestions()
        this.requiredFields = this.getRequiredFields();
   }

    render()
    {
        this.dom = $(twig({ data: questions_modal_template}).render({
                    name: this.attendeeData.name,
                    type: this.type,
                    questions: this.questions,
                    requiredFields: this.requiredFields,
                    int_company_name: app.appData.supportInfo.int_company_name,
                    attendeeData: this.attendeeData,
                    emailRequired: this.attendeeData.remote && app.appData.layout.attendance.required_email_for_remote_attendee
                }))

        this.dom.find("#attendee_questions_form").on('submit', (event) => {
            event.preventDefault()
            let formData = {}
            $(this.dom.find("#attendee_questions_form").serializeArray()).each((index ,elem) => {
                formData[elem.name] = elem.value
            })

            let data = this.attendeeData;
            data.answers = formData;

            app.pages.attendance.dom.trigger(this.type + 'QuestionsAnswered', [event, data])
            this.modal.hide();
        })

        this.dom.find('#npi_na').on('change', () => {
            if(this.dom.find('#npi_na').prop('checked')) {
                this.dom.find('#npi_num').prop('required', false).prop('disabled', true).removeClass('required is-invalid').val('')
            } else {
                this.dom.find('#npi_num').prop('disabled', false).prop('required', true)
                if(this.dom.find('#npi_num').prop('required')) {
                    this.dom.find('#npi_num').addClass('required is-invalid')
                }
            }
        })

        this.dom.find('#received_article_reprint_yes').on('change', ()=> {
            if(this.dom.find('#received_article_reprint_yes').prop('checked')) {
                this.dom.find('#article_reprint_count').prop('required', true).addClass('required is-invalid')
            }
            this.dom.find('#article_reprint_count-container').show()
        })

        this.dom.find('#received_article_reprint_no').on('change', ()=> {
            if(this.dom.find('#received_article_reprint_no').prop('checked')) {
                this.dom.find('#article_reprint_count').prop('required', false).removeClass('required is-invalid')
            }
            this.dom.find('#article_reprint_count-container').hide()
        })

        this.dom.find('#questions_ship_to').on('change', ()=> {
            let value = this.dom.find('#questions_ship_to').val();
            if(this.requiredFields['ship_to'] && (value !== '')) {
                this.dom.find('#questions_ship_to').prop('required', false).removeClass('required is-invalid')
            }
        })


        this.dom.find('input[name="affiliation"]').on('change', (event) => {
            let affiliation_val = event.target.value;
            if(affiliation_val == "Co-Promotional Rep") {
                $("#emp_upi").removeClass('is-invalid required');
                $("#emp_upi").prop("required",false);
            } else {
                $("#emp_upi").addClass('required');
                $("#emp_upi").prop("required",true);
            }
        });

        this.dom.find('#is_injector_experienced_question').hide();
        this.dom.find('#is_inj_exp_yes').on('click', () => {
            this.showInjectorExperienceQuestion();
            this.dom.find('#att_type').val('Injector');
        })
        this.dom.find('#is_inj_exp_no').on('click', () => {
            this.showInjectorExperienceQuestion();
            this.dom.find('#att_type').val('Observer');
        })

        this.dom.on('hidden.bs.modal',  () => {
            this.modal.dispose();
        })

        this.modal = new bootstrap.Modal(this.dom, {
            backdrop: 'static',
            keyboard: false,
            show: false,
            focus: false
        });

        if(this.dom.find('#received_article_reprint_no').prop('checked')) {
            this.dom.find('#article_reprint_count').prop('required', false).removeClass('required is-invalid')
        }

        return this
    }

    getQuestions() {
        let questions = {}
        if(app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id].configuration.manage_attendance.questions) {
            questions = app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id].configuration.manage_attendance.questions[this.type][this.meetingPhase] || {};

            if(typeof app.appData.serviceTypes[this.meetingData.service_id] == 'undefined' || !parseInt(app.appData.serviceTypes[this.meetingData.service_id].has_meals)) {
                delete questions['had_meal']
            }
        }
        return questions
    }

    getRequiredFields() {
        let requiredFields = {}
        let meetingPhase = this.meetingPhase + "_required"
        if(app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id].configuration.manage_attendance.questions) {
            requiredFields = app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id].configuration.manage_attendance.questions[this.type][meetingPhase] || {};
        }
        
        return requiredFields
    }

    showDialog()
    {
        let noQuestionsAvailable = Object.keys(this.questions).every((question) => !this.questions[question]);
        return !$.isEmptyObject(this.questions) && !noQuestionsAvailable
    }

    showInjectorExperienceQuestion() {
        this.dom.find('#is_injector_experienced_question').show();
        this.dom.find('#injector_experience').attr('required', true);
    }
}
