import { MeetingStatusLegend } from './MeetingStatusLegend'
import {ComplianceMonitor} from "../components/ComplianceMonitor";
import {SimpleSendInvite} from "./SimpleSendInvite";

export class ProgramTable {

    constructor(table_dom, headers) {
        if(!headers) {
            headers = app.appData.layout.programs.list;
        }

        this.programsTable = table_dom;
        this.programTableId = this.programsTable.attr('id');
        this.tableColumns = [];

        headers = Object.values(headers)
        headers.sort((header1, header2) => { header1.display_order - header2.display_order})

        let i = 0;
        $.each(headers, (key, value) => {
            let label = value.name;

            if(value.sorting) this.sortcolumn = [i, value.sorting];

            let width = "auto"
            if(value.col == 'compliance') {
                width = "5%"
            }

            this.tableColumns.push({
                "data": value.col,
                "title": label,
                "width": width,
                render : (data, type, row) => {
                    if(type === 'export' && value.col == 'compliance_action') {
                        let compliance = this.getComplianceText(row.compliance);
                        return compliance.message
                    } else {
                        if(value.col == 'compliance') {
                            let compliance = this.getComplianceText(data);

                            data = compliance.textClass ? '<span data-bs-toggle="tooltip" title="'+compliance.message+'"><i class="fas fa-ribbon '+compliance.textClass+'"></i> </span>' : ''
                            data = '<div class="text-center"> '+ data +'</div>'
                        } else if(value.col == 'compliance_action') {
                            return this.getComplianceAction(row);
                        } else if (value.col == 'speaker') {
                            if(row.speakers_with_ids
                                && row.speakers_with_ids !== null
                                && app.appData.permissions.speakers)
                            {
                                let speaker_ids = row.speakers_with_ids.split(';');
                                let links = [];

                                speaker_ids.forEach(s => {
                                    let speaker = s.split('|');
                                    links.push('<a href="speaker" data-param="' + speaker[0] + '">' + speaker[1] + '</a>');
                                })


                                return links.join(', ');
                            }
                        } else if (value.col == 'manage_attendance') {
                            return `<a href="attendance" data-param="${row.meeting_id}"><i class="far fa-user"></i></a>`
                        } else if (value.col == 'send_invite') {
                            if(row.status_key == 'confirmed') {
                                return `<i class="send-invite fas fa-envelope"></i>`
                            }

                            return ''
                        }

                        if ( value.format == 'formatDate' && type === 'sort') {
                            var d = new Date(data);
                            return d.getTime();
                        } else if(value.format == 'active_flag') {
                            let text = ''
                            if(data =='No') {
                                text = '<span class="text-center"><i class="fas fa-flag text-danger"  border="0" /></span>'
                            }
                            return text
                        } else {
                            return '<span>'+app.format.column(data, value, row)+'</span>'
                        }
                    }
                },
                'visible': value.visible == "1"
            })

            i++;
        });
    }

    bindProgramFilter() {
        app.programFilter.dom.off('submit').on('submit', (e) => {
            let program_id = app.programFilter.dom.find('#program_id').val();
            if(program_id) {
                app.programFilter.dom.find('#program_id').val('');
                app.navigate('meeting', [program_id]);
                return;
            }

            this.filterData = app.programFilter.setFilterData();
            e.preventDefault();
            this.reloadDatatable();
        })
    }

    getComplianceText(data) {
        let textClass = ''
        let message = ''
        if(data.status == 'full') {
            textClass = 'text-success'
            data.speakers.forEach(speaker => {
                message += speaker.speaker +" monitored by "+ speaker.monitored_by +" on "+ speaker.monitored_at +" \r\n"
            })
        } else if(data.status == 'partial') {
            textClass = 'text-primary'
            data.speakers.forEach(speaker => {
                message += speaker.speaker +" monitored by "+ speaker.monitored_by +" on "+ speaker.monitored_at +" \r\n"
            })
        } else if(data.status == 'none') {
            textClass = 'text-danger'
            message = 'Not Monitored'
        }

        return { textClass: textClass, message: message }
    }

    getComplianceAction(row) {
        let complianceMonitor = new ComplianceMonitor();
        complianceMonitor.monitor(row.compliance);

        return complianceMonitor.dom
    }

    initializeDataTable() {
        if(!this.sortcolumn) {
            this.sortcolumn = [this.tableColumns.map(function(e) { return e.data; }).indexOf('meeting_date'), 'asc'];
        }

        $.fn.dataTable.moment( 'MM-DD-YYYY' );
        let that = this;

        this.datatable = this.programsTable.DataTable({
            "ajax": {
                "url": "api/programs/list",
                "type": "POST",
                "dataSrc": json => json,
                "data": (d) => {
                    d.data = this.filterData
                }
            },
            "columns": this.tableColumns,
            "pagingType": "full_numbers",
            "width" : "100%",
            "dom": '<"row"<"col-md-6"l><"col-md-6"<"row"<"col-md-9 search-box"f><"col-md-3 dt-buttons text-end"<"w-md-50 float-start export"><"w-md-50 float-start text-start ps-1 status-legend">>>>><tr>ip',
            "processing": true,
            "language": { search: "",
                searchPlaceholder: 'Search...',
                emptyTable: 'No meetings available. Please change filter and search again. ',
                processing: 'Loading...'
            },
            "order": [this.sortcolumn],
            "paging": ($(window).width() < 768 && that.programTableId === "upcoming_programs") ? false : true,
            "info": $(window).width() < 768 ? false : true,
            "destroy": true,
            "drawCallback": function() {
                app.convertDatatableToCard(that.programTableId);
            }
        });

        this.programsTable.find('tbody').off('click', 'tr span').on('click', 'tr span', (event) => {
            let data = this.programsTable.DataTable().row($(event.target).parents('tr')).data();
            app.navigate('meeting', [data.meeting_id]);
        });

        this.programsTable.find('tbody').off('click', 'a').on('click', 'a', (event) => {
            event.preventDefault();

            app.navigate($(event.currentTarget).attr('href'), [$(event.currentTarget).data('param')]);
        });

        this.programsTable.find('tbody').off('click', 'tr button.mcomply').on('click', 'tr button.mcomply', (elem) => {
            elem.stopPropagation();
            let row = $(elem.target).parents('tr')
            let data = this.programsTable.DataTable().row(row).data();

            let complianceMonitor = new ComplianceMonitor(data.compliance);
            data.speaker_id = this.filterData.speaker_id
            complianceMonitor.handleMarkReview(data.meeting_id, {'speaker_id': this.filterData.speaker_id}).then( compliance => {
                data.compliance = compliance
                this.programsTable.DataTable().row(row).data(data).draw()
            })
        });

        this.programsTable.find('tbody').off('click', 'tr .view_observation').on('click', 'tr .view_observation', (elem) => {
            elem.stopPropagation();
            let row = $(elem.target).parents('tr')
            let data = this.programsTable.DataTable().row(row).data();

            let complianceMonitor = new ComplianceMonitor();
            data.speaker_id = this.filterData.speaker_id
            complianceMonitor.handleRecordObservation(data).then( compliance => {
                data.compliance = compliance
                this.programsTable.DataTable().row(row).data(data).draw()
            })
        });

        this.programsTable.find('tbody').off('click', 'tr .send-invite').on('click', 'tr .send-invite', (event) => {
            event.stopPropagation();
            event.preventDefault();

            let row = $(event.target).parents('tr')
            let data = this.programsTable.DataTable().row(row).data();

            new SimpleSendInvite(data.meeting_id)
        })

        let wrapper_name = this.programsTable.attr('id') + '_wrapper';
        $('#' + wrapper_name + ' .export').html('<button class="dt-button btn btn-success"> <span><i class="fas fa-file-excel"></i> </button>');
        $('#' + wrapper_name + ' .export').on('click', () => {
            window.open('api/programs/list/export?'+ $.param({"data": this.filterData}));
        })

        $('.export').attr('title', 'Click to download');

        $('.status-legend').first().html('<button class="dt-button btn btn-dark"> <span><i class="fas fa-info-circle"></i> </button>');

        $('.status-legend').first().on('click', () => {
            let meetingStatusLegend = new MeetingStatusLegend();
            let modal = new bootstrap.Modal(meetingStatusLegend.dom);
            modal.show();
        })
        $('.status-legend').attr('title', 'Click to view Status Legend');
    }

    reloadDatatable() {
        this.datatable.ajax.reload();
    }

    setCustomFilterData(custom_filters) {
        this.filterData = custom_filters;
    }

}
