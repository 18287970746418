let template = require('./modal.reason_for_change.twig');

export class ModalReasonForChange
{
    constructor(data) {
        this.request_id = window.location.toString().split("/").pop();
        this.dom = $(template);

        let message = '';
        let new_program_type_ids = [];
        let new_go_live_date = '';
        if (data.new_go_live_date) {
            new_go_live_date = data.new_go_live_date;
            message += "Go-Live date changed from " + data.go_live_date + " to " + data.new_go_live_date + ". ";
        }
        if (data.new_program_type_ids) {
            new_program_type_ids = data.new_program_type_ids;
            let program_type_names = [];
            data.program_type_ids.forEach(e => program_type_names.push(app.appData.programTypes[e].program_name));
            let new_program_type_names = [];
            data.new_program_type_ids.forEach(e => new_program_type_names.push(app.appData.programTypes[e].program_name));
            data.new_program_type_names = new_program_type_names;
            message += "Program Type changed from " + program_type_names.join(', ') + " to " + data.new_program_type_names.join(', ') + ". ";
        }

        this.dom = $(twig({data: template}).render(data));

        this.dom.find('#reason_for_change').on('change, input', (e) => {
            let modal = $(e.target).closest('div.modal-content');
            if (modal.find('#reason_for_change').val().length > 0) {
                modal.find('#submit_change').prop("disabled", false);
            } else {
                modal.find('#submit_change').prop("disabled", true);
            }
        });

        this.dom.find('#cancel_change').on('click', () => {
            $('.action-cancel').click();
        });

        this.dom.find('#submit_change').on('click', () => this.submitChange(new_program_type_ids, new_go_live_date, message));

        let modal = new bootstrap.Modal(this.dom);
        modal.show();
    }

    submitChange(new_program_type_ids, new_go_live_date, message) {
        message += "On " + moment().format('MM/DD/YYYY h:m A') + ". ";
        message += "By " + app.appData.current_user.user_id + ". ";
        message += "Reason:  " + this.dom.find('#reason_for_change').val() + ". ";
        $.ajax({
            url: 'api/marketingcontent/update',
            method: 'post',
            data: {
                request_id: this.request_id,
                program_type_ids: new_program_type_ids,
                go_live_date: new_go_live_date,
                message: message,
                user_id: app.appData.current_user.user_id
            },
            success: (ajax_data) => {
                if(ajax_data.success) {
                    this.dom.find('.action-message').text(ajax_data.message).addClass('text-success');
                    setTimeout(() => window.location.reload(), 300);
                } else {
                    this.dom.find('.action-message').text('Error: ' + ajax_data.message).addClass('text-danger');
                }
            }
        })
    }
}