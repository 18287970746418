import FilterNode from "./FilterNode";
export default class FilterStack {
    dom = {};
    keys = [];
    stack = new Map();
    constructor(props = {}) {
        const { dom, keys } = props;
        this.dom = dom;
        this.keys = keys;
        this.buildStack()
        window.stack = this.stack;
    }

    getNode(name) {
        return this.stack.get(name);
    }

    buildStack() {
        this.keys.forEach(key => {
            const filterNodeProps = {
                name: key,
                node: this.dom.find('#' + key),
                dependencies: [...this.stack.keys()]
            };
            this.stack.set(key, new FilterNode(filterNodeProps))
        })
    }

    refresh() {
        [...this.stack.keys()].forEach(key => {
            const filterNode = this.stack.get(key);
            this.toggleFilterNode(filterNode);
        })
    }

    toggleFilterNode(filterNode) {
        if(filterNode.dependencies.length > 0) {
            filterNode.dependencies.forEach(depKey => {
                let depFilterNode = this.stack.get(depKey)
                const nodeValue = depFilterNode.node.find('select').val() ?? '';
                //reset/hide/empty only if the value is empty AND the dependant filter is being displayed
                if ((nodeValue == '' || nodeValue?.length === 0) && depFilterNode.node.lengh > 0) {
                    filterNode.resetValues();
                    filterNode.node.hide();
                    filterNode.node.empty();

                    return;
                }
                filterNode.node.show();
            });
        }
    }
}
