var send_comm_template = require('./send_communication.twig');

export class SendCommunication
{
    constructor(config, recipients, isClosing) {
        this.config = config
        this.recipients = recipients
        this.config.has_bcc_me = app.appData.layout.attendance.send_communications.has_bcc_me || isClosing
        this.communication = ''

        this.dom = $(twig({ data: send_comm_template }).render({
            config: this.config,
            recipients: this.recipients,
            cancelText: isClosing ? 'Skip' : 'Cancel'
        }))

        // remove a recipient
        this.dom.find('.remove-recipient').on('click', (event) => {
            let elem = $(event.target).closest('.recipient')

            this.removeRecipient(elem.data('recipientIndex'))
            elem.remove()
        });

        this.dom.find('.communication').click('click', (event) => {
            this.communication = this.config.communications[$(event.target).data('communicationIndex')]
            let url = 'api/attendance/' + this.config.meetingId + '/communications/preview?media_id=' + this.communication.media_id

            this.dom.find('.communication').removeClass('active')
            $(event.target).addClass('active')

            this.dom.find('#subject').html(this.communication.subject)
            this.displayAttachmentList(this.dom);

            this.dom.find('.communication-preview iframe').attr('src', url)
        })

        this.dom.find('.send-btn').on('click', () => {
            this.send()
        })

        //Select the first permitted communication
        this.dom.find('.communication').not('.hide').first().trigger('click')

        this.modal = new bootstrap.Modal(this.dom);
        this.modal.show();
    }

    removeRecipient(index) {
        this.recipients.splice(index)
    }

    send() {
        let mediaTypeName = this.config.communications[this.dom.find(".communication.active").data('communicationIndex')]['media_type_name']
        let recipientData = [];

        this.dom.find('.recipient').each((index, element) => {
            element = $(element)
            recipientData.push({
                'attendee_id': this.recipients[element.data('recipientIndex')]['attendee_id'],
                'email': element.find('.recipient-email :selected').val()
            })
        })

        return $.ajax({
            url: 'api/attendance/' + this.config.meetingId + '/communications/send',
            data: {
                recipients: recipientData,
                media_type_name: mediaTypeName,
                bcc_user: this.dom.find("#bcc_user").is(':checked') ? 1 : 0
            },
            type: 'POST',
            dataType: 'JSON'
        }).done((response) => {
            if(response.success ) {
                this.dom.trigger('emailsSent', [event, response])
                this.modal.hide();
            }
        })
    }

    displayAttachmentList(element){
        let content = '';
        return $.ajax({
            url: 'api/attendance/' + this.config.meetingId + '/communications/getattachments',
            data: {
                media_id: this.communication.media_id
            },
            type: 'GET',
            dataType: 'JSON'
        }).done((response) => {
            if(response.length){
                response.forEach((attachment) => {
                    content += '<span data-type= "' + attachment.attachment_type + '" data-entity-id= "' + attachment.media_id + '"data-file-id = "' + attachment.file_id + "" + '" class="email_attachment">';
                    content += attachment.filename + '</span>';
                })

                $(element).find("#attachment_list").html(content);
                this.dom.find(".email_attachment").on("click", (event) => {
                    this.viewAttachment(event);
                });
            }

        })
    }

    viewAttachment(event){
        let company_key = app.appData.companyKey;
        event.preventDefault();
        let data = $(event.target).data();
        let media_id = data.entityId;
        let file_id = data.fileId;

        let url = '';
        switch(data.type)
        {
            case 'led':
                url = "view_media.php?disposition_type=inline&company_key=" + company_key + "&media_id=" + media_id + "&meeting_id=" + this.config.meetingId;
                break;
            case 'meeting':
                url = 'view_media.php?disposition_type=inline&file_id=' + file_id;
                break;
        }

        if(url){
            window.open(url, '_blank');
        }

    }

}
