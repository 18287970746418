import { CustomModal } from "../../components/customModal";
import { DenyAttendeeEval } from "./denyAttendeeEval";
import { AttendeeEvaluation } from "./attendeeEvaluation";

import template from './patientModelInformation.twig';
import survey_container_template from './surveyContainer.twig';
import eval_template from '../../../../node_modules/@appdev/evaluations-ui/evaluations/evaluation.twig'

export class PatientModelInformation{

    constructor(meetingId, attendeeId) {
        this.meetingId = meetingId;
        this.attendeeId = attendeeId;
        this.answerSets = '';
        this.pre_data = '';
        this.post_data = '';
    }

    loadPatientModelInformation(evalType, placeholder){
        return $.ajax({
            url: "api/prepostwork/meeting/"  + this.meetingId + "/patientmodelinfo",
            type: "GET",
            data: {
                attendee_id: this.attendeeId,
                eval_type: evalType
            },
            success: (data) => {
                let emptyMessage = '';

                if(data.question_sets_data.length === 0 && evalType === "post_meeting_pm_info"){
                    emptyMessage = "No Post-Program Patient Model Information is available for this attendee."
                }else if(data.question_sets_data.length === 0 && evalType === "pre_meeting_pm_info"){
                    emptyMessage = "No Pre-Program Patient Model Information is available for this attendee."
                }

                if(data['question_sets_data'] && data['question_sets_data'].length){
                    data['question_sets_data'].forEach(function (question_set, index) {
                        question_set.name =  question_set.name + " - " + (index + 1);
                    });
                }

                this.evalDom = $(twig({ data: survey_container_template }).render({
                    surveyEvalData: data,
                    emptyMessage: emptyMessage,
                    evalType: evalType,
                    hasApprovalProcess: true,
                    hasCRUOperations: true
                }));

                $(placeholder).find(".accordion-wrap").html(this.evalDom);
            }
        })
    }

    approvePatientModelInfo(eval_id, answer_set_id){
        $.ajax({
            url: "api/prepostwork/meeting/"  + this.meetingId + "/approve",
            type: "GET",
            async: false,
            data: {
                attendee_id: this.attendeeId,
                eval_id: eval_id,
                answer_set_id: answer_set_id
            },
            success: () => {
                let currentAccordion = $("input[name='answer_set_id'][value="+ answer_set_id +"]").closest(".accordion_body");

                $(currentAccordion).find(".approval_message").html("Patient Model Information Approved").removeClass("hidden").show().delay(50000).fadeOut();

                let form = $(currentAccordion).find("form[name='evaluation']");
                setApprovalStatus(form, eval_id, answer_set_id);
            }
        });
    }

    addNewPatientModel(evalType){
        $.ajax({
            url: "api/prepostwork/meeting/"  + this.meetingId + "/createpatientmodelinfo",
            type: "GET",
            data: {
                attendee_id: this.attendeeId,
                evalType: evalType
            },
            success: (data) => {
                let html = $(twig({data: eval_template}).render(data)).html();
                console.log(html);
                this.evalModal = new AttendeeEvaluation(html);
                this.evalModal.ready();
                let modal = new bootstrap.Modal(this.evalModal.dom);
                modal.show();
            }
        });
    }

    loadPreProgramPatientModel() {
        let dfd = $.Deferred();

        this.loadPatientModelInformation('pre_meeting_pm_info', '#pre_program_patient_model_information').done((data) => {
            this.pre_data = data;
            this.populateAnswers('#pre_program_patient_model_information', this.pre_data);
            dfd.resolve();
        });

        return dfd.promise();
    }


    loadPostProgramPatientModel() {
        var dfd = $.Deferred();

        this.loadPatientModelInformation('post_meeting_pm_info', '#post_program_patient_model_information').done((data) => {
            this.post_data = data;
            this.populateAnswers('#post_program_patient_model_information', this.post_data);
            dfd.resolve();
        });

        return dfd.promise();
    }

    ready(){
        var that = this;
        this.dom = $(twig({ data: template }).render({
        }));

        $('#patient_model_information').html(this.dom);

        $.when(this.loadPreProgramPatientModel(), this.loadPostProgramPatientModel()).done(function () {
            that.handleEvalActions();
        });

        this.dom.find('.toggler').on('click', function(){
            let table_id = $(this).closest('.table_wrapper').attr('id');
            $('.inverted_header').find($('[data-controller-for=' + table_id + ']')).toggle();
            $('#' + table_id).toggle();
        });

        this.dom.find('.inverted_header_toggler').on('click', function(){
            let table_id = $(this).parent().attr('data-controller-for');
            $('#' + table_id).toggle();
            $(this).parent().toggle();
        });

        this.dom.find('.action_btn_wrap a').click(function(e){
            e.preventDefault();
        });

    }

    populateAnswers(placeholder, data){
        $(placeholder).find("form[name=evaluation]").each((i, el) => {
            let answers;
            answers = data.data[i].answers;

            if(answers === undefined || answers.length <= 0)
            {
                return -1;
            }

            for(let i = 0; i < answers.length; i++)
            {
                if (parseInt(answers[i].choice_id) > 0)
                {   let answer_value = answers[i].choice_id;
                    $(el).find("input[name^="+answers[i].question_id+"]").each(function(i, element){
                        if($(element).val() === answer_value){
                            $(element).attr("checked","checked");
                        }
                    });
                }
                else if (answers[i].value != null)
                {
                    $(el).find("input[name="+answers[i].question_id+"][value="+answers[i].value+"]").attr("checked","checked");
                }
                else if (answers[i].text != null)
                {
                    $(el).find("textarea[name="+answers[i].question_id+"]").html(answers[i].text);
                    $(el).find("input[name="+answers[i].question_id+"]").val(answers[i].text);
                }else if(answers[i].file_id != null){
                    // $(el).find("input[name="+answers[i].question_id+"]").hide();
                    this.populatePhoto(answers[i].file_id, $(el).find("input[name="+answers[i].question_id+"]"));
                    $(el).find("label[for="+answers[i].question_id+"]").html(answers[i].display_name).hide();
                }

            }

            $(el).find('.question_container').each((i, element) => {
                let dependencies = $(element).data('dependencies');
                let showQuestion = true;

                for(let i in dependencies)
                {
                    showQuestion = false;
                    let dependency = dependencies[i];

                    if ($(el).find('.question_choice input[type=checkbox]') &&
                        $(el).find('.question_choice input[name="' + dependency.question_id + '[]"' + '][value=' + dependency.choice_id + ']').is(':checked')) {
                        showQuestion = true;
                        break;
                    }

                    if ($(el).find('.question_choice input[type=radio]') &&
                        $(el).find('.question_choice input[name=' + dependency.question_id + '][value=' + dependency.choice_id + ']').is(':checked')) {
                        showQuestion = true;
                        break;
                    }
                }

                if(showQuestion)
                {
                    $(element).show();
                }
                else
                {
                    $(element).find('input,select,textarea').each((i,el) => {
                        if($(el).prop('type') === 'textarea'){
                            $(el).val('');
                        } else {
                            $(el).prop('checked', false);
                        }
                    })
                    $(element).hide();
                }
            });

            $(placeholder).find('.question_choice input').on('change', (event) => {
                let thisForm = $(event.target).closest('form');

                if($(event.target).is(':checked') || $(event.target).attr('type') === 'checkbox') {
                    thisForm.find('.Question').each((i, element) => {
                        let dependencies = $(element).data('dependencies');
                        let showQuestion = false;

                        for (let i in dependencies) {
                            let dependency = dependencies[i];

                            if (thisForm.find('.question_choice input[type=checkbox]') &&
                                thisForm.find('.question_choice input[name="' + dependency.question_id + '[]"' + '][value=' + dependency.choice_id + ']').is(':checked')) {
                                showQuestion = true;
                                break;
                            }

                            if (thisForm.find('.question_choice input[type=radio]') &&
                                thisForm.find('.question_choice input[name=' + dependency.question_id + '][value=' + dependency.choice_id + ']').is(':checked')) {
                                showQuestion = true;
                                break;
                            }
                        }

                        if (showQuestion) {
                            $(element).show();
                        } else {
                            $(element).hide();
                            $(element).find('input,select,textarea').html('');
                        }
                    })
                }
            });

            $('.submit_container').hide();
            $('form').find("input").attr("disabled","disabled");
            $('form').find("textarea").attr("disabled","disabled");

        });
    }

    populatePhoto(file_id, element){
        $.ajax({
            url: "api/prepostwork/meeting/"  + this.meetingId + "/modelphoto",
            type: "GET",
            async: false,
            data: {
                attendee_id: this.attendeeId,
                file_id: file_id
            },
            success: (data) => {
                let photo =  '<a class="photo_anchor">' +
                                        '<img class="photo" height="100px" width="100px"/>' +
                                    '</a>';

                $(".photo_anchor").attr("href", data.src).attr("download", data.file_name).removeClass("photo_anchor");
                $(".photo").attr("src", data.src).attr("alt", data.file_name).removeClass("photo");

                element.parent(".question_upload_file_container").append(
                    photo
                );
            }
        });
    }

    getNewPatientModelForm(evalType){
        $.ajax({
            url: "api/prepostwork/meeting/"  + this.meetingId + "/createpatientmodelinfo",
            type: "GET",
            async: false,
            data: {
                attendee_id: this.attendeeId,
                evalType: evalType
            },
            success: (data) => {
                this.evalDom = $(twig({ data: survey_container_template }).render({
                    surveyEvalData: data,
                }));
            }
        });
    }

    openDenialModal(eval_id, answer_set_id) {
        new DenyAttendeeEval(this.meetingId, this.attendeeId, eval_id, answer_set_id);
    }

    highlight_missing_required(form){
        $(form).find(".missing_required").removeClass("missing_required");

        $(form).find(".question_container_range").each(
            function(i,v){
                if ($(v).find("input[type=radio]:checked").length == 0 &&
                    $(v).hasClass("is_mandatory") &&
                    $(v).is(":visible")){
                    $(v).addClass("missing_required");
                }
            }
        );

        $(form).find(".question_container_choice").each(
            function(i,v) {
                let element = $(this);
                if (element.hasClass('qt_Multiple')) {
                    let checked = false;
                    if(element.hasClass('is_mandatory')){
                        $(element).find(".question_choice").each(function(i,v){
                            if($(v).find("input[type='checkbox']:checked").length > 0){
                                checked = true;
                            }
                        });

                        if(checked === false){
                            element.addClass('missing_required');
                        }else{
                            element.removeClass('missing_required');
                        }
                    }
                } else {
                    if ($(v).find("input[type=radio]:checked").length == 0 &&
                        $(v).hasClass("is_mandatory") &&
                        $(v).is(":visible")) {
                        $(element).addClass("missing_required");
                    }
                }
            }
        );

        $(form).find(".qt_Open").each(
            function(i,v){
                if (!$(v).find(".qt_Open").find("textarea").val() &&
                    $(v).find(".qt_Open").hasClass("is_mandatory") &&
                    $(v).find(".qt_Open").is(":visible")){
                    $(v).find(".qt_Open").addClass("missing_required");
                }
            }
        );

        $(form).find(".question_inputbox_container").each(function(i,v) {
            if ($(v).find('input').val() == 0 &&
                $(v).hasClass("is_mandatory") ) {
                $(v).parent(".question_container").addClass("missing_required");
            }
        });

        $(form).find(".question_upload_file_container").each(function(i,v) {
            if ($(v).find('.custom-file-input')[0].files.length == 0 &&
                $(v).hasClass("is_mandatory") ) {
                $(v).parent(".question_container").addClass("missing_required");
            }
        });
    }

    submit_evaluation(event, form){
        event.preventDefault();
        this.highlight_missing_required(form);
        $(form).find('.evaluation_waiting_message').show();

        let form_data = new FormData();
        $(form).find('input[type="file"]').each(function( index, element ) {
            form_data.append(element.id, element.files[0])
        });

        let other_data = $(form).serializeArray();
        $.each(other_data, function(key, input){
            form_data.append(input.name, input.value);
        });


        $.ajax({
            url	: "api/evaluation",
            type: "POST",
            data: form_data,
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            success	: (data) => {
                $(form).find('.evaluation_waiting_message').css('display', 'none');
                if (data.success)
                {
                    $(form).find(".missing_required_message").hide();
                    $(form).find(".evaluation_success_message").show();
                    $('.eval-complete').show();
                    $('#eval_button').removeClass('btn-dark').addClass('btn-primary');
                }
                else
                {
                    $(form).find(".evaluation_success_message").hide();
                    $(form).find(".missing_required_message").show();
                }
            }
        });
    }

    handleEvalActions(){
        $('.approve_btn').on('click', (e) => {
            let element_id = $(e.target).closest(".accordion_body").attr('id');
            let form = $("form[id=" + element_id + "]");

            let eval_id = form.find("input[name='question_set_id']").val();
            let answer_set_id = form.find("input[name='answer_set_id']").val();

            this.approvePatientModelInfo(eval_id, answer_set_id);
        });

        $('.deny_btn').on('click', (e) => {
            let element_id = $(e.target).closest(".accordion_body").attr('id');
            let form = $("form[id=" + element_id + "]");

            let eval_id = form.find("input[name='question_set_id']").val();
            let answer_set_id = form.find("input[name='answer_set_id']").val();

            this.invalidMod = new CustomModal({
                title: "<p style='color: red;'>Alert:</p>",
                content: "<p style='color: red;'>Please notify your HCP Attendee that his/her patient model selection has not been approved and that an alternate patient model should be selected.</p>",
                buttons: [
                    {
                        text: "Confirm",
                        id: 'confirmbtn',
                        close:true,
                        class: 'btn-danger',
                        action: () => {
                            this.openDenialModal(eval_id, answer_set_id);
                        }
                    }
                ]
            });


        });

        $('.edit_btn').on('click', (e) => {
            let element_id = $(e.target).closest(".accordion_body").attr('id');
            let form = $("form[id=" + element_id + "]");

            form.find('.submit_container').show();
            form.find("input").removeAttr("disabled");
            form.find("textarea").removeAttr("disabled");
        });

        this.dom.find('.add_new_patient_model_btn').on('click', (e) => {
            let evalType = e.target.dataset.eval_type;
            this.addNewPatientModel(evalType);
        });

        $("form[name='evaluation']").each(function() {
            let thisForm = $(this);
            let eval_id = thisForm.find("input[name='question_set_id']").val();
            let answer_set_id = thisForm.find("input[name='answer_set_id']").val();

            setApprovalStatus(thisForm, eval_id, answer_set_id);
        });

        $("form[name=evaluation]").submit((event) => {
            let thisForm = event.target;
            this.submit_evaluation(event, thisForm)
        });
    }
}

function  setApprovalStatus(form, evalId, answerSetId){
    $.ajax({
        url: "api/prepostwork/getapprovalstatus",
        type: "GET",
        async: false,
        data: {
            attendeeId: $("#attendees").val(),
            evalId: evalId,
            answerSetId: answerSetId
        },
        success: (data) => {
            if(data && data.approved === '1'){
                form.closest(".accordion_header").find(".approved").show();
                form.closest(".accordion_header").find(".denied").hide();
                form.closest(".accordion_header").find(".pending").hide();
                form.closest(".accordion_body").find(".action_btn_wrap").hide();
            }else if(data && data.denied === '1'){
                form.closest(".accordion_header").find(".denied").show();
                form.closest(".accordion_header").find(".approved").hide();
                form.closest(".accordion_header").find(".pending").hide();
                form.closest(".accordion_body").find(".action_btn_wrap").hide();
            }else{
                form.closest(".accordion_header").find(".pending").show();
                form.closest(".accordion_header").find(".denied").hide();
                form.closest(".accordion_header").find(".approved").hide();
            }
        }
    });
}
