import {Page} from "../page";

let template = require('./feedback.twig');

export class Feedback extends Page
{
    constructor() {
        super();
        this.dom = $(twig({ data: template }).render())

        let category_dd = this.dom.find('[name=feedback_category]');
        let meeting_id_input = this.dom.find('[name=meeting_id]');

        Object.values(app.appData.feedbackCategories).forEach(
            category => category_dd.append('<option value="'+category.category_id+'">'+category.name+'</option>'));

        category_dd.on('change', () => {
            let category_id = category_dd.val();
            Object.values(app.appData.feedbackCategories).forEach(
                category => {
                    if (category.category_id == category_id) {
                        if (category.meeting_related == 1) {
                            meeting_id_input.attr('required', 'required').removeAttr('disabled');
                        } else {
                            meeting_id_input.removeAttr('required').attr('disabled', 'disabled');
                        }
                    }
                }
            ); // end loop
        })

        this.dom.find('[type=reset]').on('click', () => {
            this.dom.find('input, select, textarea').val('');
        })

        this.dom.find('form').on('submit', event => {
            event.preventDefault();

            $.ajax({
                url: 'api/feedback',
                type: 'post',
                data: {
                    feedback_category : this.dom.find('[name=feedback_category]').val(),
                    feedback_text     : this.dom.find('[name=feedback_text]').val(),
                    meeting_id        : this.dom.find('[name=meeting_id]').val(),
                }
            }).then(data => {
                displayNotification({
                    title: data.success ? 'Feedback Submitted!' : 'Something went wrong',
                    success: data.success
                });

                let feedback_id = data.feedback_id;
                this.dropzone.options.url = $('base').attr('href') + 'api/feedback/' + feedback_id + '/files';
                this.dropzone.processQueue();

                this.dom.find('[type=reset]').trigger('click');
            })
        })

    }

    ready() {
        if(typeof this.dropzone == 'undefined') {
            this.dropzone = new Dropzone('#drop_zone', {
                url: '/fake/path',
                autoQueue: true,
                autoProcessQueue: false,
                parallelUploads: 10
            })

            this.dropzone.on('queuecomplete', () => this.dropzone.removeAllFiles())

            this.dropzone.on("addedfile", (file) => {
                file.previewElement.addEventListener("click", () => {
                    this.dropzone.removeFile(file);
                });
            });
        }
    }
}