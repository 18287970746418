var employee_search_template = require('./employee_search_modal.twig');

export class EmployeeSearch
{

    reloadDatatable() {
        this.datatable.ajax.reload();
    }

    setFilterData() {
        this.filterData = {};
        this.dom.find("#form_filter_employee input, #form_filter_employee select").each((index ,elem) => {
            this.filterData[$(elem).attr('name').replace('employee_search_', '')] = $(elem).val();
        })
    }

    constructor() {
        this.dom = $(twig({ data: employee_search_template }).render({
            notice_msg: app.appData.layout.attendance.notice_msg ?? false
        }))

        this.datatable = this.dom.find("#datatable_employee").DataTable({
            "ajax": {
                "url": "api/attendance/searchemployees",
                "data": (d) => {
                    d.data = this.filterData;
                    d.meeting_id = $("#meeting_id").val();
                }
            },
            "columns": [
                {data: 'firstname'},
                {data: 'lastname'},
                {data: 'city'},
                {data: 'state'},
                {
                    data: 'wwid',
                    render: (data, type, row) => {
                        if(row.attendee_id)
                        {
                            return 'Added';
                            //return '<button type="button" class="btn btn-sm btn-primary add-target" data-target-id="' + row.target_id + '" data-address-id="' + row.address_id + '"> Invite</button>';
                        }
                        else
                        {
                            return '<button data-wwid="' + row.wwid + '"' +  'data-firstname="' +  row.firstname + '" data-lastname="' +  row.lastname + '" data-affiliation="' + row.affiliation + '" class="btn btn-sm btn-primary add-employee">Add</button>';
                        }
                    }
                }
            ],
            "pagingType": "full_numbers",
            "width" : "100%",
            "dom": '<"row"<"col-6"l><"col-6"<"row">>><t>rip',
            "pageLength": 10,
            "scrollCollapse": true,
            "processing":true,
            "language": {
                "loadingRecords": 'Please wait while we fetch your results.',
                "emptyTable":     "Use filters above to search.",
                "processing": 'Please wait while we fetch your results.'
            },
            "drawCallback": function() {
                app.convertDatatableToCard("datatable_employee");
            }
        })

        this.dom.find('#form_filter_employee').on('submit', (e) => {
            e.preventDefault();

            this.setFilterData();
            this.reloadDatatable()

            this.dom.find('.add_attendee_via_form_button').show()
        })

        this.dom.find("#datatable_employee tbody").on('click', '.add-employee', (e) => {
            let button = $(e.target)
            let employeeData = button.data()
            button.remove()

            app.pages.attendance.dom.trigger('employeeAddClicked', [e, employeeData])
        })

        this.dom.find('.add_attendee_via_form_button').on('click', (event) => {
            this.modal.hide();
            let employeeData = this.filterData
            employeeData['att_type'] = 'Rep'
            app.pages.attendance.dom.trigger('addViaFormClicked', [event, employeeData])
        })

        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false});
        this.modal.show();
    }
}
