let template = require('./modal.add_note.twig');

export class ModalAddNote
{
    constructor() {
        this.dom = $(template);
        this.dom.find('#submit_note').on('click', () => this.submitNote());
        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false, show: false});
    }

    setData(data) {
        this.data = data;
    }

    submitNote() {
        let text = this.dom.find('[name=note]').val();
        this.data.note = text;

        $.ajax({
            url: "api/speakernominate/changestatus",
            method: "POST",
            data: this.data
        }).then(response => {
            this.modal.hide();
            if(response.success) {
                displayNotification({
                    title: 'Speaker nomination denied',
                    success: true
                });
                // Reload the DataTable after the modal is hidden
                this.onModalHidden();
            }
            else {
                displayNotification({
                    title: 'Speaker nomination denial failed',
                    success: false
                });
            }
        })
    }

    onModalHidden() {
        // Reload the DataTable
        if (this.reloadCallback) {
            this.reloadCallback();
        }
    }

    setReloadCallback(callback) {
        this.reloadCallback = callback;
    }
}
