import { Page } from './page.js'
import {Dropdown} from "../components/Dropdown";

let template = require('./programguidance/programguidance.twig');
let newsTemplate = require('./programguidance/newsModal.twig');
let meetingTemplate = require('./programguidance/meetingModal.twig');

export class ProgramGuidance extends Page {
    bestPracticeContent = [];
    constructor() {
        super();
        this.projects = '';
        this.news = '';
        this.meetings = '';
    }

    getNews(async){
        return $.ajax({
            url: "api/news/getallnewsforbrands",
            method  : 'get',
            async: async
        });
    }

    getExternalMeetings(async){
        return $.ajax({
            url: "api/programguidance/getexternalmeetings",
            method  : 'get',
            async: async
        });
    }

    drawFiscalYearDD() {
        let year = new Date().getFullYear();
        let data = Object.values(app.appData.projects).
        map(x => { return {'year':x.proj_fy}}).
        filter((v, i, a) => a.map(mapObj => mapObj.year).indexOf(v.year) === i).sort((a, b) => b.year - a.year)

        let fiscalYearDD = new Dropdown(data)
        fiscalYearDD
            .required(true)
            .multiple(false)
            .draw(this.dom.find('#fiscal_year_container'), 'year', 'year', 'fiscal_year')

        this.dom.find('#fiscal_year_container').find('select').on('change', (event) => {
            this.controlProjectDisplay(event.target.value);
        })

        this.dom.find('#fiscal_year_container').find('select').val(year).trigger('change');
    }

    getProjects(){
        this.projects = app.format.sortObjectByKey(app.appData.projects, 'project_name', 'sorted_projects' );
    }

    controlProjectDisplay(yearSelected = this.dom.find("#fiscal_year").val()){
        this.dom.find( ".project_container" ).each(function() {
            if(yearSelected == '' || $(this).data('project_year') == yearSelected){
                $(this).show();
            }else{
                $(this).hide();
            }
        });
    }

    loadElements(){
        this.getProjects();

        this.getExternalMeetings(false).done((data) => {
            this.meetings = data;
        });

        this.getNews(false).done((data) => {
            this.news = data;
        });

        this.dom = $(twig({data: template}).render({
            projects: this.projects,
            news: this.news,
            meetings: this.meetings,
        }));

        this.drawFiscalYearDD();
        this.controlProjectDisplay();
        $('.content-body').html(this.dom);

        this.dom.find(".news_link").click((e) => {
            let res = this.news.find((item) => {
                return item.id == e.target.id;
            });

            let newsModalDom = $(twig({data: newsTemplate}).render({
                news: res
            }));

            let modal = new bootstrap.Modal(newsModalDom);
            modal.show();
        });

        this.dom.find(".meeting_link").click((e) => {
            let meetingId = e.target.id;
            let res = this.meetings.find((item) => {
                return item.meeting_id == meetingId;
            });

            res.status_color = app.format.setstatusColor(meetingId);
            let meetingModalDom = $(twig({data: meetingTemplate}).render({
               meeting: res
            }));

            meetingModalDom.find('#program_id_popup').on('click', (event) => {
                event.preventDefault();
                app.navigate('meeting', [meetingId]);
            });

            let modal = new bootstrap.Modal(meetingModalDom);
            modal.show();
        });

        // Best Practices page
        this.dom.find('.best_practices_nav_buttons_wrap button, .best_practices_content_wrap .sum_menu a.to_best_practice').on('click', (event) => {
            this.hideAll()
            let best_practices_container = this.dom.find('.best_practices_content_wrap')

            // sub-menu updates
            best_practices_container.show()
            best_practices_container.find('.to_best_practice').removeClass('active').find('span.arrow').show()
            best_practices_container.find('#' + event.target.id).addClass('active').find('span.arrow').hide()

            // add content
            this.getBestPracticeContent(
                event.target.getAttribute('media-type-name'),
                best_practices_container.find('.content_body'))
        })

        this.dom.find('.sum_menu a.back').on('click', () => {
            this.hideAll()
            this.dom.find('.main_content_wrap').show()
            this.dom.find('.best_practices_nav_buttons_wrap').show()
        })

        // Program
        this.dom.find('.project_list .project_container').on('click', (event) => {
            let project_id = event.target.getAttribute("data-project_id")
            this.drawProjectPage(project_id)
        })

        this.dom.on('change', '#program_budget', (event) => {
            this.drawProjectPage(event.target.value)
        })

        this.dom.on('change', '#program_topic', () => {
            this.drawProjectPage()
        })

        this.dom.find('.sum_menu a#associatedSpeakers').on('click', () => {
            let project_id = this.dom.find('#program_budget').find(":selected").val()
            let topic_id = this.dom.find('#program_topic').find(":selected").val()
            window.open('speakers/project_id='+project_id+'/topic_id=' + topic_id + '/_blank')
        })

        this.dom.on('click', '.select2-container', () => {
            $('.select2-container--default').addClass('program_guidance_dd')
        })
    }

    hideAll(){
        this.dom.find('.main_content_wrap').hide()
        this.dom.find('.best_practices_nav_buttons_wrap').hide()
        this.dom.find('.best_practices_content_wrap').hide()
        this.dom.find('.project_content_wrap').hide()
    }

    getBestPracticeContent(media_type_name, container){
        if ((this.bestPracticeContent[media_type_name] ?? false) && !this.bestPracticeContent[media_type_name]['error']) {
            this.drawHTMLContent(this.bestPracticeContent[media_type_name], container)
            return
        }

        $.ajax({
            url: "api/programguidance/best-practice-content",
            method: 'get',
            data: {
                media_type_name: media_type_name
            },
            async: false
        }).then((data) => {
            this.drawHTMLContent(data, container)
            this.bestPracticeContent[media_type_name] = data
        });
    }

    drawHTMLContent(content, container) {
        let html_led = document.createElement('div');
        html_led.innerHTML = content.html;
        container.html('').html(html_led.childNodes[0].nodeValue)
    }

    drawProjectPage(
        project_id = this.dom.find('#program_budget').find(":selected").val(),
        topic_id = this.dom.find('#program_topic').find(":selected").val()
    ) {
        this.hideAll()
        this.dom.find('.project_content_wrap').show()
        this.drawProjectDD(project_id)
        this.drawTopicDD(project_id, topic_id)
        this.drawProjectContent(project_id)
    }

    drawProjectDD(project_id) {
        let dd_dome = this.dom.find('.program_budget_wrap')
        dd_dome.find('select').remove()
        let year = this.dom.find('#fiscal_year').val()
        let projects = Object.values(app.appData.projects).filter(el => el.proj_fy == year || year == '')
        let dd = new Dropdown(projects)
        dd.addClass('col')
            .required(true)
            .multiple(false)
            .draw(dd_dome, 'project_id', 'project_name', 'program_budget')

        dd_dome.find('select#program_budget option').removeClass('selected')
        dd_dome.find('select#program_budget option[value="'+project_id+'"]').attr('selected', 'selected').addClass('checked')

        dd.refreshMultiSelect()
    }

    drawTopicDD(project_id, selected_topic_id) {
        let projects_topic_ids = app.appData.projects[project_id].topic_ids
        let dd_dome = this.dom.find('.program_topic_wrap')
        dd_dome.find('select').remove()
        let li_dome = dd_dome.parent()
        if (projects_topic_ids == '') {
            li_dome.hide()
            return
        }

        li_dome.show()
        let projects_topic = projects_topic_ids.split(',').map(function (x) {
            return parseInt(x, 10);
        })
        let topics = app.appData.topics.filter(topic =>
            projects_topic.includes(parseInt(topic.topic_id, 10))
            && (new Date(topic.expiry_date) > new Date() || !topic.expiry_date)
        )

        let dd = new Dropdown(topics)
        dd.addClass('col')
            .required(true)
            .multiple(false)
            .draw(dd_dome, 'topic_id', 'topic_name', 'program_topic')

        if (selected_topic_id == '0') {
            selected_topic_id = projects_topic.map(Number).sort((a, b) => a - b)[0]
        }

        projects_topic = projects_topic.map(Number).sort((a, b) => a - b)
        dd_dome.find('select#program_topic option').removeClass('checked').removeAttr('selected')
        dd_dome.find('select#program_topic option[value="' + selected_topic_id + '"]').attr('selected', 'selected').addClass('checked')
        dd.refreshMultiSelect()
    }

    drawProjectContent(project_id = {}) {
        let project = app.appData.projects[project_id]

        // update image
        let project_content_dom = this.dom.find('.project_content_wrap')
        if (project.logo?.src) {
            project_content_dom.find('.img_wrap img').attr('src', project.logo.src).show()
        } else {
            project_content_dom.find('.img_wrap img').attr('src', '').hide()
        }

        $.ajax({
            url: "api/programguidance/best-practice-content",
            method: 'get',
            data: {
                media_type_name: "Program Guidance",
                project_id: this.dom.find('#program_budget').find(":selected").val(),
                topic_id: this.dom.find('#program_topic').find(":selected").val()
            },
            async: false
        }).then((data) => {
            let project_id = this.dom.find('#program_budget').find(":selected").val()
            let topic_id = this.dom.find('#program_topic').find(":selected").val()
            this.drawProjectDD(project_id)
            this.drawTopicDD(project_id, topic_id)
            this.drawHTMLContent(
                data,
                project_content_dom.find('.content_body'),
            )
        });
    }

    ready() {
        $.when(this.loadElements()).then(function () {
            let content_body_h = $(".content-body").height() - 40;
            let suitable_h = $(window).height() * 0.8;

            let winner_h = (content_body_h > suitable_h) ? content_body_h : suitable_h;
            $(".program_guidance .main_content_wrap").css("height", winner_h);

            let wrapper_h = $(".program_guidance").height();
            let header_h = $(".program_guidance").find(".header_wrap").height();
            let tab_header_h = $("#news_events_tab").height();
            let tab_pane_h = wrapper_h - (header_h + tab_header_h) - 20;

            $("#events_list").height(tab_pane_h);
            $("#news_list").height(tab_pane_h);
        });
    }
}
