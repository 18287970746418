let template = require ('./modal_template.twig');
export class CustomModal {
    defaultButtons = [{
        text: 'Close',
        id: 'closebtn',
        close: true,
        class: 'btn-info',
        action: () => this.close()
    }]

    constructor(config) {
        this.config = { }

        this.config.contentClass    = config.content_class ? config.content_class : ''
        this.config.contentOnly     = config.contentOnly ? config.contentOnly : false
        this.config.modalClass      = config.modal_class ? config.modal_class : ''
        this.config.contentId       = config.content_id ? config.content_id : ''
        this.config.size            = config.modal_size ? config.modal_size : 'modal-lg'
        this.config.content         = $(config.content ? config.content : '')
        this.config.title           = config.title ? config.title : ''
        this.config.close_callback  = config.close_callback ? config.close_callback : null;
        this.config.open_callback  = config.open_callback ? config.open_callback : null;
        this.config.buttons         = config.buttons ? config.buttons : this.defaultButtons;
        this.config.static         = config.static ? config.static : false

        this.dom = $(twig({data: template}).render(this.config));

        if(this.config.contentOnly) {
            this.content = this.dom.find('.content');
        } else {
            this.content = this.dom.find('.modal-body');
        }

        this.content.append(this.config.content);

        this.modal = new bootstrap.Modal(this.dom);

        this.dom.on('hidden.bs.modal', (event) => {
            this.config.content.detach();
            $(event.target).remove();
            if(typeof this.config['close_callback'] === 'function') {
                this.config['close_callback']()
            }
        });

        this.dom.on('shown.bs.modal', () => {
            if(typeof this.config['open_callback'] === 'function') {
                this.config['open_callback']()
            }
        });

        this.setButtonActions();

        this.dom.find('.navbar a').on('click', (event) => {
            $(event.target).closest('.navbar').find('.nav-item').removeClass('active');
            $(event.target).closest('.nav-item').addClass('active');
        })
    }

    setButtonActions() {
        this.config.buttons.forEach(button => {
            if(button.action && button.id) {
                this.dom.find('#'+button.id).on('click', (event) => {
                    event.preventDefault();
                    button['action']();
                })
            }
        })
    }

    isOpen() {
        let modalIsShown = (this.dom.data('bs.modal') || {})._isShown;
        return !(typeof modalIsShown === 'undefined' || !modalIsShown);
    }

    open() {
        this.content.append(this.config.content);
        this.modal.show();
    }

    close() {
        this.modal.hide();
    }
}
