 import { Page } from './page.js'

let template = require('./microstrategy/microstrategy.twig');

export class MicroStrategy extends Page{
    constructor() {
        super();
        this.dom = $(twig({ data: template }).render())
    }

    ready() {
        if(typeof this.dossier == 'undefined') {
            // Set user level filter object array
            this.setDefaultFilters();
            // Retrieve suite of dossiers and initiate default view
            $.ajax({
                url: 'api/microstrategy',
                success: (data) => {
                    this.config = data;
                    this.token = atob(this.config.token).split('.');
                    this.config.username = this.token[1];
                    this.config.password = this.token[2];

                    this.dossier = this.loadDossier(this.config.dossiers[0].dossier_id);

                    for(let i in this.config.dossiers)
                    {
                        let dossier = this.config.dossiers[i];
                        let link = $('<option class="dropdown-item" data-dossier_id="'+dossier.dossier_id+'">'+dossier.name+'</option>');
                        $('#dossier-menu').append(link);
                    }

                    $("#dossier-menu").change( (event) => {
                        this.loadDossier($(event.target).children('option:selected').data('dossier_id'));
                    });

                    $("#instanceReset").click( () => {
                        this.loadDossier($('#dossier-menu').children('option:selected').data('dossier_id'));
                    });
                }
            });
        }

    }

    getDossierDefinition(id)
    {
        return new Promise( (resolve,reject)  =>
        {
            $.ajax({
                url: 'api/microstrategy/definition/'+id,
                success: (data) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                }
            })
        });
    }

    setDefaultFilters()
    {
        $.ajax({
            url: 'api/microstrategy/filters',
            async: false,
            success: (data) => {
                this.defaultFilters = [];
                for (let prop in data.filters) {
                    if (Object.prototype.hasOwnProperty.call(data.filters, prop)) {
                        // Create selection objects
                        let filterValues = [];
                        $.each(data.filters[prop].split(","),(index,value) => {
                            filterValues.push({"name":value});
                        })
                        // Format filter name
                        let filterName = '';
                        $.each(prop.split("_"), (index,name) => {
                            filterName += name.charAt(0).toUpperCase() + name.substring(1) + " ";
                        })
                        // Add to filters array
                        this.defaultFilters.push({
                            "name": filterName.trim(),
                            "selections":filterValues
                        });
                    }
                }
            }
        });
    }

    getDossierChapterFilters(definedFilters)
    {
        let filters = [];
        $.each(definedFilters, (index,filterObj) => {
            let defaultObj = this.defaultFilters.filter( (filter) => filter.name === filterObj.name );
            if(defaultObj !== undefined)
            {
                filters.push(defaultObj[0]);
            }
        });

        return filters;
    }

    setupDossierNavigation(dossierObj)
    {
        let toc = dossierObj.getTableContent();
        let select_html = "";
        let cur_page = dossierObj.getCurrentPage();
        for (let i = 0; i < toc.chapters.length; i++) {
          let chapter = toc.chapters[i];
          select_html = select_html + '<optgroup label="'+chapter.name+'">'
          for (let j = 0; j < chapter.pages.length; j++) {
            let page = chapter.pages[j];
            let selected = '';
            if(page.name == cur_page.name){
                selected = "selected = 'selected'";
            }
            select_html = select_html + '<option value='+page.nodeKey+' '+selected+'>'+page.name+'</option>'
          }
          select_html = select_html + '</optgroup>';
        }
        let $el = $("#toc");
        $el.empty();
        $el.append(select_html);

        $('.dossierNav').show();

        $('#nextPage').on('click',function(){
            dossierObj.goToNextPage();
            let new_page = dossierObj.getCurrentPage();
            $('#toc').val(new_page.nodeKey)

        });
        $('#prevPage').on('click',function(){
            dossierObj.goToPrevPage();
            let new_page = dossierObj.getCurrentPage();
            $('#toc').val(new_page.nodeKey)
        });
        $('#toc').on('change', function(){
            let selected_page = $('#toc').val();
            let page = dossierObj.getPageByNodeKey(selected_page);
            dossierObj.navigateToPage(page);
        });
    }

    getDossierInstance(dossierId,filterResults)
    {
        return new Promise ( (resolve, reject) => {
            var baseURL = this.config.api_root;
            var projectId = this.config.project_id;
            var options = {
                method: 'POST',
                credentials: 'include', // Including cookie
                mode: 'cors', // Setting as cors mode for cross origin
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "username": this.config.username,
                    "password": this.config.password,
                    "loginMode": 1,
                })
            };

            fetch(baseURL + 'auth/login', options)
            .then(function(response) {
                if (response.ok) {
                    var authToken = response.headers.get('x-mstr-authToken')
                    var options = {
                        method: 'POST',
                        credentials: 'include', // Including cookie
                        mode: 'cors', // Setting as cors mode for cross origin
                        headers: {
                            "content-type": "application/json",
                            "accept": "application/json",
                            "x-mstr-authtoken": authToken,
                            "x-mstr-projectid": projectId,
                        },
                        body: JSON.stringify({
                            // Set the filters at instance creation
                            "filters": filterResults,
                            /* When this flag is set to “true”, it is the published view
                            ** that is executed and all manipulations that the user performs
                            ** on the dossier or document instance are auto-saved in the published view.
                            ** That is why we set it to false!
                            */
                            "persistViewState": false
                        })
                    }

                    fetch(baseURL + 'dossiers/' + dossierId + '/instances', options)
                    .then(response => {
                        response.json().then(json => {
                            resolve(json)
                        })
                    })

                } else {
                    response.json().then(json => {
                        resolve(json)
                    });
                }
            }).catch(error => {
                reject(error)
            });
        });
    }


    loadDossier(id) {
        this.getDossierDefinition(id)
        /*Get the chapter level filters based on the deifnition for the first chapter
        At this point we are assuming there will only be one chapter for each selection in the dossier dropdown
        If this changes we will need to update this filter function to accomodate mulitple chapters*/
        .then(definitionResult => this.getDossierChapterFilters(definitionResult.definition.chapters[0].filters))
        .then(filterResults => this.getDossierInstance(id,filterResults))
        .then((instanceResults) => {
            let container = $(this.dom).find("#mydossier")[0];
            let url = this.config.app_root + "/" + this.config.project_id + "/"+id;
            return microstrategy.dossier.create({
                url: url,
                enableResponsive: true,
                placeholder: container,
                containerHeight: '800px',
                disableNotification: true,
                instance: instanceResults,
                filterFeature: {
                    enabled: false,
                    edit: false,
                    summary: false
                }
            });
        }).then(createResult => {
            this.setupDossierNavigation(createResult)
        });
    }
}


