let template = require('./report_table.twig');

export class ReportTable{
    constructor(config) {
        this.config = (typeof config === 'object') ? config : { ordering : true };
        this.dom = $(template);

        this.table = this.dom.find('#reports-table');
        this.columns = [];
        this.totalColumns = [];
        this.filterData = [];
    }

    createHeader(columns) {
        this.columns = columns;
        this.headers = [];
        $.each(columns, (key, column) => {
            this.headers.push({
                "data": column.key,
                "title": column.label,
                "column": column,
                render : (data, type, row) => {
                    if ( column.format == 'formatDate' && type === 'sort') {
                        var d = new Date(data);
                        return d.getTime();
                    } else {
                        return app.format.column(data, column, row);
                    }
              }
            });
        })
    }

    initializeTable(data) {
        this.data = data.data;
        this.createHeader(data.columns)
        this.totalColumns = data.totals ? data.totals : [];

        let vm = this;

        this.table.DataTable({
            data: data.data,
            columns: this.headers,
            ordering: this.config.ordering,
            "paging": false,
            "dom": '<"row m-0"<"col-sm-6 h-sm-40"l><"col-sm-6"<"budget_report_searchbox text-end pb-1" f><"pb-1"<"text-end"<"text-end download-csv"B>>>>><t>ip',
            "buttons": [{
                'extend' : 'csvHtml5',
                'className':'btn btn-success',
                'text': '<i class="fas fa-file-excel"></i>',
                'title': data.new_title ? data.new_title : this.config.title
            }],
            "language": { search: "",
                searchPlaceholder: 'Search...',
            },
            "footerCallback": function ( tfoot, data, start, end ) {
                $('#tblFooter').empty();
                $('#tblFooter').remove();

                let foot = '';
                this.api().columns().every(function (index) {
                    let sum = '';
                    let styleClass = '';
                    if(index == 0) {
                        sum = 'Total'
                    } else if($.inArray(vm.headers[index].data, Object.keys(vm.totalColumns)) > -1){
                        sum = 0;
                        for(let i = start; i < end; i++) {
                            sum += app.format.intVal(data[i][vm.headers[index].data]);
                        }
                        sum = app.format.column(sum, vm.headers[index].column);
                    }
                    foot += '<th class="'+styleClass+'">'+ sum+' </th>';
                });
                $(this).append('<tfoot id="tblFooter"><tr>'+foot+'</tr></tfoot>');
            },
            "drawCallback": function() {
                app.convertDatatableToCard("reports-table", this.headers);
            },
            "destroy": true
        });

        $('.dt-button').off('click').on('click', (event) => {
            event.preventDefault();
            this.exportData();
        })
       $('.dt-button').attr('title', 'Click to download');

        $('.page-loading-overlay').hide();
    }

    exportData() {
        let url = "api/reports/export";
        let data = $.param($('form#report-filters').serializeArray());
        window.open(url+'?'+data);
    }

    recreateTable(filterData) {
        $(".page-loading-overlay").show();
        $.ajax({
            url: "api/reports/data",
            type: "post",
            data: filterData
        }).then(data => {
            this.initializeTable(data);
        })
    }
}