import { AttendeeEvalModal } from "../manageattendance/AttendeeEvalModal";
let template = require('./tablecolumns.twig');

export class AttendeeList {

    constructor(table, columns, meetingData) {
        this.dom = $(template)
        this.tableColumns = []
        this.hideColumns = []
        this.exportOrder = []
        this.meetingData = meetingData,
        this.attendaceConfig = app.appData.layout.attendance

        $('#'+table +' thead tr').children('th').each((k, elem) => {
            let key = $(elem).data('key');
            let column = columns.find(({ columnKey }) => columnKey == key)
            if(typeof column !== 'undefined'){
                if('hide_on_portal' in column && column.hide_on_portal) {
                    this.hideColumns.push(this.tableColumns.length);
                }
                if('user_type_ids' in column && $.inArray(app.appData.curPosition.user_type_id,column.user_type_ids) < 0 ) {
                    this.hideColumns.push(this.tableColumns.length);
                }
                if('check_config_path' in column) {
                    // If the configuration does not exist or is set to false, hide the column
                    let defaultProgramTypeConfigObj = false;
                    if(typeof app.appData.defaultProjectProgramTypeConfig != "undefined")
                    {
                        defaultProgramTypeConfigObj = app.appData.defaultProjectProgramTypeConfig;
                    }
                    let projectProgramTypeConfigObj = false;
                    if(typeof app.appData.projects[this.meetingData.project_id].program_types != "undefined"
                        && typeof app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id].configuration != "undefined")
                    {
                        projectProgramTypeConfigObj = app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id].configuration;
                    }
                    let configUndefined = false;
                    for (var i=0, path=column.check_config_path.split('.'), len=path.length; i<len; i++){
                        if(typeof projectProgramTypeConfigObj[path[i]] != "undefined") {
                            projectProgramTypeConfigObj = projectProgramTypeConfigObj[path[i]];
                        } else {
                            projectProgramTypeConfigObj = false;
                            configUndefined = true;
                        }
                        if(typeof defaultProgramTypeConfigObj[path[i]] != "undefined") {
                            defaultProgramTypeConfigObj = defaultProgramTypeConfigObj[path[i]];
                        } else {
                            defaultProgramTypeConfigObj = false;
                        }
                    }

                    if(configUndefined && defaultProgramTypeConfigObj == false) {
                        this.hideColumns.push(this.tableColumns.length);
                    } else if(projectProgramTypeConfigObj == false) {
                        this.hideColumns.push(this.tableColumns.length);
                    }
                }
                if('export_order' in column) {
                    if(parseInt(column.export_order) >= 0) {
                        this.exportOrder[parseInt(column.export_order)] = this.tableColumns.length;
                    }
                }
                else
                {
                    this.exportOrder[this.tableColumns.length] = this.tableColumns.length;
                }

                this.tableColumns.push({
                    "orderable": 'orderable' in column ? column.orderable : true,
                    "data": key,
                    render : (data, type, row) => {
                        if (key == 'reminder_email') {
                            row.reminder_email_timestamp = row.reminder_email_timestamp || 0;
                            let timeHasPassed = moment(parseInt(row.reminder_email_timestamp), 'X').isBefore(moment().subtract(1, 'days'))
                            row.canEmail = timeHasPassed
                                && (app.appData.projects[meetingData.project_id].max_att_reminder == -1
                                    || row.num_reminder_sent < app.appData.projects[meetingData.project_id].max_att_reminder)
                        }

                        let html ='<span id="' + key + '">' + (data || '') + '</span>';
                        let is_account_num_editable = false;
                        let view_attendee_evals = false;
                        let view_evaluations = false;
                        if(typeof app.appData.layout.attendance.show_evaluations != 'undefined') {
                            view_evaluations = true;
                        }

                        if (view_evaluations) {
                            let allowed_user_type = app.appData.layout.attendance.show_evaluations.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id));
                            if (key == 'account_num' && type == 'display' && !['Rep', 'Speaker', 'Staff'].includes(row.att_type)) {
                                html = '<div class="text-nowrap"><input id="' + key + '" class="action_input" attendeeid="' + row.attendee_id + '" default_value="' + (data || '')
                                    + '" value="' + (data || '') + '" disabled></input>';
                                html += '<i class="fas fa-pen fa-lg fc-content action-update ps-1"></i>';
                                html += '<i class="fas fa-save fa-lg fc-content action-save ps-1" style="display: none"></i>';
                                html += '<i class="fas fa-ban fa-lg fc-content action-cancel ps-1" style="display: none"></i></span>';
                                html += '<div class="action-message"></div></div>';
                                is_account_num_editable = true;
                            }

                            if (key == 'attendee_eval' && type == 'display' && !['Rep'].includes(row.att_type)
                                && allowed_user_type && row.eval_status.completed == 1) {
                                html = '<div class="text-center">';
                                html += '<i class="fas fa-eye fa-lg fc-content action-view ps-1" attendeeid="' + row.attendee_id + '"></i>';
                                html += '</div>';
                                view_attendee_evals = true;
                            }
                        }

                        if('speaker_color' in this.attendaceConfig && row.att_type == 'Speaker'){
                            row.firstname = '<span style="color:' + this.attendaceConfig.speaker_color + '">' + (row.firstname || '') + '</span>';
                            row.lastname = '<span style="color:' + this.attendaceConfig.speaker_color + '">' + (row.lastname || '') + '</span>';
                        }

                        if('employee_color' in this.attendaceConfig && ['Rep', 'Staff'].includes(row.att_type)){
                            row.firstname = '<span style="color:' + this.attendaceConfig.employee_color + '">' + (row.firstname || '') + '</span>';
                            row.lastname = '<span style="color:' + this.attendaceConfig.employee_color + '">' + (row.lastname || '') + '</span>';
                        }

                        if(typeof column !== 'undefined') {
                            let column_html = '';
                            if(type === 'export' && 'export' in column && column.export != '') {
                                column_html = this.dom.find("#"+column.export).first().html()
                            }
                            else if(type === 'export' && 'export_yesno' in column && column.export_yesno != '')
                            {
                                let val = "";
                                if(data == 1) {
                                    val = "Yes";
                                } else if(data == 0) {
                                    val = "No";
                                }
                                column_html = '<span id="' + key + '">' + val + '</span>';
                            }
                            else if(type === 'export' && 'export_value' in column && column.export_value != "") {
                                column_html = '<span id="' + key + '">' + row[column.export_value] || '' + '</span>';
                            }
                            else if(('html' in column) && column.html != '')
                            {
                                column_html = this.dom.find("#"+column.html).first().html()
                            }

                            if(column_html) {
                                html =  twig({ data: column_html }).render({data: data, row: row, meetingData: meetingData, appData: app.appData});
                            }
                        }
                        if (is_account_num_editable) AttendeeList.registerUpdateActions();
                        if (view_attendee_evals) AttendeeList.viewEvaluationModal(meetingData);
                        return html;
                    }
                });
            }
        });

        this.attendeesTable = $('#' + table).DataTable({
            "ajax": {
                "url": "api/attendees",
                "type": "GET",
                "dataSrc": function(json) {
                    AttendeeList.registerUpdateActions(100);
                    AttendeeList.checkEmailIsExistForRemoteAttendee(json)
                    return json;
                },
                "data": {
                    'meeting_id': $('#meeting_id').val()
                }
            },
            "processing": true,
            "responsive": true,
            "columns": this.tableColumns,
            "columnDefs": [ {
                'targets': this.hideColumns,
                'visible': false
            }],
            "pagingType": "full_numbers",
            "width" : "100%",
            "dom": '<"row"<"col-md-6"l><"col-md-6"<"row justify-content-end"<"col-md-10 search-box"f><"col-md-2 dt-buttons text-end"<"w-md-50 float-start export"B>>>>>r<t>ip',
            "language": { search: "",
                searchPlaceholder: 'Search...',
                loadingRecords: 'Please wait while we refresh the grid.',
                processing: 'Please wait while we refresh the grid.'
            },
            "buttons": [ {
                'extend' : 'csvHtml5',
                'className':'btn btn-success',
                'text': '<i class="fas fa-file-excel"></i>',
                'title': 'Attendee List: Meeting ' + $('#meeting_id').val(),
                'exportOptions': {
                    orthogonal: 'export',
                    columns: [...this.exportOrder].filter(n => {
                       return typeof n !== 'undefined' && !this.hideColumns.includes(n)
                    })
                }
            }],
            "paging": ($(window).width() < 768) ? false : true,
            "info": $(window).width() < 768 ? false : true,
            "drawCallback": function() {
                app.convertDatatableToCard("datatable_attendees");
            }
        });
        $('.export').attr('title', 'Click to download');

        $('#datatable_attendees_paginate').on('mouseup click', 'li a', async () => {
            const timer = ms => new Promise(res => setTimeout(res, ms))
            await timer(10); // waiting for painting table
            AttendeeList.checkEmailIsExistForRemoteAttendee()
        })
    }

    static registerUpdateActions(wait = 0) {
        setTimeout(() => {
            $('.action-update').on('click', (e) => this.updateActions(e))
            $('.action-save').on('click', (e) => this.saveActions(e))
            $('.action-cancel').on('click', (e) => this.cancelActions(e))
            $('.action_input').on('keyup', (e) => this.inputActions(e))
        }, wait);
    }

    static updateActions(e) {
        let update_element = $(e.target);
        let parent = update_element.parent();
        update_element.hide();
        parent.children('.action-cancel').show();
        parent.children('input').removeAttr("disabled");
    }

    static cancelActions(e) {
        let cancel_element = $(e.target);
        let parent = cancel_element.parent();
        let input_element = parent.children('input');
        let default_value = input_element.attr('default_value');
        input_element.val(default_value);
        input_element.attr('disabled', 'disabled');
        parent.children('.action-save, .action-cancel').hide();
        parent.children('.action-update').show();
        parent.children('.action-message').text('').removeClass('text-danger');
    }

    static inputActions(e) {
        let input_element = $(e.target);
        let parent = input_element.parent();
        let default_value = input_element.attr('default_value');
        let value = input_element.val();

        // validations
        if (input_element.attr('id') == 'account_num') {
            if (value.length != 0 && (
                isNaN(value)
                || value.length < 8
                || value.length > 9
                || !(['10'].includes(value.substring(0,2)))
            )
            ) {
                parent.children('.action-message').text('Must start with 10 and is 8 or 9 digits').addClass('text-danger');
                parent.children('.action-save').hide();
                return;
            } else {
                parent.children('.action-message').text('').removeClass('text-danger');
            }
        }

        // show/hide save icon
        if (default_value != value) {
            parent.children('.action-save').show();
        } else {
            parent.children('.action-save').hide();
        }
    }

    static saveActions(element) {
        let save_element = $(element.target);
        let parent = save_element.parent();
        if(parent.attr('class') != 'saved') {
            parent.addClass('saved');
            let input_element = parent.children('input');
            let key = input_element.attr('id');
            let value = input_element.val();
            let previous_value = input_element.attr('default_value');
            this.postUpdate(parent, key, previous_value, value);
        }
    }

    static postUpdate(element, key, previous_value, value) {
        // get data for post
        let meeting_notes = '';
        let attendee_name = element.parent('tr').children('td').children('span#firstname').text() + ' '
            + element.parent('tr').children('td').children('span#lastname').text();
        if (key == 'account_num') {
            meeting_notes += 'McKesson ID ';
            meeting_notes += previous_value == '' ? value + ' added' : previous_value + ' updated to Mckesson ID ' + value;
            meeting_notes += ' for Attendee ' + attendee_name + '. ';
        }
        // post update
        $.ajax({
            url     : 'api/attendance/update-attendee',
            method  : 'post',
            data    : {
                attendee_id: element.children('input#' + key).attr('attendeeid'),
                meeting_id: $('#meeting_id').val(),
                meeting_notes: meeting_notes,
                updated_field: key,
                value: value,
                user_id: app.appData.current_user.user_id,
            },
            success: (data) => {
                let updated_input = $('input#' + data.key + '[attendeeid="' + data.attendee_id + '"]');
                let td_element = updated_input.parent('td');

                if (data.success) {
                    updated_input.attr('default_value', data.value)
                    updated_input.attr('value', data.value)
                    td_element.children('.action-cancel, .action-save').hide();
                    updated_input.attr('disabled', 'disabled')
                    td_element.children('.action-message').text(data.message).addClass('text-success');
                    setTimeout(() => {
                        td_element.children('.action-message').text('').removeClass('text-success');
                        td_element.children('.action-update').show();
                    }, 2000);
                } else {
                    td_element.children('.action-message').text(data.message).addClass('text-danger');
                }
                td_element.removeClass('saved');
            }
        })
    }

    static async checkEmailIsExistForRemoteAttendee(attendeeArray = []) {
        // check config once
        if (!this.checkEmailIsExistForRemoteAttendeeConfigKey) {
            // check function's layout config key
            let function_config = app.appData.layout.attendance.required_email_for_remote_attendee ?? false

            // check if editable_email column exist
            let layout_have_edit_email_field = false
            app.appData.layout.attendance.listColumns.forEach(o => {
                if (o.columnKey == 'editable_email') {
                    layout_have_edit_email_field = true
                }
            })

            // add config
            if (function_config && layout_have_edit_email_field){
                this.checkEmailIsExistForRemoteAttendeeConfigKey = 'do'
            } else {
                this.checkEmailIsExistForRemoteAttendeeConfigKey = 'exit'
            }
        }

        // close function if config is exit
        if (this.checkEmailIsExistForRemoteAttendeeConfigKey == 'exit') {
            return
        }

        // save attendeeArray to this
        if (attendeeArray.length > 0) {
            let newAttendeeArray = []
            attendeeArray.map(async attendee => {
                newAttendeeArray[attendee.attendee_id] = attendee
            })

            app.appData.attendeeArray = newAttendeeArray
        }

        // exit if attendee list is empty
        if (app.appData.attendeeArray.length == 0) {
            return
        }

        // read table and update data in `app.appData.attendeeArray`
        if ($('#datatable_attendees tr').length > 0
            && $('#datatable_attendees tr').eq(1).text() != 'Please wait while we refresh the grid.')
        {
            $('#datatable_attendees > tbody > tr').each(async function(_, tr) {
                let email_el = $(tr).find('.edit-email-read')
                let email = email_el.find('div div.email').text()
                let is_remote = $(tr).find('.remote-buttons .yes').hasClass('active')

                if (is_remote && !email) {
                    let attendee_id = email_el.attr('attendee_id')
                    app.appData.attendeeArray[attendee_id].remote = is_remote
                    app.appData.attendeeArray[attendee_id].email = email
                }
            });
        }

        // get remote attendee without email
        let remoteAttendeeWithoutEmail = []
        app.appData.attendeeArray.forEach((a) => {
            if (a.remote == '1' && !a.email) {
                remoteAttendeeWithoutEmail[a.attendee_id] = {
                    attendee_id: a.attendee_id,
                    attendee_name: a.firstname + ' ' + a.lastname
                }
            }
        })

        // exit if remoteAttendeeWithoutEmail is empty
        if (remoteAttendeeWithoutEmail.length == 0) {
            return
        }

        // add warning painting table
        let tryCount = 0
        const timer = ms => new Promise(res => setTimeout(res, ms))
        while (tryCount < 50 && $('#datatable_attendees tr').eq(1).text() == 'Please wait while we refresh the grid.') {
            await timer(100);
            tryCount++;
        }

        // add required email message to table
        $('#datatable_attendees > tbody > tr').each(async function(_, tr) {
            let email_el = $(tr).find('.edit-email-read')
            let email = email_el.find('div div.email').text()
            let is_remote = $(tr).find('.remote-buttons .yes').hasClass('active')
            let attendee_id = email_el.attr('attendee_id')
            email_el.find('.invalid-feedback').remove()

            if (is_remote && !email && remoteAttendeeWithoutEmail[attendee_id]) {
                email_el.append('<div class="invalid-feedback">E-mail required for remote attendee.</div>').find('.invalid-feedback').show()
            }
        });
    }

    static updateRow(attendeeObject) {
        $('#datatable_attendees > tbody > tr').each(async function(_, tr) {
            let email_el = $(tr).find('.edit-email-read')
            let attendee_id = email_el.attr('attendee_id')

            if (attendeeObject.attendee_id == attendee_id) {
                email_el.find('div div.email').text(attendeeObject.email)
                $(tr).find('.edit-email-edit').find('textarea').text(attendeeObject.email)
                email_el.find('.invalid-feedback').remove()
            }
        });
    }

    static viewEvaluationModal(meetingData) {
        $(".action-view").off().on("click", (e) => {
            let attendee_id = $(e.target).attr("attendeeid");
            let title = "Evaluation for #" + attendee_id;
            let meeting_id = meetingData.meeting_id;
            new AttendeeEvalModal(meeting_id, attendee_id, title, "view");
        });
    }
}
