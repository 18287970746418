import template from './denyException.twig'
import {CustomModal} from "../../../components/customModal";

export class DenyException
{
    constructor(exception_id, exception_table) {

        this.exception_id = exception_id;
        this.exception_table = exception_table;

        this.dom = $(twig({data: template}).render({
        }));

        this.dom.find('#cancel').on('click', () => {
            this.close();
        })

        this.dom.find('#save').on('click', () => {
            if (this.dom.find('#noteText').val().trim() == '') {
                alert("Reason is required.");
            } else {
                $.ajax({
                    url: "api/exceptions/deny",
                    type: "post",
                    data: {
                        eid: this.exception_id,
                        reason: this.dom.find('#noteText').val().trim()
                    },
                    beforeSend: () => {
                        $("#noteText, #save").prop('disabled',true);
                    }
                }).done(() => {
                    $("#noteText, #save").prop('disabled',false);
                    this.close();
                });
            }
        })

        this.dom.on('shown.bs.modal',  () => {
            this.dom.find('#noteText').focus()
        })

        this.modal = new CustomModal({
            title: 'Deny '+app.appData.labels.exception+' Reason',
            content: this.dom,
            buttons: []
        })

        this.modal.open();
    }

    close() {
        this.dom.find("#noteText").val("");
        this.exception_table.filterTrigger();
        this.modal.close();
    }
}
