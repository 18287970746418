import { Page } from './page.js'
import { Dropdown } from '../components/Dropdown'
import { ExceptionTable } from '../components/ExceptionTable'
import { CancelException } from "./exceptions/cancelexception/cancelException"
import { ApproveException } from "./exceptions/approveexception/approveException"
import { DenyException } from "./exceptions/denyexception/denyException"
import { ReviewException } from "./exceptions/reviewexception/reviewException"
import { ModalViewUploadLog } from "./exceptions/ModalViewUploadLog";

let exceptions_template = require('./exceptions/exceptions.twig');

export class Exceptions extends Page{
    constructor() {
        super();
        this.label = app.appData.labels.exception;
        this.dom = $(twig({ data: exceptions_template }).render({'label':this.label}));
        this.columns = [];
        this.filterData = [];
        this.logModal = new ModalViewUploadLog();
    }

    renderExceptionStatus(data) {
        this.dom.find('#exception-status-dd-container').html('');
        let exceptionStatusDD = new Dropdown(data)
        exceptionStatusDD
            .label(this.label+' Status')
            .all(false)
            .multiple(true)
            .draw(this.dom.find('#exception-status-dd-container'), 'exception_status_key', 'exception_status_name', 'exception_status[]')
    }

    renderExceptionCategory(data) {
        this.dom.find('#exception-category-dd-container').html('');
        let exceptionCategoryDD = new Dropdown(data)
        exceptionCategoryDD
            .label(this.label+' Category')
            .all(false)
            .multiple(true)
            .draw(this.dom.find('#exception-category-dd-container'), 'exception_category_key', 'exception_category_name', 'exception_category[]')
    }

    renderDates() {
        this.dom.find('#exception-from-date').datepicker();
        this.dom.find('#exception-to-date').datepicker();
    }

    initializeExceptions() {
        $.ajax({
            url: "api/exceptions/filters"
        }).then(data => {
            this.exception_status = data.status;
            let defaultCheck = true;
            let defaultExceptionStatus = 'initialized';
            let defaultSearchId = '';
            let defaultOrderArr = [0,'desc'];
            let defaultCheckboxDisplay = 'block';
            let hiddenCheckbox = (app.appData.layout.exceptions?.hidden_checkbox ?? false);
            if(app.uri.match("exceptions/search/") !== null)
            {
                defaultCheck = false;
                defaultExceptionStatus = '';
                defaultSearchId = app.uri.match(/\d+/)[0];
            } else if (hiddenCheckbox) {
                defaultCheck = false;
                defaultCheckboxDisplay = 'none';
                defaultOrderArr = [1,'desc'];
            }
            this.orderArr = defaultOrderArr;

            this.renderExceptionStatus( this.exception_status);
            this.renderExceptionCategory(data.category);
            this.renderDates();
            this.dom.find('#my_exceptions_only').prop('checked', defaultCheck)
            this.dom.find('#my_exceptions_only').prop('disabled', false)
            this.dom.find('#my_exceptions_only_container').css('display',defaultCheckboxDisplay)
            this.dom.find($('select[name="exception_status[]"]')).val(defaultExceptionStatus)
            this.dom.find($('select[name="exception_status[]"]')).trigger('change');

            this.filterTrigger(defaultSearchId);

            this.dom.find($('#apply-filters')).show();

            this.dom.find('#exception-filters').on('submit', (event) => {
                event.preventDefault();
                this.filterTrigger();
            })

            $(document).on("click", ".exdeny", (event) => {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();

                let exceptionId = $(event.target).data().eid;
                new DenyException(exceptionId, this);
            });

            $(document).on("click", ".excancel", (event) => {
                event.preventDefault(); 
                event.stopPropagation();
                event.stopImmediatePropagation();

                let exceptionId = $(event.target).data().eid;
                new CancelException(exceptionId, this);
            });

            $(document).on("click", ".exapprove", (event) => {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();

                let exceptionId = $(event.target).data().eid;
                new ApproveException(exceptionId, this);
            });

            $(document).on("click", ".exreview", (event) => {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();

                let exceptionId = $(event.target).data().eid;
                new ReviewException(exceptionId, this);
            });

            $(document).on("click", ".exlinkmeeting", (event) => {
                let meeting_id = $(event.target).data().meetingid;
                app.navigate('meeting', [meeting_id]);
            });

            this.dom.find("#exception_container").show();
            this.dom.find(".page-loading-overlay").hide();

        })
    }

    filterTrigger(defaultSearchId = '') {
        if(this.validateFilters()) {
            this.dom.find('.page-loading-overlay').show();
            this.filterData = this.dom.find('#exception-filters').serialize();
            this.filterData += '&mode=list';
            let exception_status = this.dom.find($('select[name="exception_status[]"]')).val().join(', ');
            this.exceptionTable = new ExceptionTable({
                title: exception_status,
                ordering : true,
                order: this.orderArr,
                search : { "search" : defaultSearchId }
            });

            this.exceptionTable.recreateTable(this.filterData).then(() => {
                if(defaultSearchId !== '')
                {
                    this.dom.find('.viewlog_list[data-id="'+defaultSearchId+'"]').trigger('click');
                }
            });

            this.dom.find('#exceptions-table').html(this.exceptionTable.dom);
        }
    }

    validateFilters() {
        let ret = true;
        var required = $('input,textarea,select').filter('[required]:visible');
        $.each(required, (i, filter) => {
            let elem = $('[name="'+filter.name+'"]');
            elem.removeClass('is-invalid');
            if(!elem.val().length) {
                ret = ret && false;
                elem.addClass('is-invalid');
            }
        })
        return ret;
    }

    ready(){
        if(typeof this.exceptionTable == 'undefined')
        {
            this.initializeExceptions();
        }

        $(document).on("click", ".viewlog_list", (event) => {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();

            this.logModal.showModal($(event.target).data());
        });
    }
}
