var add_hipaa_template = require('./add_hipaa_modal.twig');

export class AddHipaa
{
    constructor(meetingPhase) {
        this.meetingPhase = meetingPhase
        this.dom = $(twig({ data: add_hipaa_template }).render({meetingPhase: this.meetingPhase}))
        let hipaaSpinner = this.dom.find("#hipaa-spinner")

        hipaaSpinner.show()
        this.getHipaaCounts().done((data) => {
            if(data.success)
            {
                this.dom.find("#hipaa_num").val(data.data.hipaa_count)
                this.dom.find("#hipaa_meal").val(data.data.hipaa_meal_count)
                this.dom.find("#add_hipaa_button").prop('disabled', false)
            }
            else
            {
                this.dom.find('#hipaa-message')
                    .addClass('alert-danger')
                    .text('Error loading HIPAA counts, please refresh the page.')
                    .show()
            }

            hipaaSpinner.hide()
        })

        this.dom.find('#add_hipaa_form').on('submit', (e) => {
            e.preventDefault();

            var hipaa_num = parseInt(this.dom.find('#hipaa_num').val());
            var hipaa_meal_num = parseInt(this.dom.find('#hipaa_meal').val());

            if(hipaa_num < hipaa_meal_num)
            {
                this.dom.find('#hipaa-message')
                    .addClass('alert-danger')
                    .text('HIPAA meals can not be greater than HIPAA attendees.')
                    .show()
                this.dom.find('#hipaa_meal').focus()

                return false;
            }

            hipaaSpinner.show()
            this.saveHipaaCounts(hipaa_num, hipaa_meal_num).done((data) => {
                if(data.success)
                {
                    this.dom.trigger('participantAdded', [e, data.data]);
                    this.modal.hide();
                }
            })
        });

        this.dom.on('hidden.bs.modal',  () => {
            this.modal.dispose();
        })

        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false});
        this.modal.show();
    }

    saveHipaaCounts(hipaaNum, hipaaMeals)
    {
        return $.ajax({
            url     : 'api/attendance/hipaa',
            method  : 'post',
            data    : {
                meeting_id: $('#meeting_id').val(),
                data : {
                    hipaa_num: hipaaNum,
                    hipaa_meals: hipaaMeals
                }
            }
        })
    }

    getHipaaCounts()
    {
        return $.ajax({
            url     : 'api/attendance/hipaacounts',
            method  : 'get',
            data    : {
                meeting_id: $('#meeting_id').val()
            }
        })
    }
}