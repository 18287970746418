import { Page } from './page.js'
import { Dropdown } from '../components/Dropdown'

let template = require('./marketingcontent/add.twig');

export class AddMarketingContent extends Page {

    constructor() {
        super();
        this.dom = $(twig({ data: template }).render({}));
        this.initDatepickers();
        this.dom.find('#marketing_list').on('click', () => {
            app.navigate('marketingcontent');
        })
        this.ddloaded = false;
    }

    validateForm() {
        let form = this.dom.find('#add_marketing_content_form')[0];

        let form_is_valid = form.checkValidity();

        if(this.dom.find('#program_type_id').val() == undefined
        || this.dom.find('#program_type_id').val() == '') {
            form_is_valid = false;
            this.dom.find('#program_type_id').siblings('.select2-container').find('.select2-selection').css({"border-color": "#dc3545"});
        }
        else {
            this.dom.find('#program_type_id').siblings('.select2-container').find('.select2-selection').css({"border-color": "#28a745"});
        }

        if(this.dom.find('#product_id').val() == undefined
        || this.dom.find('#product_id').val() == '') {
            form_is_valid = false;
            this.dom.find('#product_id').siblings('.select2-container').find('.select2-selection').css({"border-color": "#dc3545"});
        }
        else {
            this.dom.find('#product_id').siblings('.select2-container').find('.select2-selection').css({"border-color": "#28a745"});
        }

        form.classList.add('was-validated');

        return form_is_valid;
    }

    addEventHandlers() {
        this.dom.find('#marketing_file').fileupload({
            maxChunkSize: 1000000000, // 1000 MB
            url: "api/marketingcontent",
            dataType: 'json',
            type: 'POST',
            autoUpload: false,
            replaceFileInput: false,
            start: () => {
                this.dom.find('#add_marketing_content_form').hide(400);
                this.dom.find('div.card-body').append("<div id='submit_progress' class='progress w-25 mx-auto my-auto' style='height: 3em;'><div class='progress-bar progress-bar-striped progress-bar-animated bg-success w-100' role='progressbar'>Submitting Request...</div> </div>");
                this.dom.find('html').animate({scrollTop: '0px'}, 400);
            },
            done: (e, res) => {
                let message = res.result;

                if(res.result.success) {
                    message.message = "Request submitted successfully.";
                }
                else {
                    message.message = "Failed to submit request.";
                }

                displayNotification(message)

                app.navigate('marketingcontent')
                this.resetMarketingContentForm();
                this.dom.find('#add_marketing_content_form').show();
            },
            fail: () => {
                this.dom.find('#submit_progress').remove();
                let message = {};
                message.message = "Failed to process request.";
                message.success = false;

                displayNotification(message)
            }
        });

        this.dom.find('#full_material_title').on('keyup keydown change', (e) => {
            let char_count = $(e.target).val().length;
            this.dom.find('#full_material_title_char_count').text(char_count + "/" + $(e.target).attr('maxlength'));
        });

        this.dom.find('#full_old_material_title').on('keyup keydown change', () => {
            if(this.dom.find('#action').val() === 'replace'
            || this.dom.find('#action').val() === 'remove') {
                this.dom.find('#select_old_material_title').val(null).trigger('change');
            }
        });

        this.dom.find('#marketing_file').on('change', (e) => {
            $("#chosen_file_name").text($(e.target)[0]['files'][0]["name"]);
        });

        this.dom.find('#polling_deck').on('change', () => {
            if (this.dom.find('#polling_deck').val() === '1') {
                this.dom.find('#polling').prop('readonly', false).addClass('is-invalid').attr('required', 'required');
            } else {
                this.dom.find('#polling').prop('readonly', true).removeClass('is-invalid').removeAttr('required');
            }
        });

        this.dom.find('#videos_deck').on('change', () => {
            if (this.dom.find('#videos_deck').val() === '1') {
                this.dom.find('#videos').prop('readonly', false).addClass('is-invalid').attr('required', 'required');
            } else {
                this.dom.find('#videos').prop('readonly', true).removeClass('is-invalid').removeAttr('required');
            }
        });

        this.dom.find('#animation_deck').on('change', () => {
            if (this.dom.find('#animation_deck').val() === '1') {
                this.dom.find('#slides_with_animation').prop('readonly', false).addClass('is-invalid').attr('required', 'required');
            } else {
                this.dom.find('#slides_with_animation').prop('readonly', true).removeClass('is-invalid').removeAttr('required');
            }
        });

        this.dom.find('#action').on('change', (e) => {
            let selectedAction = $(e.target).val();

            switch(selectedAction) {
                case 'add':
                    this.dom.find('#audience_type').prop('disabled', false).addClass('invalid');
                    this.dom.find('#retraining_required').prop('disabled', false).addClass('invalid');

                    this.dom.find('#select_old_material_title').off();
                    this.dom.find('#select_old_material_title').prop('disabled', true);
                    this.dom.find('#select_old_material_title').removeAttr('required').removeClass('is-invalid');

                    this.dom.find('#full_old_material_title').prop('readonly', true).removeAttr('required').removeClass('is-invalid');
                    this.dom.find('#old_prc_code').prop('readonly', true).removeAttr('required').removeClass('is-invalid');
                    this.dom.find('#full_material_title').prop('readonly', false).attr('required', 'required').addClass('invalid');
                    this.dom.find('#prc_code').prop('readonly', false).attr('required', 'required').addClass('is-invalid');

                    this.dom.find('#material_information').prop('readonly', false);

                    this.dom.find('#material_upload_section input').prop('readonly', false);
                    this.dom.find('#material_upload_section').show(400);

                    this.dom.find('#go_live_date').prop('disabled', false).addClass('is-invalid').attr('required', 'required').val('').css('background-color', 'white');
                    this.dom.find('#expiration_date').prop('disabled', false).addClass('is-invalid').attr('required', 'required').val('').css('background-color', 'white');
                    break;
                case 'replace':
                    this.dom.find('#audience_type').prop('disabled', false).addClass('is-invalid');
                    this.dom.find('#retraining_required').prop('disabled', false).addClass('is-invalid');

                    this.dom.find('#full_old_material_title').prop('readonly', false).attr('required', 'required').addClass('is-invalid');
                    this.dom.find('#old_prc_code').prop('readonly', false).attr('required', 'required').addClass('is-invalid');
                    this.dom.find('#full_material_title').prop('readonly', false).attr('required', 'required').addClass('invalid');
                    this.dom.find('#prc_code').prop('readonly', false).attr('required', 'required').addClass('is-invalid');

                    this.dom.find('#material_information').prop('readonly', false);

                    this.dom.find('#material_upload_section input').prop('readonly', false);
                    this.dom.find('#material_upload_section').show(400);

                    this.dom.find('#go_live_date').prop('disabled', false).addClass('is-invalid').attr('required', 'required').val('').css('background-color', 'white');
                    this.dom.find('#expiration_date').prop('disabled', false).addClass('is-invalid').attr('required', 'required').val('').css('background-color', 'white');

                    this.dom.find('#select_old_material_title').off();
                    this.dom.find('#select_old_material_title').prop('disabled', false);
                    this.dom.find('#select_old_material_title').on('change', () => {
                        this.dom.find('span.select2.select2-container span.selection span.select2-selection__rendered').text(this.dom.find('#select_old_material_title option:selected').text());

                        if(this.dom.find('#select_old_material_title').val() !== ''
                        && this.dom.find('#select_old_material_title').val() != undefined) {
                            this.dom.find('#full_old_material_title').val(this.dom.find('#select_old_material_title option:selected').text());
                            this.dom.find('#old_prc_code').val(this.dom.find('#select_old_material_title option:selected').data('prccode'));
                        }
                    });
                    this.dom.find('#select_old_material_title').triggerHandler('change');
                    break;
                case 'remove':
                    this.dom.find('#audience_type').prop('disabled', true).removeClass('is-invalid');
                    this.dom.find('#retraining_required').prop('disabled', true).removeClass('is-invalid');
                    this.dom.find('#material_information').prop('readonly', true);

                    this.dom.find('#full_old_material_title').prop('readonly', false).attr('required', 'required').addClass('is-invalid');
                    this.dom.find('#old_prc_code').prop('readonly', false).attr('required', 'required').addClass('is-invalid');
                    this.dom.find('#full_material_title').prop('readonly', true).removeAttr('required').removeClass('is-invalid');
                    this.dom.find('#prc_code').prop('readonly', true).removeAttr('required').removeClass('is-invalid');

                    this.dom.find('#material_upload_section').hide(400);
                    this.dom.find('#material_upload_section input').prop('readonly', true);

                    this.dom.find('#go_live_date').prop('disabled', true).removeClass('is-invalid').removeAttr('required').val('').css('background-color', '#e9ecef');
                    this.dom.find('#expiration_date').prop('disabled', true).removeClass('is-invalid').removeAttr('required').val('').css('background-color', '#e9ecef');

                    this.dom.find('#select_old_material_title').off();
                    this.dom.find('#select_old_material_title').prop('disabled', false);
                    this.dom.find('#select_old_material_title').on('change', () => {
                        this.dom.find('span.select2.select2-container span.selection span.select2-selection__rendered').text(this.dom.find('#select_old_material_title option:selected').text());

                        if(this.dom.find('#select_old_material_title').val() !== ''
                        && this.dom.find('#select_old_material_title').val() != undefined) {
                            this.dom.find('#full_old_material_title').val(this.dom.find('#select_old_material_title option:selected').text());
                            this.dom.find('#old_prc_code').val(this.dom.find('#select_old_material_title option:selected').data('prccode'));
                        }
                    });
                    this.dom.find('#select_old_material_title').triggerHandler('change');

                    break;
            }

            this.dom.find('#add_marketing_content_form')[0].reset();
            this.resetMarketingContentForm();
            this.dom.find('#action').val(selectedAction);
        });

        this.dom.find('#material_type_container').on('change', '#material_type', (e) => {
            let selectedMaterialType = $(e.target).val();
            let selectedAction = this.dom.find('#action').val();
            this.dom.find('#slide_deck_wrapper').addClass('d-none').find('select').prop('required',false);
            this.dom.find('#slide_deck_container').addClass('d-none').find('select, input').prop('required', false);
            this.dom.find('#prc_other_container').addClass('d-none').find('input').prop('required', false);
            switch(this.dom.find("#material_type option:selected").data("typekey")) {
                case "slide_deck":
                    this.dom.find('#slide_deck_wrapper').removeClass('d-none').find('select').prop('required',true);
                    this.dom.find('#slide_deck_container').removeClass('d-none').find('select, input').prop('required', true);
                    if(this.dom.find('#program_type_id option:selected').text().toLowerCase().includes('pep')) {
                        this.dom.find('#full_material_title').attr('maxLength', 100);
                    }

                    if(this.dom.find('#action').val() === 'add'
                    || this.dom.find('#action').val() === 'replace') {
                        this.dom.find('#audience_type').prop('disabled', false).addClass('is-invalid');
                        this.dom.find('#retraining_required').prop('disabled', false).addClass('is-invalid');
                    }
                    break;
                case "invitation_flyer":
                case "invitation_email":
                case "invitation_direct_mail":
                case "evaluation":
                case "web_blurb":
                case "supporting_resources":
                case "other":
                    this.dom.find('#audience_type').prop('disabled', true).removeClass('is-invalid').removeAttr('required');
                    this.dom.find('#retraining_required').prop('disabled', true).removeClass('is-invalid').removeAttr('required');

                    if(this.dom.find('#action').val() === 'replace'
                    || this.dom.find('#action').val() === 'remove') {
                        this.dom.find('#full_old_material_title').prop('readonly', false).addClass('is-invalid').attr('required', 'required');
                    }
                    else {
                        this.dom.find('#full_old_material_title').prop('readonly', true).removeClass('is-invalid').removeAttr('required');
                    }
                    break;
            }

            this.resetMarketingContentForm();
            this.dom.find('#action').val(selectedAction);
            this.dom.find('#material_type').val(selectedMaterialType);
        });

        this.dom.find('#eval_prc_container').on('change', (e) => {
            let selectedAction = $(e.target).val();
            this.dom.find('#prc_other_container').addClass('d-none').find('input').prop('required', false);
            if (selectedAction == 'Other') {
                this.dom.find('#prc_other_container').removeClass('d-none').find('input').prop('required', true);
            } else {
                this.dom.find('#eval_prc').val(selectedAction);
            }
        });

        this.dom.find('#program_type_container').on('change', '#program_type_id', () => {
            if(this.dom.find('#program_type_id option:selected').text().toLowerCase().includes('pep')
            && this.dom.find('#material_type option:selected').data('typekey') === 'slide_deck') {
                this.dom.find('#full_material_title').attr('maxlength', 100);
            }
            else {
                this.dom.find('#full_material_title').attr('maxlength', 1500);
            }

            let char_count = this.dom.find('#full_material_title').val().length;
            this.dom.find('#full_material_title_char_count').text(char_count + "/" + this.dom.find('#full_material_title').attr('maxlength'));
        });

        this.dom.find('#submit_marketing_content').on('click', () => {
            if(this.validateForm()) {
                let action = this.dom.find('#action').val();
                let form_data = new FormData(this.dom.find('#add_marketing_content_form')[0]);
                form_data.append("program_type_id", this.dom.find('#program_type_id').val());
                form_data.append("product_id", this.dom.find('#product_id').val());

                if(action === 'replace'
                || action === 'add') {
                    let requestContent = {
                        files: this.dom.find('#marketing_file').prop('files'),
                        formData: form_data
                    };

                    this.dom.find('#marketing_file').fileupload('send', requestContent);
                }
                else if(action === 'remove') {
                    this.dom.find('#add_marketing_content_form').hide(400);
                    this.dom.find('div.card-body').append("<div id='submit_progress' class='progress w-25 mx-auto my-auto' style='height: 3em;'><div class='progress-bar progress-bar-striped progress-bar-animated bg-success w-100' role='progressbar'>Submitting Request...</div> </div>");
                    this.dom.find('html').animate({scrollTop: '0px'}, 400);

                    $.ajax({
                        url: 'api/marketingcontent',
                        data: form_data,
                        dataType: 'json',
                        method: 'POST',
                        processData: false,
                        contentType: false
                    }).then(
                        (res) => {
                            let message = res;

                            if(res.success) {
                                message.message = "Request submitted successfully.";
                            }
                            else {
                                message.message = "Failed to submit request.";
                            }

                            displayNotification(message)

                            app.navigate('marketingcontent')
                            this.resetMarketingContentForm();
                            this.dom.find('#add_marketing_content_form').show();
                        },
                        () => {
                            let message = {};
                            message.message = "Failed to process request.";
                            message.success = false;

                            displayNotification(message)
                        }
                    );
                }
            }
        });
    }

    resetMarketingContentForm() {
        this.dom.find('#submit_progress').remove();

        this.dom.find('#add_marketing_content_form')[0].reset();
        this.dom.find('#add_marketing_content_form').removeClass('was-validated');

        this.dom.find('#select_old_material_title').val(null);
        this.dom.find('#select_old_material_title').siblings('span.select2.select2-container').find('span.selection span.select2-selection__rendered').text(this.dom.find('#select_old_material_title option:selected').text());

        this.dom.find("#chosen_file_name").text('');

        this.dom.find('#product_id').siblings('.select2-container').find('.select2-selection').css({"border-color": "#dc3545"});
        this.dom.find('#program_type_id').siblings('.select2-container').find('.select2-selection').css({"border-color": "#dc3545"});

        this.dom.find('#program_type_id').val(null).trigger('change');
        this.dom.find('#product_id').val(null).trigger('change');
    }

    drawProgramTypesDD(data) {
        if(typeof data === 'object') {
            data = Object.values(data)
        }

        let progamTypeDD = new Dropdown(data)
		progamTypeDD
            .all(false)
            .label(' ')
            .required(true)
            .multiple(true)
            .addClass('is-invalid')
            .draw(this.dom.find('#program_type_container'), 'program_type_id', 'program_name', 'program_type_id')

        this.dom.find('#program_type_id').siblings('.select2-container').find('.select2-selection').css({"border-color": "#dc3545"});

        return new $.Deferred().resolve();
    }

    drawProductsDD(data) {
        let productsDD = new Dropdown(data)
		productsDD
            .all(false)
            .label(' ')
            .required(true)
            .addClass('is-invalid')
            .multiple(true)
            .draw(this.dom.find('#product_id_container'), 'id', 'name', 'product_id')

        this.dom.find('#product_id').siblings('.select2-container').find('.select2-selection').css({"border-color": "#dc3545"});

        return new $.Deferred().resolve();
    }

    drawMaterialTypesDD(data) {
        let materialTypeDD = new Dropdown(data)
		materialTypeDD
            .all(false)
            .label(' ')
            .required(true)
            .addClass('is-invalid')
            .draw(
                this.dom.find('#material_type_container'),
                'material_type_id',
                'material_type_name',
                'material_type',
                ["typekey"]
            );

        return new $.Deferred().resolve();
    }

    drawPrcCodesDD(data) {
        data.push({'prc_id': "Other"});
        let prcCodesDD = new Dropdown(data)
        prcCodesDD
            .all(false)
            .label(' ')
            .required(true)
            .addClass('is-invalid')
            .draw(
                this.dom.find('#eval_prc_container'),
                'prc_id',
                'prc_id',
                'eval_prc'
            );

        return new $.Deferred().resolve();
    }

    drawContactsDD(data) {
        let contactsDD = new Dropdown(data)
		contactsDD
            .all(false)
            .label(' ')
            .required(true)
            .addClass('is-invalid')
            .draw(this.dom.find('#program_ops_contact_container'), 'wwid', 'name', 'program_ops_contact')

        return new $.Deferred().resolve();
    }

    drawOldMaterialsDD(data) {
        let oldMaterialsDD = new Dropdown(data)
		oldMaterialsDD
            .all(false)
            .label(' ')
            .addClass('is-invalid')
            .draw(this.dom.find('#old_material_container'),
                'resource_id',
                'resource_name',
                'select_old_material_title',
                [
                    "prccode"
                ]
            );

        this.dom.find('#select_old_material_title').select2();

        return new $.Deferred().resolve();
    }

    drawDDs() {
        this.ddloaded = true;
        return $.when(
            this.drawProductsDD(app.appData.brandsProducts),
            this.drawMaterialTypesDD(app.appData.materialTypes),
            this.drawOldMaterialsDD(app.appData.oldMaterialTitles),
            this.drawContactsDD(app.appData.programOpsContacts),
            this.drawProgramTypesDD(app.appData.programTypes),
            this.drawPrcCodesDD(app.appData.prcCodes),
        );
    }

    initDatepickers() {
        this.dom.find('#go_live_date').datepicker({
            "format": "mm/dd/yyyy",
            "startDate": new Date()
        });

        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        this.dom.find('#expiration_date').datepicker({
            "format": "mm/dd/yyyy",
            "startDate": tomorrow
        });

        this.dom.find('#distribution_date').datepicker({
            "format": "mm/dd/yyyy",
            "startDate": new Date()
        });
    }

    ready() {
        if(!app.appData.brandsProducts) {
            fetch('api/marketingcontent/init')
            .then(response => { return response.json() })
            .then(data => {
                app.appData = { ... app.appData, ...data };
                return this.drawDDs();
            })
            .then(this.addEventHandlers.bind(this));
        }
        else if(!this.ddloaded) {
            this.drawDDs().then(this.addEventHandlers.bind(this));
        }
        this.dom.find('#material_type').val(null).trigger('change');
    }
}
