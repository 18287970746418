import { VenueSearch } from "./VenueSearch";
import { Card } from "./Card";
import { GoogleMap } from "../../components/GoogleMap";
import { Format } from "../../helpers/Format"

var venue_card_template = require('./venue_card.twig');

export class VenueCard extends Card
{
    constructor() {
        super();
        app.appData.layout.meeting_request.int_company_name =  app.appData.supportInfo.int_company_name
        this.dom = $(twig({ data: venue_card_template }).render(app.appData.layout.meeting_request));
        this.format = new Format();

        this.dom.find('.address_form:not(.always_visible)').hide();

        this.dom.find('.alternative_location_form').hide();
        this.dom.find('#add_alternative_location_button').on('click', () => {
            this.dom.find('#add_alternative_location_button').hide();
            this.dom.find('.alternative_location_form').show();
            this.dom.find('#secondary_venue .address_form').hide();
            this.dom.find('#secondary_venue .location_search_button').trigger('click');
        })

        this.resetCatererForm();

        this.dom.find('#add_caterer_button').on('click', () => {
            this.dom.find('#add_caterer_button').hide();
            this.dom.find('.caterer_form').show();
            this.dom.find('#caterer .address_form').hide();
            this.dom.find('#caterer .location_search_button').trigger('click');
        })

        let primary_venue = this.dom.find('#primary_venue');
        this.drawCountryStateDD(primary_venue.find('.country_dd'), primary_venue.find('.state_dd'));
        let secondary_venue = this.dom.find('#secondary_venue');
        this.drawCountryStateDD(secondary_venue.find('.country_dd'), secondary_venue.find('.state_dd'));
        let caterer = this.dom.find('#caterer');
        this.drawCountryStateDD(caterer.find('.country_dd'), caterer.find('.state_dd'));

        this.dom.find('.location_search_button').each((i, element) => {
            $(element).on('click', (e) => {
                if(typeof this.venueSearch == 'undefined')
                {
                    this.venueSearch = new VenueSearch();
                }else{
                    this.venueSearch.showModal(this.venueSearch.dom);
                }

                this.venueSearch.venue_slot = $(element).closest('.venue_slot')
                this.venueSearch.dom.find('.venue_type_dd').show();
                if($(e.target).data('venueType') == 'caterer') {
                    this.venueSearch.dom.find('.venue_type_dd').hide();
                }

                this.venueSearch.dom.off('VenueApplied').on('VenueApplied', () => {
                    $(element).closest('.venue_slot').find('.address_form').show();
                    this.checkAscensionLocation();
                    this.cardFilledOutTriggers();
                })

                this.venueSearch.dom.off('VenueAddManually').on('VenueAddManually', () => {
                    $(element).closest('.venue_slot').find('.address_form').show();
                    this.checkAscensionLocation();
                    this.cardFilledOutTriggers();
                })
            })
        })

        this.dom.find('.location_search_button:first()').on('click', () => {
            $('#secondary_venue').show();
        })

        this.dom.find('.location_map_button').each((i, element) => {
            $(element).on('click', () => {
                let slot_element = $(element).closest('.venue_slot');
                let venue_data = [];
                slot_element.find('input, select').each((i, e) => { venue_data.push($(e).val()) })

                let map = new GoogleMap();
                map.showGoogleMap(venue_data.join(' '));
            })
        })

        this.dom.find('#food_beverage').on('change', (event) => {
            if($(event.target).val() == 1) {
                this.dom.find('#set_num_container').show();
            } else {
                this.dom.find('#set_num_container').hide();
                this.resetCatererForm();
            }
        })

        this.dom.on('change', 'input.required, select.required, .vs_address1', () => {
            this.checkAscensionLocation();
            this.cardFilledOutTriggers();
        });

        this.dom.on('change', 'select[name="venue_type"]', () => {
            this.checkAscensionLocation();
            this.cardFilledOutTriggers();
        });

        this.dom.on('keyup', '.addr-pattern-valid', () => {
            let countryVal = $(event.target).closest('.venue_slot').find('.vs_country').val();
            this.format.setPatternTitleForAddressElements($(event.target),countryVal);
        });

        this.dom.on('change','.vs_country', () => {
            $(event.target).closest('.venue_slot').find('.addr-pattern-valid').each((index,el) => {
                this.format.setPatternTitleForAddressElements($(el),$(event.target).val());
                this.processPatternValidation($(el));
            });
        });

        // click `clear` in location form
        this.dom.on('click', '.location_clear_button', (event) => {
            $(event.target).parent().closest('.venue_slot').find('.address_form input, .address_form select').val(null);
        });

        this.dom.on('blur', '.pattern-valid', () => {
            this.processPatternValidation($(event.target));
        })

        this.drawVenueTypeDDs();
    }

    processPatternValidation(element) {
        let pattern = $(element).attr('pattern');
        if(typeof pattern == 'string' && $(element).val().match('^'+pattern+'$') == null) {
            $(element).addClass('is-invalid required').closest('div').find('span').text($(element).attr('title'))
            this.dom.trigger('CardNotFilledOut');
        } else {
            $(element).removeClass('is-invalid required').closest('div').find('span').text('')
            this.dom.trigger('CardFilledOut');
        }
    }

    resetAscensionLocationQuestion() {
        let asc_loc_radio_props = {
            "disabled": true,
            "checked": false
        };

        this.dom.find('#ascension_location_container').hide();
        this.dom.find('#ascension_location_container input[name="is_ascension_location"]').removeClass('required is-invalid').prop(asc_loc_radio_props).off();

        this.dom.find('#asc_trn_req_code').removeClass('required is-invalid').prop('disabled', true).hide().val(null);
        this.dom.find('#asc_trn_req_code_label').hide();

        this.dom.find('#food_beverage').val(null).prop('disabled', false);
        this.dom.find('#add_caterer_button').prop('disabled', false);
    }

    checkAscensionLocation() {
        if(!$('#meeting_type_dd').is(":visible")
        || $('#meeting_type_dd').val().trim() == '') {
            return;
        }

        let meetingTypes = app.appData.programtypeMeetingTypes[$('#program_type_dd').val()] ?? app.appData.projects[this.project_id].meeting_types
        let meeting_type_out_office = meetingTypes[$('#meeting_type_dd').val()]['out_office'] == true;

        let venue_filled = (
            this.dom.find('input[name="venue_name"]').val().trim().length > 0
            && this.dom.find("select[name='venue_type']").val() != ''
        );

        if((app.appData.layout.meeting_request.ascension_location || false)
        && !meeting_type_out_office
        && venue_filled
        && this.dom.find('#ascension_location_container input[name="is_ascension_location"]').prop('disabled')) {
            let ascLocQuestion = this.dom.find('#ascension_location_container input[name="is_ascension_location"]');

            let asc_loc_radio_props = {
                "disabled": false,
                "checked": false
            };

            ascLocQuestion.addClass('required is-invalid').prop(asc_loc_radio_props).on('change', () => {
                if(this.dom.find('#ascension_location_container input[name="is_ascension_location"]:checked').val() == "1") {
                    this.dom.find('#asc_trn_req_code').addClass('required is-invalid').prop("disabled", false).val(null).show();
                    this.dom.find('#asc_trn_req_code_label').show();

                    this.dom.find('#food_beverage').val(0).trigger('change').prop('disabled', true);
                    this.dom.find('#add_caterer_button').prop('disabled', true);
                }
                else {
                    this.dom.find('#asc_trn_req_code').removeClass('required is-invalid').prop("disabled", true).hide().val(null);
                    this.dom.find('#asc_trn_req_code_label').hide();
                    this.dom.find('#food_beverage').val(null).prop('disabled', false);
                    this.dom.find('#add_caterer_button').prop('disabled', false);
                }

                this.resetCatererForm();
            });

            new bootstrap.Popover($('#asc_loc_tooltip'), {
                html: true,
                placement: 'auto',
                container: 'body',
                trigger: 'hover',
                content: "<div class='align-text-start'>If you are uncertain if this is an Ascension Facility or have questions about this requirement, do not proceed with contacting the HCP(s). Instead, utilize the following resources to ensure the Ascension Policy is met:<br><br><ul><li>Check if the facility is on the Resource Group Roster: <a href='https://theresourcegroup.com/participantroster'>https://theresourcegroup.com/participantroster</a></li><li>All questions about the Vendor Access Policy should be directed to The Resource Group’s Customer Care team at ListenFirst@TheResourceGroup.com or 844.753.4778</li><li>Access information about Ascension Vendor Credentialing, Policy, and the Training Request Form on Go/OpenDoor (on VPN)</li></ul></div>"
            })

            this.dom.find('#ascension_location_container').show();
        }
        else if((app.appData.layout.meeting_request.ascension_location || false)
            && (meeting_type_out_office || !venue_filled)
            && this.dom.find('#ascension_location_container input[name="is_ascension_location"]').prop('disabled')) {
            this.resetAscensionLocationQuestion();
        }
        else if(this.dom.find('#ascension_location_container').length
            && this.dom.find("#ascension_location_container").is(":visible")
            && (meeting_type_out_office || !venue_filled)) {
            this.resetAscensionLocationQuestion();
            this.resetCatererForm();
        }
    }

    cardFilledOutTriggers() {
        super.cardFilledOutTriggers();

        // Highlight primary venue search button if primary venue required and venue name is blank
        let button = this.dom.find('#primary_venue .location_search_button');
        if(this.requiredFields.mf_venue.venue_name
        && this.dom.find('#primary_venue .address_form:hidden').length) {
            button.removeClass('btn-dark').addClass('btn-danger');
        }
        else {
            button.removeClass('btn-danger').addClass('btn-dark');
            this.dom.trigger('NeedBudgetUpdate');
        }
    }

    resetCatererForm() {
        this.dom.find('.caterer_form').hide();
        this.dom.find('.caterer_form input, .caterer_form select').val(null);
        this.dom.find('#add_caterer_button').show();
    }

    getVenueData() {
        let primary_venue_data = {};
        let secondary_venue_data = {};
        let caterer_data = {};
        let ascension_location_data = {};

        $('#primary_venue input:not(":checked"), #primary_venue select').each((i, e) => { primary_venue_data[$(e).attr('name')] = $(e).val() })
        $('#primary_venue input[type=checkbox]').each((i, e) => { primary_venue_data[$(e).attr('name')] = $(e).is(':checked') ? 1 : 0 });

        $('#secondary_venue input, #secondary_venue select').each((i, e) => { secondary_venue_data[$(e).attr('name')] = $(e).val() });

        let food_bev_dd = $("#food_beverage");
        let food_bev_dd_disabled = food_bev_dd.prop("disabled");
        food_bev_dd.prop("disabled", false);

        $('#caterer input, #caterer select').each((i, e) => { caterer_data[$(e).attr('name')] = $(e).val() });

        food_bev_dd.prop("disabled", food_bev_dd_disabled);

        $('#ascension_location_container input[type!="radio"]').each((i, e) => { ascension_location_data[$(e).attr('name')] = $(e).val() });
        $('#ascension_location_container input[type="radio"]:checked').each((i, e) => { ascension_location_data[$(e).attr('name')] = $(e).val() });

        return {
            primary     : primary_venue_data,
            secondary   : secondary_venue_data,
            caterer     : caterer_data,
            ascension   : ascension_location_data
        }
    }

    drawVenueTypeDDs() {
        let dds = this.dom.find('.venue_type_dd');
        let filtered_type = {};

        if(app.appData.layout.meeting_request.meetingtype_venuetype_filter) {
            let params = {}
            params.meeting_type = $('#meeting_type_dd').val();
            $.ajax({
                url: "api/filteredvenuetypes",
                data: params,
                async: false
            }).done((data) => {
                let typeid = {};
                typeid[data.venue_type_id] = data;
                filtered_type = typeid;
            })
        }

        dds.each((i, element) => {
            let dd = $(element);
            let venueTypes = app.appData.programTypeVenueTypes[$('#program_type_dd').val()] || app.appData.venueTypes

            if(app.appData.layout.meeting_request.meetingtype_venuetype_filter) {
                venueTypes = filtered_type;
            }

            dd.empty().append($("<option></option>"))
            for(let i in venueTypes) {
                let venueType = venueTypes[i];
                let venueTypeOption = $("<option value='" + venueType.venue_type_id + "'>" + venueType.venue_type_name + "</option>");
                dd.append(venueTypeOption);
            }
        })
    }

    clearAndHide() {
        this.dom.find('.address_form').hide();
        this.dom.find('#add_alternative_location_button').show();

        this.resetCatererForm();
        this.resetAscensionLocationQuestion();
        super.clearAndHide();
    }

    showPrimaryVenueForm() {
        this.dom.find('.location_search_button:first').hide();
        this.dom.find('.primary_location_form').show();
    }

    show() {
        super.show();

        this.project_id = app.pages.meetingrequest.formCards.project_card.project_id;
        this.program_type_id = app.pages.meetingrequest.formCards.project_card.program_type_id;

        this.resetCatererForm();

        this.resetAscensionLocationQuestion();
        $('#meeting_type_dd').on('change', () => {
            this.checkAscensionLocation();
            this.cardFilledOutTriggers();
        });

        let meeting_type_id = app.pages.meetingrequest.formCards.project_card.meeting_type_id;
        let event_type_id = app.pages.meetingrequest.formCards.project_card.event_type_id;
        let meeting_type_event_type;

        if(typeof app.appData.meetingTypeEventTypes[meeting_type_id] !== 'undefined') {
            meeting_type_event_type = app.appData.meetingTypeEventTypes[meeting_type_id][event_type_id];
        }

        let ppConfig = app.appData.projects[this.project_id].program_types[this.program_type_id].configuration.sb.meeting_request;
        let catering_enabled = ppConfig.catering_enabled &&
            (!$('#meeting_type_dd').is(":visible") || $('#meeting_type_dd option:selected').data('catering'));

        let show_contact_made = ppConfig.show_contact_made;
        let show_dietary_restrictions = ppConfig.show_dietary_restrictions;
        let show_reservation_made = ppConfig.show_reservation_made;
        let show_requesting_org = ppConfig.show_requesting_org;

        if(!catering_enabled) {
            this.dom.find('#caterer').hide();
        }

        if(!show_contact_made) {
            this.dom.find('[name=contact_made]').parent().hide();
        }

        if(!show_dietary_restrictions) {
            this.dom.find('[name=dietary_restrictions]').parent().hide();
        }

        if(!show_reservation_made) {
            this.dom.find('[name=reservation_made]').parent().hide();
        }

        if(!show_requesting_org) {
            this.dom.find('#requesting_org').hide();
        } else {
            this.dom.find('#requesting_org').show();
        }

        if(typeof meeting_type_event_type == 'undefined') {
            return;
        }

        let venue_id = meeting_type_event_type['default_venue_id'];
        if(!venue_id) {
            return;
        }

        $.ajax({
            url: 'api/venues/' + venue_id
        }).then(data => {
            this.showPrimaryVenueForm();

            this.dom.find('.primary_location_form').find('.address_form input, .address_form select').val('');

            let venue_data = Object.entries(data.venue);
            let venue_data_length = venue_data.length;

            for(let i = 0; i < venue_data_length; i++) {
                this.dom.find('.primary_location_form .vs_' + venue_data[i][0]).val(venue_data[i][1]);
            }

            this.checkAscensionLocation();
            this.cardFilledOutTriggers();
        });
    }
}
