var validate_rsvp_template = require('./validate_rsvp_model.twig');

export class ValidateRsvp
{
    constructor() {
        this.dom = $(twig({ data: validate_rsvp_template }).render());

        this.dom.find('#rsvp_close').on('click', () => {
            this.dom.find('#x_close').click();
        });

        this.modal = new bootstrap.Modal(this.dom);
        this.modal.show();
    }
}
