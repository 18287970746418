import {Card} from "./Card";

let upload_mirf_card = require('./upload_mirf_card.twig');

export class UploadMirfCard extends Card {
    constructor() {
        super();
        this.dom = $(twig({data: upload_mirf_card}).render());

        this.dom.find('.custom-file-input').on('change', (event) => {
            this.dom.find('.custom-file-label').html($(event.target)[0]['files'][0]["name"]);
            this.cardFilledOutTriggers();
        });
    }

    isFilledOut() {
        let filled_out = true;

        if(this.dom.find('.custom-file-input').val() == ""){
            filled_out = false;
            this.dom.find('.custom-file-input').addClass('is-invalid');
        }
        else{
            this.dom.find('.custom-file-input').removeClass('is-invalid');

        }

        return filled_out && super.isFilledOut();
    }
}