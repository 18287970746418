var template = require('./fileupload.twig');

export default class FileUpload {
    constructor(){
        this.dom = $(twig({ data: template }).render({
            document_types: app.appData.attachmentTypes
        }));

        let files = {};

        // Add events
        this.dom.find('input[type=file]').on('change', (event) => {
            files = event.target.files;
        });

        this.dom.find('.custom-file-input').on('change', (event) => {
            this.dom.find('.custom-file-label').html($(event.target).val());
        });

        this.dom.find('form').on('submit', (event) => {
            event.preventDefault();
            let data = new FormData(event.target)
            $.each(files, (key, value) => {
                data.append(key, value);
            });

            $.ajax({
                url : 'meeting/' + this.meeting_id + '/upload',
                method: 'post',
                data: data,
                processData: false,
                contentType: false
            }).then(() => {
                files = {};
                this.dom.find('#file_upload_form_container').hide();
                this.dom.find('#file_upload_success_container').show();
            })
        });
    }

    reset()
    {
        this.dom.find('form')[0].reset();
        this.dom.find('#file_upload_success_container').hide();
        this.dom.find('#file_upload_form_container').show();
    }
}
