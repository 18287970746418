import {CustomModal} from "../../components/customModal";

let template = require('./specialty_legend.twig');

export class SpecialtyLegend {
    constructor(meetingData) {
        this.meeting_id = meetingData.meeting_id;
        this.dom = $(twig({ data: template }).render({specialtyLegend: meetingData.specialty_legend}));
        let modal = new CustomModal({
            title: 'Specialty Legend',
            content: this.dom,
            buttons: [
                {
                    text: 'Export',
                    id: 'exportbtn',
                    close: false,
                    class: 'btn-danger',
                    action: () => this.export()
                },
                {
                    text: 'Close',
                    id: 'closebtn',
                    close: true,
                    class: 'btn-info',
                }
            ]
        });

        modal.open();
    }

    export() {
        window.location.href = "/api/attendance/approvedspecialties/export?meeting_id=" + this.meeting_id;
    }
}
