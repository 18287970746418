import {Card} from "./Card";

var template = require('./budget_card.twig');

export class BudgetCard extends Card {
    constructor() {
        super();
        this.dom = $(twig({data: template}).render());

        this.formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        if(app.appData.layout.meeting_request.show_budget.user_types.includes(
            parseInt(app.appData.curPosition.user_type_id)
        )) {
            this.dom.find('#budget_card').show();
        } else {
            this.dom.find('#budget_card').hide();
        }

        if(    typeof app.appData.layout.meeting_request.show_expenses !== 'undefined'
            && app.appData.layout.meeting_request.show_expenses.user_types.includes(parseInt(app.appData.curPosition.user_type_id)
        )) {
            this.dom.find('#expense_card').show();
        } else {
            this.dom.find('#expense_card').hide();
        }

        this.data = {
            budget: { amount: 0, spent: 0, remaining:0 },
            total: { default: 0 },
            expenses: []
        }

    }

    update(request_data) {
        return $.ajax({
            url: 'api/meetingrequest/expenses',
            method: 'get',
            data: request_data
        }).then((data) => {
            this.data = data;
            this.displayData();
        }).catch(() => {
            displayNotification({
                message: 'An issue has occurred, please contact support.',
                type: false
            });

            app.pages.meetingrequest.formCards.project_card.project_dd.val(null).trigger('change');
        })
    }

    reset() {
        this.data = {
            budget: { amount: 0, spent: 0, remaining:0 },
            total: { default: 0 },
            expenses: []
        }
        this.displayData();
    }

    displayData() {
        if(typeof this.data.total == 'undefined') {
            this.dom.find('#budget_alloted').html('Not available');
            this.dom.find('#budget_remaining').html('Not available');
        }
        else {
            this.dom.find('#budget_alloted').html(this.formatter.format(this.data.budget.amount));
            this.dom.find('#budget_remaining').html(this.formatter.format(this.data.budget.remaining - this.data.total.default));

            this.dom.find('#total_default_expenses').html(this.formatter.format(this.data.total.default));

            this.dom.find('#default_expenses_container').empty();
            for(let i in this.data.expenses) {
                this.dom.find('#default_expenses_container').append(
                    '<div class="col-12">' + this.data.expenses[i].label + ' ' +
                    '<span class="float-end">' + this.formatter.format(this.data.expenses[i].amount) + '</span>' +
                    '</div>');
            }
        }
    }
}
