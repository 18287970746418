import {Card} from "./Card";

let slt_form_email_card_template = require('./slt_form_email_card.twig');

export class SLTFormEmailCard extends Card {
    constructor() {
        super();
        this.is_short_lead = false;
        this.dom = $(twig({data: slt_form_email_card_template}).render());
    }

    isFilledOut(){
        this.is_short_lead = app.pages['meetingrequest'].formCards.meeting_date_card.short_lead_time;
        let filled_out = false;
        let exclude_short_lead = (typeof app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()]
        .configuration.sb.meeting_request.exclude_short_lead_form_email_details_card == "undefined") 
        ? false : app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()]
        .configuration.sb.meeting_request.exclude_short_lead_form_email_details_card;        
        if(this.is_short_lead){
            
            if(exclude_short_lead){
                $('#sfec_placeholder').removeAttr('required').removeClass('required is-invalid');
                $('#slt_form_email_card').hide();
                filled_out= true;
            }else{
                $('#sfec_placeholder').attr('required', 'required').addClass('required is-invalid');
                $('#slt_form_email_card').show();
                $('#speaker_card').hide();
            }
        }
        else{
            $('#sfec_placeholder').removeAttr('required').removeClass('required is-invalid');
            $('#slt_form_email_card').hide();
            filled_out = true;
        }
        this.updateLink();

        return filled_out && super.isFilledOut();
    }

    show(){
        super.show();
        this.dom.find('[name=sfec_placeholder]').attr('required', 'required').addClass('required is-invalid');

        this.updateLink();

    }

    updateLink(){
        let projProgTypeConf = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()].configuration;
        let default_slt_form_email_link = app.appData.defaultProjectProgramTypeConfig.sb.meeting_request.slt_form_email_link;

        let slt_form_email_link = default_slt_form_email_link;

        if(typeof projProgTypeConf.sb.meeting_request.slt_form_email_link !== 'undefined'){
            slt_form_email_link = projProgTypeConf.sb.meeting_request.slt_form_email_link;
        }    
        $("#slt_form_email_link").attr('href', slt_form_email_link);
    }
}