export class ExportCalendar {
    constructor(meeting_data) {
        this.dom = '';
        if(meeting_data.status_name == 'Logistics Confirmed'
        || meeting_data.status_name == 'Confirmed') {
            this.dom = $('<div class="float-end"><a href="#" id="export_button"><i class="far fa-calendar-plus fa-2x"></i><button class="btn btn-outline-primary">Add to calendar</button></a></div>');

            if(meeting_data.calendar_icon) {
                this.dom.find('button').hide();
            }
            else {
                this.dom.find('i').hide();
            }

            this.dom.find('#export_button').on('click', (e) => {
                e.preventDefault();

                let local_start_datetime = this.convertToLocalTZ(meeting_data.meeting_date, meeting_data.timezone)
                let local_end_datetime = moment(local_start_datetime).add(1, 'hours');

                if(meeting_data.meeting_endtime) {
                    local_end_datetime = this.convertToLocalTZ(meeting_data.meeting_endtime, meeting_data.timezone)
                }

                if(meeting_data.venue == undefined) {
                    meeting_data.venue = meeting_data.venue_name + ", " + meeting_data.venue_address1 + " " + meeting_data.venue_address2 + " " + meeting_data.venue_city + ", " + meeting_data.venue_state + " " + meeting_data.venue_zip;
                }

                let speaker = meeting_data.speaker_name_degree || meeting_data.speaker;

                let event = {
                    start: local_start_datetime.format('YYYYMMDDTHHmmss'),
                    end: local_end_datetime.format('YYYYMMDDTHHmmss')
                };

                if(meeting_data.external_programs_project == '1') {
                    Object.assign(
                        event,
                        {
                            title: meeting_data.sponsored_program_name,
                            url: meeting_data.sponsored_program_url,
                            description: meeting_data.sponsored_program_name + "\\n" + meeting_data.program_name + "\\n" + meeting_data.sponsored_program_url,
                            location: meeting_data.live_meeting_type == '1' ? meeting_data.venue.replace(/<br\/>/gi, ' ') : 'Virtual'
                        }
                    );
                }
                else {
                    let description = meeting_data.topic_name.replace(/<br\/>/gi, '\\n ') + "\\n" + meeting_data.venue.replace(/<br\/>/gi, '\\n ');

                    Object.assign(
                        event,
                    {
                            title: meeting_data.meeting_id + " : " + meeting_data.program_name + " " + speaker.replace(/<br\/>/gi, ' '),
                            url: $('base').attr('href') + app.uri,
                            description: description,
                            location: meeting_data.venue.replace(/<br\/>/gi, ' ')
                        }
                    );
                }

                let icsMSG = this.getCalendarData(event);

                if(typeof window !== "undefined"
                && window.navigator.msSaveOrOpenBlob
                && window.Blob) {
                    var blob = new Blob([icsMSG], { type: 'text/calendar;charset=utf-8' });
                    window.navigator.msSaveOrOpenBlob(blob, 'download.ics');
                }
                else {
                    var element = document.createElement('a');
                    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(icsMSG));
                    element.setAttribute('download', 'download.ics');

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                }
            });
        }
    }

    getCalendarData(event) {
        let uuid = uuidv4();

        return [
            "BEGIN:VCALENDAR",
            "VERSION:2.0",
            "CALSCALE:GREGORIAN",
            "METHOD:PUBLISH",
            "X-PUBLISHED-TTL:PT1H",
            "BEGIN:VEVENT",
            "UID:"+uuid,
            "SUMMARY:"+event.title,
            "DTSTAMP:"+moment().format('YYYYMMDDTHHmmss'),
            "DTSTART:"+event.start,
            "DTEND:"+event.end,
            "DESCRIPTION:"+event.description,
            "URL:"+event.url,
            "LOCATION:"+event.location,
            "END:VEVENT",
            "END:VCALENDAR"
        ].join("\r\n");
    }

    convertToLocalTZ(dateToFormat, timezone) {
        let tz = moment.tz.guess(true);
        if(tz == timezone) {
            return moment(dateToFormat);
        } else {
            let t = moment.tz(dateToFormat, timezone);
            return t.clone().tz(tz);
        }
    }

    hours12to24(meeting_time) {
        let t = meeting_time.split(" ");
        let am_pm = t[1];
        let h_m = t[0].split(":");
        let h = h_m[0];
        let pm = am_pm ? am_pm.toLowerCase() == 'pm' : null;

        let hour = (h == 12 ? pm ? 12 : 0 : pm ? parseInt(h) + 12 : h)
        hour = hour.length < 2 ? '0'+hour : hour ;
        return hour+":"+h_m[1];
    }
}
