import { Dropdown } from "./Dropdown";

var field_template = require('./speaker_filter_fields.twig')

export class SpeakerFilter {
    constructor(filters, wrapper) {
        this.containers = [];
        this.manual_reset_fields = [];

        this.filters = filters.sort((filter1, filter2) => { return filter1.display_order - filter2.display_order });

        filters.forEach((filter) => {
            if(!this.containers[filter.name]) {
                this.containers[filter.name] = $(twig({data: field_template}).render({filter: filter}));
            }

            if(filter.type == 'dropdown') {
                this.renderFiltersDD(filter);
            }

            if(filter.reload) {
                this.containers[filter.name].find('select').on('change', () => {
                    wrapper.trigger($.Event('reloadTable'))
                })
            }

            wrapper.append(this.containers[filter.name]);
        })
    }

    drawFiscalYearDD(filter) {
        this.containers[filter.name].html('');
        let projects = Object.values(app.appData.projects);
        let distinctYears = [...new Set(projects.map(proj => proj.proj_fy))].sort();
        const currentYear = new Date().getFullYear();
        let year = distinctYears.includes(currentYear) ? currentYear : (distinctYears.length > 0 ? distinctYears[distinctYears.length - 1] : '');
        let data = distinctYears.map(year => {
            return {'year': year}
        });
        let fiscalYearDD = new Dropdown(data)
        fiscalYearDD
            .all(false)
            .label(filter.label)
            .draw(this.containers[filter.name], 'year', 'year', filter.real_field)
        this.containers[filter.name].find('select').on('change', (elem) => {
            if (filter.dependency) {
                let dependencies = !Array.isArray(filter.dependency) ? filter.dependency.split() : filter.dependency;
                dependencies.forEach((dependency) => {
                    if (dependency === 'project_id' && this.getFilterConfig('project_id')) {
                        this.drawProjectDD(elem.currentTarget.value);
                    }

                    if (dependency === 'brand_id' && this.getFilterConfig('brand_id')) {
                        this.drawBrandDD();
                    }

                })
            }

        })
        this.containers[filter.name].find('select').val(year).trigger('change');
    }

    drawProjectDD(year = '', brand = '') {
        let filter = this.getFilterConfig('project_id');
        if(!this.containers[filter.name]) {
            this.containers[filter.name] = $(twig({data: field_template}).render({filter: filter}));
        }

        this.containers[filter.name].html('');
        let projects = Object.values(app.appData.projects);
        if(year) {
            projects = Object.values(app.appData.projects).filter(project => project.proj_fy == year);
        }

        if(brand) {
            projects = Object.values(app.appData.projects).filter(project => project.brand_id === brand);
        }

        let projectDD = new Dropdown(projects)
        projectDD
           .label(filter.label)
           .all(true)
           .draw(this.containers[filter.name], 'project_id', 'project_name', filter.name)

        this.containers[filter.name].find('select').on('change', (elem) => {
            if (filter.dependency) {
                let dependencies = !Array.isArray(filter.dependency) ? filter.dependency.split(): filter.dependency;

                dependencies.forEach((dependency) => {
                    if(dependency === 'topic_id' && this.getFilterConfig('topic_id') && elem.currentTarget.value) {
                        this.drawTopicDD(elem.currentTarget.value);
                    }

                    if(dependency === 'region' && this.getFilterConfig('region') && elem.currentTarget.value) {
                        this.drawRegionDD(elem.currentTarget.value);
                    }
                    
                    if(dependency === 'region2' && this.getFilterConfig('region2') && elem.currentTarget.value) {
                        this.drawRegion2DD(elem.currentTarget.value);
                    }

                })
            }
        }).trigger('change');
    }

    drawConsultantCategoryDD(filter) {
        this.containers[filter.name].html('');
        let consultantCategoryDD = new Dropdown(app.appData.consultantCategories)
        consultantCategoryDD
           .label(filter.label)
           .all(true)
           .multiple(filter.multiple ? filter.multiple : false)
           .addClass('w-100')
           .draw(this.containers[filter.name], 'id', 'consultant_category_type', filter.real_field)
    }

    drawSpecialtyDD(filter) {
        this.containers[filter.name].html('');
        let specialtyDD = new Dropdown(app.format.getSortedSpecialties())
        specialtyDD
           .label(filter.label)
           .all(false)
           .addClass('w-100')
           .multiple(filter.multiple ? filter.multiple : false)
           .draw(this.containers[filter.name], 'short_name', 'short_name', filter.real_field)
    }

    drawTllDD(filter) {
        this.containers[filter.name].html('');
        let tllDD = new Dropdown(app.appData.tlls)
        tllDD
           .label(filter.label)
           .all(false)
           .addClass('w-100')
           .multiple(filter.multiple ? filter.multiple : false)
           .draw(this.containers[filter.name], 'wwid', 'name', filter.real_field)
    }

    drawDateNotBooked(filter) {
        let data = [];
        this.containers[filter.name].html('');
        Object.keys(filter.values).map(function(objectKey) {
            data.push({field: objectKey, value: filter.values[objectKey]});
        });
        let dateNotBookDD = new Dropdown(data)
        dateNotBookDD
           .label(filter.label)
           .all(false)
           .multiple(filter.multiple ? filter.multiple : false)
           .draw(this.containers[filter.name], 'field', 'value', filter.real_field)
    }

    drawStateDD(filter) {
        this.containers[filter.name].html('');
        let stteDD = new Dropdown(app.appData.states)
        stteDD
           .label(filter.label)
           .all(true)
           .multiple(filter.multiple ? filter.multiple : false)
           .draw(this.containers[filter.name], 'alpha2', 'name', filter.real_field)
    }

    brandDD(data){
        let filter = this.getFilterConfig('brand_id');
        let year =  this.containers['fiscal_year'] ? this.containers['fiscal_year'].find('select').val(): '';
        this.containers[filter.name].html('');
        let brandDD = new Dropdown(data);
        brandDD
            .label(filter.label)
            .all(true)
            .multiple(filter.multiple ? filter.multiple : false)
            .draw(this.containers[filter.name], 'brand_id', 'brand_name', filter.real_field)

        this.containers[filter.name].find('select').on('change', (elem) => {
            if (filter.dependency) {
                let dependencies = !Array.isArray(filter.dependency) ? filter.dependency.split() : filter.dependency;

                dependencies.forEach((dependency) => {
                    if(dependency === 'project_id' && this.getFilterConfig('project_id') && elem.currentTarget.value) {
                        this.drawProjectDD(year, elem.currentTarget.value);
                    }

                })
            }

        }).trigger('change');
    }

    drawBrandDD(){
        let year = this.containers['fiscal_year'] ? this.containers['fiscal_year'].find('select').val() : false;
        if(year){
            $.ajax({
                url: 'api/projects/brands',
                method: 'get',
                data: {
                    fiscal_year: year
                }
            }).then((data) => {
                this.brandDD(data);
            })
        } else {
            let data = (typeof app.appData.brands === 'object') ? Object.values(app.appData.brands) : app.appData.brands;
            this.brandDD(data);
        }
    }

    topicDD(rawData) {
        let filter = this.getFilterConfig('topic_id');
        this.containers[filter.name].html('');
        let data = rawData.sort((topic1, topic2) => { return topic1.topic_name > topic2.topic_name ? 1 : -1 })
        let topicDD = new Dropdown(data)
        topicDD
           .label(filter.label)
           .all(true)
           .multiple(filter.multiple ? filter.multiple : false)
           .draw(this.containers[filter.name], 'topic_id', 'topic_name', filter.real_field)

            this.containers[filter.name].find('select').on('change', (e) => {
                if(this.getFilterConfig('trained_speakers')) {
                    let trainedSpeakerCheckbox = $('#' + this.getFilterConfig('trained_speakers').name);
                    let trainedSpeakerLabel = $('#' + this.getFilterConfig('trained_speakers').name + '_label');

                    trainedSpeakerCheckbox.prop('checked', false).hide();
                    trainedSpeakerLabel.hide();

                    if($("option:selected", e.target).prop('disabled') == false
                    && $("option:selected", e.target).text().length) {
                        trainedSpeakerCheckbox.prop('checked', true).show();
                        trainedSpeakerLabel.show();
                    }
                }
            }).trigger('change');
    }

    drawTopicDD(project_id) {
        if(project_id) {
            $.ajax({
                url     : 'api/projects/topics',
                method  : 'get',
                data: { projects : this.containers['project_id'].find('select').val() }
            }).then((data) => {
                this.topicDD(data)
            })
        } else {
            this.topicDD(app.appData.topics)
        }
    }

    drawDegreeDD(filter) {
        this.containers[filter.name].html('');
        let degreeDD = new Dropdown(app.format.getSortedDegrees())
        degreeDD
           .label(filter.label)
           .all(true)
           .multiple(filter.multiple ? filter.multiple : false)
           .draw(this.containers[filter.name], 'degree_abbrv', 'degree_desc', filter.real_field)
    }

    drawRegionDD(project_id) {
        let filter = this.getFilterConfig('region');
        let backend_search = app.appData.layout.speakers.backend_search ?? false;
        this.containers[filter.name].html('');
        let params = '';
        if(project_id) {
            params = '?project_id='+project_id;
        }
        if(filter) {
            fetch('api/speakers/regions'+params)
            .then(response => response.json())
            .then((data) => {
                let districtDD = new Dropdown(data)
                districtDD
                .label(filter.label)
                .all(false)
                .multiple(filter.multiple)
                .draw(this.containers[filter.name], 'region', 'region', 'region' + (filter.multiple && backend_search ? '[]' : ''))
            })
        }
    }

    drawRegion2DD(project_id) {
        let filter = this.getFilterConfig('region2');
        let backend_search = app.appData.layout.speakers.backend_search ?? false;
        this.containers[filter.name].html('');
        let params = '';
        if(project_id) {
            params = '?project_id='+project_id;
        }
        if(filter) {
            fetch('api/speakers/region2'+params)
            .then(response => response.json())
            .then((data) => {
                let districtDD = new Dropdown(data)
                districtDD
                .label(filter.label)
                .all(false)
                .multiple(filter.multiple)
                .draw(this.containers[filter.name], 'region2', 'region2', 'region2' + (filter.multiple && backend_search ? '[]' : ''))
            })
        }
    }

    drawLicenseStateDD(filter) {
        this.containers[filter.name].html('');
        if(filter) {
            let stateDD = new Dropdown(app.appData.states)
            stateDD
            .label(filter.label)
            .all(false)
            .multiple(filter.multiple)
            .draw(this.containers[filter.name], "alpha2", "alpha2", filter.name)
        }
    }

    drawSpeakerFieldDD(filter) {
        this.containers[filter.name].html('');
        if (filter && app.appData.layout.speakers.sfield_dd) {
            fetch('api/speakers/sfield?type='+filter.name)
            .then(response => response.json())
            .then((data) =>{
                let fieldDD = new Dropdown(data)
                fieldDD
                .label(filter.label)
                .all(false)
                .multiple(filter.multiple)
                .draw(this.containers[filter.name], 'field_key', 'field_key', filter.name + (filter.multiple ? '[]' : ''))
            })
        }
    }

    drawSpeakerExpDD(filter) {
        this.containers[filter.name].html('');
        if (filter && !app.appData.layout.speakers.hide_exp) {
            let expDD = new Dropdown(app.appData.layout.speakers.experience_dd)
            expDD
                .label(filter.label)
                .all(false)
                .multiple(false)
                .draw(this.containers[filter.name], 'key', 'val', filter.name)
        }
    }

    renderFiltersDD(filter) {
		filter.real_field = filter.name
		if (filter.multiple) {
			filter.real_field = filter.name + "[]"
			this.manual_reset_fields.push(filter);
		}
		switch (filter.name) {
			case 'fiscal_year' :
				this.drawFiscalYearDD(filter)
				break;
			case 'consultant_category' :
				this.drawConsultantCategoryDD(filter)
				break;
			case 'tll' :
				this.drawTllDD(filter)
				break;
			case 'date_not_booked_tod' :
				this.drawDateNotBooked(filter)
				break;
			case 'state' :
				this.drawStateDD(filter)
				break;
			case 'brand_id' :
				this.drawBrandDD(filter)
				break;
			case 'topic_id' :
				this.drawTopicDD()
				break;
			case 'specialty' :
				this.drawSpecialtyDD(filter)
				break;
			case 'degree' :
				this.drawDegreeDD(filter)
				break;
			case 'region' :
				this.drawRegionDD()
				break;
			case 'license_state' :
				this.drawLicenseStateDD(filter)
                break;
            case 'practice_info':
                this.drawPracticeInfoDD(filter)
                break;
            case 'injection_location':
            case 'guidance_used':
                this.drawSpeakerFieldDD(filter)
                break;
            case 'speaker_experience':
                this.drawSpeakerExpDD(filter)
                break;
            case 'region2' :
                this.drawRegion2DD()
                break;
		}
	}

	drawPracticeInfoDD(filter) {
		this.containers[filter.name].html('');
		let degreeDD = new Dropdown(app.appData.practiceInfo)
		degreeDD
            .label(filter.label)
            .all(true)
            .multiple(filter.multiple ? filter.multiple : false)
            .draw(this.containers[filter.name], 'field_key', 'field_key', filter.real_field)
	}

    getFilterConfig(selectedKey) {
        return this.filters.find(field => field.name == selectedKey)
    }
}
