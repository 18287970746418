var manage_group_template = require('./manage_group.twig');

export class ManageGroup
{
    constructor(config) {
        this.config = config

        this.dom = $(twig({ data: manage_group_template }).render())

        this.canAddToProgram = config.meetingData.status_key == 'confirmed' || config.meetingData.status_key == 'occured'

        this.getGroups().then((response) => {
            this.groups = response.data
            this.initializeTable()
        })

        this.dom.find('#add_group_form').on('submit', (event) => {
            let groupData = {
                groupName: this.dom.find('#group_name').val()
            }

            this.addGroup(groupData)
            this.dom.find('#group_name').val('')

            event.preventDefault()
        })

        this.dom.find('#datatable_groups').on('click', '.delete', (event) => {
            let groupData = $(event.target).closest('a').data()

            if(confirm('Are you sure you want to delete ' + groupData.groupName +  '?')) {
                this.deleteGroup(groupData.groupId)
            }

            event.preventDefault()
        })

        this.dom.find('#datatable_groups').on('click', '.edit', (event) => {
            let groupData = $(event.target).closest('a').data()
            this.dom.trigger('ShowEditGroup', [event, groupData])

            event.preventDefault()
        })

        this.dom.find('#datatable_groups').on('click', '.add-to-program', (event) => {
            let groupData = $(event.target).closest('a').data()
            let td = $(event.target).closest('td')
            $(event.target).closest('a').remove()
            td.append(`
                <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                </div>`
            );

            this.addToProgram(groupData.groupId).then(() => {
                td.find('.spinner-border').remove()
            })

            event.preventDefault()
        })

        this.dom.on('hidden.bs.modal',  () => {
            this.modal.dispose();
        })

        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false});
        this.modal.show();
    }

    deleteGroup(group_id) {
        return $.ajax({
            url : `/api/attendance-group/${group_id}`,
            type : 'DELETE'
        }).then((response) => {
            if(response.success) {
                this.datatable.row('#group-' + group_id).remove().draw()

                displayNotification({
                    message: 'Group deleted.',
                    success: true
                })
            }

            return response
        })
    }

    addGroup(data) {
        return $.ajax({
            url: `/api/attendance-group`,
            type: 'POST',
            data: data
        }).then((response) => {
            if(response.success) {
                this.datatable.row.add(response.data).draw()
            }
            return response
        })
    }

    getGroups() {
        return $.ajax({
            url: `/api/attendance-group`,
            type: 'GET'
        })
    }

    addToProgram(groupId) {
        let xhr = $.ajax({
            url: `api/attendance/addgroupattendees`,
            type: 'POST',
            data: {
                meeting_id: this.config.meetingData.meeting_id,
                group_id: groupId
            }
        })
        xhr.then((response) => {
            if(response.success) {
                this.dom.trigger('participantAdded')
            }

            return response
        })

        return xhr
    }

    initializeTable() {
        this.datatable = this.dom.find('#datatable_groups').DataTable({
            "rowId": (data) => { return "group-" + data.group_id },
            "data": this.groups,
            "displayLength": 10,
            "sort": true,
            "paginate": true,
            "filter": false,
            "paginationType": "full_numbers",
            "columns": [
                {"data": "group_name"},
                {"data": "num_of_members"},
                {"data": "time_created"},
                {"data": "time_updated"},
                {
                    "data": null,
                    "render": (data, type, row) => {
                        let html = `
                            <a class="edit mx-1" data-group-id="${row['group_id']}" data-group-name="${row['group_name']}" title='Edit'><i class="fas fa-edit"></i></a>
                            <a class="delete mx-1" data-group-id="${row['group_id']}" data-group-name="${row['group_name']}" title='Delete'><i class="fas fa-trash-alt"></i></a>`
                        if(this.canAddToProgram && row['num_of_members'] > 0 ) {
                            html += `<a class="add-to-program mx-1" data-group-id="${row['group_id']}" data-group-name="${row['group_name']}" title='Add to program'><i class="fas fa-plus"></i></a>`
                        }

                        return html;
                    }
                }
            ]
        })
    }
}
