var comm_history_template = require('./email_metrics_modal.twig');

export class EmailMetricsModal
{
    constructor(config) {
        this.config = config

        this.getMetrics()
        this.dom = $(twig({ data: comm_history_template }).render({config: this.config, communications: this.metrics}))

        this.modal = new bootstrap.Modal(this.dom);
        this.modal.show();
    }

    getMetrics() {
        return $.ajax({
            url     : `api/attendance/${this.config.meetingId}/communications/metrics`,
            method  : 'get',
            async: false
        }).done((response) => {
            this.metrics = response.data
        });
    }
}
