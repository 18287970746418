import { Dropdown } from "../../components/Dropdown";

let template = require('./modal.add_note.twig');

export class ModalAddNote
{
    constructor() {
        this.meeting_id;
        this.exception_id;
        this.status_id;
        this.response = {};
        this.formData = {
            note: '',
            noteAppend: '',
            notifyArray: []
        };
        this.dropdownArray = [];
        this.config = app.appData.layout.meeting?.exception_tagged_users_config ?? {};

        this.dom = $(twig({data: template}).render({
        }));

        this.modal = new bootstrap.Modal(this.dom);

        this.dom.find('form').on('submit', () => {
            this.dom.find(':input[type=submit]').prop('disabled',true);
            this.submitNote().then((response) => {
                this.response = response;
                this.modal.hide();
                this.dom.find(':input[type=submit]').prop('disabled',false);
            });
        });

        if(Object.keys(this.config).length == 0)
        {
            this.dom.find("#notify_form").hide();
        } else if(this.config?.user_type_members && this.config.user_type_members.length > 0) {
            this.drawDropdownConfigs();
        }
    }

    setData(data){
        this.meeting_id = data.meetingId;
        this.exception_id = data.id;
        this.status_id = data.statusId;
        if(this.config?.meeting_members && Object.keys(this.config.meeting_members).length > 0)
        {
            $.ajax({
                url: "api/getMeetingUsers",
                data: {
                    meeting_id: this.meeting_id,
                    user_fields: Object.keys(this.config.meeting_members)
                },
                method: 'post'
            }).then((data) => {
                this.populateMeetingUsers(data);
            })
        }
    }

    populateMeetingUsers(userData){
        let user;
        let memberElement;
        let memberElements = [];
        let memberLabelElements = [];
        for (user in userData) {
            if(typeof userData[user].name == "string" && userData[user].name > '') {
                memberElement = $("<div class='col'>");
                memberElement.append(
                    "<div for='notify_" + user + "'>" + userData[user].name + "</div>"
                );
                memberElements.push(memberElement)
                memberLabelElements.push("<div class='col'><input type='checkbox' name='notify_" + user + "' id='notify_" + user + "' value='" + userData[user].wwid + "'>&nbsp;" + this.config.meeting_members[user] + "</div>")
            }
            

        }
        this.dom.find('#notify_members_container, #notify_members_labels_container').html('');
        this.dom.find('#notify_members_labels_container').append(memberLabelElements);
        this.dom.find('#notify_members_container').append(memberElements);
    }

    drawDropdownConfigs(){
        let ddIndex;
        let ddElement;
        let ddElements = [];
        for (ddIndex in this.config.user_type_members)
        {
            let identifier = 'notify-dd-'+ddIndex;
            ddElement = $('<div class="col-md-6">');
            ddElement.append(
                "<label>" + this.config.user_type_members[ddIndex].label + "</label>",
                '<div class="'+identifier+'"></div>'
            );
            ddElements.push(ddElement);
            $.ajax({
                url: 'api/sfmember',
                method: 'get',
                data: {
                    "user_type_ids": this.config.user_type_members[ddIndex].user_type_ids,
                    "project_id": []
                }
            }).then((data) => {
                let dropdown = new Dropdown(data)
                dropdown
                    .label('Select')
                    .all(false)
                    .multiple(true)
                    .isModalContainer(true)
                    .draw(this.dom.find('.'+identifier), 'wwid', 'full_name', identifier)
                this.dropdownArray.push(dropdown)
            })
        }

        this.dom.find('#notify_dropdowns').html('');
        this.dom.find('#notify_dropdowns').append(ddElements);
    }

    aggregateData(){
        this.formData.note = this.dom.find('#ex_add_note').val().trim();
        let dfd = jQuery.Deferred();
        let appendArray = [];
        let dd;

        if(this.formData.note === '') {
            dfd.reject("Note text is required for form submission.");
        } else {
            // Checkboxes
            this.dom.find('#notify_members_container').find('input:checked').each(( index, element ) => {
                this.formData.notifyArray.push($(element).val());
                let identifier = $(element).attr('name').replace('notify_','');
                appendArray.push("<strong>" + this.config.meeting_members[identifier] + ":</strong> " + $(element).labels().text());
            });
            // Dropdowns
            for (dd in this.dropdownArray) {
                let identifier = 'notify-dd-' + dd;
                if($('#' + identifier + ' :selected').length > 0)
                {
                    let label = "<strong>" + this.config.user_type_members[dd].label + ":</strong> " ;
                    let list = [];
                    this.dom.find('#' + identifier + ' :selected').each((selectIndex,selectElement) => {
                        this.formData.notifyArray.push($(selectElement).val());
                        list.push($(selectElement).text());
                    });
                    appendArray.push(label + list.join(', '));
                }
            }
            if(appendArray.length > 0)
            {
                this.formData.noteAppend += '<br>Notified: ';
                this.formData.noteAppend += appendArray.join(' | ');
            }
            dfd.resolve();
        }

        return dfd.promise();
    }

    reset(){
        this.dom.find('#ex_add_note').val('');
        this.dom.find('#notify_members_container').find('input:checked').prop('checked',false);
        this.formData = {
            note: '',
            noteAppend: '',
            notifyArray: []
        };
        this.response = {};
        let dd;
        for (dd in this.dropdownArray){
            this.dom.find('#notify-dd-'+dd+' :selected').prop('selected',false);
            this.dropdownArray[dd].refreshMultiSelect();
        }
    }

    insertNote(){
        return $.ajax({
            url: "api/exceptions/addnote",
            type: "post",
            data: {
                excepId: this.exception_id,
                noteText: this.formData.note + this.formData.noteAppend,
                status: this.status_id
            }
        })
    }

    setupNotification(){

        return $.ajax({
            url: "api/notifications/notify",
            type: "post",
            data: {
                meeting_id: this.meeting_id,
                wwids: this.formData.notifyArray,
                note: this.formData.note,
                html_prepend: '<a target="_blank" href="exceptions/search/'+this.exception_id+'">Exception ID '+
                    this.exception_id +'</a> (<a target="_blank" href="meeting/'+ this.meeting_id +'">Program ID #'+
                    this.meeting_id +'</a>):&nbsp;'
            }
        })
    }

    submitNote(){
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        let dfd = jQuery.Deferred();

        this.dom.find('.add-note-error').html('').removeClass('alert-danger');
        this.aggregateData()
        .then(() => {
            $.when(this.insertNote(), this.setupNotification())
                .done((noteResult, notifyResult) => {
                dfd.resolve({
                    'success' : noteResult[0].success,
                    'message' : noteResult[0].message + '<br>' + notifyResult[0].message
                });
            })
        },(error) => {
            this.dom.find('.add-note-error').html(error).addClass('alert-danger').show();
            dfd.reject({'success' : false});
        });
        return dfd.promise();
    }
}