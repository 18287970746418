import {Card} from "./Card";

let external_programs_template = require('./external_programs_card.twig');
let external_programs_table_template = require('./external_programs_table.twig');

export class ExternalProgramsCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({data: external_programs_template}).render());
    }

    displayData(project_id, program_type_id, meeting_date) {
        this.dom.find('.card-body').empty();

        if(parseInt(project_id) === 0
        || parseInt(program_type_id) === 0
        || meeting_date === "") {
            this.dom.hide();
            return false;
        }

        let program_type = app.appData.projects[project_id].program_types[program_type_id];

        if(program_type != undefined
        && program_type.configuration.sb.meeting_request.display_external_program_alert) {
            $.ajax({
                url: 'api/meetingrequest/externalprograms',
                data: {
                    brand_id: app.appData.projects[project_id].brand_id,
                    event_type_id: app.appData.projects[project_id].event_type_id,
                    meeting_date: meeting_date
                }
            }).then(data => {
                if(data.meetings.length > 0) {
                    let external_programs_table_dom = $(twig({data: external_programs_table_template}).render(data));

                    external_programs_table_dom.find('.title').each((idx, el) => {
                        if($(el).text().length > 10) {
                            let full_text = $(el).text();

                            let display_text = full_text.slice(0, 10) + '...';

                            $(el).data('content', full_text).addClass('long_title');
                            $(el).text(display_text);
                        }
                    });

                    new bootstrap.Popover(external_programs_table_dom.find('.long_title'), {
                        placement: 'top',
                        trigger: 'hover',
                        container: 'body'
                    })

                    this.dom.find('.card-body').append(external_programs_table_dom);
                    this.dom.show();
                }
                else {
                    this.dom.hide();
                }
            })
        }
    }
}
