import {Calendar as FullCalendar} from "@fullcalendar/core";
import dayGrid from "@fullcalendar/daygrid";
import timeGrid from "@fullcalendar/timegrid";
import {ExportCalendar} from "./ExportCalendar";

let template = require('./calendar.twig');
let status_template = require('./calendar_status_legend.twig');
let popup_template = require('./calendar_popup.twig');
let external_program_popup_template = require('./external_program_calendar_popup.twig');

export class Calendar
{
    constructor(options) {
        let statusColor = {}
        let exclude = options.exclude ?? []
        $.each(app.appData.meetingStatusColorMap, (key, status) => {
            if(!exclude.includes(key)) {
                key = key == 'default'?'Completed':key;
                statusColor[key] = status.replace(/text/, 'btn');
            }
        });

        this.dom = $(twig({ data: template }).render({status_color_map: statusColor}));
        this.options = options;
        this.calendar_status_legend = $(twig({ data: status_template }).render());

        this.renderCalendar();
    }

    renderCalendar() {
        this.calendar = new FullCalendar(this.dom.find('.fc_calendar_container')[0], {
            'plugins': [
                dayGrid,
                timeGrid
            ],
            defaultView: 'dayGridMonth',
            nowIndicator: true,
            viewSkeletonRender: (info) => {
                this.attachCalDropdowns(info);
            },
            datesRender: (info) => {
                this.attachCalDropdowns(info);
                if(this.dom.find('div.fc-center .dropdown-components.dropdown-clone').data('manualChange') !== 1) {
                    let current_start = info.view.currentStart;
                    let current_start_date = parseInt(current_start.getDate(), 10);
                    let current_start_month = parseInt(current_start.getMonth(), 10) + 1;
                    let current_start_year = parseInt(current_start.getFullYear(), 10);

                    this.dom.find('div.fc-center .calendar-dropdown-days select').val(current_start_date);
                    this.dom.find('div.fc-center .calendar-dropdown-months select').val(current_start_month);
                    this.dom.find('div.fc-center .calendar-dropdown-years select ').val(current_start_year);
                }
            },
            dayRender: (day) => {
                // When availability options provided, block out specific days of week & requested date ranges
                if(this.options.availability){
                    let unavailable_dates = this.options.availability.unavailable_dates;
                    let len = unavailable_dates.length;
                    let event_date_str = moment(day.date).format('YYYYMMDD');
                    let dow = day.date.getDay();

                    if(!this.options.availability.all && this.options.availability.weekdays[dow] !== true) {
                        $(day.el).addClass('date-unavailable');
                    } else {
                        for(let range=0; range<len; range++){
                            let range_start = unavailable_dates[range]['start_date'];
                            let range_end = unavailable_dates[range]['end_date'];
                            if(event_date_str >= moment(range_start).format('YYYYMMDD') && event_date_str <= moment(range_end).format('YYYYMMDD')){
                                $(day.el).addClass('date-unavailable');
                            }
                        }
                    }
                }
            },
            header: {
                left: 'dayGridMonth,timeGridWeek,timeGridDay',
                center: false,
                right: 'today prev,next'
            },
            buttonText: {
                today:     'Today',
                  month:    'Month',
                  week:     'Week',
                  day:      'Day',
                  list:     'List'
            },
            allDayText: 'All Day',
            events: (info, success, failure) => {
                let data = {
                    'from_program_date': info.startStr,
                    'to_program_date': info.endStr
                }
                if(typeof this.options.filter_form != 'undefined') {
                    data = {...data, ...this.options.filter_form.setFilterData()};
                }

                $.ajax({
                    url: this.options.url,
                    method: 'GET',
                    dataType: 'json',
                    data: data
                }).then(
                    (events) => {
                        $.each(events, (idx, event) => {
                            let formatted_meeting_id = app.appData.layout.programs.format_meeting ? 'MF'+event.meeting_id.toString().padStart(5,0) : event.meeting_id
                            event.title = event.title ? event.title : event.mtg_time+', '+formatted_meeting_id;
                            event.className = app.format.setstatusColor(event);
                            event.start = event.meeting_date;
                        });
                        success(events);
                    },
                    (err) => {
                        failure(err);
                    }
                );
            },
            eventClick: (eventClickInfo) => {
                this.showCalendarPopup(eventClickInfo);
            },
            eventRender: (info) => {
                if(info.view.type === 'timeGridWeek' || info.view.type === 'timeGridDay') {
                    let events = this.calendar.getEvents();
                    let current_start = new Date(info.view.currentStart);
                    let current_end = new Date(info.view.currentEnd);
                    let earliest_hour = 99;

                    let event_start = new Date();
                    let event_end = new Date();

                    $.each(events, (idx, event) => {
                        event_start = new Date(event.start);
                        event_end = new Date(event.end);

                        if(event_start >= current_start
                            && event_end <= current_end
                            && event_start.getHours() < earliest_hour) {
                            earliest_hour = event_start.getHours();
                        }
                    });

                    earliest_hour = (earliest_hour.toString().length > 1) ? earliest_hour.toString() : '0' + earliest_hour;

                    let earliest_time = earliest_hour + ':00:00';
                    this.dom.find('html, body').animate({
                        scrollTop: this.dom.find('div.fc-slats tr[data-time="'+earliest_time+'"]').offset().top - 4 * this.dom.find('div.fc-slats tr').first().height()
                    });

                    this.dom.find('div.fc-scroller').animate({
                        scrollTop: this.dom.find('div.fc-slats tr[data-time="'+earliest_time+'"]').position().top - 4 * this.dom.find('div.fc-slats tr').first().height()
                    });
                }

                var title = $(info.el).find('.fc-title, .fc-list-item-title');

                if(app.appData.layout.programs.show_sponsored_programs && info.event.extendedProps.sponsored_program == 'Yes') {
                    title.append('<br />');
                    if(info.event.extendedProps.sponsored_program_name) {
                        title.append(info.event.extendedProps.sponsored_program_name);
                    } else {
                        title.append('<i>sponsored</i> ')
                    }

                    title.append('<i class="fas fa-star" style="color: white"></i>');
                }

                let host = info.event.extendedProps.accountable;
                if(host == null) {
                    host = info.event.extendedProps.rep;
                }
                title.append('<br />Host: ' + host);

                title.append('<br />' + app.appData.labels.speaker + ': ' + info.event.extendedProps.speaker);

                //hub/spoke join
                if(this.checkHubJoin(info.event.extendedProps)){
                    title.append('<div style="width: 100%"><div class="btn btn-xs btn-light join-btn float-end" data-page="meetingrequest">Join</div></div>');
                } else if ($('#joinable_programs').is(':checked')) {
                    info.el.style.display = 'none'
                }

            },
            displayEventTime: false
        });

        $('.calExport').html('<button class="float-end btn btn-success export-cal" title="Click to Download"> <span><i class="fas fa-file-excel"></i> </button>');
        $('.export-cal').on('click', () => {
            window.open('api/programs/list/export?'+ $.param({"data": app.programFilter.setFilterData()}));
        })
        this.calendar.render();
    }

    attachCalDropdowns(viewInfo) {
        let current_full_date = new Date();
        let current_date = current_full_date.getDate();
        let current_month = current_full_date.getMonth() + 1;
        let current_year = current_full_date.getFullYear();
        let start_year = current_year - 5;
        let days_in_current_month = this.getDaysInMonth(current_month - 1, current_year);

        this.dom.find('.calendar-dropdown-years select.calendar-dropdown').not('.dropdown-clone').children('option').each((idx, option) => {
            $(option).val(start_year).text(start_year);
            start_year++;
        });

        this.generateDaysDropdown(days_in_current_month);

        let dropdown_components = this.dom.find('.dropdown-components').not('.dropdown-clone').clone();
        dropdown_components.toggleClass('dropdown-clone', true);
        this.dom.find('select.calendar-dropdown', dropdown_components).toggleClass('dropdown-clone', true);

        this.dom.find('div.fc-center').empty().append(dropdown_components);

        this.dom.find('.calendar-dropdown-years select.calendar-dropdown').val(current_year);
        this.dom.find('.calendar-dropdown-months select.calendar-dropdown').val(current_month);
        this.dom.find('.calendar-dropdown-days select.calendar-dropdown').val(current_date);

        this.dom.find('div.fc-center .dropdown-components').on('change', '.calendar-dropdown', viewInfo.view.calendar, () => {
            let year = parseInt(this.dom.find('div.fc-center .calendar-dropdown-years select.calendar-dropdown').val(), 10);
            let month = parseInt(this.dom.find('div.fc-center .calendar-dropdown-months select.calendar-dropdown').val(), 10);
            let day = parseInt(this.dom.find('div.fc-center .calendar-dropdown-days select.calendar-dropdown').val(), 10);

            let days_in_month = this.getDaysInMonth(month, year);
            this.generateDaysDropdown(days_in_month);

            let days_dropdown_component = this.dom.find('.dropdown-components').not('.dropdown-clone').find('.calendar-dropdown-days select.calendar-dropdown').clone();
            days_dropdown_component.toggleClass('dropdown-clone', true);
            this.dom.find('div.fc-center .calendar-dropdown-days select.calendar-dropdown').replaceWith(days_dropdown_component);

            this.dom.find('div.fc-center .dropdown-components.dropdown-clone').data('manualChange', 1);

            month = (month.toString().length === 1 ? '0' : '') + month.toString();
            day = (day.toString().length === 1 ? '0' : '') + day.toString();

            this.calendar.gotoDate(year + '-' + month + '-' + day);

            this.dom.find('.calendar-dropdown-years select.calendar-dropdown').val(year);
            this.dom.find('.calendar-dropdown-months select.calendar-dropdown').val(parseInt(month, 10));
            this.dom.find('.calendar-dropdown-days select.calendar-dropdown').val(parseInt(day, 10));

            this.dom.find('div.fc-center .dropdown-components.dropdown-clone').data('manualChange', 0);

            this.dynamicallyAdjustCalDropdownWidth();
        });

        this.dynamicallyAdjustCalDropdownWidth();

        if(viewInfo.view.type.toLowerCase() === 'timegridweek'
            || viewInfo.view.type.toLowerCase() === 'timegridday') {
            this.dom.find('div.fc-center .row').show();
            return true;
        } else if (viewInfo.view.type.toLowerCase() === 'daygridmonth') {
            this.dom.find('div.fc-center .row').show();
            this.dom.find('div.fc-center .row .calendar-dropdown-days').hide();

            return true;
        }

        return false;
    }

    getDaysInMonth(month, year) {
        return (new Date(year, month, 0)).getDate();
    }

    generateDaysDropdown(days_in_current_month) {
        let days_offset = this.dom.find('.calendar-dropdown-days select.calendar-dropdown').not('.dropdown-clone').children('option').length - days_in_current_month + 1;

        if(days_offset > 0) {
            //remove days
            for(let i = 1; i < days_offset; i++) {
                this.dom.find('.calendar-dropdown-days select.calendar-dropdown').not('.dropdown-clone').find(':last-child').remove();
            }
        }
        else if(days_offset < 0) {
            //add days
            days_offset = Math.abs(days_offset) + 1;
            let current_day_option = parseInt(this.dom.find('.calendar-dropdown-days select.calendar-dropdown').not('.dropdown-clone').find(':last-child').val(), 10);
            let final_day_option = current_day_option + days_offset;
            current_day_option++;

            for(current_day_option; current_day_option <= final_day_option; current_day_option++) {
                this.dom.find('.calendar-dropdown-days select.calendar-dropdown').not('.dropdown-clone').append('<option value="'+current_day_option+'">'+current_day_option+'</option>');
            }
        }
    }

    dynamicallyAdjustCalDropdownWidth() {
        $(window).on('resize', () => {
            let window_width = $(window).width();

            if(window_width >= 1500) {
                this.dom.find('.calendar-dropdown-months').removeClass('col-lg-8').addClass('col-lg-6');
                this.dom.find('.calendar-dropdown-days').removeClass('col-lg-4 col-lg-3').addClass('col-lg-2');
                this.dom.find('.calendar-dropdown-years').removeClass('col-lg-6').addClass('col-lg-4');
            }
            else if(window_width >= 1300) {
                this.dom.find('.calendar-dropdown-months').removeClass('col-lg-8').addClass('col-lg-6');
                this.dom.find('.calendar-dropdown-days').removeClass('col-lg-4 col-lg-2').addClass('col-lg-3');
                this.dom.find('.calendar-dropdown-years').removeClass('col-lg-6').addClass('col-lg-4');
            }
            else {
                this.dom.find('.calendar-dropdown-months').removeClass('col-lg-6').addClass('col-lg-8');
                this.dom.find('.calendar-dropdown-days').removeClass('col-lg-2 col-lg-3').addClass('col-lg-4');
                this.dom.find('.calendar-dropdown-years').removeClass('col-lg-4').addClass('col-lg-6');
            }
        });

        $(window).trigger('resize');
    }

    joinHubClick(event) {
        let initial = app.pages.meetingrequest.formCards.project_card;
        let spoke = app.pages.meetingrequest.formCards.spoke_card;

        $.ajax({
            url: "api/getMeetingData",
            data: {
                meeting_id: event.meeting_id
            },
            method: 'post'
        }).then((data) => {
            let info = data.logistics_info;
            initial.dom.find('#project_dd').val(info.project_id).trigger('change');
            initial.dom.find('#project_dd').attr('disabled', 'disabled')
            initial.dom.find('#program_type_dd').val(info.spokeProgramType).trigger('change');
            initial.dom.find('#program_type_dd').attr('disabled', 'disabled')
            //initial.dom.trigger('cardFilledOut');

            spoke.dom.find('#hub_meeting_id').val(event.meeting_id).trigger('change');
            spoke.from_calendar = true;
        })

        app.navigate('meetingrequest');
    }

    checkHubJoin(eventInfo) {
        let repCheck = app.appData.current_user.user_id != eventInfo.wwid;
        let hubjoinCheck = eventInfo.hub_join == '1';
        let statusCheck = eventInfo.status_key == "confirmed";
        let future = new Date() < new Date(eventInfo.meeting_date);

        return hubjoinCheck && repCheck && future && statusCheck;
    }

    showCalendarPopup(eventClickInfo) {
        eventClickInfo.jsEvent.preventDefault();

        let program = eventClickInfo.event.extendedProps;
        let popup_dom;

        if(program.external_programs_project == '1') {
            let popup_data = {
                meeting_id: program.meeting_id,
                program_name: program.program_name,
                title: program.sponsored_program_name,
                url: program.sponsored_program_url,
                date: app.format.datestamp(program.meeting_date),
                time: program.mtg_time,
                live: program.live_meeting_type,
                location: program.venue,
                status_color: app.format.setstatusColor(program),
                meeting_status: program.status_name,
                base_uri: program.base_uri
            };

            popup_dom = $(twig({ data: external_program_popup_template }).render(popup_data));
        }
        else {
            let popup_data = {
                meeting_id: program.meeting_id,
                project_name: program.project_name,
                accountable: program.accountable,
                rep: program.rep,
                date: app.format.datestamp(program.meeting_date),
                time: program.mtg_time,
                status_color: app.format.setstatusColor(program),
                meeting_status: program.status_name,
                hub_join: this.checkHubJoin(program),
                base_uri: program.base_uri,
                speaker_term: app.appData.labels.speaker + '(s)',
                labels: app.appData.labels
            };

            if(program.speaker) {
                let speakers = program.speaker;

                if(program.speaker_name_degree != undefined) {
                    let speaker_names = program.speaker_name_degree.split('<br/>');
                    let speaker_names_formatted = [];

                    $.each(speaker_names, (idx, speaker_name) => {
                        let speaker_name_degree_parts = speaker_name.split(/,\s/);
                        let speaker_name_parts = speaker_name_degree_parts[0].split(/\s/);
                        speaker_names_formatted.push(speaker_name_parts[speaker_name_parts.length - 1] + ', ' + speaker_name_parts[0]);
                    });

                    speakers = speaker_names_formatted.join('<br/>');
                }

                popup_data.speakers = speakers;
            }

            if(program.venue
            && program.venue_name) {
                popup_data.venue = program.venue;
            }

            popup_dom = $(twig({ data: popup_template }).render(popup_data));

            popup_dom.find('#detail_hub_join').on('click', () => {
                this.joinHubClick(program);
            });
        }

        if(eventClickInfo.jsEvent.target.className.split(" ").indexOf("join-btn") >= 0) {
            this.joinHubClick(program);
        }

        popup_dom.find('#program_id_popup').data('meetingId', 'meeting/' + program.meeting_id);
        popup_dom.find('#program_id_popup').on('click', (event) => {
            event.preventDefault();
            app.navigate('meeting', [program.meeting_id]);
        });

        let p = Object.assign({}, program);
        p.calendar_icon = true;
        let exportCalendar = new ExportCalendar(p);
        popup_dom.find('#popup_actions').append(exportCalendar.dom);

        $('.program-popup-placeholder').hide();
        $('#calendar_popup').empty().append(popup_dom);
    }
}
