var template = require('./attendee_detail_modal.twig');

export class AttendeeDetailModal
{
    constructor(attendee) {
        this.attendee = attendee

        this.dom = $(twig({ data: template }).render({attendee: this.attendee}))
    }
}
