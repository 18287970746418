import { Page } from './page.js'
import { Dropdown } from "../components/Dropdown";
import FilterStack from '../components/Filter/FilterStack.js';
import { DownloadModal } from "./resources/DownloadModal";
import filter_validation_errors from './resources/filter_validation_errors.twig';
let template = require('./resources/resources.twig');
let impnews_template = require('./resources/resource_impnews.twig');
let content_template = require('./resources/resource_content.twig');
let content_without_tab_template = require('./resources/resource_content_without_tab.twig');

export class Resources extends Page{

    filters = {
        keys: [
            'franchise_container',
            'brand_container',
            'project_container',
            'topic_container',
            'product_container',
            'program_container'
        ],
        required: new Map([
            ['franchise_container', { name: 'Franchise'}],
            ['brand_container', { name: 'Brand'} ]
        ]),
        map: new Map(),
        stack: {},
    }
    filterMap = new Map();
    filterStack = {};
    constructor()
    {
        super();
        let filter = false;
        this.filtertype = false;
        this.activeTab = null;
        this.content_loaded = false;
        this.avoid_resource_tab = false;

        if(typeof app.appData.layout.resources.filter !== 'undefined') {
            filter = app.appData.layout.resources.filter
            this.filtertype = app.appData.layout.resources.filtertype
        }
        this.dom = $(twig({ data: template }).render({
            filter: filter,
            filtertype: this.filtertype,
            user_type_id: app.appData.curPosition.user_type_id,
            resource_types: app.appData.layout.resources?.types
        }));
        this.initFilters();

        if(app.appData.layout.resources.avoid_resource_tab !== 'undefined') {
            this.avoid_resource_tab = app.appData.layout.resources.avoid_resource_tab;
        }
    }

    initFilterMap() {
        this.filterMap = new Map([
            ['brand_container', {
                    label: 'Brands',
                    dropdown_data: app.appData.brands,
                    draw_keys: ['brand_id', 'brand_name', 'brand_id']
                }
            ],
        ])
    }

    setTabs(data)
    {
        this.tabs = {};
        $.each(
            data, (key, download) => {
            if(download.tabs) {
                this.setTabs(download.tabs)
            } else if(download.title) {
                    this.tabs[key] = download;
            }
            }
        )
        return this.tabs;
    }

    getBrandOptions()
    {
        const selectedFranchise = this.dom.find('#franchise_container').find('select').val();
        const franchiseProjects = Object.values(app.appData.projects).filter(project => project.franchise_id == selectedFranchise).map(project => project.brand_id);
        const brandIds = [...new Set(franchiseProjects)];
        const brandOptions = Object.values(app.appData.brands).filter(brand => brandIds.includes(brand.brand_id));

        return brandOptions;
    }

    drawBrandDD()
    {
        this.dom.find('#brand_container').html('');
        let brandOptions = this.getBrandOptions();
        let brandDD = new Dropdown(brandOptions);
        const labelText = 'Brands';

        brandDD
            .label(labelText)
            .setContainerLabel(labelText)
            .all(true)
            .multiple(true)
            .draw(this.dom.find('#brand_container'), 'brand_id', 'brand_name', 'brand_id')
        const brandSelect = this.dom.find('#brand_container').find('select');
        brandSelect.on('change', (e) => {
            this.filters.stack.refresh();
            const fiscalYear = this.dom.find('#fiscal_container').find('select').val() ?? false;
            const franchise = this.dom.find('#franchise_container').find('select').val() ?? false;

            let filterNode = this.filters.stack.getNode('brand_container');
            const previousValue = filterNode.getPreviousValue();
            const multiBrandValue = brandSelect.val() ?? [];

            if (previousValue == '' || e.target.value == '' || multiBrandValue.length > 0) {
                this.dom.find('#project_container').empty();
                this.drawProjectDD(fiscalYear, franchise, false);
                 // topic is a dependency of project, redraw in case multi filters selected in filter stack
                this.dom.find('#project_container').find('select').trigger('change');
            }
            filterNode.setPreviousValue(e.target.value);
        });
    }

    drawFiscalYearDD()
    {
        this.dom.find('#fiscal_container').html('');
        let year = new Date().getFullYear();
        let data = Object.values(app.appData.projects).
            map(x => { return {'year':x.proj_fy}}).
            filter((v, i, a) => a.map(mapObj => mapObj.year).indexOf(v.year) === i).sort((a, b) => b.year - a.year)
        let fiscalYearDD = new Dropdown(data)
        fiscalYearDD
        .all(false)
        .label("Year")
            .draw(this.dom.find('#fiscal_container'), 'year', 'year', 'fiscal_year')
            this.dom.find('#fiscal_container').find('select').on(
                'change', (elem) => {
                this.drawProjectDD(elem.currentTarget.value, false, false);
                }
            )
        this.dom.find('#fiscal_container').find('select').val(year).trigger('change');
    }

    drawFranchiseDD() {
        this.dom.find("#franchise_container").html('');
        let franchise = Object.values(
            app.appData.franchise.filter(
                franchise => Object.values(app.appData.projects).map(project => project.franchise_id).includes(franchise.franchise_id)
            )
        );

        let franchiseDD = new Dropdown(franchise);
        const labelText = 'Franchise';

        franchiseDD
            .label(labelText)
            .setContainerLabel(labelText)
            .all(false)
            .draw(this.dom.find('#franchise_container'), 'franchise_id', 'franchise_name', 'franchise_id');
        this.dom.find('#franchise_container').find('select').on(
            'change', () => {
                this.filters.stack.refresh();
                this.drawBrandDD();
                this.dom.find('#brand_container').find('select').trigger('change');
            }
        )
    }

    drawProductDD() {
        this.dom.find('#product_container').html('');
        let products = this.getContextualProducts();
        let productsDD = new Dropdown(products);
        const labelText = 'Products';

        productsDD
            .label(labelText)
            .setContainerLabel(labelText)
            .all(false)
            .draw(this.dom.find('#product_container'), 'id', 'name', 'product_id');
        this.dom.find('#product_container').find('select').on(
            'change', (e) => {
                this.filters.stack.refresh();
                let filterNode = this.filters.stack.getNode('product_container');
                const previousValue = filterNode.getPreviousValue();
                if (previousValue == '' || e.target.value == '') {
                    this.drawProgramTypeDD();
                }
                filterNode.setPreviousValue(e.target.value);
            }
        )
    }
    getContextualProducts() {
        const products = app.appData.products;
        const selectedTopics = this.dom.find('#topic_container').find('select').val() ?? [];

        let productOptionsMap = new Map();
        selectedTopics.forEach(selectedTopicId => {
            const matchedProducts = products.filter(product => product.topic_ids.split(',').includes(selectedTopicId));
            matchedProducts.forEach(matchedProduct => {
                productOptionsMap.set(matchedProduct.id, matchedProduct);
            })
        });

        return [...productOptionsMap.values()];
    }

    drawProjectDD(fiscalYear, currentFranchise, currentProduct) {
        this.dom.find('#project_container').html('');
        let projects = Object.values(app.appData.projects);
        const brandValues = this.dom.find('#brand_container').find('select').val() ?? [];

        if (fiscalYear) {
            projects = Object.values(app.appData.projects).filter(project => project.proj_fy == fiscalYear);
        }

        if (currentFranchise && brandValues.length === 0) {
            projects = projects.filter((project) => project.franchise_id == currentFranchise);
        }

        if (brandValues.length > 0) {
            projects = projects.filter((project) => brandValues.includes(project.brand_id.toString()));
        }

        if (currentProduct) {
            projects = projects.filter((project) => project.product_ids.includes(currentProduct))
        }

        let projectDD = new Dropdown(projects)
        const labelText = 'Projects';

        projectDD
            .label(labelText)
            .setContainerLabel(labelText)
            .all(true)
            .multiple(true)
            .draw(this.dom.find('#project_container'), 'project_id', 'project_name', 'project_id')

        const projectSelect = this.dom.find('#project_container').find('select');

        projectSelect.on(
            'change', (e) => {
                if(app.appData.layout.resources.filtertype === 'allergan') {
                    this.filters.stack.refresh();
                    let filterNode = this.filters.stack.getNode('project_container');
                    const previousValue = filterNode.getPreviousValue();
                    const multiProjectValue = projectSelect.val() ?? [];
                    if (previousValue == '' || e.target.value == '' || multiProjectValue.length > 0) {
                        this.drawTopicNameDD();
                    }
                    filterNode.setPreviousValue(e.target.value);
                }

            }
        )
    }

    drawTopicNameShortDD()
    {
        this.dom.find('#full_presentation_title_container').html('');
        let data = Object.values(app.appData.topics).filter(function(item) {
            return item.topic_name_short;
        }).reduce(function(field, e1){
            let matches = field.filter(function(e2){return e1.topic_name_short == e2.topic_name_short});
            if (matches.length == 0) field.push(e1);
            return field;
        }, []);
        let topicNameShortDD = new Dropdown(data);
        const labelText = 'Full Presentation Title';

        topicNameShortDD
            .label(labelText)
            .setContainerLabel(labelText)
            .all(true)
            .multiple(true)
            .draw(this.dom.find('#full_presentation_title_container'), 'topic_name_short', 'topic_name_short', 'topic_name_short')
    }

    drawTopicNameDD()
    {
        this.dom.find('#topic_container').html('');
        let topics = this.getContextualTopicOptions();
        let topicNameDD = new Dropdown(topics);
        const labelText= 'Topics';

        topicNameDD
            .label(labelText)
            .setContainerLabel(labelText)
            .all(true)
            .multiple(true)
            .draw(this.dom.find('#topic_container'), 'topic_id', 'topic_name', 'topic_id')

        const topicSelect = this.dom.find('#topic_container').find('select');

        topicSelect.on('change', (e) => {
            this.filters.stack.refresh();
            let filterNode = this.filters.stack.getNode('topic_container');
            const previousValue = filterNode.getPreviousValue();
            const multTopicValue = topicSelect.val() ?? [];

            if (previousValue == '' || e.target.value == '' || multTopicValue.length > 0) {
                this.drawProductDD();
            }
            filterNode.setPreviousValue(e.target.value);
        });
    }

    drawProgramTypeDD(currentProject)
    {
        this.dom.find('#program_container').html('');
        let programTypes = Object.values(app.appData.programTypes);
        if(currentProject) {
            programTypes = Object.values(app.appData.programTypes).filter(programTypes => programTypes.project_ids.includes(currentProject));
        }
        let programTypeDD = new Dropdown(programTypes)
        const labelText = 'Program Types';

        programTypeDD
            .label(labelText)
            .setContainerLabel(labelText)
            .all(true)
            .multiple(true)
            .draw(this.dom.find('#program_container'), 'program_type_id', 'program_name', 'program_type_id');

        this.dom.find('#program_container').find('select').on('change', () => {
            this.filters.stack.refresh();
        });
    }

    drawPrcIdDD()
    {
        this.dom.find('#prc_id_container').html('');
        let data = Object.values(app.appData.topics).filter(function(item) {
            return item.prc_id;
        }).reduce(function(field, e1){
            let matches = field.filter(function(e2){return e1.prc_id == e2.prc_id});
            if (matches.length == 0) field.push(e1);
            return field;
        }, []);
        let prcIdDD = new Dropdown(data);
        const labelText ='Veeva Document Number';
        prcIdDD
            .label(labelText)
            .setContainerLabel(labelText)
            .all(true)
            .multiple(true)
            .draw(this.dom.find('#prc_id_container'), 'prc_id', 'prc_id', 'prc_id')
    }

    getContextualTopicOptions()
    {
        const selectedProjectIds = this.dom.find('#project_container').find('select').val() ?? [];
        const selectedProjects = Object.values(app.appData.projects).filter(project => selectedProjectIds.includes(project.project_id.toString()));
        const validTopicIds = []
        selectedProjects.forEach(project => validTopicIds.push(project.topic_ids));
        const distinctTopicIds = [...new Set(validTopicIds.join(',').split(','))];
        const topicOptions = app.appData.topics.filter(topic => distinctTopicIds.includes(topic.topic_id.toString()));

        return topicOptions
    }

    getResources(filter)
    {
        return $.ajax({
            url: "api/resources",
            type: "get",
            data: filter
        })
    }

    loadTabContent(data)
    {
        data.tabs = this.setTabs(data.downloads);
        this.data = data;
        let all_headers = [
            { key: "resource_name", title: "FILE NAME", size: 8},
            { key: "resource_type", title: "TYPE", size: 1},
            { key: "resource_size", title: "FILE SIZE", size: 2}
        ]

        let headers = {}
        for(let [key] of Object.entries(this.data.tabs)) {
            headers[key] = all_headers;
        }

        if(app.appData.layout.resources.headers) {
            headers = app.appData.layout.resources.headers;
        }

        this.content_dom = $(
            twig({ data: content_template }).render(
                {
                    data: this.data,
                    headers: headers
                }
            )
        );
        this.dom.find('#download-section-data').append(this.content_dom)

        this.content_dom.find('ul.nav-tabs li:first-child a').addClass('active');
        this.content_dom.find('.tab-content div:first-child').addClass('active');

        // Desktop-view table selection tabs
        this.content_dom.find('a.nav-link').on('click', (e) => {
            this.activeTab = $(e.target).attr("id");
        });

        // Mobile-view table selection dropdown
        this.content_dom.find('#resource-dropdown').on('change', function(){
            // Maintain table selection event through hidden tabs
            let tab_id = $(this).val();
            let $tab = $('#' + tab_id);
            this.activeTab = $tab.attr('id');
            $tab.click();
        });

        this.content_dom.find('.download-button').off().on(
            'click', function (event) {
                event.preventDefault();
                let resourceId = $(this).data('resource-id');
                window.open("api/resources/"+resourceId+"/download");
            }
        )

        this.content_dom.find('.download-modal-button').off().on(
            'click', function(event){
                event.preventDefault();
                let resourceId = $(this).data('resource-id');
                let downloadModal = new DownloadModal(resourceId);
                let modal = new bootstrap.Modal(downloadModal.dom);
                modal.show();
            }
        )

        this.dom.find('#download_loading_overlay').hide();
        this.dom.find('#download-section-data').show();
    }

    LoadDataTable(data, resource_type){
        this.dom.find('#download-section-data').html('');
        this.data = data;

        let headers = [
            { key: "resource_name", title: "FILE NAME", size: 4},
            { key: "program_name", title: "PROGRAM TYPE", size: 4},
            { key: "resource_type", title: "TYPE", size: 1},
            { key: "resource_size", title: "FILE SIZE", size: 2}
        ];

        this.content_dom = $(
            twig({ data: content_without_tab_template }).render(
                {
                    data: this.data.downloads[resource_type]?.data ?? [],
                    headers: headers
                }
            )
        );

        this.dom.find('#download-section-data').append(this.content_dom);

        this.content_dom.find('.download-button').off().on(
            'click', function (event) {
                event.preventDefault();
                let resourceId = $(this).data('resource-id');
                window.open("api/resources/"+resourceId+"/download");
            }
        )

        this.content_dom.find('.download-modal-button').off().on(
            'click', function(event){
                event.preventDefault();
                let resourceId = $(this).data('resource-id');
                let downloadModal = new DownloadModal(resourceId);
                let modal = new bootstrap.Modal(downloadModal.dom);
                modal.show();
            }
        )

        this.dom.find('#download_loading_overlay').hide();
        this.dom.find('#download-section-data').show();
    }

    initTable()
    {
        // MS36113: Disable paging in mobile view
        let params = app.isMobile() ? {bPaginate: false} : {};
        this.dom.find(".resource-table").DataTable(params);
    }

    drawFilters() {
        this.clearValidationErrors();
        this.drawFiscalYearDD();
        if(this.filtertype) {
            this.drawFranchiseDD();

            if (app.appData.curPosition.user_type_id == 8) {
                this.drawTopicNameShortDD();
                this.drawPrcIdDD();
            }
        }

        if(this.avoid_resource_tab){
            this.dom.find('#resource_type_container').removeClass("hidden");
        }
    }

    initFilters()
    {
        if(app.appData.layout.resources.filter) {
            this.dom.find('#filter_resource').on(
                'click', event => {
                    event.preventDefault();
                    if(app.appData.layout.resources.filtertype === 'allergan') {
                        const invalidFields = this.validateFilters();
                        if (invalidFields.length > 0) {
                            this.renderFilterErrors(invalidFields);
                            return;
                        }
                    }
                    this.dom.find('#download_loading_overlay').show();
                    this.dom.find('#download-section-data').hide();

                    let filters = {};
                    if(app.appData.layout.resources.filtertype == 'allergan') {
                        filters = {
                            brand_id: this.dom.find('#brand_id').val(),
                            franchise_id: this.dom.find('#franchise_id').val(),
                            product_id: this.dom.find('#product_id').val(),
                            project_id: this.dom.find('#project_id').val(),
                            topic_id: app.appData.curPosition.user_type_id == 8 ? this.getTopicIdForFilter() : this.dom.find('#topic_id').val(),
                            program_type_id: this.dom.find('#program_type_id').val()
                        }
                    } else {
                        filters = {
                            project_id: this.dom.find('#project_id').val(),
                            year: this.dom.find('#fiscal_year').val(),
                        }
                    }

                    this.getResources(filters)
                        .then(
                            data => {
                                this.content_dom.detach()
                                if(this.avoid_resource_tab){
                                    let resource_type = this.dom.find('#resource_type').val();
                                    this.LoadDataTable(data, resource_type);
                                    this.initTable();
                                }else{
                                    this.loadTabContent(data);
                                    this.content_dom.find('a#' + this.activeTab).trigger('click');
                                }
                            }
                        )
                }
            )

            this.dom.find('#clear_filters').on('click', () => {
                this.clearValidationErrors();
                this.dom.find('#franchise_container').empty();
                this.drawFranchiseDD();
                this.dom.find('#brand_container').empty().hide();
                this.dom.find('#project_container').empty().hide();
                this.dom.find('#topic_container').empty().hide();
                this.dom.find('#product_container').empty().hide();
                this.dom.find('#program_container').empty().hide();
                this.initFilterStack();
            });
        }
    }

    // getTopicIdForFilter combine topic_id from `Full Presentation Title`, `Topics` and `Veeva Document Number(PRC ID)` filter
    // for filter by topic_id on the backend. User AND for filter. Return []topic_id. For user_type_id == 8
    getTopicIdForFilter()
    {
        // read value
        let topic_id_from_topic_name = this.dom.find('#topic_id').val();
        let topic_id_from_topic_name_short = Object.values(app.appData.topics).map(function (obj) {
            return $('#topic_name_short').val().includes(obj.topic_name_short) && obj.topic_id ? obj.topic_id : null;
        }).filter(n => n);
        let topic_id_from_prc = Object.values(app.appData.topics).map(function (obj) {
            return $('#prc_id').val().includes(obj.prc_id) && obj.topic_id ? obj.topic_id : null;
        }).filter(n => n);

        // all topic_id to topic_ids array and count filled filters
        let topic_ids = [];
        let topic_id_filter_count = 0;
        if (topic_id_from_topic_name.length > 0) {
            topic_ids.push.apply(topic_ids, topic_id_from_topic_name)
            topic_id_filter_count++;
        }
        if (topic_id_from_topic_name_short.length > 0) {
            topic_ids.push.apply(topic_ids, topic_id_from_topic_name_short)
            topic_id_filter_count++;
        }
        if (topic_id_from_prc.length > 0) {
            topic_ids.push.apply(topic_ids, topic_id_from_prc)
            topic_id_filter_count++;
        }
        // get topic_ids in all filled filters
        let valWhichRepeat = (arr,count) => {
            const obj = arr.reduce((ac,a) => (ac[a] = ac[a] + 1 || 1,ac),{});
            let data = Object.keys(obj).filter(x => obj[x] >= count);
            return data.length == 0 && count > 0 ? ['1.1'] : data;
        };
        return valWhichRepeat(topic_ids,topic_id_filter_count);
    }

    initFilterStack()
    {
        this.filters.stack = new FilterStack({dom: this.dom, keys: this.filters.keys});
        this.filters.stack.refresh();
    }

    validateFilters()
    {
        let invalidFields = [];
        this.clearValidationErrors();
        for(const [key, value] of this.filters.required) {
            const fieldVal = this.dom.find(`#${key}`).find('select').val();
            this.filters.stack.getNode(key).setValid();
            if(fieldVal?.length > 0) {
                continue;
            }
            this.filters.stack.getNode(key).setInvalid();
            invalidFields.push(value.name);
        }

        return invalidFields;
    }

    renderFilterErrors(invalidFields)
    {
        this.dom.find('#filter_validation_errors').append($(twig({ data: filter_validation_errors }).render({invalidFields: invalidFields}))).show();
    }

    clearValidationErrors()
    {
        this.dom.find('#filter_validation_errors').empty().hide();
    }

    ready()
    {
        let resource_type = '';
        this.drawFilters();
        if(this.avoid_resource_tab){
            resource_type = this.dom.find('#resource_type').val();
        }

        if(!this.content_loaded) {
            this.dom.find('#download_loading_overlay').show();
            this.getResources()
            .then(
                data => {
                    this.news_dom = $(twig({ data: impnews_template }).render({news: data.news}));
                    this.dom.find('#important-news').append(this.news_dom)

                    this.content_loaded = true;

                    if(this.avoid_resource_tab){
                        this.LoadDataTable(data, resource_type);
                        this.initTable();
                    }else{
                        this.loadTabContent(data);
                        this.initTable();
                    }

                    this.dom.find('.collapse-button').on(
                        'click', (e) => {
                        e.preventDefault();
                        if($(e.target).hasClass('collapsed')) {
                            $(e.target).removeClass('collapsed');
                            let id = $(e.target).attr('href');
                            $('#'+id).css({'height': "auto"})
                        } else {
                            $(e.target).addClass('collapsed');
                            let id = $(e.target).attr('href');
                            $('#'+id).css({'height': '42px'});
                        }
                    })
                    this.initFilterStack();
                }
            )
        }
    }
}
