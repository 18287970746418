var comm_history_template = require('./communication_history_modal.twig');

export class CommunicationHistoryModal
{
    constructor(config, attendee) {
        this.config = config
        this.attendee = attendee

        this.dom = $(twig({ data: comm_history_template }).render({config: this.config, attendee: this.attendee}))

        this.dom.find('.email-view').on('hide.bs.collapse',  (event) => {
            let id = event.target.id
            this.dom.find('a[aria-controls="#' + id + '"] i').removeClass('fa-chevron-up').addClass('fa-chevron-down')
        })

        this.dom.find('.email-view').on('show.bs.collapse',  (event) => {
            let id = event.target.id
            this.dom.find('a[aria-controls="#' + id + '"] i').removeClass('fa-chevron-down').addClass('fa-chevron-up')
        })

        this.modal = new bootstrap.Modal(this.dom);
        this.modal.show();
    }
}
