import { Page } from '../page.js'

let template = require('../notifications/notifications.twig');

export class Notifications extends Page {
    constructor() {
        super();
        this.truncate_length = 300;
        this.dom = $(twig({ data: template }).render())
        this.setNotificationCount();

        if(app.currentPage == 'notifications'){
            $('#notification_link').removeClass("notification-link").addClass('notification-link-active');
        }
    }

    setNotificationCount(){
        $.ajax({
            url: "api/notifications/count",
            method: "GET"
        }).then(data => {
            if(data.count > 0){
                $('#notification-count').html(data.count);
            }
            else{
                $('#notification-count').html('');
            }
        })

    }

    ready()
    {
        $('#datatable_alert_notifications').DataTable({
            columns: [
                { data: 'from_name', title: 'From', width: "10%"},
                { data: 'added_date', title: 'Date/Time', width: "10%" },
                { 
                    data: 'html_note', 
                    title: 'Note',
                    render: (data, type, row) => {
                        let return_str = data;
                        if (data.length > this.truncate_length){
                            return_str = '<div id="short-str-' + row.notification_id + '" >' + data.substring(0, this.truncate_length) + '...<a  class="show_more_link" data-notification-id="' + row.notification_id +'">Read More</a></div>'
                            return_str = return_str + '<div id="long-str-' + row.notification_id + '" style="display:none;">' + data + '<a  class="show_less_link" data-notification-id="' + row.notification_id +'">Read Less</a></div>'
                        }
                        return return_str;
                    }
                },
                {
                    data: 'action',
                    title: 'Action',
                    width: "20%",
                    render: (data, type, row) => {
                        let str_return = '';
                        if (!row.read_date || row.read_date == "0000-00-00 00:00:00") {
                            str_return = '<button class ="btn btn-primary notification-mark-read" data-notification-id="'+row.notification_id+'" >Mark as Read</button>';
                        }
                        return str_return;
                    }
                }
            ],
            "ajax": {
                url: 'api/notifications'
            },
            "createdRow": (row, data) => {
                if (!data.read_date || data.read_date == "0000-00-00 00:00:00") {
                    $(row).addClass('notification-unread');
                }
            },
            "paging": false,
            "dom": '<t>',
            "destroy": true
        });

        this.dom.find("#datatable_alert_notifications tbody").on('click', '.notification-mark-read', (e) => {
            let button = $(e.target)
            let row = $(e.target).closest(".notification-unread");
            let buttonData = button.data()
            button.remove();
            row.removeClass("notification-unread")

            $.ajax({
                url: "api/notifications/" + buttonData.notificationId + "/read",
                method: "GET"
            }).then(() => {
                this.setNotificationCount();
            });

        });

        this.dom.find("#datatable_alert_notifications tbody").on('click', '.show_more_link', (e) => {
            let notification_info = $(e.target).data();
            this.dom.find("#short-str-" + notification_info.notificationId).hide();
            this.dom.find("#long-str-" + notification_info.notificationId).show();
        });

        this.dom.find("#datatable_alert_notifications tbody").on('click', '.show_less_link', (e) => {
            let notification_info = $(e.target).data();
            this.dom.find("#long-str-" + notification_info.notificationId).hide();
            this.dom.find("#short-str-" + notification_info.notificationId).show();
        });


    }


}
