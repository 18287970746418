import {Card} from "./Card";
import { GoogleAddressAutocomplete } from "../../helpers/GoogleAddressAutocomplete"

let template = require('./SponsorshipCard.twig');

export class SponsorshipCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({data: template}).render());

        this.drawCountryStateDD(this.dom.find('.mailing_country_dd'), this.dom.find('.mailing_state_dd'));

        $(this.dom).find('.postalcode').mask('99999')
        $(this.dom).find('.phonenumber').mask('(999) 999-9999')
        $(this.dom).find('.phonenumber').attr('pattern', '^([0-9]){10}$')

        this.dom.find('input, textarea').on('keyup', () => this.cardFilledOutTriggers());
        this.dom.find('input[type=checkbox]').on('click', () => this.cardFilledOutTriggers());
        this.dom.on('change', 'input, select, textarea', () => this.cardFilledOutTriggers() );

        this.dom.find('.other').on('change', (event) => {
            let other_text = $(event.target).parent().next();
            if ($(event.target).is(':checked')) {
                other_text.show();
                other_text.find('input').addClass('required is-invalid');
            } else {
                other_text.hide();
                other_text.find('input').removeClass('required is-invalid')
            }
        });
    }

    show() {
        super.show();
        new GoogleAddressAutocomplete('sponsorship_card');
    }

    isFilledOut() {
        let filled_out = true;
        let checkbox = ['type_of_org', 'type_of_req', 'tangible_benefit'];

        this.dom.find('input[type!=checkbox][type!=radio].required, select.required, textarea.required').each((i, element) => {
            let pattern = $(element).attr('pattern');
            let regex =  null;
            if(pattern) {
                regex =  new RegExp(pattern);
            }

            $(element).removeClass('is-invalid');
            if(element.value == '' || (regex && element.value && !$(element).cleanVal().toString().match(regex)))
            {
                $(element).addClass('is-invalid');
                filled_out = false;
            }
        });

        $.each(checkbox, (i, name) => {
            if(!this.dom.find('input[name="'+name+'"]:checked').length) {
                filled_out = false;
            }
        })

        return filled_out;
    }

    getData() {
        let sponsorship_data = {};
        if(this.dom.is(':visible')) {
            this.dom.find('input, select, textarea').each((i, e) => {
                let name = $(e).attr('name');
                let type = $(e).attr('type');
                if(type == 'checkbox') {
                    if($(e).is(':checked')) {
                        if(sponsorship_data[name] == undefined) {
                            sponsorship_data[name] = new Array();
                        }
                        sponsorship_data[name].push($(e).val());
                    }
                } else if($(e).val()) {
                    sponsorship_data[name] = $(e).val()
                }
            })
        }

        return sponsorship_data;
	}
}
