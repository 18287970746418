
var template = require('./user_profile.twig');

export class UserProfile {
    constructor() {
        this.dom = $(twig({ data: template }).render({
            userProfile : app.appData.current_user,
            optIn : app.appData.layout.user_profile.sms_opt_in
        }));


        $(this.dom).find('#userbox-button').on('click', () => $('#userbox-menu').toggle())

        $(this.dom).find('#sms_opt_in').on('click', () => {
            $.ajax({
                url: 'api/profile/smstoggle',
                method: 'post'
            });
        })

        $(this.dom).find('#close').on('click', (event) => {
            event.preventDefault();
            $('#userbox-menu').hide()
        })

    }
}
