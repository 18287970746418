import {AttendeeEvaluation} from "@appdev/evaluations-ui/evaluations/attendee";
import template from './attendee_eval_modal.twig';

export class AttendeeEvalModal {
    constructor(meeting_id, attendee_id, title, mode = "") {

        this.attendee_id = attendee_id;
        this.meeting_id = meeting_id;
        this.dom = $(twig({data: template}).render({
            title: title
        }));

        let evaluation = new AttendeeEvaluation(mode);
        evaluation.fetch_evaluation_html(
            this.dom.find(".modal-body"),
            this.meeting_id,
            this.attendee_id,
            0,
            "",
            "",
            "",
            mode
        );

        (new bootstrap.Modal(this.dom[0])).show();

        this.dom.on('hidden.bs.modal', () => {
            this.dom.remove()
        })
    }
}
