let template = require('./modal.review_questions.twig');

export class ModalReviewQuestions
{
    constructor(data) {
        this.dom = $(template);
        this.data = data;

        this.dom.find("#additional_review_question").on('submit', (event) => {
            event.preventDefault()
            $(this.dom.find("#additional_review_question").serializeArray()).each((index ,elem) => {
                this.data[elem.name] = elem.value
            })
            $.ajax({
                url: "api/speakernominate/changestatus",
                method: "POST",
                data: this.data
            }).then(response => {
                this.modal.hide();
                if(response.success) {
                    displayNotification({
                        title: 'Speaker nomination review successful',
                        success: true
                    });
                    app.navigate('speakernominate');
                }
                else {
                    displayNotification({
                        title: 'Speaker nomination review failed',
                        success: false
                    });
                }
            })
        })

        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false, show: false});
        this.modal.show();
    }
}
