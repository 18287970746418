var meeting_cards_template = require('./meeting_cards.twig');

export class MeetingCards
{
    constructor(data) {
        this.meetings = data;

        $.each(this.meetings, (i, meeting) => {
            meeting.statusClass = app.format.setstatusColor(meeting)
            meeting.button_text = (
                moment((new Date(meeting.meeting_date)).toISOString()) < moment()
                && (meeting.rep_id == app.appData.current_user.user_id
                    || meeting.accountable_id == app.appData.current_user.user_id))

                ? 'Close Program' : 'Program Detail'
        })
        this.meetings.sort((a, b) => (moment((new Date(a.meeting_date)).toISOString()) > moment((new Date(b.meeting_date)).toISOString())) ? 1 : -1)
        this.dom = $(twig({ data: meeting_cards_template }).render({meetings : this.meetings}))

        this.dom.find('#meeting-scrollbar').first().width(this.meetings.length * 380);

        this.dom.find('#scrollRight').on('click', () => {
            this.scrollBar(true);
        });

        this.dom.find('#scrollLeft').on('click', () => {
            this.scrollBar(false);
        });

        this.dom.find('#close_program').on('click', function() {
            let programId = $(this).data('id');
            app.navigate('meeting', [programId]);
        });
    }


    scrollBar(right) {
        let scrollPosition = 0,
            margin = 14,
            currentPosition = parseInt($('.scrollbar-container').scrollLeft()),
            scrollerWidth = parseInt($('.scrollbar-container').width()),
            cardWidth = parseInt($('.scrollbar-container .card').width()),
            scroll = parseInt(scrollerWidth / (cardWidth + 10)) * (cardWidth + margin);

        if(right) {
            scrollPosition = currentPosition + scroll;
        }
        else if(currentPosition > scrollerWidth + cardWidth + margin) {
            let allCardWidth = ((parseInt(currentPosition / (cardWidth + margin)) -1) * (cardWidth + margin));
            scrollPosition = allCardWidth;
        } else {
            scrollPosition = currentPosition - scroll;
        }

        $('.scrollbar-container').animate( { scrollLeft: scrollPosition }, 500 );
    }


}
