let template = require('./exception_table.twig');

export class ExceptionTable{
    constructor(config) {
        let defaultConfig = { ordering : true, order : [ 0, "desc" ] };
        this.config = (typeof config === 'object') ? config : defaultConfig;
        this.dom = $(template);

        this.table = this.dom.find('#exception-table');
        this.columns = [];
        this.totalColumns = [];
        this.filterData = [];
    }

    createHeader(columns) {
        this.columns = columns;
        this.headers = [];
        $.each(columns, (key, column) => {
            this.headers.push({
                "data": column.key,
                "title": column.label,
                "column": column,
                render : (data, type, row) => {
                    if ( column.format == 'formatDate' && type === 'sort') {
                        var d = new Date(data);
                        return d.getTime();
                    } else if (column.key == 'meeting_id') {
                        return '<a href="meeting/'+ data +'">'+ data +'</a>';
                    } else {
                        return app.format.column(data, column, row);
                    }
                }
            });
        })
    }

    initializeTable(data) {
        this.data = data.data;
        this.createHeader(data.columns)
        let button = '';

        if(data.card == 0) {
            button = {
                'extend' : 'csvHtml5',
                'className':'export btn btn-success',
                'text': '<i class="fas fa-file-excel"></i>',
                'title': this.label+' Export'
            };
        } else if (data.perms.add) {
            button = {
                'className':'add_exception_button btn btn-danger',
                'text': '<i class="fas fa-plus cur-pointer"></i> New',
                'title': 'Add Manual '+this.label
            };
        }

        this.table.DataTable({
            data: data.data,
            columns: this.headers,
            ordering: this.config.ordering,
            search: this.config.search,
            "order": [this.config.order],
            "paging": $(window).width() < 768 ? false : true,
            "info": $(window).width() < 768 ? false : true,
            "dom": '<"row m-0"<"col-md-6"l><"col-md-4 search-box text-end pb-1" f><"dt-buttons col-md-2 pb-1"<"text-end download-csv"<"text-end"B>>>><t>ip',
            "buttons": (typeof button === 'object') ? [button] : [],
            "language": { search: "",
                searchPlaceholder: 'Search...',
            },
            "drawCallback": function() {
                app.convertDatatableToCard("exception-table");
            },
            "destroy": true
        });
        $('.download-csv').attr('title', 'Click to download');

        $('.dt-button.export').off('click').on('click', (event) => {
            event.preventDefault();
            this.exportData();
        })

        $('.page-loading-overlay').hide();
    }

    exportData() {
        let url = "api/exceptions/export";
        let data = $.param($('form#exception-filters').serializeArray());
        window.open(url+'?'+data);
    }

    recreateTable(filterData) {
        $(".page-loading-overlay").show();
        return $.ajax({
            url: "api/exceptions/data",
            type: "get",
            data: filterData
        }).then(data => {
            this.initializeTable(data);
        })
    }
}