var attendance_counts_template = require('./attendance_counts.twig');

export class AttendanceCounts
{
    constructor(counts, config) {
        let shownCounts = app.appData.layout['attendance'].counts[config.meetingPhase]
        this.dom = $(twig({ data: attendance_counts_template }).render({counts: counts,
            meetingPhase: config.meetingPhase,
            shownCounts: shownCounts,
            eventTypeConfig: config.eventTypeConfig,
            speaker: app.appData.labels.speaker,
            speaker_color: app.appData.layout.attendance.speaker_color !== undefined ? app.appData.layout.attendance.speaker_color: 'transparent',
            employee_color: app.appData.layout.attendance.employee_color !== undefined ? app.appData.layout.attendance.employee_color: 'transparent'
        }));
    }
}
