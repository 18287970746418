import {Page} from "../page";
import template from './autherror.twig'

export class AuthError extends Page {
    constructor(params) {
        super();

        let data;
        $.ajax({
            url: 'api/autherror/init',
            data: params,
            async: false,
            success: response => {
                data = response;
            }
        })

        this.dom = $(twig({data: template}).render(data));
    }

    ready() {

    }
}