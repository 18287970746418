import {Card} from "./Card";

var template = require('./av_options_card.twig');

export class AVOptionsCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({data: template}).render());

        for(let i in app.appData.av_options){
            let option = app.appData.av_options[i];
            this.dom.find('#av_options_selections_container').append(
                $('<div class="col-6">' +
                    '<input type="checkbox" value="' + option.id + '"/><label>' + option.name + '</label>' +
                    '</div>')
            )
        }

        this.dom.find('#needs_av').on('change', (event) => {
            if($(event.target).is(':checked')) {
                this.dom.find('#av_options_selections_container').show();
            } else {
                this.dom.find('#av_options_selections_container').hide();
                this.dom.find('#av_options_selections_container input[type=checkbox]').prop('checked', false);
            }
        });

        this.dom.on('change', 'input', () => {
            this.dom.trigger($.Event('NeedBudgetUpdate'));
        })
    }

    getAvOptions() {
        let checkedOptions = [];
        this.dom.find('#av_options_selections_container input:checked').each((i, element) => {
            checkedOptions[$(element).val()] = 'on';
        });

        return {
            checked: checkedOptions,
            needs_av: this.dom.find('#needs_av').is(':checked') ? 1 : 0
        }
    }

    clear() {
        super.clear();
        this.dom.find('#av_options_selections_container').hide();
    }
}