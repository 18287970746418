import { Dropdown } from '../../components/Dropdown'

import { PieChart } from '../../vendor/d3Observable/PieChart';

var budget_cards_template = require('./budget_cards.twig');

export class BudgetCards
{
    renderProjectsdd() {
        let projects = [];
        Object.entries(app.appData.projects)
            .sort((a, b) => a[1].proj_fy <= b[1].proj_fy ? a[1].project_name.localeCompare(b[1].project_name) : -1)
            .forEach(item => projects.push(item[1]));

        this.projectDD = new Dropdown(projects)
        this.projectDD
        .label('Projects')
        .draw(this.dom.find('#project-dd-container'), 'project_id', 'project_name', 'project_id');
    }

    setData() {
        let projectDD = this.dom.find('#project-dd-container').children();
        let project_id;
        if(projectDD) {
            project_id = projectDD.val();
        }

        this.dom.find('#budget_loading_overlay').show();
        this.dom.find('#budget-body').hide();
        $.post("api/budgets/summary", {'project_id': project_id ?? null}, (data) => {
            this.dom.find('#budget-amount').html(app.format.money(data.total));
            this.prepareChartData(data);
        })
        .then(() => {
            this.dom.find('#budget_loading_overlay').hide();
            this.dom.find('#budget-body').show();
            this.drawChart();
        })
    }

    prepareChartData(data) {
        this.budgetSummary = [
        {'label' : 'Remaining', 'amount': parseFloat(data.remaining) < 0 ? 0 : parseFloat(data.remaining)},
        {'label' : 'Spent', 'amount': parseFloat(data.spent)}
        ];
    }

    drawChart() {
        if(typeof this.budgetSummary == 'undefined') {
            return;
        }

        let chart;
        let data = this.budgetSummary.filter(item => item.amount != 0);
        if(data.length) {
            let params = {
                name: d => d.label,
                value: d => d.amount,
                format: "($,.2f",
                fontsize: 16,
                width: 210,
                height: 140,
                innerRadius: 20,
                colors: [
                    'rgb(157,181,241)',
                    'rgb(241,157,165)',
                ]
            };

            chart = PieChart(data, params);
        } else {
            chart = PieChart([{label: "$0 Remaining", amount: "1"}], {
                name: d => d.label,
                value: d => d.amount,
                hideValues: true,
                format: "d",
                fontsize: 16,
                width: 210,
                height: 140,
                innerRadius: 20,
                colors: [
                    'rgb(157,181,241)',
                    'rgb(241,157,165)',
                ]
            });
        }

        this.dom.find('#budget-chart').html(chart);
    }

    setApprovalCount() {
        $.post("api/programs/count/approval", (data) => {
            this.dom.find('#approval-count').html(data.count);
        })
    }

    constructor() {
        this.budgetData = {
            approvalCount: null,
            budgets : {}
        };

        this.dom = $(twig({ data: budget_cards_template }).render({'data': this.budgetData}));

        this.setApprovalCount();

        this.renderProjectsdd();

        $(this.dom).find('#project-dd-container').first().on('change', () => {
            this.setData();
        })

        $(this.dom).find('#report-button').on('click', () => {
            app.navigate('reports');
        })

        let value = this.dom.find('#project-dd-container option:not([value=""]):first').val();
        this.dom.find('#project-dd-container select').val(value).trigger('change');
    }
}
