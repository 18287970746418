
export class TrainingList {

    constructor(meetingId, attendeeId) {
        this.meetingId = meetingId;
        this.attendeeId = attendeeId;
        
        this.initializeTable('pre');
        this.initializeTable('post');
    }
    
    initializeTable(workType){
        $("#" + workType + "_program_training_table").DataTable({
            processing: true,
            serverSide: false,
            destroy: true,
            displayLength: 25,
            sorting: [[1, "desc"]],
            bPaginate: false,
            bFilter: false,
            bInfo: false,
            language: {
                processing: '<div class="spinner-border text-primary" role="status"><span class="sr-only">Loading...</span></div>',
            },
            ajax: {
                url: "api/prepostwork/meeting/" + this.meetingId + "/training",
                dataSrc: (json) => {
                    return json;
                },
                data: () => {
                    return {
                        attendee_id: this.attendeeId,
                        module_type: workType
                    };
                },
            },
            columns: [
                {data: 'module_name', title: 'Title'},
                {data: 'mandatory_indication', title: 'Mandatory'},
                {data: 'training_type', title: 'Training Type'},
                {data: 'completed_date_time', title: 'Completed Date & Time'},
                {data: 'attempts_count', title: 'Quiz(# of attempts)'}
            ],
        });
    }
}
