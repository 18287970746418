import {Evaluation} from "@appdev/evaluations-ui/evaluations/evaluation";

var template = require('./attendeeEvaluation.twig');

export class AttendeeEvaluation extends Evaluation {
    constructor(evalContent) {
        super();
        this.dom = $(twig({ data: template }).render({ evalContent }));
    }

    submit_evaluation(event){
        event.preventDefault();
        let form = event.target;

        this.highlight_missing_required();
        $(form).find('.evaluation_waiting_message').show();

        let form_data = new FormData();
        $(form).find('input[type="file"]').each(function( index, element ) {
            form_data.append(element.id, element.files[0])
        });

        let other_data = $(form).serializeArray();
        $.each(other_data, function(key, input){
            form_data.append(input.name, input.value);
        });

        $.ajax({
            url	: "api/evaluation",
            type: "POST",
            data: form_data,
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            success	: (data) => {
                $(form).find('.evaluation_waiting_message').css('display', 'none');
                if (data.success)
                {
                    $(form).find(".missing_required_message").hide();
                    $(form).find(".evaluation_success_message").show();

                    $('.eval-complete').show();
                    $('#eval_button').removeClass('btn-dark').addClass('btn-primary');
                }
                else
                {
                    $(form).find(".evaluation_success_message").hide();
                    $(form).find(".missing_required_message").show();
                }
            }
        });
    }

    ready() {
        this.show_hide_dependencies(this.dom)

        $("#new_patient_model_information").find('.question_choice input').on('change', (event) => {
            let thisForm = $("#new_patient_model_information");

            if($(event.target).is(':checked') || $(event.target).attr('type') === 'checkbox') {
                thisForm.find('.Question').each((i, element) => {
                    let dependencies = $(element).data('dependencies');
                    let showQuestion = false;

                    for(let i in dependencies)
                    {
                        let dependency = dependencies[i];
                        
                        if(thisForm.find('.question_choice input[type=checkbox]') &&
                            thisForm.find('.question_choice input[name="'+dependency.question_id+'[]"' + '][value='+dependency.choice_id+']').is(':checked'))
                        {
                            showQuestion = true;
                            break;
                        }
                        
                        if(thisForm.find('.question_choice input[type=radio]') &&
                            thisForm.find('.question_choice input[name='+dependency.question_id+'][value='+dependency.choice_id+']').is(':checked'))
                        {
                            showQuestion = true;
                            break;
                        }
                    }

                    if(showQuestion)
                    {
                        $(element).show();
                    }
                    else
                    {
                        $(element).hide();
                        $(element).find('input,select,textarea').html();
                    }
                })
            }
        })

        this.dom.find("form[name=evaluation]").submit((event) => {
            this.submit_evaluation(event, this.dom)
        });
    }
}
