import {Card} from "./Card";

var template = require('./consultation_card.twig');

export class ConsultationCard extends Card {
    constructor() {
        super();
        this.dom = $(twig({data: template}).render({'consultationReasons': app.appData.consultationReasons}));

        this.dom.on('change', 'select.required', () => {
            this.cardFilledOutTriggers();
        })
    }
}