var add_non_reportable_template = require('./add_non_reportable_modal.twig');
export class AddNonReportable
{
    constructor(meetingPhase) {
        this.meetingPhase = meetingPhase
        this.dom = $(twig({ data: add_non_reportable_template }).render({meetingPhase: this.meetingPhase}))
        let nonReportableSpinner = this.dom.find("#non_reportable-spinner")

        nonReportableSpinner.show()
        this.getNonReportableCounts().done((data) => {
            if(data.success)
            {
                this.dom.find("#non_reportable_num").val(data.data.non_reportable_count)
                this.dom.find("#non_reportable_meal").val(data.data.non_reportable_meal_count)
                this.dom.find("#add_non_reportable_button").prop('disabled', false)
            }
            else
            {
                this.dom.find('#non_reportable-message')
                    .addClass('alert-danger')
                    .text('Error loading Non-Reportable counts, please refresh the page.')
                    .show()
            }

            nonReportableSpinner.hide()
        })

        this.dom.find('#add_non_reportable_form').on('submit', (e) => {
            e.preventDefault();

            var non_reportable_num = parseInt(this.dom.find('#non_reportable_num').val());
            var non_reportable_meal_num = parseInt(this.dom.find('#non_reportable_meal').val());

            if(non_reportable_num < non_reportable_meal_num)
            {
                this.dom.find('#non_reportable-message')
                    .addClass('alert-danger')
                    .text('Non-Reportable meals can not be greater than Non-Reportable attendees.')
                    .show()
                this.dom.find('#non_reportable_meal').focus()

                return false;
            }

            nonReportableSpinner.show()
            this.saveNonReportableCounts(non_reportable_num, non_reportable_meal_num).done((data) => {
                if(data.success)
                {
                    this.dom.trigger('participantAdded', [e, data.data]);
                    this.modal.hide();
                }
            })
        });

        this.dom.on('hidden.bs.modal', () => {
            this.modal.dispose();
        })

        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false});
        this.modal.show();
    }

    saveNonReportableCounts(non_reportableNum, non_reportableMeals)
    {
        return $.ajax({
            url     : 'api/attendance/nonreportable',
            method  : 'post',
            data    : {
                meeting_id: $('#meeting_id').val(),
                data : {
                    non_reportable_num: non_reportableNum,
                    non_reportable_meals: non_reportableMeals
                }
            }
        })
    }

    getNonReportableCounts()
    {
        return $.ajax({
            url     : 'api/attendance/nonreportablecounts',
            method  : 'get',
            data    : {
                meeting_id: $('#meeting_id').val()
            }
        })
    }
}