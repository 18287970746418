import template from './surveyEval.twig';
import survey_container_template from './surveyContainer.twig';
import eval_template from '../../../../node_modules/@appdev/evaluations-ui/evaluations/evaluation.twig'

export class SurveyEval {

    constructor(meetingId, attendeeId) {
        this.meetingId = meetingId;
        this.attendeeId = attendeeId;

        this.dom = $(twig({ data: template }).render({
        }));

        $('#eval_survey').html(this.dom);

        this.dom.find('.toggler').on('click', function(){
            let table_id = $(this).closest('.table_wrapper').attr('id');
            $('.inverted_header').find($('[data-controller-for=' + table_id + ']')).toggle();
            $('#' + table_id).toggle();
        });

        this.dom.find('.inverted_header_toggler').on('click', function(){
            let table_id = $(this).parent().attr('data-controller-for');
            $('#' + table_id).toggle();
            $(this).parent().toggle();
        });

        this.loadSurveyEVal('pre_meeting', '#pre_program_survey_eval');
        this.loadSurveyEVal('post_meeting', '#post_program_survey_eval');
    }
    
    loadSurveyEVal(evalType, placeholder){
        $.ajax({
            url: "api/prepostwork/meeting/"  + this.meetingId + "/surveyeval",
            type: "GET",
            data: {
                attendee_id: this.attendeeId,
                eval_type: evalType
            },
            success: (data) => {
                let emptyMessage = '';

                if(data.question_sets_data.length === 0 && evalType === "pre_meeting") {
                    emptyMessage = "No Pre-Program survey/eval is available for this attendee."
                } else if (data.question_sets_data.length === 0 && evalType === "post_meeting") {
                    emptyMessage = "No Post-Program survey/eval is available for this attendee."
                } else {
                    Object.entries(data.data).forEach((entry, index) => {
                        let survey = entry[1];
                        let evalDom = $(twig({data: eval_template}).render(survey));
                        data.data[index].html = evalDom.html();
                    })
                }

                this.evalDom = $(twig({ data: survey_container_template }).render({
                    evalType: evalType,
                    surveyEvalData: data,
                    emptyMessage: emptyMessage,
                    showActionButtons: false
                }));

                $(placeholder).find(".accordion-wrap").html(this.evalDom);
            }
        }).done((data) => {
            $(placeholder).find("form[name=evaluation]").each((i, element) => {
                let answers;

                answers = data.data[i].answers;

                if(answers === undefined || answers.length <= 0)
                {
                    return -1;
                }

                for(let i = 0; i < answers.length; i++)
                {
                    if (answers[i].na == "1")
                    {
                        $(element).find("input[name="+answers[i].question_id+"][value=na]").attr("checked","checked");
                    }
                    else if (answers[i].choice_id > 0)
                    {
                        $(element).find("input[name="+answers[i].question_id+"][value="+answers[i].choice_id+"]").attr("checked","checked");
                    }
                    else if (answers[i].value != null)
                    {
                        $(element).find("input[name="+answers[i].question_id+"][value="+answers[i].value+"]").attr("checked","checked");
                    }
                    else if (answers[i].text != null)
                    {
                        $(element).find("textarea[name="+answers[i].question_id+"]").html(answers[i].text);
                    }
                    else if(answers[i].file_name != null){
                        $("input[name="+answers[i].question_id+"]").hide();
                        $("label[for="+answers[i].question_id+"]").html(answers[i].file_name);
                    }

                }
                
            });

            this.ready();
        })
    }

    setScore(form){
        let total_score_gained = 0;
        let grand_total = 0;
        let count = 0;
        let total_selected_field_count = 0;

        $(form).find(".question_container_range").each(function(i,v){
            let name = $(v).find("input").attr('name');
            let max = 0;
            count++;
            let selected_val = $(`input[name="${name}"]:checked`).val();
            if(parseInt(selected_val) > 0){
                total_selected_field_count++;
                total_score_gained = total_score_gained + parseInt(selected_val);
            }

            $(`input[name="${name}"]`).each(function(i, v){
                let val = parseInt($(v).val());
                if(val > max){
                    max = val
                }
            });
            grand_total = max + grand_total;
        });

        $(form).find(".grand_total").html(grand_total);

        if(count === total_selected_field_count){
            $(form).find(".total_score_gained").html(total_score_gained);
        }else{
            $(form).find(".total_score_gained").html(0);
        }
    }

    ready(){
        $('.submit_container').hide();

        $('form').find("input").attr("disabled","disabled");
        $('form').find("textarea").attr("disabled","disabled");

        $(".total_confidence_score").each((i,v) => {
            let form = $(v).parents("form");
            this.setScore(form);
        });
    }
}
