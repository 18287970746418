import { Page } from './page.js'
import { Dropdown } from "../components/Dropdown";

let template = require('./requestcert/requestcert.twig');
let dynamicCardTemplate = require('./requestcert/multi_requestcert.twig');

export class RequestCert extends Page {
    constructor() {
        super();

        this.cardCount = 0;
        this.dom = $(twig({ data: template }).render(
            { int_company_name: app.appData.supportInfo.int_company_name }
        ))

        this.setUpDropdows()

        this.dom.find('#training_date').datepicker({
            'endDate': moment().toDate()
        });

        this.dom.find('#degree-other-container').hide();
        this.dom.find('input[name="training_received"]').on('change', (event) => {
            this.dom.find('.training_options').attr('disabled', 'disabled').removeAttr('required').val('')
            this.dom.find("[name='" + $(event.target).val() + "_trainings']").removeAttr('disabled').attr('required', 'required')
        });

        this.dom.find('#degree').on('change', () => {
            this.dom.find('#degree-other-container').hide();
            this.dom.find('#degree_other').removeAttr('required').val('')

            if (this.dom.find('#degree').val()) {
                let degree = this.dom.find('#degree').val().toLowerCase()
                if (degree === 'oth' || degree === 'other') {
                    this.dom.find('#degree-other-container').show()
                    this.dom.find('#degree_other').attr('required', 'required')
                }
            }
        })

        this.dom.find("#multirequestcert-form").on('submit', (event) => {
            event.preventDefault();
            // Parse serialized form data
            let formDataObject = this.parseSerializedFormData($('#multirequestcert-form').serialize());
            // Now you have formDataObject containing all form data as key-value pairs
            let multiFormDataObject = this.cardCount == 1 ? [formDataObject] : this.organizeFormData(formDataObject);
            this.submitTraningRequest(multiFormDataObject).then((data) => {
                if (data.success) {
                    alert("Request(s) received");
                    document.location.reload();
                } else {
                    alert("Api Request(s) Failed");
                }
            })

        })

        this.dom.find("#add_hcp_btn").on('click', () => {
            // Find elements within the newly added card
            let prevCard = $('.card-' + this.cardCount);
            const firstnameInput = prevCard.find('#firstname').val();
            const lastnameInput = prevCard.find('#lastname').val();
            const emailInput = prevCard.find('#email').val();
            const degreeInput = prevCard.find('#degree').val();
            const specialtyInput = prevCard.find('#specialty').val();
            let result = this.validate(firstnameInput, lastnameInput, emailInput, degreeInput, specialtyInput)

            if (result != '') {
                let msg = 'Please check these fields in the current card ' + result;
                displayNotification({
                    title: msg,
                    success: false
                });
                return;
            }
            this.addHcpCard();
        });
    }

    organizeFormData(formData) {
        let users = [];
        // Determine the number of users based on the length of any array value in the form data
        let userCount = formData.firstname.length;

        // Loop through each user
        for (let i = 0; i < userCount; i++) {
            let user = {};
            // Loop through each key in the form data
            for (let key in formData) {
                // If the value is an array, assign the corresponding element to the user object
                if (Array.isArray(formData[key])) {
                    user[key] = formData[key][i];
                } else {
                    // If the value is not an array, assign it directly to the user object
                    user[key] = formData[key];
                }
            }
            // Push the user object to the users array
            users.push(user);
        }
        return users;
    }

    parseSerializedFormData(serializedData) {
        let formData = {};
        // Split the serialized data string by '&' to get key-value pairs
        let pairs = serializedData.split('&');
        // Loop through each key-value pair
        pairs.forEach(pair => {
            // Split each pair by '=' to separate key and value
            let keyValue = pair.split('=');
            // Decode URI component to handle special characters properly
            let key = decodeURIComponent(keyValue[0]);
            let value = decodeURIComponent(keyValue[1] || '');
            // If key already exists, append value to an array
            if (Object.prototype.hasOwnProperty.call(formData, key)) {
                if (!Array.isArray(formData[key])) {
                    formData[key] = [formData[key]];
                }
                formData[key].push(value);
            } else {
                // Otherwise, set key-value pair
                formData[key] = value;
            }
        });
        return formData;
    }

    validate(firstnameInput, lastnameInput, emailInput, degreeInput, specialtyInput) {
        let isValid = '';
        const validCharacters = /^[A-Za-z]+$/; // Only alphabets allowed
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        isValid += firstnameInput != '' && validCharacters.test(firstnameInput) ? '' : 'Firstname, ';
        isValid += lastnameInput != '' && validCharacters.test(lastnameInput) ? '' : 'Lastname, ';
        isValid += emailInput != '' && emailPattern.test(emailInput) ? '' : 'Email, ';
        isValid += degreeInput ? '' : 'Degree, ';
        isValid += specialtyInput ? '' : 'Specialty, ';

        isValid = isValid.replace(/,\s*$/, '');

        return isValid;
    }


    addHcpCard() {
        this.cardCount++;
        const newHcpRow = $(twig({ data: dynamicCardTemplate }).render({ cardCount: this.cardCount }));
        newHcpRow.appendTo('#hcp_section');
        let card = $('.card-' + this.cardCount);

        let specialtyDD = new Dropdown(app.format.getSortedSpecialties())
        specialtyDD
            .addClass('input-group-text')
            .addClass('form-control')
            .required('required')
            .label('Select  a specialty...')
            .draw(card.find("#specialty-dd-container"), 'code', 'short_name', 'specialty')

        let sortedDegrees = app.format.getSortedDegrees()
        sortedDegrees = sortedDegrees.filter(d => d.active == '1')
        let degreeDD = new Dropdown(sortedDegrees)
        degreeDD
            .addClass('input-group-text')
            .addClass('form-control')
            .label('Select a degree...')
            .required('required')
            .draw(card.find("#degree-dd-container"), 'degree_abbrv', 'degree_desc', 'degree')

        const degreeDropdown = card.find('#degree');
        const degreeOtherContainer = card.find('#degree-other-container');
        const degreeOtherInput = card.find('#degree_other');
        degreeOtherContainer.hide();

        // Set up event handlers within the card
        degreeDropdown.on('change', () => {
            degreeOtherContainer.hide();
            degreeOtherInput.removeAttr('required').val('');

            if (degreeDropdown.val()) {
                const degree = degreeDropdown.val().toLowerCase();
                if (degree === 'oth' || degree === 'other') {
                    degreeOtherContainer.show();
                    degreeOtherInput.attr('required', 'required');
                }
            }
        });

        // Remove Hcp Card
        newHcpRow.find('.remove_hcp_btn').on('click', () => {
            newHcpRow.find('input').trigger('change');
            newHcpRow.remove();
            this.cardCount--;
            this.updateHcp();
        });

    }

    setUpDropdows() {
        let statesDD = new Dropdown(app.appData.states)
        statesDD
            .addClass('input-group-text')
            .addClass('form-control')
            .label('Select a state...')
            .required()
            .draw(this.dom.find("#state-dd-container"), 'alpha2', 'name', 'state')

        this.getTrainings().then(() => {
            Object.keys(this.trainings).forEach((key) => {
                Object.keys(this.trainings[key]).forEach((innerKey) => {
                    this.dom.find('#' + key + "_trainings").append($('<option>').val(innerKey).text(this.trainings[key][innerKey]))
                })
            })
        })
    }

    updateHcp() {
        $('.form-row[id^="row_"]').each(function(index) {
            const contactId = index + 1;
            const currentRow = $(this);
            const currentCard = currentRow.find('[class^="card-"]');
            const removeBtn = currentRow.find('.remove_hcp_btn');
            currentRow.attr('id', 'row_' + contactId);
            currentCard.attr('class', 'row card-' + contactId);
            removeBtn.attr('data-contact-id', contactId);
        });
    }

    getTrainings() {
        return $.ajax({
            url: 'api/requestcert/trainings',
            method: 'get',
            dataType: 'json'
        }).then((data) => {
            this.trainings = data.trainings || {};
        })
    }

    submitTraningRequest(data = []) {
        return $.ajax({
            url: 'api/requestcert',
            method: 'post',
            // dataType: 'json',
            data: { "data": data }
        })
    }

    ready() {
        this.dom.find('#save_success_message').hide()
        this.dom.find('#save_error_message').hide()
        if(!this.cardCount) {
            this.addHcpCard();
        }
    }
}
