import { Page } from '../page.js'
import { Calendar } from "../../components/Calendar";
import { ProgramFilter } from "../../components/ProgramFilter";

let template = require('../programs/programs.twig');

export class Programs extends Page {
    constructor() {
        super();
        this.dom = $(twig({ data: template }).render({'listColumns': app.appData.layout.programs.list}))

        if(!app.programFilter) {
            app.programFilter = new ProgramFilter(app.appData.layout.programs.filters);
        }

        this.dom.find('#btn_show_filters').on('click',() => {
            $('#more_filters').removeClass('d-none');
            this.dom.find('#btn_hide_filters').removeClass('d-none');
            this.dom.find('#btn_show_filters').addClass('d-none');
        })

        this.dom.find('#btn_hide_filters').on('click',() => {
            $('#more_filters').addClass('d-none');
            this.dom.find('#btn_show_filters').removeClass('d-none');
            this.dom.find('#btn_hide_filters').addClass('d-none');
        })

        this.dom.on('click','#joinable_programs', (event) => {
            this.joinablePrograms(event.target)
        })
    }

    getCalendar() {
        $('#calendar_card').html('<div id="calendar"></div>');

        this.calendar = new Calendar({
            url : 'api/programs/calendar',
            filter_form: app.programFilter,
            exclude : ['unavailable']
        });

        $('#calendar').append(this.calendar.dom).ready(() => {
            this.joinablePrograms()
        });
    }

    showCalendar() {
        $('#calendar_pane').addClass('active');
        $('#list_pane').removeClass('active');

        $('ul.nav-pills > li.nav-item').removeClass('active');
        $('#calendar_tab').addClass('active');

        $('.tab-content').css({
            'background-color': 'transparent',
            'box-shadow': '0 0 0 0'
        });

        $('.program-popup-placeholder').show();
        $('.program-popup').hide();
    }

    joinablePrograms(t = document.getElementById('joinable_programs')) {
        if (!JSON.parse(app.appData.configs.JOINABLE_PROGRAM_ON_CALENDAR_REP_PORTAL ?? '{"is_show":false}').is_show) {
            return
        }


        let calendar_item_classes = '.text-primary, .text-warning, .text-danger, .text-info, .text-success'
        let calendar_item = this.dom.find('.fc_calendar_container').find(calendar_item_classes)
        if (t.checked) {
            calendar_item.hide()
            this.dom.find('.fc_calendar_container').find("[data-page='meetingrequest']").closest(calendar_item_classes).show()
        } else {
            calendar_item.show()
        }
    }

    ready()
    {
        app.programFilter.ready();

        if(app.checkPerms('meeting_calendar')) {
            if(!this.dom.find('#calendar_filter_container').html()) {
                this.dom.find('#calendar_filter_container').append(app.programFilter.dom);
            }

            app.programFilter.showCalendarView();

            if(!this.calendar) {
                this.getCalendar();
            }
            this.showCalendar();

            $('#calendar_tab').on('click', () => {
                this.showCalendar();
            });

            app.programFilter.dom.off('submit').on('submit', (event) => {
                event.preventDefault();
                this.calendar.calendar.destroy();
                this.getCalendar();
            })
        }

        // show more filters by default
        if ((app.appData.configs.SHOW_MORE_FILTERS_BY_DEFAULT_ON_CALENDAR_REP_PORTAL ?? false)) {
            $('#btn_show_filters').click()
        }
    }
}
