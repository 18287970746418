import { Page } from './page.js'
import { Dropdown } from '../components/Dropdown'
import { ReportTable } from '../components/ReportTable'

let reports_template = require('./reports/reports.twig');

export class Reports extends Page{
    constructor() {
        super();
        this.dom = $(reports_template);
        this.columns = [];
        this.filterData = [];
    }

    renderReportsTypes(data) {
        this.dom.find('#report-types-dd-container').html('');
        let reportTypesDD = new Dropdown(data)
        reportTypesDD
        .label('Report Types')
        .all(false)
        .draw(this.dom.find('#report-types-dd-container'), 'report_type_key', 'report_type_name', 'report_type')
        this.dom.find('#report-types-dd-container > select').addClass("input-group-text")
    }

    displayFilters(data, depOn=false) {
        let dependent_filters = [];
        $.each(app.appData.layout.reports.filters, (i, f) => {
            let filter = data.find(d => {
                return d.name == f
            });
            if(filter) {
                let size = 2;
                if(filter.multiple) {
                    size = 4
                }
                let containerId= filter.name+"-dd-container";
                $('#'+containerId).remove();
                if(depOn)  {
                    $('<div id="'+containerId+'"  class="col-6 col-sm-4 col-md-'+ size +' report_type_filters pb-2"></div>').insertAfter(this.dom.find("#"+depOn+"-dd-container"));

                } else {
                    $('<div id="'+containerId+'"  class="col-6 col-sm-4 col-md-'+ size +' report_type_filters pb-2"></div>').insertBefore(this.dom.find('#report-filter-button'));
                }
                let real_name = filter.name
                if(filter.multiple) {
                    real_name = filter.name + '[]'
                }
                if(filter.data && filter.data.length > 0) {
                    if(filter.all) {
                        let firstElement = {
                            "innerhtml" : 'All',
                            "value": filter.all_value || filter.data.map( el => el.value ).join(',')
                        }
                        filter.data.unshift(firstElement)
                    }
                    new Dropdown(filter.data)
                    .label(filter.label)
                    .all(true)
                    .required(filter.required)
                    .multiple(filter.multiple)
                    .draw($('#'+containerId), 'value', 'innerhtml', real_name)

                    if(filter.dependent) {
                        dependent_filters.push(filter.dependent);
                        $('#'+containerId+' select').on('change', (event) => {
                            this.getFilterData($(event.target).val(), filter)
                            .then((d) => {
                                this.displayFilters(d, filter.name)
                            })
                        })
                    }
                } else {
                    let inputClass = '';
                    $('#'+containerId).html('<input type="text" id="'+filter.name+'" placeholder="'+filter.label+'" class="form-input input-group-text '+inputClass+'" name="'+filter.name+'" />');

                    if(filter.name.indexOf('_date') > -1) {
                        $('#'+filter.name).datepicker();
                    }
                }
            }
        })

        $('#filters-container > select').addClass("input-group-text")

    }

    getFilterData(selected_values, filter) {
        let data = this.dom.find('#report-filters').serializeArray()
        data.push({ name: 'field', value: filter.dependent})
        return $.ajax({
            url: "api/reports/filters/data",
            type: "get",
            data: data
        })
    }

    renderFilters() {
        this.dom.find('.report_type_filters').remove();
        $.ajax({
            url: "api/reports/filters",
            type: "post",
            data: {'report_type': this.dom.find('[name="report_type"]').val()}
        }).then(data => {
            this.displayFilters(data);
            this.dom.find($('#apply-filters')).show();
        })
    }

    initializeReport() {
        $.ajax({
            url: "api/reports/types"
        }).then(data => {
            this.report_types = data;
            this.renderReportsTypes(data);

            this.dom.find($('select[name="report_type"]')).on('change', () => {
                this.renderFilters();
            })

            this.dom.find('#report-filters').on('submit', (event) => {
                event.preventDefault();
                if(this.validateFilters()) {

                    this.dom.find('.page-loading-overlay').show();
                    this.filterData = $(event.target).serialize();
                    let report_type = this.dom.find($('select[name="report_type"]')).val();
                    this.reportsTable = new ReportTable({
                        title: report_type,
                        ordering : true
                    });

                    this.isExportOnly()
                    .then(data => {
                        if(data.export_only) {
                            this.reportsTable.exportData(report_type)
                            this.dom.find('#reports-table').html('<div class="card"> <div class="card-body">This report is export only.</div></div>')
                        } else {
                            this.reportsTable.recreateTable(this.filterData);
                        }
                    })
                    this.dom.find('#reports-table').html(this.reportsTable.dom);
                }
            })

            if(!app.isMobile()){
                this.dom.find("#reports_container").show();
            }

            this.dom.find(".page-loading-overlay").hide();

        })
    }

    isExportOnly() {
        let data = this.dom.find('#report-filters').serializeArray()
        return $.ajax({
            url: "api/reports/export_only",
            data: data
        })
    }

    validateFilters() {
        let ret = true;
        var required = $('input,textarea,select').filter('[required]:visible');
        $.each(required, (i, filter) => {
            let elem = $('[name="'+filter.name+'"]');
            elem.removeClass('is-invalid');
            if(elem.val() === null || (!elem.val().length && elem.val() !== "")) {
                ret = ret && false;
                elem.addClass('is-invalid');
            }
        })
        return ret;
    }

    ready(){
        if(typeof this.reportsTable == 'undefined')
        {
            this.dom.find('#apply-filters').hide();
            this.initializeReport();
        }
    }
}
