import { Page } from "../page.js";
import { SpeakerSlot } from "./SpeakerSlot";
import { GoogleMap } from "../../components/GoogleMap";
import ProgramEvaluation from "../../components/ProgramEvaluation";
import FileUpload from "./FileUpload";
import { ExportCalendar } from "../../components/ExportCalendar";
import { ComplianceMonitor } from "../../components/ComplianceMonitor";
import { ModalAddNote } from "./ModalAddNote";
import { ModalViewUploadLog } from "../exceptions/ModalViewUploadLog";
import { SimpleSendInvite } from "../../components/SimpleSendInvite";
import { ExceptionTable } from "../../components/ExceptionTable";
import { ManualException } from "../exceptions/manualexception/manualException";
import { CancelException } from "../exceptions/cancelexception/cancelException";
import { CustomModal } from "../../components/customModal";

let template = require("./meetingdetail.twig");
let accountable_template = require("./accountable.twig");
let location_template = require("./location.twig");
let fedex_template = require("./fedex.twig");
let note_template = require("./note.twig");
let status_note_template = require("./status_note.twig");
let resource_item_template = require("./resource_item.twig");
let supply_items_template = require("./supply_items.twig");
let expense_template = require("./expense.twig");
let expense_items_template = require("./expense_items.twig");
let spoke_programs_template = require("./spoke_programs.twig");
let tour_programs_template = require("./tour_programs.twig");
let short_lead_program_template = require("./short_lead_program.twig");
let topic_module_template = require("./topic_module.twig");
let sponsorship_template = require("./sponsorship.twig");
let prep_call_template = require("./prep_call.twig");
let deny_modal_template = require("./modal.deny.twig");
let audience_type_template = require("./audience_types.twig");
let tim_materials_template = require("./tim_materials.twig");

export class MeetingDetail extends Page {
  constructor() {
    super();
    let data = app.appData.layout.meeting;
    data.company_key = app.appData.companyKey;
    data.labels = app.appData.labels;
    data.show_meeting_notes =
      typeof data.show_meeting_notes == "undefined"
        ? true
        : data.show_meeting_notes;

    this.dom = $(twig({ data: template }).render(data));

    this.formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    this.dom.find("#approve_meeting").on("click", () => {
      $.ajax({
        url: "api/meeting/" + this.meeting_id + "/approve",
        method: "post",
      }).then((data) => {
        if (data.success) {
          if (window.app.pages.home.budgetCard != undefined) {
            window.app.pages.home.budgetCard.setApprovalCount();
          }

          app.navigate("meeting", [this.meeting_id]);
        } else {
          displayNotification({
            message: data.message,
            success: false,
          });
        }
      });
    });

    this.dom.find("#deny_meeting").on("click", () => {
      let denyModal = new CustomModal({
        title: "Deny Meeting",
        content: deny_modal_template,
        static: true,
        buttons: [
          {
            id: "cancel-deny-btn",
            text: "Cancel",
            close: true,
            class: "btn-info",
          },
          {
            id: "deny-btn",
            text: "Deny",
            class: "btn-danger",
            action: () => {
              if (denyModal.dom.find("form")[0].reportValidity()) {
                $.ajax({
                  url: "api/meeting/" + this.meeting_id + "/deny",
                  method: "post",
                  data: {
                    note: denyModal.dom.find("#deny_note").val(),
                  },
                }).then((data) => {
                  denyModal.close();
                  if (data.success) {
                    if (window.app.pages.home.budgetCard != undefined) {
                      window.app.pages.home.budgetCard.setApprovalCount();
                    }

                    app.navigate("meeting", [this.meeting_id]);
                  }
                });
              }
            },
          },
        ],
      });
      denyModal.open();
    });

    this.dom.find("#occurred_yes_button").on("click", () => {
      $.ajax({
        url: "api/meeting/" + this.meeting_id + "/attest/Yes",
        method: "post",
      }).then((data) => {
        if (data.success) {
          if (
            typeof app.appData.layout.meeting.show_eval_upon_occurred_yes !==
              "undefined" &&
            app.appData.layout.meeting.show_eval_upon_occurred_yes
          ) {
            this.showEvalModalOnNavigate = true;
          }

          app.navigate("meeting", [this.meeting_id]);
        }
      });
    });

    this.dom.find("#occurred_no_button").on("click", () => {
      $.ajax({
        url: "api/meeting/" + this.meeting_id + "/attest/No",
        method: "post",
      }).then((data) => {
        if (data.success) {
          if (
            typeof app.appData.layout.meeting.send_email_upon_occurred_no !==
            "undefined"
          ) {
            $.ajax({
              url: "api/meeting/" + this.meeting_id + "/send_dno",
              method: "post",
            });
          }
          app.navigate("meeting", [this.meeting_id]);
        }
      });
    });

    this.dom.find("#add_note_button").on("click", () => new ModalAddNote());

    this.dom
      .find("#send_invite_button")
      .on("click", () => new SimpleSendInvite(this.meeting_id));
    this.uploadModal = new FileUpload();
    this.dom.find("#upload_button").on("click", () => {
      this.uploadModal.meeting_id = this.meeting_id;
      this.uploadModal.reset();
      let modal = new bootstrap.Modal(this.uploadModal.dom);
      modal.show();
      $("#document_type").empty();
      $("#document_type").append(
        '<option value="" disabled selected>Select Document Type</option>'
      );
      $("#document_type").append('<option value=""></option>');
      $.each(this.uploadDocTypes, (idx, docType) => {
        $("#document_type").append(
          '<option value="' + idx + '">' + docType + "</option>"
        );
      });
    });

    this.dom.on("click", ".tour-link", (event) => {
      event.preventDefault();
      app.navigate($(event.currentTarget).attr("href"), [
        $(event.currentTarget).data("param"),
      ]);
    });

    // 💩
    this.showEvalModalOnNavigate = false;
  }

  isPending(logistics_info) {
    return (
      ["Approval", "Pending"].indexOf(logistics_info.status_type) > -1 &&
      logistics_info.status_key != "confirmed"
    );
  }

  populateSpeakerDetailsSection(speaker_info, topic_module_info) {
    $("#speaker_slots").empty();
    $.each(speaker_info, (idx, speaker) => {
      let speaker_slot = new SpeakerSlot(speaker, topic_module_info);
      $("#speaker_slots").append(speaker_slot.dom);
    });
  }

  /**
   * Fill in information for the Location Details block
   *
   * @param array venue_info Format must match below for function to populate correctly
   *  [
   *     {
   *        venue_type_name: string,
   *        venue_address: string,
   *        venue_city: string,
   *        venue_state: string,
   *        venue_zip: string,
   *        venue_phone: string,
   *        venue_fax: string,
   *        contact_made: int,
   *        venue_reservation_made: int,
   *        venue_reservation_name: string
   *     },
   *     ...
   * ]
   */
  populateLocationDetailsSection(venue_info, out_office) {
    this.dom.find("#locations").empty();

    let show_ascension_location_obj = {
      show_ascension_location:
        app.appData.layout.meeting_request.ascension_location || false,
    };
    let out_office_obj = {
      out_office: out_office,
    };
    venue_info = $.extend(
      venue_info,
      show_ascension_location_obj,
      out_office_obj
    );

    this.dom
      .find("#locations")
      .append($(twig({ data: location_template }).render(venue_info)));
  }

  populateTopicModuleDetailsSection(
    topic_module_info,
    post_meeting,
    is_program_host
  ) {
    this.dom.find("#topic_modules").empty();
    let count = 0;
    let confirmed_modules = 0;
    $.each(topic_module_info, (idx, topic_module) => {
      confirmed_modules = 0;
      if (
        topic_module.supplemental_material.length > 0 ||
        topic_module.meeting_modules.length > 0
      ) {
        count++;
        topic_module.post_meeting = post_meeting;
        topic_module.companyKey = app.appData.companyKey;
        topic_module.confirm_topic_modules =
          app.appData.layout.meeting.confirm_topic_modules;
        topic_module.count = count;
        if (
          topic_module.meeting_modules.length > 0 &&
          topic_module.meeting_modules[0].confirmed_timestamp &&
          topic_module.meeting_modules[0].confirmed_timestamp !=
            "0000-00-00 00:00:00"
        ) {
          confirmed_modules++;
        }
        topic_module.confirmed_modules = confirmed_modules;
        this.dom
          .find("#topic_modules")
          .append(
            $(twig({ data: topic_module_template }).render(topic_module))
          );
        this.dom
          .find(".topic_module_" + count + " .optional_modules")
          .select2({ maximumSelectionLength: topic_module.max_sub_material });
        if (
          post_meeting &&
          is_program_host &&
          app.appData.layout.meeting.confirm_topic_modules
        ) {
          if (!confirmed_modules) {
            this.dom
              .find(
                ".topic_module_" + count + " #confirm_topic_modules_container"
              )
              .show();
          }
        }
      }
    });

    if (count > 0) {
      this.dom.find("#topic_module_details").show();
    }

    this.dom.find("#supplemental_materials").select2();

    this.dom.find(".delete-module").on("click", (e) => {
      $.ajax({
        url: "api/meeting/" + this.meeting_id + "/module/delete",
        method: "post",
        data: {
          meeting_id: this.meeting_id,
          topic_id: $(e.currentTarget).data("topic-id"),
          module_id: $(e.currentTarget).data("module-id"),
        },
      }).then((data) => {
        if (data.success) {
          app.navigate("meeting", [this.meeting_id]);
        }
      });
    });

    this.dom.find(".confirm_topic_modules").on("click", (e) => {
      $(e.target).prop("disabled", true);
      let topic_module = $(e.target).siblings(".optional_modules");
      let topic_module_id = topic_module.val();
      let topic_id = topic_module.data("topic");
      $.ajax({
        url: "api/meeting/" + this.meeting_id + "/module/confirm",
        method: "post",
        data: {
          meeting_id: this.meeting_id,
          topic_module_id: topic_module_id,
          topic_id: topic_id,
          checked: $(e.target).is(":checked") ? 1 : 0,
        },
      }).then((data) => {
        if (data.success) {
          displayNotification({
            success: true,
            message: data.message,
          });
          app.navigate("meeting", [this.meeting_id]);
        } else {
          $(e.target).prop("disabled", false);
          displayNotification({
            success: false,
            message: data.message,
          });
        }
      });
    });

    this.dom.find("#addModule").on("click", (e) => {
      let selected_modules = this.dom.find("#supplemental_materials").val();
      if (selected_modules.length < 1) {
        displayNotification({
          success: false,
          message: "Please select modules to add",
        });
      } else {
        $.ajax({
          url: "api/meeting/" + this.meeting_id + "/module/add",
          method: "post",
          data: {
            meeting_id: this.meeting_id,
            topic_id: $(e.target).data("topic-id"),
            module_id: selected_modules,
          },
        }).then((data) => {
          if (data.success) {
            app.navigate("meeting", [this.meeting_id]);
          }
        });
      }
    });

    this.dom.find(".optional_modules").on("change", (e) => {
      let element = $(e.target).siblings(".confirm_topic_modules");
      element.prop("disabled", true);
      if ($(e.target).select2("data").length >= $(e.target).data("min")) {
        element.prop("disabled", false);
      }
    });

    this.dom.find(".optional_modules").trigger("change");
  }

  populateComplianceSection(compliance_info) {
    this.dom.find("#compliance_field").empty();
    compliance_info.monitoring_only = true;
    let complianceMonitor = new ComplianceMonitor();
    complianceMonitor.monitor(compliance_info);

    this.dom.find("#compliance_field").append(complianceMonitor.dom);

    $(".mcomply").on("click", () => {
      complianceMonitor
        .handleMarkReview(this.meeting_id, { speaker_id: "" })
        .then((compliance) => {
          this.populateComplianceSection(compliance);
        });
    });
  }

  populateAlternateLocationDetailsSection(alternate_venue_info) {
    let alternate_venue_dom = $(
      twig({ data: location_template }).render(alternate_venue_info)
    );
    $(alternate_venue_dom).prepend(
      '<h3 class="text-primary"> Alternate Location</h2>'
    );
    $(alternate_venue_dom)
      .find(
        "#venue_initial_contact, #venue_reservation_made, #venue_reservation_name"
      )
      .parent("div")
      .hide();
    this.dom.find("#locations").append(alternate_venue_dom);
  }

  populateSpokeProgramsSection(hub_spoke_info, is_program_host, base_uri) {
    hub_spoke_info.min_spoke_programs = 0;

    let spokeCount = Object.keys(hub_spoke_info.spoke_programs).length;
    for (let i = 0; i < spokeCount; i++) {
      hub_spoke_info.spoke_programs[i].status_color = app.format.setstatusColor(
        hub_spoke_info.spoke_programs[i]
      );

      if (
        !hub_spoke_info.spoke_count_excluded_statuses.includes(
          hub_spoke_info.spoke_programs[i].status_key
        )
      ) {
        hub_spoke_info.min_spoke_programs++;
      }
    }

    hub_spoke_info.base_uri = base_uri;
    hub_spoke_info.is_program_host = is_program_host;
    hub_spoke_info.spokeLabel = app.appData.labels.spoke;
    let spoke_programs = $(
      twig({ data: spoke_programs_template }).render(hub_spoke_info)
    );

    if (!is_program_host) {
      spoke_programs.find("#max_spoke_programs").prop("disabled", true);
    }

    spoke_programs.find("#max_spoke_programs_update_btn").on("click", () => {
      let new_max_spoke_programs = this.dom.find("#max_spoke_programs").val();

      if (
        new_max_spoke_programs > 100 ||
        new_max_spoke_programs < hub_spoke_info.min_spoke_programs
      ) {
        displayNotification({
          success: false,
          message:
            "Spoke program count must be between " +
            hub_spoke_info.min_spoke_programs +
            " and 100",
        });
        return;
      }

      $.ajax({
        url: "api/meeting/" + this.meeting_id + "/spokelimit",
        data: {
          max_spoke_programs: this.dom.find("#max_spoke_programs").val(),
        },
        dataType: "json",
        method: "post",
      }).then(
        (res) => {
          if (res.success) {
            displayNotification({
              success: true,
              message: "Spoke program count updated successfully",
            });
          } else {
            displayNotification({
              success: false,
              message: "Failed to update spoke program count",
            });
          }
        },
        () => {
          displayNotification({
            success: false,
            message: "Failed to update spoke program count",
          });
        }
      );
    });

    spoke_programs.find(".approve_spoke").on("click", (e) => {
      let spoke_mtg_id = $(e.target).attr("data-meetingID");

      let spoke_approve_deny_container = $("#spoke_" + spoke_mtg_id).find(
        ".spoke_approve_deny_container"
      );

      let spinner = [
        '<div class="spinner-border text-primary" role="status">',
        '<span class="sr-only">Loading...</span>',
        "</div>",
      ];
      spoke_approve_deny_container.empty().append(spinner.join(""));

      $.ajax({
        url: "api/meeting/" + spoke_mtg_id + "/spoke/approve",
        method: "post",
        dataType: "json",
      }).then(
        (res) => {
          if (res.success) {
            let approve_msg = [
              '<div class="text-center">',
              '<span class="text-success">&#x2714 Meeting Approved</span>',
              "</div>",
            ];

            spoke_approve_deny_container.empty().append(approve_msg.join(""));
          } else {
            let error_msg = [
              '<div class="text-center">',
              '<span class="text-danger">Error. Please try again.</span>',
              "</div>",
            ];

            spoke_approve_deny_container.empty().append(error_msg.join(""));
          }
        },
        () => {
          let error_msg = [
            '<div class="text-center">',
            '<span class="text-danger">Error. Please try again.</span>',
            "</div>",
          ];

          spoke_approve_deny_container.empty().append(error_msg.join(""));
        }
      );
    });

    spoke_programs.find(".deny_spoke").on("click", (e) => {
      let spoke_mtg_id = $(e.target).attr("data-meetingID");

      let spoke_approve_deny_container = $("#spoke_" + spoke_mtg_id).find(
        ".spoke_approve_deny_container"
      );

      let spinner = [
        '<div class="spinner-border text-primary" role="status">',
        '<span class="sr-only">Loading...</span>',
        "</div>",
      ];
      spoke_approve_deny_container.empty().append(spinner.join(""));

      $.ajax({
        url: "api/meeting/" + spoke_mtg_id + "/spoke/deny",
        method: "post",
        dataType: "json",
      }).then(
        (res) => {
          if (res.success) {
            let deny_msg = [
              '<div class="text-center">',
              '<span class="text-danger">&#x2716 Meeting Denied</span>',
              "</div>",
            ];

            spoke_approve_deny_container.empty().append(deny_msg.join(""));
          } else {
            let error_msg = [
              '<div class="text-center">',
              '<span class="text-danger">Error. Please try again.</span>',
              "</div>",
            ];

            spoke_approve_deny_container.empty().append(error_msg.join(""));
          }
        },
        () => {
          let error_msg = [
            '<div class="text-center">',
            '<span class="text-danger">Error. Please try again.</span>',
            "</div>",
          ];

          spoke_approve_deny_container.empty().append(error_msg.join(""));
        }
      );
    });

    this.dom.find("#spoke_programs").empty().append(spoke_programs);
  }

  populateTourProgramsSection(tour_info, user_type, base_uri) {
    let tourCount = Object.keys(tour_info.tour_programs).length;
    for (let i = 0; i < tourCount; i++) {
      tour_info.tour_programs[i].status_color = app.format.setstatusColor(
        tour_info.tour_programs[i]
      );
    }

    tour_info.base_uri = base_uri;
    let tour_programs = $(
      twig({ data: tour_programs_template }).render({
        data: tour_info,
        user_type: user_type,
      })
    );
    this.dom.find("#tour_programs").empty().append(tour_programs);
  }

  populatePrepCallSection(data) {
    this.dom.find("#prep_call_data").empty();
    this.dom
      .find("#prep_call_data")
      .append($(twig({ data: prep_call_template }).render({ data: data })));
    this.dom.find("#prep_call_details").show();
  }

  populateCatererDetailsSection(caterer_info, meal_meeting_type) {
    this.dom.find("#caterers").empty();
    if (caterer_info.venue_name !== "" && meal_meeting_type == 1) {
      this.dom
        .find("#caterers")
        .append($(twig({ data: location_template }).render(caterer_info)));
      this.dom.find("#caterer_details").show();
    }
  }

  populateSponsorshipDetailsSection(sponsorship_info, attendees_info) {
    this.dom.find("#sponsorship").empty();
    this.dom.find("#sponsorship").append(
      $(
        twig({ data: sponsorship_template }).render({
          sponsorship_info: sponsorship_info,
        })
      )
    );
    this.dom
      .find("#speaker_attendees")
      .text(attendees_info.counts.speaker_confirmed);
    this.dom.find("#sponsorship_details").show();
  }

  populateSupplyItemsDetailsSection(supply_items) {
    this.dom.find("#supply_items").empty();
    this.dom.find("#supply_items").append(
      $(
        twig({ data: supply_items_template }).render({
          supply_items: supply_items,
        })
      )
    );
    this.dom.find("#supply_items_details").show();
  }

  populateExpenseDetailsSection(expense_items) {
    this.dom.find("#expense_items").empty();
    this.dom
      .find("#expense_items")
      .append(
        $(twig({ data: expense_items_template }).render({ expense_items }))
      );
    this.dom.find("#expense_items").show();
  }

  populateBusinessJustificationSection(business_justification_info) {
    this.dom.find("#business_justification_container").empty();
    $.each(business_justification_info, (idx, note) => {
      note.update_time = moment(note.update_time).format("MM/DD/YYYY hh:mm A");
      this.dom
        .find("#business_justification_container")
        .append($(twig({ data: note_template }).render(note)));
    });
  }

  populateNotesSection(notes, rep_notes, status_timestamps) {
    this.dom.find("#status_timestamps_container").empty();
    $.each(status_timestamps, (idx, status_timestamp) => {
      if (status_timestamp.timestamp !== false) {
        let note = {
          update_time: moment(new Date(status_timestamp.timestamp)).format(
            "MM/DD/YYYY hh:mm A"
          ),
          meeting_note: status_timestamp.label,
        };

        this.dom
          .find("#status_timestamps_container")
          .append($(twig({ data: status_note_template }).render(note)));
      }
    });

    this.dom.find("#notes_container").empty();
    $.each(notes, (idx, note) => {
      note.update_time = moment(note.update_time).format("MM/DD/YYYY hh:mm A");
      this.dom
        .find("#notes_container")
        .append($(twig({ data: note_template }).render(note)));
    });

    this.dom.find("#rep_notes").html(rep_notes ? rep_notes : "N/A");

    $("#export_notes").on("click", function () {
      let spl = window.location.href.split("/");
      let meeting_id = spl[spl.length - 1];
      window.open(
        "api/meeting/exportNotes?" + $.param({ meeting_id: meeting_id })
      );
    });
  }

  populateAVSection(avInfo, column, element) {
    this.dom.find(element).empty();
    if (avInfo.length == 0) {
      this.dom.find(element)
        .append('<div class="detail-field col-12">None Specified</div>');
      return;
    }

    const avData = avInfo[0][column] ? avInfo[0][column].split(",") : ['None Specified'];
    $.each(avData, (idx, av_opt) => {
      this.dom.find(element)
        .append(
          '<div class="detail-field col-12">' + av_opt + "</div>"
        );
    });
  }

  populateAdvertisingSection(advertising_options) {
    this.dom.find("#advertising_options_list").empty();
    $.each(advertising_options, (idx, advertising_options) => {
      this.dom
        .find("#advertising_options_list")
        .append(
          '<div class="detail-field col-12">' +
            advertising_options.name +
            (advertising_options.other_text
              ? " : " + advertising_options.other_text
              : "") +
            "</div>"
        );
    });

    if (advertising_options.length == 0) {
      this.dom
        .find("#advertising_options_list")
        .append('<div class="detail-field col-12">None Specified</div>');
    }
  }

  populateMeetingLogisticsSection(logistics_info, projProgTypeConfig) {
    $("#formatted_meeting_id").text(this.formatted_meeting_id);

    $("#meeting_status").text(logistics_info.status_name);

    $("#canceled_reason_name").text("");
    if (
      app.appData.layout.meeting.show_canceled_reason &&
      logistics_info.status_type == "Cancelled" &&
      logistics_info.canceled_reason_name != null
    ) {
      $("#canceled_reason_name").text(
        "(" +
          logistics_info.canceled_reason_name +
          ": " +
          logistics_info.canceled_reason_other +
          ")"
      );
    }

    $("#status_container")
      .removeClass()
      .addClass(app.format.setstatusColor(logistics_info));

    $("#project_name").text(logistics_info.project_name);
    $("#program_type").text(logistics_info.program_name);
    $("#meeting_type").text(logistics_info.meeting_type_name);

    if (logistics_info.meeting_type_name) {
      $(".meeting_type_container").show();
    } else {
      $(".meeting_type_container").hide();
    }

    let formatted_date = app.format.datestamp(logistics_info.meeting_date);
    let displayTime = {
      hr: logistics_info.event_start_time_hr
        ? logistics_info.event_start_time_hr
        : logistics_info.start_time_hr,
      min: logistics_info.event_start_time_min
        ? logistics_info.event_start_time_min
        : logistics_info.start_time_min,
      ampm: logistics_info.event_start_time_ampm
        ? logistics_info.event_start_time_ampm
        : logistics_info.start_time_ampm,
    };
    let time =
      displayTime.hr +
      ":" +
      displayTime.min +
      " " +
      displayTime.ampm +
      " " +
      (logistics_info.timezone_name || "");
    this.dom.find("#date_time").text(formatted_date + ", " + time);
    this.dom
      .find("#created_date .detail-field")
      .text(app.format.datestamp(logistics_info.created_date));

    let meeting_closed_date = app.format.datestamp(
      logistics_info.meeting_close_date
    );
    if (meeting_closed_date) {
      this.dom
        .find("#meeting_close_date .detail-field")
        .text(app.format.datestamp(meeting_closed_date));
    } else {
      this.dom.find("#meeting_close_date").hide();
    }

    let formatted_end_date = app.format.datestamp(logistics_info.end_date);
    let displayEndTime = {
      hr: logistics_info.end_time_hr ? logistics_info.end_time_hr : "",
      min: logistics_info.end_time_min ? logistics_info.end_time_min : "",
      ampm: logistics_info.end_time_ampm ? logistics_info.end_time_ampm : "",
    };

    let end_time =
      displayEndTime.hr +
      ":" +
      displayEndTime.min +
      " " +
      displayEndTime.ampm +
      " " +
      (logistics_info.timezone_name || "");
    let end_date_time = formatted_end_date + ", " + end_time;

    if (
      displayEndTime.hr == "" ||
      displayEndTime.min == "" ||
      displayEndTime.ampm == ""
    ) {
      end_date_time = formatted_end_date;
    }

    this.dom.find("#end_date_time").text(end_date_time);
    this.dom
      .find("#all_day_meeting")
      .text(logistics_info.all_day_meeting == "1" ? "Yes" : "No");

    if (
      typeof app.appData.layout.meeting_request.show_arrival_time !==
        "undefined" &&
      app.appData.layout.meeting_request.show_arrival_time
    ) {
      $("#arrival_time_container").show();
      let arrival_time =
        logistics_info.start_time_hr +
        ":" +
        logistics_info.start_time_min +
        " " +
        logistics_info.start_time_ampm +
        " " +
        (logistics_info.timezone_name || "");
      $("#arrival_time").text(arrival_time);
    }

    logistics_info.meeting_date2 =
      logistics_info.meeting_date2 != "0000-00-00 00:00:00" &&
      this.pendingMeeting
        ? logistics_info.meeting_date2
        : "";
    logistics_info.meeting_date3 =
      logistics_info.meeting_date3 != "0000-00-00 00:00:00" &&
      this.pendingMeeting
        ? logistics_info.meeting_date3
        : "";
    let alternate_dates = app.format.datestamp(logistics_info.meeting_date2);
    if (logistics_info.meeting_date3) {
      alternate_dates +=
        "<br />" + app.format.datestamp(logistics_info.meeting_date3);
    }

    $("#alternate_date span").html(alternate_dates);

    this.dom
      .find("#fedex")
      .empty()
      .append($(twig({ data: fedex_template }).render(logistics_info)));
    //this.dom.find('#fedex_details').show();
    if (logistics_info.injector_experience_name) {
      $("#injector_experience_name").html(
        logistics_info.injector_experience_name +
          " (" +
          logistics_info.injector_experience_desc +
          ")"
      );
      $("#injector_experience_name_container").show();
    } else {
      $("#injector_experience_name_container").hide();
    }

    // show Cannula shipment requested
    if (
      typeof projProgTypeConfig.sb.meeting_request.show_cannula_request !==
        "undefined" &&
      projProgTypeConfig.sb.meeting_request.show_cannula_request
    ) {
      this.dom.find("#is_cannula_shipment_requested_container").show();
      this.dom
        .find("#is_cannula_shipment_requested")
        .text(logistics_info.is_cannula_shipment_requested == 1 ? "Yes" : "No");
    }
  }

  populateCoordinatorSection(coordinator_info, base_uri) {
    $("#coordinator_name").text(coordinator_info.coordinator_name);
    $("#coordinator_phone").text(coordinator_info.coordinator_phone);
    $("#coordinator_email").html(
      '<a href="mailto:' +
        coordinator_info.coordinator_email +
        '" > ' +
        (coordinator_info.coordinator_email
          ? coordinator_info.coordinator_email
          : "") +
        "</a>"
    );
    $("#coordinator_pic").attr("src", base_uri + "/images/agent.png");
  }

  populateAccountableSection(
    accountable,
    host,
    manager,
    a_terr_name,
    h_terr_name,
    p_terr_name,
    accountable_cell,
    host_cell,
    manager_cell,
    appearance_toggle
  ) {
    let accountable_details = $("<div>");

    if (
      host !== null &&
      host.trim() != "" &&
      ($.inArray("host", appearance_toggle) !== -1 ||
        $.inArray("all", appearance_toggle) !== -1)
    ) {
      accountable_details.append(
        $(
          twig({ data: accountable_template }).render({
            header: "Program Host",
            host: host,
            terr_name: h_terr_name,
            cell_phone: this.formatCellNumber(host_cell),
          })
        )
      );
    }

    if (
      accountable !== null &&
      accountable.trim() != "" &&
      accountable != host &&
      ($.inArray("accountable", appearance_toggle) !== -1 ||
        $.inArray("all", appearance_toggle) !== -1)
    ) {
      accountable_details.append(
        $(
          twig({ data: accountable_template }).render({
            header: app.appData.layout.meeting_detail.rep_title,
            host: accountable,
            terr_name: a_terr_name,
            cell_phone: this.formatCellNumber(accountable_cell),
          })
        )
      );
    }

    if (
      manager !== null &&
      manager.trim() != "" &&
      ($.inArray("manager", appearance_toggle) !== -1 ||
        $.inArray("all", appearance_toggle) !== -1)
    ) {
      accountable_details.append(
        $(
          twig({ data: accountable_template }).render({
            header: "Manager",
            host: manager,
            terr_name: p_terr_name,
            cell_phone: this.formatCellNumber(manager_cell),
          })
        )
      );
    }

    $("#rep_details").empty().append(accountable_details);
  }

  populateAttendeesSection(attendees_info, logistics_info, projProgTypeConfig) {
    $("#actual_attendees").text(attendees_info.counts.total_attendee);
    $("#rsvp_attendees").text(attendees_info.counts.rsvp_att);
    $("#estimated_attendees").text(attendees_info.estimated_attendees);
    $("#rep_attendees").text(attendees_info.counts.rep);
    $("#speaker_attendees").text(attendees_info.counts.speaker);
    $("#invited_attendees").text(attendees_info.counts.att_invited);
    $("#cancelled_attendees").text(attendees_info.counts.att_cancelled);
    $("#attendees_actual_attended").text(attendees_info.counts.att_act_att);
    $("#attendees_gtd_num").text(attendees_info.counts.gtd_num);

    if (app.appData.layout.meeting.attendee_view == 1) {
      $("#actual_attendees_container").hide();
    } else {
      $("#invited_attendees_container").hide();
      $("#cancelled_attendees_container").hide();
      $("#attendees_actual_attended_container").hide();
    }

    if (
      typeof projProgTypeConfig.sb.meeting_request.show_estimated_patients !==
        "undefined" &&
      projProgTypeConfig.sb.meeting_request.show_estimated_patients
    ) {
      this.dom.find("#est_patients_container").show();
      this.dom.find("#est_patients").text(logistics_info.est_patients);
    }
  }

  populateResourcesSection(resources_info, meeting_id) {
    let icon = "";
    let link = "";
    let alt_text = "";

    $("#resources_list").empty();
    $("#tim_material_list").empty();
    $.each(resources_info, (idx, resource) => {
      if (resource.resource_type === "uploaded_resource") {
        link = "api/programs/" + meeting_id + "/resources/" + resource.file_id;
      } else if (resource.resource_type === "online_document") {
        link = "api/programs/" + meeting_id + "/medias/" + resource.media_id;
      } else if (resource.resource_type === "ref_resource") {
        link = "api/resources/" + resource.resource_id + "/download";
      }

      switch (resource.file_type) {
        case "application/pdf":
        case "pdf":
          icon = "far fa-file-pdf fa-lg text-danger";
          alt_text = "pdf";
          break;
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spre":
        case "excel":
          icon = "fas fa-file-excel fa-lg text-success";
          alt_text = "excel";
          break;
        case "pptx":
        case "ppsx":
          icon = "fas fa-file-powerpoint fa-lg text-warning";
          alt_text = "pptx";
          break;
        case "mp4":
          icon = "fas file-video fa-lg";
          alt_text = "mp4";
          break;
        case "doc":
        case "docx":
        default:
          icon = "fas fa-file-word fa-lg text-primary";
          alt_text = "doc";
          break;
      }

      let resource_item = $(
        twig({ data: resource_item_template }).render({
          alt: alt_text,
          icon: icon,
          display_name: resource.display_name,
          link: link,
        })
      );

      $("#resources_list").append(resource_item);

      if (app.appData.layout.meeting.show_tim_materials) {
        if (resource.resource_type === "ref_resource") {
          let tim_material = $(
            twig({ data: tim_materials_template }).render({
              display_name: resource.display_name,
              resource_prc: resource.resource_prc,
              show_tim_on_sb: resource.show_tim_on_sb,
            })
          );

          $("#tim_material_section, #tim_material_body").show();
          $("#tim_material_list").append(tim_material);
        }
      }
    });
  }

  populateMeetingExpensesSection(meeting_expenses, logistics_info) {
    if (
      typeof app.appData.layout.meeting_request.show_expenses !== "undefined" &&
      app.appData.layout.meeting_request.show_expenses.user_types.includes(
        parseInt(app.appData.curPosition.user_type_id)
      )
    ) {
      let show_actual_expenses = false;
      this.dom.find("#expenses_container").empty();

      if (
        logistics_info.status_key == "occurred" ||
        logistics_info.status_key == "recd" ||
        logistics_info.status_key == "closed"
      ) {
        show_actual_expenses = true;
        this.dom.find("#expense_type").empty().html("Actual Program Expenses");
      }

      $.each(meeting_expenses, (idx, expense) => {
        let expense_value = show_actual_expenses
          ? expense.actual
          : expense.estimated;
        if (expense_value) {
          expense.value = this.formatter.format(expense_value);
          this.dom.find("#expenses_container").append(
            $(
              twig({ data: expense_template }).render({
                expense_group: idx,
                expense_value: expense.value,
              })
            )
          );
        }
      });

      // Mileage rate & cost per person
      this.dom.find("#cost_per_person").hide();
      this.dom.find("#mileage_reimbursement").hide();
      if (
        app.appData.curPosition.user_type_id == 8 &&
        app.appData.companyKey.includes("allergan")
      ) {
        // Cost per person
        let cpp = parseFloat(logistics_info.cost_per_person);
        if (cpp > 0) {
          let dollars = app.format.money(cpp);
          this.dom.find("#cost_per_person").show();
          this.dom.find("#cost_per_person_container").text(dollars);
        }

        // Mileage reimbursement
        let reimbursement = parseFloat(logistics_info.mileage_reimbursement);
        if (
          reimbursement > 0 &&
          !show_actual_expenses &&
          logistics_info.status_type != "Cancelled"
        ) {
          let dollars = app.format.money(reimbursement);
          $("#mileage_reimbursement").show();
          $("#mileage_reimbursement_container").text(dollars);
        }
      }
    } else {
      this.dom.find("#expenses_section").hide();
    }
  }

  populateAlertsSection(alerts_info) {
    let meeting_alert_skeleton = $(
      "#meeting_alerts_header h3.meeting-alert"
    ).first();
    let working_meeting_alert = null;

    $.each(alerts_info, (idx, alert) => {
      $("#meeting_alerts_header").append(meeting_alert_skeleton.clone());
      working_meeting_alert = $(
        "#meeting_alerts_header h3.meeting-alert"
      ).last();

      switch (alert.severity) {
        case "info":
          working_meeting_alert.addClass("alert-success");
          break;
        case "danger":
          working_meeting_alert.addClass("alert-danger");
          break;
        default:
          working_meeting_alert.addClass("alert-success");
          break;
      }

      if (alert.navigate) {
        let link = $('<span class="pointer">');
        link.text(alert.message);
        link.on("click", () => {
          app.navigate(alert.navigate, alert.navigateParams);
        });

        working_meeting_alert.html(link);
      } else {
        working_meeting_alert.text(alert.message);
      }
    });

    meeting_alert_skeleton.remove();
  }

  populateShortLeadProgramSection(short_lead_program_info) {
    let slp_dom = $(
      twig({ data: short_lead_program_template }).render({
        slt_questions: short_lead_program_info,
      })
    );
    this.dom.find("#short_lead_program").empty().append(slp_dom);
  }

  populateExceptionsSection() {
    let filterData = "mode=detail";
    filterData += "&meeting_id=" + this.meeting_id;
    let exceptionTable = new ExceptionTable();
    exceptionTable.recreateTable(filterData);

    this.dom.find("#exceptions_table").html(exceptionTable.dom);
  }

  getMeetingData(async = true) {
    return $.ajax({
      url: "api/getMeetingData",
      async: async,
      data: {
        meeting_id: this.meeting_id,
      },
      method: "post",
    });
  }

  ready() {
    this.meeting_id = window.location.toString().split("/").pop();
    this.formatted_meeting_id =
      " #" + ("000000000" + this.meeting_id).slice(-6);

    return this.getMeetingData().then((meeting_data) => {
      if (!meeting_data.can_view) {
        alert("You don't have access to this meeting. Go back!");
        window.history.back();
        return;
      }

      let projProgTypeConfig = meeting_data.project_program_type_config;

      this.pendingMeeting = this.isPending(meeting_data.logistics_info);

      this.uploadDocTypes = meeting_data.upload_doc_types;

      this.populateAccountableSection(
        meeting_data.logistics_info.accountable_name,
        meeting_data.logistics_info.host_name,
        meeting_data.logistics_info.manager_name,
        meeting_data.logistics_info.a_terr_name,
        meeting_data.logistics_info.h_terr_name,
        meeting_data.logistics_info.p_terr_name,
        meeting_data.logistics_info.accountable_phone,
        meeting_data.logistics_info.host_phone,
        meeting_data.logistics_info.manager_phone,
        meeting_data.logistics_info.show_accountable_details
      );
      this.populateAlertsSection(meeting_data.alerts_info);
      this.populateAttendeesSection(
        meeting_data.attendees,
        meeting_data.logistics_info,
        projProgTypeConfig
      );

      let catering_enabled =
        projProgTypeConfig.sb.meeting_request.catering_enabled;
      if (catering_enabled) {
        this.populateCatererDetailsSection(
          meeting_data.caterer_info,
          meeting_data.logistics_info.meal
        );
      }

      if (projProgTypeConfig.sb.meeting_request.show_all_day_meeting_checkbox) {
        $("#all_day_meeting_container").show();
      }

      if (projProgTypeConfig.sb.meeting_request.show_end_date) {
        $("#end_date_time_container").show();
      }

      this.populateCoordinatorSection(
        meeting_data.logistics_info,
        meeting_data.base_uri
      );
      this.populateLocationDetailsSection(
        meeting_data.venue_info,
        meeting_data.logistics_info.out_office
      );
      this.populateMeetingLogisticsSection(
        meeting_data.logistics_info,
        projProgTypeConfig
      );
      this.populateNotesSection(
        meeting_data.notes,
        meeting_data.logistics_info.rep_notes,
        meeting_data.status_timestamps
      );
      this.populateResourcesSection(
        meeting_data.resources,
        meeting_data.logistics_info.meeting_id
      );
      this.populateSpeakerDetailsSection(
        meeting_data.speaker_info,
        meeting_data.topic_module_info
      );
      this.populateSupplyItemsDetailsSection(meeting_data.supply_items);
      this.populateMeetingExpensesSection(
        meeting_data.expenses,
        meeting_data.logistics_info
      );

      if (
        meeting_data.venue_info.venue_confirmed != "0000-00-00 00:00:00" &&
        meeting_data.venue_info.venue_confirmed != null
      ) {
        this.dom.find(".venue_confirmed_badge").show();
      } else {
        this.dom.find(".venue_confirmed_badge").hide();
      }

      if (
        this.pendingMeeting &&
        meeting_data.alternate_venue_info.venue_type_name
      ) {
        this.populateAlternateLocationDetailsSection(
          meeting_data.alternate_venue_info
        );
      }

      if (app.appData.layout.meeting_detail.show_expenses) {
        this.populateExpenseDetailsSection(meeting_data.expenses);
      }

      if (meeting_data.can_approve) {
        this.dom.find("#approval_container").show();
      } else {
        this.dom.find("#approval_container").hide();
      }

      if (meeting_data.can_attest) {
        this.dom.find("#occurred_question").show();
      } else {
        this.dom.find("#occurred_question").hide();
      }

      if (meeting_data.can_upload) {
        this.dom.find("#upload_button").show();
      } else {
        this.dom.find("#upload_button").hide();
      }

      if (meeting_data.can_send_invite) {
        this.dom.find("#send_invite_button").show();
      } else {
        this.dom.find("#send_invite_button").hide();
      }

      if (meeting_data.show_manage_attendance) {
        this.dom.find("#manage_attendance_button").show();
      } else {
        this.dom.find("#manage_attendance_button").hide();
      }

      if (meeting_data.show_sponsorship_msg) {
        let resource_codes = [];
        $.each(meeting_data.resources, (idx, info) => {
          resource_codes.push(info.file_code);
        });

        // update message to include doctypes
        let show_msg = false;
        $.each(this.uploadDocTypes, (idx, docType) => {
          // if(!$.inArray(idx,resource_codes)){
          if (!resource_codes.includes(idx)) {
            show_msg = true;
            $("#upload_doc_types").append("<li><h5>" + docType + "</h5></li>");
          }
        });
        if (show_msg) {
          this.dom.find("#sponsorship_msg").show();
          this.dom.find("#upload_button").show();
        }
      }

      let evalButton = this.dom.find("#eval_button");
      if (
        (meeting_data.eval.question_set_id > 0 &&
          meeting_data.eval.mode == "eval") ||
        meeting_data.eval.mode == "eval_view"
      ) {
        evalButton.off().on("click", () => {
          if (!this.evalModal) {
            this.evalModal = new ProgramEvaluation(meeting_data);
          }
          this.evalModal.ready();
        });

        evalButton
          .find(".eval-button-label")
          .text(meeting_data.eval.eval_button);
        if (meeting_data.eval.mode == "eval") {
          evalButton.removeClass("btn-dark").addClass("btn-primary");
        } else {
          evalButton.removeClass("btn-primary").addClass("btn-dark");
          evalButton.find(".eval-complete").show();
        }

        evalButton.show();

        if (this.showEvalModalOnNavigate) {
          this.showEvalModalOnNavigate = false;
          evalButton.trigger("click");
        }
      } else {
        evalButton.hide();
      }

      if (meeting_data.logistics_info.status_name == "Logistics Confirmed") {
        this.dom.find("#export_button").show();
      } else {
        this.dom.find("#export_button").hide();
      }

      if (meeting_data.logistics_info.manage_attendance_completed > 0) {
        this.dom.find(".attendance-complete").show();
      } else {
        this.dom.find(".attendance-complete").hide();
      }

      this.dom
        .find("#manage_attendance_button")
        .off()
        .on("click", () => {
          app.navigate("attendance", [this.meeting_id]);
        });

      this.dom
        .find("#view_expenses_button")
        .off()
        .on("click", () => {
          app.navigate("meetingexpenses", [this.meeting_id]);
        });

      this.dom
        .find("#prepostwork_button")
        .off()
        .on("click", () => {
          app.navigate("prepostwork", [this.meeting_id]);
        });

      this.dom.find(".location_map_button").each((i, element) => {
        $(element).on("click", () => {
          let slot_element = $(element).closest(".venue_slot");

          if (!(this.mapModal ?? false)) {
            this.mapModal = new GoogleMap();
          }

          this.mapModal.showGoogleMap(
            slot_element.find(".venue_program_address").text()
          );
        });
      });

      if (
        meeting_data.form_cards.length &&
        (meeting_data.form_cards.includes("speaker_card") ||
          meeting_data.form_cards.includes("manual_speaker_card"))
      ) {
        this.dom.find("#speaker_details").show();
      } else {
        this.dom.find("#speaker_details").hide();
      }

      if (
        projProgTypeConfig.sb.meeting_request.display_faculty_prep_section &&
        meeting_data.prep_call.length
      ) {
        this.populatePrepCallSection(meeting_data.prep_call);
      } else {
        this.dom.find("#prep_call_details").hide();
      }

      if (
        meeting_data.form_cards.length &&
        !meeting_data.form_cards.includes("topic_module_card")
      ) {
        this.dom.find("#topic_module_details").hide();
      } else {
        this.dom.find("#topic_module_details").hide();
        this.populateTopicModuleDetailsSection(
          meeting_data.topic_module_info,
          meeting_data.logistics_info.occurred,
          meeting_data.is_program_host
        );
      }

      if (
        meeting_data.form_cards.length &&
        !meeting_data.form_cards.includes("venue_card")
      ) {
        this.dom.find("#location_details").hide();
      } else {
        this.dom.find("#location_details").show();
      }

      if (
        meeting_data.form_cards.length &&
        meeting_data.form_cards.includes("sponsorship_card")
      ) {
        this.populateSponsorshipDetailsSection(
          meeting_data.sponsorship_info,
          meeting_data.attendees
        );
      } else {
        this.dom.find("#sponsorship_details").hide();
      }

      if (
        meeting_data.form_cards.length &&
        !meeting_data.form_cards.includes("supply_items_card")
      ) {
        this.dom.find("#supply_items_details").hide();
      } else {
        this.dom.find("#supply_items_details").show();
      }

      if (
        meeting_data.form_cards.length &&
        !meeting_data.form_cards.includes("fedex_card")
      ) {
        this.dom.find("#fedex_details").hide();
      } else {
        this.dom.find("#fedex_details").show();
      }

      if (
        meeting_data.form_cards.length &&
        !meeting_data.form_cards.includes("consultation_card")
      ) {
        this.dom.find("#consultation_section").hide();
      } else {
        this.dom.find("#consultation_section").show();
        this.dom
          .find("#consultation_reason")
          .text(meeting_data.logistics_info.consultation_reason);
      }

      if (
        meeting_data.form_cards.length &&
        !meeting_data.form_cards.includes("docusign_attestation_card")
      ) {
        this.dom.find("#docusign_attestation_section").hide();
      } else {
        this.dom.find("#docusign_attestation_section").show();
        this.dom
          .find("#docusign_attestation_needed")
          .text(
            meeting_data.logistics_info.docusign_attestation_needed == "1"
              ? "Yes"
              : "No"
          );
      }

      if (
        meeting_data.form_cards.length &&
        meeting_data.form_cards.includes("spoke_card") &&
        projProgTypeConfig.sb.meeting_request.is_hub &&
        meeting_data.hub_spoke_info.is_hub == "1" &&
        projProgTypeConfig.sb.meeting.show_spokes != undefined &&
        projProgTypeConfig.sb.meeting.show_spokes
      ) {
        this.populateSpokeProgramsSection(
          meeting_data.hub_spoke_info,
          meeting_data.is_program_host,
          meeting_data.base_uri
        );
        this.dom.find("#spoke_program_details").show();
      } else {
        this.dom.find("#spoke_program_details").hide();
      }

      if (Object.keys(meeting_data.tour_info).length) {
        this.populateTourProgramsSection(
          meeting_data.tour_info,
          app.appData.curPosition.user_type_id,
          meeting_data.base_uri
        );
        this.dom.find("#tour_program_details").show();
      } else {
        this.dom.find("#tour_program_details").hide();
      }

      if (
        meeting_data.form_cards.length &&
        meeting_data.form_cards.includes("slt_questions_card") &&
        meeting_data.logistics_info.short_lead_time === "on" &&
        projProgTypeConfig.sb.meeting_request.allow_short_lead_pr !=
          undefined &&
        projProgTypeConfig.sb.meeting_request.allow_short_lead_pr
      ) {
        this.populateShortLeadProgramSection(
          meeting_data.short_lead_program_info
        );
      } else {
        this.dom.find("#short_lead_program_details").hide();
      }

      if (meeting_data.exceptions_perm.card) {
        if (meeting_data.exceptions_perm.add) {
          this.dom.find(".add_exception_button").show();
        } else {
          this.dom.find(".add_exception_button").hide();
        }
        this.populateExceptionsSection(meeting_data.exceptions_list);
      } else {
        this.dom.find("#exceptions_section").hide();
      }

      if (meeting_data.compliance_status) {
        this.dom.find("#compliance_section").show();
        this.populateComplianceSection(meeting_data.compliance_status);
      } else {
        this.dom.find("#compliance_section").hide();
      }

      if (
        meeting_data.form_cards.length &&
        meeting_data.form_cards.includes("advertising_card")
      ) {
        this.dom.find("#advertising_options_section").show();
        this.populateAdvertisingSection(meeting_data.advertising_options);
      } else {
        this.dom.find("#advertising_options_section").hide();
      }

      if (
        meeting_data.form_cards.length &&
        meeting_data.form_cards.includes("av_options_card") &&
        projProgTypeConfig.sb.meeting_request.show_av
      ) {
        this.dom.find("#av_details_section").show();
        this.populateAVSection(meeting_data.av_info,"av_options" ,"#av_options_list");
        this.populateAVSection(meeting_data.av_info, "av_providers" , "#av_provider_list");
      } else {
        this.dom.find("#av_details_section").hide();
      }

      let show_audience_type_field =
        app.appData.layout.meeting_request.show_audience_type_field ?? false;
      if (show_audience_type_field) {
        let audience_types = app.appData.audience_types;
        let meeting_audience_types = meeting_data.audience_type;
        let audience_type_config =
          app.appData.layout.meeting.audience_type ?? false;
        let hide_audience_type_other = !!(
          audience_type_config && audience_type_config.hide_audience_type_other
        );

        this.dom.find("#audience_type_list").html("");
        this.dom.find("#audience_type_other").html("");

        $.each(meeting_audience_types, (idx, audience_type) => {
          let toggle_audience_type_other = "d-block";
          if (
            hide_audience_type_other &&
            audience_type.audience_key === "other"
          ) {
            toggle_audience_type_other = "hide";
          }
          this.dom
            .find("#audience_type_list")
            .append(
              '<span class="col-12' +
                " " +
                toggle_audience_type_other +
                '">' +
                audience_type.name +
                "</span>"
            );
          this.dom
            .find("#audience_type_other")
            .append(audience_type.audience_type_other);
        });

        this.dom.find("#edit_audience_type").hide();
        this.dom.find("#view_audience_type").show();

        if (
          audience_type_config &&
          audience_type_config.edit_audience_type &&
          $.inArray(
            app.appData.curPosition.user_type_id,
            audience_type_config.edit_audience_type
          ) >= 0
        ) {
          this.dom.find("#view_audience_type").hide();
          this.dom.find("#edit_audience_type").show();

          let audience_types_dom = $(
            twig({ data: audience_type_template }).render({
              audience_types: audience_types,
            })
          );
          let audience_type_other = "";

          let select_audience_type_dropdown =
            audience_types_dom.find("#audience_type");
          if (select_audience_type_dropdown.attr("multiple")) {
            select_audience_type_dropdown.select2({
              closeOnSelect: false,
              width: "100%",
            });
          }

          audience_types_dom
            .find("[name='audience_type[]']")
            .val(null)
            .trigger("change");
          for (let i in meeting_audience_types) {
            audience_types_dom
              .find(
                "[name='audience_type[]'] [value='" +
                  meeting_audience_types[i].audience_type_id +
                  "']"
              )
              .prop("selected", true);
            if (meeting_audience_types[i].audience_type_other) {
              audience_type_other +=
                meeting_audience_types[i].audience_type_other;
            }
          }
          audience_types_dom
            .find("[name='audience_type_other']")
            .val(audience_type_other);
          audience_types_dom.find("[name='audience_type[]']").trigger("change");
          this.dom.find("#edit_audience_type").html(audience_types_dom);

          $("#audience_type").on("change", function () {
            let select_option_text = audience_types_dom
              .find("#audience_type option:selected")
              .toArray()
              .map((item) => item.text);

            if ($.inArray("Other", select_option_text) >= 0) {
              audience_types_dom.find("#audience_type_other_container").show();
              audience_types_dom
                .find("input[name='audience_type_other']")
                .attr("required", true);
            } else {
              audience_types_dom.find("#audience_type_other_container").hide();
              audience_types_dom
                .find("input[name='audience_type_other']")
                .val("")
                .attr("required", false);
            }
          });

          audience_types_dom.on("submit", (event) => {
            let audienceData = audience_types_dom
              .find("#form_audience_type")
              .serialize();

            $.ajax({
              url: "api/meeting/" + this.meeting_id + "/audience_type",
              data: audienceData,
              method: "post",
            }).then(() => {
              displayNotification({
                message: "Audience Type saved!",
                success: true,
              });
            });
            event.preventDefault();
          });
        }
      }

      if (
        meeting_data.form_cards.length &&
        meeting_data.form_cards.includes("congress_card") &&
        projProgTypeConfig.sb.meeting_request.show_congress
      ) {
        let congress_id = 0;
        let is_congress = "No";
        let special_projects = app.appData.special_project;

        $.each(special_projects, function (index, special_project) {
          $.each(special_project, function (key, value) {
            if (key === "spec_proj_name" && value === "Congress") {
              congress_id = special_project.spec_proj_id;
              return true;
            }
          });
        });

        this.dom.find("#congress_section").show();
        if (
          congress_id > 0 &&
          meeting_data.logistics_info.spec_proj_id === congress_id
        ) {
          is_congress = "Yes";
        }

        this.dom.find("#congress").text(is_congress);
      } else {
        this.dom.find("#congress_section").hide();
      }

      if (
        meeting_data.form_cards.length &&
        meeting_data.form_cards.includes("edify_card") &&
        projProgTypeConfig.sb.meeting_request.edify_required
      ) {
        let color = meeting_data.send_to_edify ? "#BFB" : "#FBB";
        let message = meeting_data.send_to_edify
          ? "Send this meeting to EDIFY"
          : "Do not send meeting to EDIFY";
        this.dom.find("#edify_indicator").css("background", color);
        this.dom.find("#edify_message").text(message);
        this.dom.find("#edify_container").show();
      } else {
        this.dom.find("#edify_container").hide();
      }

      if (
        meeting_data.form_cards.length &&
        meeting_data.form_cards.includes("business_justification_card")
      ) {
        this.populateBusinessJustificationSection(
          meeting_data.business_justification_info
        );
      } else {
        this.dom.find("#business_justification_details").hide();
      }

      if (
        typeof app.appData.layout.meeting.show_view_expenses !== "undefined" &&
        app.appData.layout.meeting.show_view_expenses.user_types.includes(
          parseInt(app.appData.curPosition.user_type_id)
        ) &&
        app.appData.layout.meeting.show_view_expenses.statuses.includes(
          meeting_data.logistics_info.status_key
        )
      ) {
        this.dom.find("#view_expenses_button").show();
      } else {
        this.dom.find("#view_expenses_button").hide();
      }

      if (meeting_data.logistics_info.rationale) {
        this.dom.find("#rationale_section").show();
        this.dom.find("#rationale").text(meeting_data.logistics_info.rationale);
      } else {
        this.dom.find("#rationale_section").hide();
      }

      if (
        projProgTypeConfig.sb.meeting &&
        typeof projProgTypeConfig.sb.meeting.enable_pre_post_work !==
          "undefined" &&
        projProgTypeConfig.sb.meeting.enable_pre_post_work &&
        meeting_data.is_program_host
      ) {
        this.dom.find("#prepostwork_button").show();
      } else {
        this.dom.find("#prepostwork_button").hide();
      }

      let show_contact_made =
        projProgTypeConfig.sb.meeting_request.show_contact_made;
      let show_reservation_made =
        projProgTypeConfig.sb.meeting_request.show_reservation_made;

      if (!show_contact_made) {
        this.dom.find("#venue_initial_contact").parent().hide();
      }

      if (!show_reservation_made) {
        this.dom.find("#venue_reservation_made").parent().hide();
        this.dom.find("#venue_reservation_name").parent().hide();
      }
      let logModal = new ModalViewUploadLog();
      //exceptions card click events
      $(document).on("click", ".viewlog_detail", function (event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        let logData = $(event.target).data();
        logData.meetingId = meeting_data.logistics_info.meeting_id;
        logModal.showModal(logData);
      });

      $(document).on("click", ".add_exception_button", (event) => {
        event.preventDefault();

        new ManualException(meeting_data.logistics_info.meeting_id, this);
      });

      $(document).on("click", ".excancel", (event) => {
        event.preventDefault();

        let exceptionId = $(event.target).data().eid;
        new CancelException(exceptionId, this);
      });
      //end exceptions card click events

      // Make
      let program = {
        speaker: meeting_data.speaker_info
          .map((d) => d.speaker_name)
          .join("<br/>"),
        topic: meeting_data.speaker_info.map((d) => d.topic_name).join("<br/>"),
        venue:
          meeting_data.venue_info.venue_name +
          ", " +
          meeting_data.venue_info.venue_address +
          " " +
          meeting_data.venue_info.venue_city +
          ", " +
          meeting_data.venue_info.venue_state +
          " " +
          meeting_data.venue_info.venue_zip,
        meeting_date: meeting_data.logistics_info.meeting_date,
        meeting_time:
          meeting_data.logistics_info.start_time_hr +
          ":" +
          meeting_data.logistics_info.start_time_min +
          " " +
          meeting_data.logistics_info.start_time_ampm,
        meeting_endtime: meeting_data.logistics_info.end_time_hr
          ? meeting_data.logistics_info.end_time_hr +
            ":" +
            meeting_data.logistics_info.end_time_min
            ? meeting_data.logistics_info.end_time_min
            : "00" + " " + meeting_data.logistics_info.end_time_ampm
          : null,
        timezone: meeting_data.logistics_info.timezone,
        program_name: meeting_data.logistics_info.program_name,
        meeting_id: this.meeting_id,
        status_name: meeting_data.logistics_info.status_name,
      };

      if ($("#export_button")) {
        $("#export_button").remove();
      }
      let exportCalendar = new ExportCalendar(program);
      $(exportCalendar.dom).insertAfter($("#back_button"));

      $("#backToDashboard").on("click", () => {
        app.navigate("home");
      });
    });
  }

  teardown() {
    // Bootstrap tabs shit themselves on loading new evals on navigating to another meeting
    // if you don't clear out the html/dom
    if (this.evalModal) {
      this.evalModal.dom.html("");
      this.evalModal.dom = null;
      this.evalModal = null;
    }

    if (this.mapModal) {
      this.mapModal.modal.hide();
      this.mapModal = null;
    }
  }

  formatCellNumber(number) {
    const cleanNumber = number.replace(/\D/g, "");
    if (cleanNumber.length !== 10) {
      return null;
    }

    const formattedNumber =
      cleanNumber.slice(0, 3) +
      "-" +
      cleanNumber.slice(3, 6) +
      "-" +
      cleanNumber.slice(6);
    return formattedNumber;
  }
}
