export class Page
{
    constructor() {
        this.display = true
        this.breakout()

        //$('[data-toggle="tooltip"]').tooltip()
    }

    breakout() {
        // Break out of iframe
        if (top.location!= self.location) {
            this.display = false
            let performBreakout = true
            if(typeof this.onBreakout != "undefined")
            {
                performBreakout = this.onBreakout()
            }

            if(performBreakout)
            {
                top.onbeforeunload = null
                top.location = self.location.href
            }
        }
    }
}
