import {Card} from "./Card";

var template = require('./docusign_attestation_card.twig');

export class DocusignAttestationCard extends Card {
    constructor() {
        super();
        this.dom = $(twig({data: template}).render());

        this.dom.on('change', 'select.required', () => {
            this.cardFilledOutTriggers();
        })
    }
}