import { Page } from '../page.js'
import { SpeakerFilter } from "../../components/SpeakerFilter";

import template from './speakers.twig';
import travelTemplate from './travelBadges.twig';

export class Speakers extends Page {
    constructor() {
        super();
        this.filters = [];
        this.dataLoading = false;
        this.dom = $(twig({ data: template }).render({
            'nominate_speaker' : app.appData.layout.speakers.nominate_speaker,
            'speaker' : app.appData.labels.speaker + 's'
        }));
        this.dom.on('reloadTable', () => {
            this.dom.find('.page-loading-overlay').show();
            this.dom.find('#datatable_speakers_wrapper').hide();
            this.getSpeakers()
            .then(response => {
                this.speakers = response.data;
                this.dom.find('.page-loading-overlay').show();
                this.dom.find('#datatable_speakers_wrapper').hide();
                this.initializeTable(response);

                this.dom.find('.page-loading-overlay').hide();
                this.dom.find('#datatable_speakers_wrapper').show();
                $('#speaker_filter').attr('disabled', false);
            })
        })


		this.dom.find('button[type=reset]').on('click', (event) => {
            event.preventDefault();
            $('#speaker_filter_form')[0].reset()
            $('#speaker_filter_form input[type="checkbox"]:checked').prop('checked', false).val(1);
            $('#speaker_filter_form select[multiple]').val(null).trigger('change');

			this.speakerFilter.manual_reset_fields.forEach(filter => {
				$("[name='"+filter.real_field+"']").val(null).trigger('change')
			})

			$('#trained_speakers, #trained_speakers_label').hide();
		});
    }

    initializeTable(response) {
        if(this.speakersTable) {
            $('#datatable_speakers').DataTable().clear().destroy();
            $('#datatable_speakers').html('');
        }

        this.setTableColumns(response.columns)

        this.speakersTable = $('#datatable_speakers').DataTable({
            data: response.data,
            processing: true,
            deferRender: true,
            columns: this.columns,
            language: {
                emptyTable: "No speakers to display",
                search: "",
                searchPlaceholder: "Search...",
                processing: "Loading..."
            },
            pagingType  : "full_numbers",
            width       : "100%",
            "paging": $(window).width() < 768 ? false : true,
            "info": $(window).width() < 768 ? false : true,
            "dom": '<"row"<"col-md-6"l><"col-md-6"<"row"<"col-md-10 search-box text-end"f><"w-md-50 float-start export"B>>>><tr>ip',

            "buttons": [ {
                'extend' : 'csvHtml5',
                'className':'btn btn-success',
                'text': '<i class="fas fa-file-excel"></i>',
                'title': app.appData.labels.speaker + 's',
                'exportOptions': {
                    'orthogonal' : 'export',
                    'columns' : undefined
                }
            }],
            "destroy": true,
            "drawCallback": function() {
                app.convertDatatableToCard("datatable_speakers");
            }
        });
        $('.export').attr('title', 'Click to download');

        this.dom.find('#datatable_speakers tbody').off('click')
        .on('click','img.downloadCv', (elem) => {
            event.preventDefault();
            let fileId = $(event.target).attr('data-info');
            let data = this.speakersTable.row($(elem.target).parents('tr')).data();
            window.open("api/speakers/"+data.speaker_id+"/download/"+fileId);
            return false; // Do not trigger next event handler
        })
        .on('click', 'tr', (elem) => {
            let data = this.speakersTable.row($(elem.target).parents('tr')).data();
            app.navigate('speaker', [data.speaker_id]);
        });
    }


    refreshTable(data) {
        this.speakersTable.clear();
        this.speakersTable.rows.add(data); // Add new data
        this.speakersTable.columns.adjust().draw(); // Redraw the DataTable
    }

    setSpeakerNominationButton() {
        this.dom.find('#nomination-link').addClass('d-none');
         $.ajax({
            url: 'api/speakers/nominationlink'
        }).then((data) => {
            this.nominationLink = data.link;
            if(this.nominationLink) {
                this.dom.find('#nomination-link').removeClass('d-none');
            }
        });

        this.dom.find('#nomination-link button').on('click', () => {
            if(this.nominationLink) {
                window.open(this.nominationLink, '_blank')
            }
        })
    }

    formatCvDownload(data)
    {
        let cvReturn = 'No CV'
        if(parseInt(data))
        {
            cvReturn = '<span class="downloadCv" style="font-size: 2em"  data-info="' + data + '">' +
                '<i class="fa-solid fa-file-pdf h4 text-danger"></i>' +
                '</span>';
        }

        return cvReturn
    }

    formatDayBadges(data)
    {
        var days = ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"];
        let daysObject = days.map( day => {
            let willing = 0;
            if(typeof data == 'string' && data.indexOf(day) >= 0)
            {
                willing = 1
            }

            return {
                "full_day" : day,
                "day" : day.slice(0,1),
                "willing" : willing
            }
        });

        return twig({ data: travelTemplate }).render({'days' : daysObject});
    }

    setTableColumns(columns) {
        this.columns = [];
        this.columntoFilterMap = [];
        $.each(columns, (key, column) =>  {
            this.columns.push({
                data: column.key,
                title: column.label,
                visible: ( column.format != 'exportOnly'),
                render : (data, type, row) => {
                    if ( column.format == 'formatDate' && type === 'sort') {
                        var d = new Date(data);
                        return d.getTime();
                    } else if ( column.format == 'makeCvLink' ) {
                        if(type === 'export')
                        {
                            if(parseInt(data))
                            {
                                return 'Yes'
                            }

                            return 'No'
                        }
                        return this.formatCvDownload(data)
                    } else if ( column.format == 'formatDayBadges' ) {
                        if(type === 'export')
                        {
                            return data
                        }

                        return this.formatDayBadges(data)
                    } else {
                        return app.format.column(data, column, row)
                    }
                }
            });
        })
    }

    filterSpeakers(speaker, filters) {
        let ret = true;
        filters.forEach(key => {
            if (Object.prototype.hasOwnProperty.call(speaker, key) || $.inArray(key,['location','speaker']) >= 0) {
                let value = $('#'+key).val()
                let typeIsInput = $('#'+key).is('input')
                let multi = $('#'+key).is('select[multiple]');

                if(value) {
                    if(!typeIsInput && !multi) {
                        let values = (speaker[key]?speaker[key]:'').split(',')
                        let trimmedValues = values.map( element => element.trim())

                        if(!values.includes(value) && !trimmedValues.includes(value)) {
                            ret = false;
                        }
                    } else if (multi && value.length != 0) {
                        if (!value.includes((speaker[key]))) {
                            ret = false;
                        }
                    } else if (key == 'location') {
                        ret = ret && (value == '' || !(speaker['city'].toLowerCase().indexOf(value.toLowerCase()) < 0)
                        || !(speaker['state'].toLowerCase().indexOf(value.toLowerCase()) < 0));
                    } else if (key == 'speaker') {
                        value.split(/\s/g).forEach(name =>
                            ret = ret && (name == '' || !(speaker['firstname'].toLowerCase().indexOf(name.toLowerCase()) < 0)
                                || !(speaker['lastname'].toLowerCase().indexOf(name.toLowerCase()) < 0)))
                    } else if(typeIsInput && speaker[key].toLowerCase().indexOf(value.toLowerCase()) < 0){
                        ret = false;
                    }
                }
            }
        });

        return ret;
    }

    getSpeakers() {
        let data = $('#speaker_filter_form').serializeArray();
        let category_id = $('#consultant_category').val()
        if(category_id) {
            let category_code = app.appData.consultantCategories.filter(category => category.id == category_id)
            if(category_code) {
                data = [...data, ...[{'name': 'consultant_code', 'value': category_code[0].consultant_code }]]
            }
        }
        return $.ajax({
            url: "api/speakers",
            data: data
        });
    }

    ready() {
        this.dom.find('.page-loading-overlay').show();
        this.dom.find('#datatable_speakers_wrapper').hide();

        if(typeof this.speakersTable == 'undefined' && !this.dataLoading)
        {
            this.dataLoading = true;
            this.dom.find('#speaker_filter_container').html();
            fetch('api/speakers/filters')
            .then(response => { return response.json() })
            .then(data => {
                let filter = data.map( el => el.name );
                this.filters = [...this.filters, ...filter]
                let filter_dom = this.dom.find('#speaker_filter_container');
                this.speakerFilter = new SpeakerFilter(data, filter_dom);

                // read filter param from url
                window.location.toString().split("/").filter(el => el.includes('=')).forEach(el => {
                    let name = el.substring(0, el.indexOf("="))
                    filter_dom.find('select#'+name+', input#'+name).val(el.substring(el.indexOf("=") + 1))
                })

                this.setSpeakerNominationButton();
                this.dom.find('#view_speaker').prop('disabled', true)
                this.dom.find('#reset_filter').prop('disabled', true)
                this.getSpeakers()
                .then(response => {
                    this.dataLoading = false;
                    this.speakers = response.data;
                    this.dom.find('.page-loading-overlay').show();
                    this.dom.find('#datatable_speakers_wrapper').hide();
                    this.initializeTable(response);

                    this.dom.find('.page-loading-overlay').hide();
                    this.dom.find('#datatable_speakers_wrapper').show();
                    this.dom.find('#view_speaker').prop('disabled', false)
                    this.dom.find('#reset_filter').prop('disabled', false)
                })

                $('#speaker_filter_form').first().off('submit').on('submit', (e) => {
                    e.preventDefault();
                    if(app.appData.layout.speakers.backend_search) {
                        this.dom.find('.page-loading-overlay').show();
                        this.dom.find('#datatable_speakers_wrapper').hide();
                        this.dom.find('#view_speaker').prop('disabled', true)
                        this.dom.find('#reset_filter').prop('disabled', true)
                        this.getSpeakers()
                        .then(response => {
                            this.refreshTable(response.data);

                            this.dom.find('.page-loading-overlay').hide();
                            this.dom.find('#datatable_speakers_wrapper').show();
                            this.dom.find('#view_speaker').prop('disabled', false)
                            this.dom.find('#reset_filter').prop('disabled', false)
                        })
                    } else {
                        let filteredResult = this.speakers.filter((speaker) => {
                            return this.filterSpeakers(speaker, this.filters)
                        })
                        this.refreshTable(filteredResult);
                    }
                })
            })

        }
        else {
            this.dom.find('.page-loading-overlay').hide();
            this.dom.find('#datatable_speakers_wrapper').show();
        }
    }
}