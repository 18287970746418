import {Page} from "../page";
import template from './login.twig'

export class Login extends Page {
    constructor(params) {
        super();

        let data;
        $.ajax({
            url: 'api/login/init',
            data: params,
            async: false,
            success: response => {
                data = response;
            }
        })

        app.appData = data;
        app.setPageTitle();

        this.dom = $(twig({data: template}).render(data));

        this.loginForm = this.dom.find('#login_form');
        this.passwordForm = this.dom.find('#password_form');
        this.dom.find('#first_time').on('click', event => {
            event.preventDefault();
            this.loginForm.hide();

            this.passwordForm.show();
            this.passwordForm.find('#ft_email').show();
            this.passwordForm.find('#ft_password').hide();
        })

        this.dom.find('#resetLink').on('click', event => {
            event.preventDefault();
            this.loginForm.hide();

            this.passwordForm.show();
            this.passwordForm.find('#ft_email').show();
            this.passwordForm.find('#ft_password').hide();
        })

        this.dom.find('#return_to_login').on('click', event => {
            event.preventDefault();
            this.dom.find('#message').html('');

            this.loginForm.show();
            this.passwordForm.hide();
        })

        this.passwordForm.find('form').on('submit', event => {
            event.preventDefault();
            if(this.passwordForm.find('[name=token]').val()) {
                this.passwordForm.find('[name=email]').val(null);
            }

            $.ajax({
                url: 'forgotpassword',
                method: 'post',
                data: this.passwordForm.find('form').serialize()
            }).then(data => {
                this.dom.find('#message')
                    .html(data.message)
                    .show();

                this.passwordForm.find('form')[0].reset();
            })
        })
    }

    showPasswordResetForm(token) {
        this.loginForm.hide();

        this.passwordForm.show();
        this.passwordForm.find('#ft_email').hide();
        this.passwordForm.find('#ft_password').show();
        this.passwordForm.find('[name=token]').val(token);
    }

    ready() {
    }
}