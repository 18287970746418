import {HubSearchResults} from "../../components/HubSearchResults";
import { Dropdown } from "../../components/Dropdown";

var template = require('./hub_search_modal.twig');

export class HubSearch
{
    constructor() {
        var region_search = app.appData.layout.meeting_request.region_search ? app.appData.layout.meeting_request.region_search : false;
        this.dom = $(twig({ data: template }).render({ region_search: region_search }));

        this.dom.find('#hub_search_from').datepicker({
            "clearBtn": true
        });
        this.dom.find('#hub_search_to').datepicker({
            "clearBtn": true
        });

        this.searchResults = new HubSearchResults($('[name=project_id]').val(), $('[name=program_type_id]').val());

        this.dom.find('.hub_search_results_container').append(this.searchResults.dom);

        this.dom.find('#filter_hubs_btn').on('click', () => {
            this.searchResults.results_table.ajax.reload();
        });

        if (region_search) {
            this.dom.find('#region_container').html('');
            $.ajax({
                url     : 'api/district',
                method  : 'get',
                data    : {
                    "projects": $('[name=project_id]').val(),
                    "search_type": "region"
                }
            }).then((data) => {
                let districtDD = new Dropdown(data)
                districtDD
                .all(false)
                .multiple(true)
                .draw(this.dom.find('#region_container'), 'region_name', 'region_name', 'hub_region')
            })
        }

        this.dom.on('HubMeetingSelected', (event) => {
            $('#hub_meeting_id').val(event.hub_meeting_id);
            $('#hub_meeting_id').trigger('change');

            this.modal.hide();
        })

        this.modal = new bootstrap.Modal(this.dom);
        this.modal.show();
    }
}
