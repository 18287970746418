var template = require('./simple_send_invite_template.twig');

export class SimpleSendInvite {

    constructor(meeting_id) {
        this.meeting_id = meeting_id
        this.dom = $(twig({data: template}).render({
            meeting_id: this.meeting_id
        }))

        this.modal = new bootstrap.Modal(this.dom);

        this.dom.find('form').on('submit', (event) => {
            event.preventDefault()

            this.dom.find('.btn-primary span.btn-text').text('Sending...')
            this.dom.find('.btn-primary .spinner-border').show()
            this.dom.find('.btn-primary').prop('disabled', true)

            this.email = this.dom.find('#recipient_email').val()
            this.name = this.dom.find('#recipient_name').val()
            this.sendInvite()
        })

        this.dom.on('hidden.bs.modal',  (e) => {
            this.modal.dispose();
            $(e.target).remove()
        })

        this.modal.show();
    }

    sendInvite() {
        let data = {
            meeting_id: this.meeting_id,
            email: this.email,
            name: this.name
        }

        $.ajax({
            url: `api/meeting/${this.meeting_id}/sendinvite`,
            dataType:'JSON',
            data: data,
            type:"POST"
        }).done((response) => {
            if(response.success) {
                displayNotification({
                    title: "Email sent to " + this.email,
                    success: true
                })

                this.dom.find('#recipient_email').val('')
                this.name = this.dom.find('#recipient_name').val('')
            } else {
                displayNotification({
                    title: "Email failed to send",
                    success: false
                })
            }

            this.dom.find('.btn-primary span.btn-text').text('Send')
            this.dom.find('.btn-primary .spinner-border').hide()
            this.dom.find('.btn-primary').prop('disabled', false)
        })
    }
}
