import { Page } from './page.js'
import { Dropdown } from '../components/Dropdown'
import { ReportTable } from '../components/ReportTable'

let template = require('./budgets/budgets.twig');

export class Budgets extends Page{
    constructor() {
        super();
        this.rdtFilters = [];
        this.dom = $(twig({data: template}).render({
            show_program_averages : app.appData.layout.budgets.show_program_averages.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id))
        }));
        this.filterData = {}
        this.reportData = []
        this.filters = [];
        this.from_to_labels = ['from', 'to']
    }

    createTransactionTable(data) {
        $('#transaction-table').DataTable({
                data: data,
                columns: [
                    {data: 'rdt', title: 'RDT' },
                    {data: 'user', title: 'USER' },
                    {data: 'amount', title: 'AMOUNT', render: (data) => {
                        return '<div class="text-md-end">'+ app.format.money(data) +'</div>';
                    }},
                    {data: 'timestamp', title: 'TIMESTAMP', render: (data, type) => {
                        if ( type === 'sort') {
                            var d = new Date(data);
                            return d.getTime();
                        } else {
                            return '<div class="text-md-center">'+ data +'</div>';
                        }
                     }},
                    {data: 'message', title: 'Log Message' },
                ],
                "paging": false,
                "dom": '<t>',
                "destroy": true,
                "order": [[ 3, 'desc' ]],
                "drawCallback": function() {
                    app.convertDatatableToCard("transaction-table");
                }
            });
    }

    getTransactionTrail(project_id) {
        if(project_id) {
            $.ajax({
                url: "api/budgets/"+project_id+"/transactions"
            }).then((data) => {
                this.createTransactionTable(data);
            })
        }
        else {
            this.createTransactionTable([]);
        }
    }

    transferFund() {
        let transfer_from = null
        let transfer_to = null
        let transfer_level = ''
        this.resetMessage();
        for (let filter_label of ['territory', 'district', 'region']) {
            if(this.rdtFilters.includes(filter_label)) {
                transfer_level = filter_label;
                transfer_from = $('#transfer_from_'+filter_label).val();
                transfer_to = $('#transfer_to_'+filter_label).val();
                if(transfer_from) {
                    break;
                }
            }
        }

        let input = {
            project_id: $('#project_id').val(),
            from: transfer_from,
            to: transfer_to,
            amount: parseFloat($("#amount").val().replace(/[^0-9.-]+/g,'')),
            transfer_level: transfer_level
        }
        if(this.validateData(input)) {
            this.checkfund().then(data => {
                if(data[input.from] && parseFloat(data[input.from]) >= parseFloat(input.amount)) {
                    $.ajax({
                        url: "api/budgets/transfer",
                        method: 'post',
                        data: input
                    }).then((data) => {
                        $('#budget_message').html(data.message).show();
                        if(data.success) {
                            $('#budget_message').addClass('alert-success').removeClass('alert-danger');
                        }
                        this.dom.find('#ajax_loader').show();
                        this.dom.find('#reports-table').hide();

                        this.getReportData().then(data => {
                            this.reportData = data
                            this.dom.find('#ajax_loader').hide();
                            this.dom.find('#reports-table').show();
                            this.getReportTable(data);
                        });
                        this.getTransactionTrail(input.project_id);

                    });
                    this.dom.find('#form_transfer_budget').reset();
                } else {
                    this.dom.find('#budget_message').addClass('alert-danger').html('Source RDT does not have enough budget to complete this transfer').show();
                }

                setTimeout(() => {
                    this.resetMessage()
                }, 2000)
            })
        }
    }

    resetMessage() {
        this.dom.find('#budget_message').removeClass('alert-danger').removeClass('alert-success').html('').hide();
    }

    validateData(data) {
        let ret = true;
        $('.is-invalid').removeClass('is-invalid');

        $.each(data, (index, value) => {
            if(data.transfer_level == 'region') {
                return;
            }

            if(!value) {
                ret = false;
                if(this.from_to_labels.includes(index)) {
                    $('#transfer_'+index+'_'+data.transfer_level).addClass('is-invalid')
                } else {
                    let elem = $('#'+index);
                    elem.addClass('is-invalid');
                }
            }
        })

        if(data.from == data.to) {
            this.dom.find('#budget_message').addClass('alert-danger').html('Please select different transfer from and transfer to RDT').show();
            ret = false;
        }
        return ret;
    }

    checkfund() {
        let defer = $.Deferred();
        let formattedData = [];

        this.getReportData().then(data => {
            this.reportData = data
            this.getReportTable(data);
            data.data.forEach(obj => {
                formattedData[obj.rdt] = obj.budget_remaining_efb
            })
            defer.resolve( formattedData );
        });
        return defer.promise( );
    }

    getReportData() {
        let filterData = {
            project_id: this.dom.find('#project_id').val(),
            viewname: this.dom.find('#level').val(),
            report_type: 'budget_management'
        }

        this.rdtFilters.forEach(filter => {
            let rdt = this.dom.find('#transfer_from_'+filter).val();
            let data = this.filters.find(item => item.name == filter).data;
            let item;
            if(!Array.isArray(data)) {
                item = data[$('#project_id').val()].find(item => item.rdt == rdt);
            } else {
                item = data.find(item => item.rdt == rdt);
            }

            if(typeof item != 'undefined') {
                filterData[filter] = item[filter];
            }
        })

        return $.ajax({
            url: "api/reports/data",
            type: "post",
            data: filterData
        });
    }

    getReportTable(data) {
        this.reportsTable = new ReportTable({
            title: 'Budget Management',
            ordering : true
        });
        this.reportsTable.initializeTable(data);
        $('#reports-table').html(this.reportsTable.dom);
        $('.download-csv').attr('title', 'Click to download');
        app.convertDatatableToCard("reports-table");
    }

    displayFilters(data) {
        $.each(data, (i, filter) => {
            if(filter) {
                filter.real_name = filter.name
                if(filter.name == 'project_id') {
                    this.drawProjectFilter(filter)
                } else if(filter.name != 'project_transfer') {
                    if(!this.rdtFilters.includes(filter.name)){
                        this.rdtFilters.push(filter.name)
                        this.from_to_labels.forEach(label => {
                            this.drawRdtFilters(label, filter)
                        })
                    }
                }
            }
        })

        $('#filters-container > select').addClass("input-group-text")
    }

    drawRdtFilters(label, filter, haveCurrentValue) {
        filter.real_name = "transfer_"+label+"_"+filter.name
        let containerId = label+"_"+filter.name+"-dd-container";

        let currentValue = null;
        if(haveCurrentValue) {
            currentValue = $('#'+filter.real_name).val();
        }

        $('#'+containerId).html('');
        this.dom.find('#'+containerId).removeClass('d-none');
        if(!Array.isArray(filter.data)) {
            filter.data[$('#project_id').val()].forEach(d => d.label = d[filter.name]+' - '+ d[filter.name+'_name'])
        } else {
            filter.data.forEach(d => d.label = d[filter.name]+' - '+ d[filter.name+'_name'])
        }

        //donot show list if parent filter do not have value
        let parent_filter = this.getParentFilter(filter)
        let parent_filter_value = this.dom.find('#transfer_'+label+"_"+parent_filter.name).val();
        let option_data = !this.rdtFilters.includes(parent_filter.name) || parent_filter_value
            ? (Array.isArray(filter.data) ? filter.data : filter.data[$('#project_id').val()])
            : []

        new Dropdown(option_data)
            .label(filter.label)
            .all(false)
            .required(filter.required)
            .multiple(filter.multiple)
            .draw($('#'+containerId), 'rdt', 'label', filter.real_name)

        if(currentValue || filter.name == 'region') {
            $('#'+filter.real_name).find(`option[value="${currentValue}"]:not(:disabled)`).first().prop('selected', 'selected')
        }

        $('#'+containerId+' select').on('change', (event) => {
            this.dom.find('#amount').removeClass('d-none');
            this.dom.find('.transfer_fund_btn').removeClass('d-none');
            let selected_value = $(event.target).val()

            //update from and to correspondent filters on change
            this.updateCorrespondingFilter(label, filter, selected_value)

            if(filter.dependent) {
                let selected_rdt;
                [...Object.entries(filter.data)].filter(f => {
                    if(typeof f[1].rdt == 'undefined' && typeof f[1].filter !== 'undefined') {
                        let inner = f[1].filter(g => {
                            return g.rdt == selected_value
                        })?.[0];

                        if(inner?.rdt == selected_value) {
                            selected_rdt = inner;
                        }
                    } else {
                        if(f[1]?.rdt == selected_value) {
                            selected_rdt = f[1];
                        }
                    }
                });

                let childFilter = this.getDependentFilter(selected_rdt[filter.name], filter)
                this.drawRdtFilters(label, childFilter)
            }

            let isFrom = $('#'+containerId).closest('#from-dd-container').length;
            if(isFrom) {
                let titlecasedName = filter.name.charAt(0).toLocaleUpperCase() + filter.name.substr(1);

                let level = this.dom.find('#level');
                level.find('option').each((index, item) => {
                    if($(item).text() == titlecasedName){
                        level.val($(item).val()).trigger('change');
                    }
                })
            }

        })
    }

    drawProjectFilter(filter) {
        let containerId = this.dom.find('#project-dd-container');
        containerId.html('');

        new Dropdown(filter.data)
        .label(filter.label)
        .all(false)
        .required(true)
        .multiple(false)
        .draw(containerId, 'project_id', 'project_name', filter.real_name)

        this.dom.find('#project-dd-container select').on('change', (event) => {

            let project_id = event.currentTarget.value;
            this.getTransactionTrail(project_id);

            this.dom.find('#ajax_loader').show();
            this.dom.find('#reports-table').hide();
            this.getReportData().then(data => {
                this.reportData = data
                this.dom.find('#ajax_loader').hide();
                this.dom.find('#reports-table').show();
                this.getReportTable(data);
            })

            if(filter.dependent) {
                let childFilter = this.getDependentFilter($(event.target).val(), filter);
                this.displayFilters([childFilter]);
            }
        })

        //select first project from list
        this.dom.find('#project_id').prop("selectedIndex", 2).trigger('change');
    }

    getDependentFilter(selected_values, parent_filter) {
        let filter = this.getFilterConfig(parent_filter.dependent)
        if(!Array.isArray(filter.data)) {
            filter.data = filter.data[$('#project_id').val()].filter(d => (typeof d[parent_filter.name] == 'undefined' || d[parent_filter.name] == selected_values));
        } else {
            filter.data = filter.data.filter(d => (typeof d[parent_filter.name] == 'undefined' || d[parent_filter.name] == selected_values));
        }

        return filter;
    }

    getParentFilter(filter) {
        return this.filters.find(field => field.dependent == filter.name)
    }

    updateCorrespondingFilter(label, prevfilter, value) {
        let filter = this.getFilterConfig(prevfilter.name)
        if(!Array.isArray(filter.data)) {
            filter.data = filter.data[$('#project_id').val()].filter(d => d[filter.name] != value);
        } else {
            filter.data = filter.data.filter(d => d[filter.name] != value);
        }

        let correspondentLabel = this.from_to_labels.filter(l => l != label)
        this.drawRdtFilters(correspondentLabel, filter, true);
    }

    getFilterConfig(selectedKey) {
		let filtered = this.filters.find(field => field.name == selectedKey)
        //always return deep copyt
        return $.extend(true, [],filtered);
    }

    showLevels() {
        this.dom.find('#levels_dd_container').hide();

        if(this.levels.length > 1) {
            this.dom.find('#levels_dd_container').show();
            new Dropdown(this.levels)
                .label('')
                .all(false)
                .draw(this.dom.find('#levels_dd_container'), 'name', 'label', 'level')

            this.dom.find('#levels_dd_container').on('change', () => {
                this.dom.find('#ajax_loader').show();
                this.dom.find('#reports-table').hide();
                this.getReportData().then(data => {
                    this.reportData = data
                    this.dom.find('#ajax_loader').hide();
                    this.dom.find('#reports-table').show();
                    this.getReportTable(data);
                })
            })
        }
    }

    createTransferProjectDD(label, data) {
        let containerId = this.dom.find('#project-transfer-'+label+'-container');
        containerId.html('');

        new Dropdown(data)
        .label('Project')
        .all(false)
        .required(true)
        .draw(containerId, 'project_id', 'project_name', 'project_transfer_'+label+'_project_id')

        this.dom.find('#project_transfer_'+label+'_project_id').on('change', (event) => {
            let value = $(event.target).val()
            let alternateLabel = label == 'to' ? 'from' : 'to'
            let alternateValue = this.dom.find('#project_transfer_'+alternateLabel+'_project_id').val()
            let data = this.projects.filter(d => d.project_id != value)
            this.createTransferProjectDD(alternateLabel, data)
            this.dom.find('#project_transfer_'+alternateLabel+'_project_id').val(alternateValue)

            $.ajax({
                url: "api/budgets/"+value+"/remaining"
            }).then(data => {
                this.remaining_budget = data.remaining_budget
                this.dom.find('#project-transfer-'+label+'-remaining').html("Remaining: " + app.format.money(this.remaining_budget));
            })

            this.dom.find('#reset_project_transfer').on('click', () => {
                this.dom.find('#form_project_transfer_budget').trigger('reset')
                this.dom.find('#project-transfer-from-remaining').html('')
                this.dom.find('#project-transfer-to-remaining').html('')
            })
        })
    }


    validateProjectFund(input) {
        let valid = true;
        let msg;

        if(input.from_project_id == input.to_project_id)
        {
            valid = false
            msg = 'Fund cannot be transferred between same project'
        }
        if(input.amount < 0) {
            valid = false
            msg = 'Amount value must be a number greater than 0'
        }

        if(input.amount > this.remaining_budget)
        {
            valid = false
            msg = 'Maximum amount available for this transfer: '+this.remaining_budget

        }
        if(!valid)
        {
            this.dom.find('#project_budget_message').addClass('alert-danger').html(msg)

            setTimeout(() => {
                this.dom.find('#project_budget_message').removeClass('alert-danger').html('')
            }, 2000)
        }

        return valid
    }

    transferFundFromProject() {
        let input = {
            'from_project_id': this.dom.find('#project_transfer_from_project_id').val(),
            'to_project_id': this.dom.find('#project_transfer_to_project_id').val(),
            'amount': this.dom.find('#project_amount').val()
        }
        if(this.validateProjectFund(input)) {
            input.from_project_name = this.projects.filter(project => project.project_id == input.from_project_id)[0].project_name
            input.to_project_name = this.projects.filter(project => project.project_id == input.to_project_id)[0].project_name
            $.ajax({
                url: "api/budgets/transfer/project",
                method: 'post',
                data: input
            }).then((data) => {
                $('#project_budget_message').html(data.message);
                if(data.success) {
                    $('#project_budget_message').addClass('alert-success').removeClass('.alert-danger');
                }

                setTimeout(() => {
                    this.dom.find('#project_budget_message').removeClass('alert-danger').removeClass('alert-success').html('')
                }, 2000)
            })
        }
    }


    ready(){
        if(typeof this.reportsTable == 'undefined') {
            $.ajax({
                url: "api/budgets/init",
                type: "get"
            }).then(data => {
                this.levels = data.levels
                this.showLevels();

                this.filters = data.filters.sort( (filter1, filter2) => { return filter1.display_order - filter2.display_order })
                this.displayFilters(this.filters);
                this.dom.find('.label').removeClass('d-none')

                let project_transfer = this.filters.filter(filter => { return filter.name == 'project_transfer' })
                if(project_transfer.length > 0) {
                    this.dom.find('#project_transfer_container').show();
                    this.projects = project_transfer[0].data;
                    ['from', 'to'].forEach(label => {
                        this.createTransferProjectDD(label, this.projects)
                    })
                    this.dom.find('#form_project_transfer_budget').on('submit', (event) => {
                        event.preventDefault();
                        this.transferFundFromProject(event);
                    })
                }

            })
            this.dom.find('#form_transfer_budget').on('submit', (event) => {
                event.preventDefault();
                this.transferFund();
            })
        }
    }
}
