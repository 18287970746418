export class Card
{
    constructor()
    {
        this.filled_out = false;
        this.default_values = [];
        this.requiredFields = {};
    }

    isFilledOut() {
        let filled_out = true;

        this.dom.find('input[type!=checkbox][type!=radio].required, select.required, textarea.required').each((i, element) => {
            let pattern = $(element).attr('pattern');
            let regex =  null;
            if(pattern) {
                regex =  new RegExp(pattern);
            }

            $(element).removeClass('is-invalid');
            if(element.value == '' || (regex && element.value && !$(element).cleanVal().toString().match(regex)) ||
                ($(element).data('select2') && $(element).data('min') && $(element).select2('data').length < $(element).data('min'))
            )
            {
                $(element).addClass('is-invalid');
                filled_out = false;
            }
        });

        if(this.dom.find('input[type=checkbox].required:not(:checked)').length) {
            filled_out = false;
        }

        this.dom.find('input[type=radio].required').each((idx, el) => {
            if(this.dom.find('input[name="' + $(el).attr('name') + '"]:checked').length) {
                $('input[name="' + $(el).attr('name') + '"]').removeClass('is-invalid');
            }
            else {
                filled_out = false;
            }
        });

        return filled_out;
    }

    cardFilledOutTriggers() {
        let filled_out = this.isFilledOut();

        if(filled_out != this.filled_out
            || this.dom.find('.required:not([disabled]').length === 0 )
        {
            this.filled_out = filled_out;
            if(filled_out) {
                this.dom.trigger('CardFilledOut');
            } else {
                this.dom.trigger('CardNotFilledOut');
            }
        }
    }

    clear()
    {
        this.dom.find('input:not([type=radio]):not([type=checkbox]), select, textarea').val('');
        this.dom.find('input[type=radio]').prop('checked', false);
        this.dom.find('input[type=checkbox]').prop('checked', false);
    }

    clearAndHide()
    {
        this.dom.fadeOut(100).promise().then(() => {
            this.clear();
            this.cardFilledOutTriggers();
        })
    }

    show()
    {
        this.applyRequiredFields()
        this.dom.fadeIn(100).promise().then(() => {
            this.cardFilledOutTriggers();
        });
        this.setDefaultValues();
    }

    canShowNextCard()
    {
        return this.dom.is(':visible') && this.filled_out;
    }

    setRequiredFields(fields)
    {
        this.requiredFields = fields;
    }

    applyRequiredFields()
    {
        this.filled_out = false;
        this.dom.find('.is-invalid').removeClass('is-invalid');
        this.dom.find('.required').removeClass('required');
        for(let table in this.requiredFields) {
            for(let field in this.requiredFields[table]) {
                let required = this.requiredFields[table][field];
                if(required) {
                    this.dom.find('[name="'+field+'"]').addClass('required')
                }
            }
        }
    }

    setDefaultValues()
    {
        for(let field in this.default_values){
            this.dom.find('#'+ field).val(this.default_values[field]);
        }
    }

    getStates(country_alpha2) {
        if(country_alpha2 === "US")
        {
            return app.appData.states;
        }

        let states;
        $.ajax({
            url : 'api/states/' + country_alpha2,
            async: false,
            success: (data) => { states = data }
        });
        return states;
    }

    drawCountryStateDD(country_dd, state_dd) {
        country_dd.empty().append($("<option></option>"))

        for(let i in app.appData.countries)
        {
            let country = app.appData.countries[i];
            country_dd.append("<option value='" + country.alpha2 + "'>" + country.name + "</option>");
        }
        country_dd.val('US');

        this.drawStateDD(country_dd, state_dd)
        country_dd.off().on('change', () => {
            this.drawStateDD(country_dd, state_dd)
        });
    }

    drawStateDD(country_dd, state_dd) {
        let country = country_dd.val();

        let states = this.getStates(country);
        state_dd.empty().append($("<option></option>"))

        for(let i in states)
        {
            let state = states[i];
            state_dd.append("<option value='" + state.alpha2 + "'>" + state.name + "</option>");
        }
    }

    isHub() {
        if($('#project_dd').val() == '' || typeof app.appData.projects[$('#project_dd').val()].program_types == 'undefined') {
            return false;
        }

        let program_type = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()];
        if(typeof program_type !== 'undefined') {
            return program_type.configuration.sb.meeting_request.is_hub;
        }
        return false;
    }

    isSpoke() {
        if($('#project_dd').val() == '' || typeof app.appData.projects[$('#project_dd').val()].program_types == 'undefined') {
            return false;
        }

        let program_type = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()];
        if(typeof program_type !== 'undefined') {
            return program_type.configuration.sb.meeting_request.is_spoke;
        }
        return false;
    }

}
