import { Page } from './page.js'

let template = require('./error.twig');

export class Error extends Page{

	constructor() {
		super();
		this.dom = $(template);
	}

	ready() {
	}
}
