import { ModalAddNote } from "./ModalAddNote";

let template = require('./modal.view_upload_log.twig');

export class ModalViewUploadLog
{
    constructor() {
        this.exception_id;
        this.meeting_id;
        this.dom = $(twig({ data: template }).render());

        this.modal = new bootstrap.Modal(this.dom);
        this.noteModal = new ModalAddNote();

        this.dom.on("click", "#addNewExNote", () => {
            this.modal.hide();
            this.noteModal.modal.show();
        });

        this.dom.on("click", ".remove-sup-file", (event) => {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            
            let file_id = $(event.target).data().file_id;

            if (!confirm('Are you sure you want to delete attachment?')) {
                return;
            }

            $.ajax({
                url: 'api/exceptions/' + this.meeting_id + '/exception/' + file_id + '/delete',
                method: 'delete'
            }).then(() => {
                this.dom.find("#exception_files").html("");
                this.populateExceptFiles();
            })
            

        });

        this.dom.find('form').on('submit', event => this.uploadSupAttachment(event))

        this.noteModal.dom.on('hide.bs.modal', () => {
            if(this.noteModal.response?.message){
                this.populateExceptLog();
                this.showErrorMessage(this.noteModal.response.message);
            }
            this.modal.show();
            this.noteModal.reset();
        });
    }

    uploadSupAttachment(event){
        event.preventDefault();

        let fd = new FormData(this.dom.find('form')[0]);
        let files = this.dom.find('[type=file]')[0].files;
        fd.append('uploaded_file', files[0]);

        $.ajax({
            url: 'api/exceptions/' + this.meeting_id + '/exception/' + this.exception_id + '/addAttachment',
            method: 'post',
            data: fd,
            contentType: false,
            processData: false
        }).catch(data => {
            alert(data.responseJSON.message);
        }).then(() => {
            this.dom.find("#exception_files").html("");
            this.populateExceptFiles();
        })
    }

    
    populateExceptLog(){

        $.ajax({
            url: "api/exceptions/log",
            type: "get",
            data: { notes: this.exception_id }
        }).then((data) => {
            this.dom.find("#exception_log").html("");
            $.each(data.log, (key, value) => {
                this.dom.find("#exception_log").append('<div class="log_list mb-1 pb-1">' + value.formatted + '</div>');
            });

        });

    }

    populateExceptFiles(){
        
        $.ajax({
            url: "api/exceptions/files",
            type: "get",
            data: { 
                notes: this.exception_id,
                meeting_id: this.meeting_id
            }
        }).then((data) => {
            $.each(data.files, (key, value) => {
                let file_link = '<a target="_blank" href="api/programs/' + this.meeting_id + '/resources/' + value.file_id + '">' + value.file_name + '</a>';
                let file_str = value.update_time + "  " + file_link + " Uploaded By " + value.uploaded_by + ' (' + value.user_type + ')';
                let delete_icn = ' <button class="btn btn-outline-primary btn-sm remove-sup-file" data-file_id='+value.file_id+'>Remove</button>';
                
                if(value.can_delete == true){
                    file_str = file_str + delete_icn;
                }

                this.dom.find("#exception_files").append(file_str + '<br><br>');
            });

        });

    }

    showErrorMessage(message){
        this.dom.find('.log-error').html(message).addClass('alert-success')
            .show().fadeTo(6000, 500, () => {
                this.dom.find('.log-error').html('').removeClass('alert-success');
            });
    }

    showModal(data){
        this.exception_id = data.id;
        this.meeting_id = data.meetingId;
        this.status_id  = data.statusId;
        this.dom.find("#exception_log").html("");
        this.dom.find("#exception_files").html("");
        this.dom.find('.modal-header')
            .html('<h4>Exception Log - <strong>' + data.category + '</strong> - ' + data.id + '</h4>');
        this.populateExceptLog();
        this.populateExceptFiles();
        this.modal.show();
        this.noteModal.setData(data);
    }
}