var compliance_template = require("./compliance.twig");
var compliance_confirmation_modal = require("./compliance_confirmation_modal.twig");
var compliance_record_observation_modal = require("./compliance_record_observation_modal.twig");

export class ComplianceMonitor {
  monitor(compliance_info) {
    if (app.appData.compliance.view) {
      compliance_info = { ...compliance_info, ...app.appData.compliance };
      this.dom = twig({ data: compliance_template }).render(compliance_info);
    }
  }

  handleMarkReview(meeting_id, post_data) {
    return new Promise((resolve, reject) => {
      let modal_dom = $(twig({ data: compliance_confirmation_modal }).render());

      modal_dom.find(".btn-ok").on("click", () => {
        $.ajax({
          url: "api/meeting/" + meeting_id + "/compliance",
          type: "POST",
          data: post_data,
          dataType: "json",
        }).then((data) => {
          let message = {
            message:
              "We have encountered an unexpected challenge documenting the program monitoring. " +
              "Please try again, and contact " +
              app.appData.supportInfo.int_company_name +
              " if this message persists.",
            success: false,
            title: "Compliance Monitoring Notice",
          };

          if (data.success) {
            message.message = "Program added for monitoring succesfully";
            message.success = true;
            resolve(data.data);
          }

          reject(false);

          displayNotification(message);

          modal.hide();
        });
      });

      let modal = new bootstrap.Modal(modal_dom);
      modal.show();
    });
  }

  enableFormSubmit(container) {
    let ret;
    if (container.find("#recordOb").find(".is-invalid").length == 0) {
      container.find(".btn-record").prop("disabled", false);
      ret = true;
    } else {
      container.find(".btn-record").prop("disabled", true);
      ret = false;
    }
    return ret;
  }

  handleRecordObservation(data) {
    return new Promise((resolve, reject) => {
      let compliance = data.compliance.speakers[0];

      let compliance_info = { ...compliance, ...app.appData.compliance };
      let modal_dom = $(
        twig({ data: compliance_record_observation_modal }).render(
          compliance_info
        )
      );
      let modal = new bootstrap.Modal(modal_dom);

      modal_dom.find("#observeTypes").select2({ dropdownParent: modal_dom });
      modal_dom.find("#rem_at_date").datepicker();
      modal_dom.find("#observeTypes").on("change", (event) => {
        $(event.target).addClass("is-invalid").removeClass("is-valid");
        if ($(event.target).val()) {
          $(event.target).removeClass("is-invalid").addClass("is-valid");
        }
        this.enableFormSubmit(modal_dom);

        modal_dom.find("#specify_other").addClass("d-none");
        if ($(event.target).val().includes("Other")) {
          modal_dom.find("#specify_other").removeClass("d-none");
        }
      });

      modal_dom.find("#remed").on("change", (event) => {
        $(event.target).addClass("is-invalid").removeClass("is-valid");
        if ($(event.target).val() != "") {
          $(event.target).removeClass("is-invalid").addClass("is-valid");
        }
        this.enableFormSubmit(modal_dom);
      });

      modal_dom.find(".btn-record").on("click", () => {
        if (this.enableFormSubmit(modal_dom, data)) {
          let post_data = modal_dom.find("#recordOb").serializeArray();
          post_data.push({ name: "speaker_id", value: data.speaker_id });
          let url = "api/meeting/" + data.meeting_id + "/compliance/observe";
          if (
            compliance &&
            compliance.observation_id &&
            compliance.observation_id != 0
          ) {
            url = "api/meeting/" + data.meeting_id + "/compliance/remediation";
          }
          $.ajax({
            url: url,
            type: "POST",
            data: post_data,
            dataType: "json",
          }).then((data) => {
            let message = {
              message:
                "We have encountered an unexpected challenge documenting the program monitoring. " +
                "Please try again, and contact " +
                app.appData.supportInfo.int_company_name +
                " if this message persists.",
              success: false,
              title: "Compliance Monitoring Notice",
            };

            if (data.success) {
              message.message = "Program added for monitoring succesfully";
              message.success = true;
              resolve(data.data);
            }

            reject(false);

            displayNotification(message);

            modal.hide();
          });
        } else {
          displayNotification({
            messae: "Fill all required field",
            success: false,
          });
        }
      });

      modal.show();
    });
  }
}
