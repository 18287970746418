import {Card} from "./Card";

var notes_card_template = require('./notes_card.twig');

export class NotesCard extends Card
{
    constructor() {
        super();

        this.dom = $(twig({data: notes_card_template}).render());
    }

    show() {
        super.show();

        let proj_prog_type_conf = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()].configuration;

        $('#notes_instructions').empty().hide();
        $('#notes_instructions').text(proj_prog_type_conf.sb.meeting_request.notes_instructions).show();
    }
}