var target_search_template = require('./ap_target_search_modal.twig');

import { Dropdown } from '../../components/Dropdown'

export class ApTargetSearch
{
    renderFilterDDs() {
        let specialtyDD = new Dropdown(app.format.getSortedSpecialties())
        specialtyDD
           .label('Specialty')
           .draw(this.dom.find("#target_search_specialty-dd-container"), 'code', 'short_name', 'specialty')
        this.dom.find('#target_search_specialty-dd-container > select').addClass("input-group-text")

        let degreeDD = new Dropdown(app.format.getSortedDegrees())
        degreeDD
           .label('Degree')
           .draw(this.dom.find("#target_search_degree-dd-container"), 'degree_abbrv', 'degree_abbrv', 'degree')
        this.dom.find('#target_search_degree-dd-container > select').addClass("input-group-text")

        let statesDD = new Dropdown(app.appData.states)
        statesDD
            .label('State')
            .draw(this.dom.find("#target_search_state_dd-container"), 'alpha2', 'name', 'state')
        this.dom.find('#target_search_state_dd-container > select').addClass("input-group-text")

    }

    reloadDatatable() {
        this.datatable.ajax.reload();
    }

    setFilterData() {
        this.filterData = {};
        this.dom.find("#form_filter_targets input, #form_filter_targets select").each((index ,elem) => {
            this.filterData[$(elem).attr('name').replace('target_search_', '')] = $(elem).val();
        });
        this.filterData['attendee_code'] = app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id].configuration.manage_attendance.only_search_ace_targets ? 'ace' : '';
        this.filterData['product_ids'] = this.meetingData.product_ids;
    }

    reset() {
        let topicSources = this.dom.find("[name='sources']").val()
        this.dom.find('#form_filter_targets')[0].reset()
        this.dom.find("[name='sources']").val(topicSources)
        this.dom.find('.add_attendee_via_form_button').hide()
        this.dom.find('.attendee-val-message').hide()
    }

    constructor(meetingData) {
        this.meetingData = meetingData
        let show_add_form = true

        if(typeof app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id].configuration.manage_attendance.show_add_form !== 'undefined') {
            show_add_form = app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id].configuration.manage_attendance.show_add_form
        }

        this.dom = $(twig({ data: target_search_template }).render({
            show_add_form: show_add_form,
            topic_sources: this.meetingData.topic_sources
        }))

        this.datatable = this.dom.find("#datatable_targets").DataTable({
            "processing": true,
            "serverSide": true,
            "ordering": false,
            "ajax": {
                "url": "api/attendance/searchtargets",
                "data": (d) => {
                    d.data = this.filterData;
                    d.meeting_id = $("#meeting_id").val();
                }
            },
            "columns": [
                {data: 'firstname'},
                {data: 'lastname'},
                {data: 'degree'},
                {data: 'specialty'},
                {data: 'address'},
                {data: 'city'},
                {data: 'state'},
                {
                    data: 'target_id',
                    render: (data, type, row) => {
                        if(row.can_add)
                        {
                            return '<button type="button" class="btn btn-sm btn-primary add-ap-target"  data-target-id="' + row.target_id + '"> Add</button>';
                        }
                        else
                        {
                            return '';
                        }
                    }
                }
            ],
            "pagingType": "full_numbers",
            "width" : "100%",
            "dom": '<"row"<"col-6"l><"col-6"<"row">>><t>pri',
            "deferLoading": 1,
            "pageLength": 25,
            "scrollCollapse": true,
            "language": {
                "loadingRecords": 'Please wait while we fetch your results.',
                "emptyTable":     "No Results",
                "processing": 'Please wait while we fetch your results.'
            },
            "drawCallback": function() {
                app.convertDatatableToCard("datatable_targets");
            }
        });

        this.renderFilterDDs();
        this.dom.find('#form_filter_targets').on('submit', (e) => {
            e.preventDefault()
            this.dom.find('.attendee-val-message').hide()
            this.setFilterData()
            this.datatable.clear();
            this.datatable.ajax.reload(() => {
                this.dom.find('.add_attendee_via_form_button').show();
            });
        });

        this.dom.find("#datatable_targets tbody").on('click', '.add-ap-target', (e) => {
            let button = $(e.target);
            let targetData = {...this.datatable.row($(e.target).parents('tr')).data(), ...button.data()}
            $.ajax({
                url     : 'api/attendance/addaptarget',
                method  : 'GET',
                data    : {
                    attendee_id: this.dom.find("#attendee_id").val(),
                    target_id: targetData.targetId
                }
            }).then(() => {

                $('.attendee-list-message > span').html("Associated Prescriber Association added")
                $('.attendee-list-message').addClass('alert-success').removeClass('alert-danger').show().fadeOut(5000)
                app.pages.attendance.dom.trigger('participantAdded')
                this.modal.hide();
            });
        });

        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false, show: false});
    }
}
