import {Card} from "./Card";
import {GoogleAddressAutocomplete} from "../../helpers/GoogleAddressAutocomplete";
import { Format } from "../../helpers/Format"

var template = require('./fedex_card.twig');

export class FedexCard extends Card {
    constructor() {
        super();
        this.dom = $(twig({data: template}).render());
        this.format = new Format();

        this.drawCountryStateDD(this.dom.find('.country_dd'), this.dom.find('.state_dd'));

        this.dom.on('change', 'select.required', () => {
            this.cardFilledOutTriggers();
        })
        this.dom.on('keyup', 'input.required', () => {
            this.cardFilledOutTriggers();
        })

        this.ship_supplies_to_dd = this.dom.find('#ship_supplies_to');
        this.ship_supplies_to_dd.on('change', () => {
            let ship_to = this.ship_supplies_to_dd.val();
            if (ship_to === 'venue')
            {
                this.dom.find('[name=fedex_name]').val('');
                this.dom.find('[name=fedex_company]').val($('[name=venue_name]').val());
                this.dom.find('[name=fedex_address1]').val($('[name=venue_address1]').val());
                this.dom.find('[name=fedex_address2]').val($('[name=venue_address2]').val());
                this.dom.find('[name=fedex_city]').val($('[name=venue_city]').val());

                this.dom.find('[name=fedex_country]').val($('[name=venue_country]').val());
                this.dom.find('[name=fedex_country]').trigger('change');

                this.dom.find('[name=fedex_state]').val($('[name=venue_state]').val());
                this.dom.find('[name=fedex_zip]').val($('[name=venue_zip]').val());
                this.dom.find('[name=fedex_phone]').val($('[name=venue_phone]').val());
            }
            else if (ship_to === 'self')
            {
                this.dom.find('[name=fedex_name]').val(app.appData.current_user.firstname + " " + app.appData.current_user.lastname);
                this.dom.find('[name=fedex_company]').val('Residence');
                this.dom.find('[name=fedex_address1]').val(app.appData.current_user.address1);
                this.dom.find('[name=fedex_address2]').val(app.appData.current_user.address2);
                this.dom.find('[name=fedex_city]').val(app.appData.current_user.city);

                this.dom.find('[name=fedex_country]').val(app.appData.current_user.country);
                this.dom.find('[name=fedex_country]').trigger('change');

                this.dom.find('[name=fedex_state]').val(app.appData.current_user.state);

                this.dom.find('[name=fedex_zip]').val(app.appData.current_user.zip);
                this.dom.find('[name=fedex_phone]').val(app.appData.current_user.work_phone);
            }
            else
            {
                this.dom.find('[name=fedex_name]').val('');
                this.dom.find('[name=fedex_name]').val('');
                this.dom.find('[name=fedex_address1]').val('');
                this.dom.find('[name=fedex_address2]').val('');
                this.dom.find('[name=fedex_city]').val('');
                this.dom.find('[name=fedex_country]').val('');
                this.dom.find('[name=fedex_state]').val('');
                this.dom.find('[name=fedex_zip]').val('');
                this.dom.find('[name=fedex_phone]').val('');
            }

            this.cardFilledOutTriggers();
        });

        $(this.dom).find('.postalcode').mask('99999-9999')
        $(this.dom).find('.phonenumber').mask('(999) 999-9999')
        $(this.dom).find('.phonenumber').attr('pattern', '^([0-9]){10}$')

        this.dom.on('keyup', '.vs_address1, .vs_city', () => {
            let countryVal = $(event.target).closest('.venue_slot').find('.vs_country').val();
            this.format.setPatternTitleForAddressElements($(event.target),countryVal);
        });

        this.dom.on('change','[name="fedex_country"]', () => {
            this.dom.find('.vs_address1, .vs_city').trigger('keyup').trigger('blur');
        });

        this.dom.on('blur', '.pattern-valid', () => {
            let pattern = $(event.target).attr('pattern');
            if(typeof pattern == 'string' && $(event.target).val().match('^'+pattern+'$') == null) {
                $(event.target).addClass('is-invalid required').closest('div').find('span').text($(event.target).attr('title'))
            } else {
                $(event.target).removeClass('is-invalid required').closest('div').find('span').text('')
            }
        })
    }

    getData() {
        let fedex_data = {};
        this.dom.find('input, select').each((i, e) => { fedex_data[$(e).attr('name')] = $(e).val() })

        return fedex_data;
    }

    show() {
        super.show();

        if(app.appData.layout.meeting_request.numBinders) {
            this.dom.find('#num_binders_container').show();
        } else {
            this.dom.find('#num_binders_container').hide();
        }

        new GoogleAddressAutocomplete('fedex_card');
    }

}