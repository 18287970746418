export class Format {
    column(data, column, row) {
        if (typeof column !== 'object') {
            column = {};
        }

        let formattedData = (data === 0 || data ) ? data : '';
        if ((formattedData || formattedData === 0 || column.format == 'formatTotal') && column.format) {
            let columnClass;
            let status_icon;

            switch (column.format) {
                case 'formatTotal':
                case 'formatSum':
                    formattedData = this.money(formattedData);
                    if (data < 0) {
                        formattedData = '<span class ="text-danger">' + formattedData + '</span>';
                    }
                    break;

                case 'alignCenter':
                case 'formatInt':
                    formattedData = '<div class="text-center">' + formattedData + '</div>';
                    break;

                case 'alignRight':
                    formattedData = '<div class="text-end">' + formattedData + '</div>';
                    break;

                case 'formatDate':
                    if (formattedData != '0000-00-00' && formattedData != '') {
                        formattedData = '<div style="min-width:70px">' + this.datestamp(formattedData) + '</div>';
                    } else {
                        formattedData = '';
                    }
                    break;

                case 'formatPrct':
                    formattedData = parseFloat(data).toFixed(2) + "%"
                    if (data < 0) {
                        formattedData = '<span class ="text-danger">' + formattedData + '</span>';
                    }
                    break;
                case 'formatNovnum':
                    if (row.meeting_id) {
                        formattedData = '<div class="text-primary" onclick="app.navigate(meeting/' + row.meeting_id + ')">' + data + '</a>';
                    }
                    break;
                case 'status_color':
                    columnClass = 'class = "' + this.setstatusColor(row) + '"';
                    status_icon = '<span class="meeting-status-indicator"></span>';
                    formattedData = '<div ' + columnClass + '> ' + status_icon + ' <span>' + data + '</span> ' + '</div>' + this.setCancelReasonName(row);
                    break;
                case 'status_name':
                    formattedData = '<span>' + data + '</span> ' + this.setCancelReasonName(row);
                    break;
                case 'yes_no':
                    formattedData = formattedData == '1' ? 'Yes' : 'No'
                    break;
                case 'default':
                    formattedData = formattedData ? formattedData : '';
                    break;
            }
        }
        return formattedData;
    }

    money(value, params) {
        if (!params) {
            params = {};
        }

        if (!(params && params.fraction_digit)) {
            params.fraction_digit = 2;
        }

        value = parseFloat(this.intVal(value));

        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value);
    }

    intVal(i) {
        return typeof i === 'string' ?
            i.replace(/[$,%]/g, '') * 1 :
            typeof i === 'number' ?
                i : 0;
    }

    datestamp(value) {
        if (value && value != '0000-00-00') {
            value = moment(value).format('MM-DD-YYYY');
        }
        return value;
    }

    setstatusColor(row) {
        let status = (row.status_key == 'confirmed') ? row.status_key : row.status_type;
        status = status == null ? '' : status;
        return app.appData.meetingStatusColorMap[status.toLowerCase()] || app.appData.meetingStatusColorMap['default'];
    }

    setCancelReasonName(row) {
        let result = '';
        if (app.appData.layout.meeting.show_canceled_reason && row.status_type === 'Cancelled' && row.canceled_reason_name != null) {
            let canceled_reason_name = row.canceled_reason_name;
            result = '<span title="' + canceled_reason_name + '" class="text-ellipsis">(' + canceled_reason_name + ')</span>';
        }

        return result;
    }

    getSortedSpecialties() {
        return this.sortByKey(app.appData.specialties, 'short_name', 'sorted_specialties')
    }

    getSortedDegrees() {
        return this.sortByKey(app.appData.degrees, 'degree_desc', 'sorted_degrees')
    }

    sortByKey(data, key, name) {
        if (!app.appData[name]) {
            app.appData[name] = data.sort((x, y) => {
                return x[key] > y[key] ? 1 : -1
            })
        }

        return app.appData[name]
    }

    sortObjectByKey(data, key, name) {
        if (!app.appData[name]) {
            data = Object.values(app.appData.projects);
            this.sortByKey(data, key, name);
        }
        return app.appData[name]
    }

    setPatternTitleForAddressElements(element, countryVal) {
        let letterNumberValidate = $(element).data('validletternumbercount');
        let elementPattern = $(element).data('pattern');
        let letterNumberMatch = $(element).val().match(/(?=.*[A-Za-z])(?=.*[0-9])+/);
        $(element).removeAttr('pattern').removeAttr('title');
        if(countryVal == 'US') {
            $(element).attr('pattern',elementPattern);
            if(letterNumberValidate == 1 && letterNumberMatch == null) {
                $(element).attr('title','Invalid - enter at least one number and one letter');
            } else {
                $(element).attr('title',"Invalid - ~!@$%^&*()_+=`[]{}\\|;:\"<>? special characters not allowed");
            }
        }
    }
}
``
