import { Page } from './page.js'

let template = require('./news/news.twig');

export class News extends Page{
    constructor() {
        super();

        this.dom = $('<div>');
    }
    ready() {
        $.ajax({
            url: "api/news",
            type: "get"
        }).then(data => {
            this.dom.empty().append($(twig({ data: template }).render({news:data.news_items})));

            this.dom.find('.news-item-attestation').first().on('click', function() {
                let newsId = $(this).data('id');
                $.ajax({
                    url: "api/news/attestation",
                    type: "post",
                    data: {
                        'news_id' : newsId
                    }
                })  .then(() => {
                    console.log('attested')

                })
            })

            this.dom.find('#close-news').first().on('click', function() {
                $.ajax({
                    url: "api/news/markread",
                    type: "post"
                })  .then(() => {
                    app.appData.newsCount = 0;
                    app.navigate('home');

                })
            })

            this.dom.find(".download-attachments").first().on('click', function() {
                let attachmentId = $(this).data('attachment-id');
                window.open("api/news/attachment/"+attachmentId);
            })
        })
    }
}
