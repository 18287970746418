import * as Pages from './pages'
import {Format} from "./helpers/Format";

import {Menu} from './components/Menu'
import {Positions} from './components/Positions'
import {Login} from "./pages";
import {Header} from "./components/Header/Header";
import {AuthError} from "./pages/autherror/autherror";

/* **
    * This plug-in for DataTables represents the ultimate option in extensibility
* for sorting date / time strings correctly. It uses
* [Moment.js](http://momentjs.com) to create automatic type detection and
* sorting plug-ins for DataTables based on a given format. This way, DataTables
* will automatically detect your temporal information and sort it correctly.
*
* For usage instructions, please see the DataTables blog
* post that [introduces it](//datatables.net/blog/2014-12-18).
*
* @name Ultimate Date / Time sorting
* @summary Sort date and time in any format using Moment.js
* @author [Allan Jardine](//datatables.net)
* @depends DataTables 1.10+, Moment.js 1.7+
*
* @example
*    $.fn.dataTable.moment( 'HH:mm MMM D, YY' );
*    $.fn.dataTable.moment( 'dddd, MMMM Do, YYYY' );
*
*    $('#example').DataTable();
*/

(function (factory) {
    if (typeof define === "function" && define.amd) {
        define(["jquery", "moment", "datatables.net"], factory);
    } else {
        factory(jQuery, moment);
    }
}(function ($, moment) {

    $.fn.dataTable.moment = function ( format, locale ) {
        var types = $.fn.dataTable.ext.type;

        // Add type detection
        types.detect.unshift( function ( d ) {
            if ( d ) {
                // Strip HTML tags and newline characters if possible
                if ( d.replace ) {
                    d = d.replace(/(<.*?>)|(\r?\n|\r)/g, '');
                }

                // Strip out surrounding white space
                d = $.trim( d );
            }

            // Null and empty values are acceptable
            if ( d === '' || d === null ) {
                return 'moment-'+format;
            }

            return moment( d, format, locale, true ).isValid() ?
                'moment-'+format :
                null;
        } );

        // Add sorting method - use an integer for the sorting
        types.order[ 'moment-'+format+'-pre' ] = function ( d ) {
            if ( d ) {
                // Strip HTML tags and newline characters if possible
                if ( d.replace ) {
                    d = d.replace(/(<.*?>)|(\r?\n|\r)/g, '');
                }

                // Strip out surrounding white space
                d = $.trim( d );
            }

            return !moment(d, format, locale, true).isValid() ?
                Infinity :
                parseInt( moment( d, format, locale, true ).format( 'x' ), 10 );
        };
    };

}));

window.displayNotification = function (message) {
    let isIE = !(window.ActiveXObject) && "ActiveXObject" in window

    if(isIE)
    {
        return alert(message.message)
    }

    $.notify(
        {
            message: message.title ? message.title : '' + '<br/>' + message.message
        },
        {
            placement: { from: 'bottom' },
            type: message.success ? 'success' : 'danger',
            element: $('.modal:visible').length ? $('.modal:visible') : 'body'
        })
}

class App
{
    firstStageInitItems = [
        'companyKey',
        'curPosition',
        'curUserType',
        'current_user',
        'iconMappings',
        'labels',
        'layout',
        'logo',
        'menus',
        'permissions',
        'supportInfo',

        // Home
        'projects',
        'brands',
        'meetingStatusColorMap',
        'states',
        'programStatuses',
        'defaultProjectProgramTypeConfig',
        'programTypes',
        'topics',
        'eventTypes',
        'specialties',
        'degrees',
        'newsCount',

        // Meeting and Resources
        'franchise'
    ];

    deferredPages = [
        'budgets',
        'feedback',
        'attendance',
        'meetingrequest',
        'requestcert',
        'speakernominate',
        'prospect'
    ];

    setupPageObjects() {
        this.pages = {
            'analytics': new Pages.MicroStrategy(),
            'changepassword': new Pages.ChangePassword(),
            'error': new Pages.Error(),
            'home': new Pages.Home(),
            'hubevents': new Pages.Hubevents(),
            'meeting': new Pages.MeetingDetail(),
            'news': new Pages.News(),
            'programs': new Pages.Programs(),
            'reports': new Pages.Reports(),
            'resources': new Pages.Resources(),
            'speakers': new Pages.Speakers(),
            'speaker': new Pages.SpeakerDetail(),
            'marketingcontent': new Pages.MarketingContent(),
            'add_marketingcontent': new Pages.AddMarketingContent(),
            'marketing_content_detail': new Pages.MarketingContentDetail(),
            'meetingexpenses': new Pages.MeetingExpenses(),
            'exceptions': new Pages.Exceptions(),
            'prepostwork': new Pages.PrePostWork(),
            'programguidance': new Pages.ProgramGuidance(),
            'notifications': new Pages.Notifications()
        }
    }

    setupDeferredPages() {
        // Don't forget to keep the deferredPages variable above in sync with this list
        this.pages = {
            ...this.pages,
            ...{
                'budgets': new Pages.Budgets(),
                'feedback': new Pages.Feedback(),
                'attendance': new Pages.ManageAttendance(),
                'meetingrequest' : new Pages.MeetingRequest(),
                'requestcert' : new Pages.RequestCert(),
                'speakernominate': new Pages.SpeakerNominate(),
                'prospect': new Pages.Prospect()
            }
        }
    }

    navigate(destination, params, historical) {
        if(destination != 'error' && !this.checkPerms(destination)) {
            this.navigate('error');
            return;
        }
        let page = this.pages[destination];

        let uri = destination;
        if(params && params.length > 0) {
            uri += '/' + params.join('/');
        }

        if(this.uri != uri
            && (typeof historical == 'undefined' || !historical)) {
            history.pushState(null, null, uri);
        }

        this.uri = uri;

        if(typeof this.currentPage !== "undefined" && typeof this.currentPage.dom !== "undefined") {
            this.currentPage.dom.detach();
        }

        $('.content-body').hide()
        this.contentBody.append(page.dom);

        if(typeof this.currentPage !== "undefined" && typeof this.currentPage.teardown !== "undefined") {
            this.currentPage.teardown();
        }
        this.currentPage = page;

        let ready = page.ready();
        if(typeof ready !== 'undefined' && !!ready.then)
        {
            ready.then(() => {
                $('.postalcode').mask('99999-9999')
                $('.phonenumber').mask('(999) 999-9999')
                $('.phonenumber').attr('pattern', '^([0-9]){10}$')
                $('.content-body').show()
            });
        }
        else
        {
            $('.postalcode').mask('99999-9999')
            $('.phonenumber').mask('(999) 999-9999')
            $('.phonenumber').attr('pattern', '^([0-9]){10}$');
            $('.content-body').show();
        }

        window.scrollTo(0, 0);
        $.ajax('api/keepAlive');
    }

    async initFacebook(){
        $('#facebook_header_menuitem').show()
        $('.fb-page')
            .attr('data-href', 'https://www.facebook.com/' + this.appData.facebookUsername );

        // Only load facebook sdk if opco has a facebook username
        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    initializeSocialMedia() {
        if(this.appData.facebookUsername)
        {
            this.initFacebook()
        }

        $("#facebook_icon").click(() => {
            $("#facebook_container").toggle();
            if($('#facebook_container').is(':visible'))
            {
                $('#facebook_container').focus()
            }
            $('#contact_container').hide();
        });
    }

    setPositionDropDown() {

        let position = new Positions();
        $('#social_medias_separator').after(position.dom);
    }

    setMenus() {
        let menu = new Menu();
        $('#sidebar-left').html(menu.dom);
    }

    showMeetingRequest() {
        if(!this.checkPerms('meetingrequest')) {
            $('#request_program_button').remove();
        } else {
            $('#request_program_button').off().on('click', () => {
                this.navigate('meetingrequest')
            }).show();
        }
    }

    showTimLink() {
        if (this.appData.layout.user_profile && this.appData.layout.user_profile.show_tim_link) {
            $('#tim_link').show();
        }
    }

    checkPerms(key) {
        return this.appData.permissions?.[key] ?? false;
    }

    convertDatatableToCard(tableId){
        if (this.isMobile()) {
            let labels = [];
            let th_class = [];

            $("#" + tableId).find(".colHeader").remove();

            $("#" + tableId).addClass("dt_card");
            $("#" + tableId + " thead").find("th").each(function () {
                labels.push($(this).text());
                th_class.push($(this).attr("class"));
            });

            if(labels.length === 0){
                $("#" + tableId + " thead").find("td").each(function () {
                    labels.push($(this).text());
                    th_class.push($(this).attr("class"));
                });
            }

            $("#" + tableId + " tbody").find("tr").each(function () {
                $(this).find("td").each(function (column) {
                    $(this).addClass( th_class[column]);
                    $("<div class='colHeader'>" + labels[column] + "</div>").prependTo(
                        $(this)
                    );
                });
            });
        }
    }

    isMobile(){
        return $(window).width() < 768 ? true : false;
    }

    showNotification() {
        if (this.checkPerms('notifications')){
            $('.notification_bell').show();
            $('#notifications_btn').off().on('click', () => {
                $('.nav-link').removeClass('nav-link-active');
                $('#notification_link').removeClass("notification-link").addClass('notification-link-active');
                this.navigate('notifications')
            }).show();
        }
    }

    getPageNameFromPath(pageName) {
        let path = pageName.split('/');

        if(path.includes('htdocs')) {
            pageName = path.slice(path.indexOf('htdocs')+1).join('/');
        } else {
            pageName = path.join('/');
        }

        pageName = pageName.replace(/^[/]+/, '');
        return pageName;
    }

    setPageTitle() {
        $('title').html(app.appData.companyProgram);
    }

    ready() {
        this.url = false;
        this.contentBody = $('#content_section');
        this.format = new Format();

        window.addEventListener('popstate', () => {
            params = window.location.pathname.substr(($('base').attr('href')).length).split( '/' ).filter((e) => e !== "");
            currentPage = this.getPageNameFromPath(window.location.pathname).split('/')[0];

            this.navigate(currentPage, params, true);
        });

        let path = window.location.pathname.split('/');

        if(path.includes('htdocs')) {
            this.baseIndex = path.indexOf('htdocs') + 1;
            this.base = path.slice(0, this.baseIndex).join('/') + '/';
        } else {
            this.base = '/'; // window.location.origin + '/';
        }

        let base = $('<base>');
        base.attr('href', this.base);
        $('head').append(base);
        let pageName = this.getPageNameFromPath(window.location.pathname);

        let currentPage = pageName;
        let params = {};
        window.location.search.substr(1).split('&').forEach(item => {
            let split = item.split('=');
            params[split[0]] = split[1];
        })

        $.ajaxSetup({
            beforeSend: (xhr, options) => {
                let url = options.url;
                url = url.startsWith('/') ? url.slice(1) : url;
                if(!options.external) {
                    options.url = $('base').attr('href') + url;
                }
            },
            complete: (xhr) => {
                if(!['index', 'forgotpassword'].includes(currentPage) && xhr.getResponseHeader('session') == '0')
                {
                    alert('Your session is expired or invalid, please log in again.');
                    window.location = $('base').attr('href') + 'logout';
                }
            }
        })

        this.currentPage = currentPage;
        if(!currentPage) {
            currentPage = 'home'
        } else if (currentPage == 'index') {
            let loginPage = new Login(params);
            this.contentBody.append(loginPage.dom);
            return;
        } else if (currentPage == 'forgotpassword') {
            let loginPage = new Login(params);
            loginPage.showPasswordResetForm(params.token);
            this.contentBody.append(loginPage.dom);
            return;
        } else if (currentPage == 'autherror') {
            let authError = new AuthError(params);
            this.contentBody.append(authError.dom);
            return;
        }

        let initItems = this.firstStageInitItems;
        params = window.location.pathname.substr(($('base').attr('href')).length).split( '/' ).filter((e) => e !== "");
        currentPage = this.getPageNameFromPath(pageName).split('/')[0];

        $.ajax({
            url: "api/init",
            data: {
                items: initItems
            }
        }).then(data => {
            this.appData = data;
            this.setPageTitle();

            if(this.appData.microstrategy_js != '') {
                $.ajax({
                    type: "GET",
                    url: this.appData.microstrategy_js,
                    dataType: "script",
                    external: true
                });
            }

            $.ajax({
                url: "api/init",
                data: {
                    items: initItems,
                    inverse: true
                }
            }).then(data => {
                this.appData = {...this.appData, ...data};

                this.setupDeferredPages();
                this.showMeetingRequest();

                if(this.deferredPages.includes(currentPage)) {
                    this.navigate(currentPage, params.slice(1));
                }
            })
        }).then(() => {
            this.header = new Header();
            $('section.body').prepend(this.header.dom);
            $(".inner-wrapper").css("padding-top", this.header.dom.outerHeight());
            $('#sidebar-left').show();
            this.showNotification();
            this.initializeSocialMedia();
            this.setPositionDropDown();
            this.showTimLink();
            this.setMenus();
            this.setupPageObjects();

            if(this.appData.analytics) {
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=false;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer',this.appData.analytics);
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                        'userId' : this.appData.current_user.user_id //this number must be replaced with an actual User ID
                })
            }

            if(this.appData.newsCount > 0 && this.appData.layout.news.show) {
                this.navigate('news');
            } else if(!this.deferredPages.includes(currentPage)) {
                this.navigate(currentPage, params.slice(1));
            }

            $('#opco_rp_footer').html(this.appData.supportInfo.footer);
        })


        $('#pos-select').change(() => {
            $.ajax({
                url: "api/changePos",
                type: "GET",
                data: {'newPos': this.value},
            }).then(data => this.appData = data)
                .then(() => {
                    var rdt_name = document.getElementById("pos-rdt")
                    rdt_name.innerText = this.appData.curPosition.rdt
                    document.location.reload(true)
                })
        })

        $('#sidebar-left, .content-body').on('click', () => $('.header-sub-container').hide())

        $(".overlay").on('click', ()=> {
            $(".toggle-sidebar-left").trigger("click");
        })

        $(document).on('click', '#back_button', () => window.history.back())

        $(document).on('reset', 'form', (event) => {
            event.preventDefault();
            $(event.target).find('input, select').val('');
        })

        $(window).resize(function() {
            $(".inner-wrapper").css("padding-top", $(".header").outerHeight());
        });

        if(app.isMobile()) {
            $(window).scroll(function () {
                if ($(document).scrollTop() > ($(window).height() - 200)) {
                    $("#scroll_top_button").show();
                } else {
                    $("#scroll_top_button").hide();
                }
            })
        }

        $(document).on('click', '#scroll_top_button', () => $(document).scrollTop(0))
    }
}

$(() => {
    window.app = (new App());
    app.ready();
});
