
var download_modal_template = require('./download_modal.twig');

export class DownloadModal
{
    constructor(resourceId) {

        this.resourceId = resourceId
        this.dom = $(twig({ data: download_modal_template }).render({resourceId: this.resourceId}))

        $.ajax(
        {
            url: "api/resources/"+this.resourceId,
            dataType: "json",
            method: "get"
        }).then((data) => {
            let resource = data.data;
            this.dom.find('#resource_note').html(resource.note);
        });

        this.dom.find('#download_resource_btn').off().on(
            'click', function (event) {
                console.log('in this click')
                event.preventDefault();
                let resourceId = $(this).data('resource-id');
                window.open("api/resources/"+resourceId+"/download");
            }
        )
    }
}