import template from './manualException.twig'
import {Dropdown} from "../../../components/Dropdown";
import {CustomModal} from "../../../components/customModal";

export class ManualException
{
    constructor(meeting_id, exception_table) {
        this.meeting_id = meeting_id
        this.exception_table = exception_table

        this.dom = $(twig({data: template}).render({
            'label':app.appData.labels.exception
        }));

        $.ajax({
            url: "api/exceptions/types",
            type: "get",
            dataType: 'JSON',
            data: {
                is_hide_other: app.appData.companyKey == 'incyte'
            },
        }).then((data) => {
            let exDD = new Dropdown(data)
            exDD
                .label("Select "+app.appData.labels.exception+" Category")
                .all(false)
                .required(true)
                .draw(this.dom.find('#exception_type_container').first(), 'key', 'name', 'exception_type')
        })

        this.dom.find('#cancel').on('click', () => {
            this.close();
        })

        this.dom.find('#save').on('click', () => {
            if (this.dom.find('#noteText').val().trim() == '' ||
                this.dom.find('#exception_type').val() == null ||
                this.dom.find('#exception_type').val() == '') {
                alert("Reason and Type are required.");
            } else {
                $.ajax({
                    url: "api/exceptions/manual",
                    type: "post",
                    data: {
                        type: this.dom.find('#exception_type').val(),
                        reason: this.dom.find('#noteText').val().trim(),
                        meeting_id: this.meeting_id
                    },
                    beforeSend: () => {
                        $("#noteText, #save").prop('disabled',true);
                    }
                }).done(() => {
                    $("#noteText, #save").prop('disabled',false);
                    this.close()
                })
            }
        })

        this.dom.on('shown.bs.modal',  () => {
            this.dom.find('#noteText').focus()
        })

        this.modal = new CustomModal({
            title: 'Create a New '+app.appData.labels.exception,
            content: this.dom,
            buttons: []
        })

        this.modal.open();
    }

    close() {
        this.dom.find("#noteText").val("");
        this.exception_table.populateExceptionsSection();
        this.modal.close();
    }
}
