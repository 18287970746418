import {Card} from "./Card";

var template = require('./AttestationCard.twig');

export class AttestationCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({data: template}).render(app.appData.layout.meeting_request));

        this.dom.find('input[type=checkbox]').on('click', () => {
            this.cardFilledOutTriggers();
        })
    }


    isFilledOut() {
        let filled_out = true;
        this.dom.find('#attestation').removeClass("is-invalid");

        if(!this.dom.find('#attestation').is(':checked')) {
            filled_out = false;
            this.dom.find('#attestation').addClass("is-invalid");
        }

        return filled_out;
    }

}
