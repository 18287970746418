import {Card} from "./Card";

let hub_card_template = require("./hub_card.twig");

export class HubCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({ data: hub_card_template }).render({
           spokeLabel : app.appData.labels.spoke
        }));

        this.dom.on('change keydown keyup keypress', 'input.required', () => {
            this.cardFilledOutTriggers();
        });
    }

    show() {
        let program_type;
        if($('#program_type_dd').val()) {
            program_type = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()];
        }

        if(program_type != undefined
        && program_type.configuration.sb.meeting_request.is_hub
        && program_type.configuration.sb.meeting_request.limit_spokes) {
            super.show();
        } else {
            this.clearAndHide();
        }
    }

    setRequiredFields(fields) {
        super.setRequiredFields(fields);

        if($('#program_type_dd').val()) {
            let program_type = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()];

            this.requiredFields.mf_meeting.max_spoke_programs = (program_type.configuration.sb.meeting_request.is_hub && program_type.configuration.sb.meeting_request.limit_spokes);
        }
    }

    canShowNextCard()
    {
        let limit_spokes = true;
        if($('#program_type_dd').val()) {
            let program_type = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()];

            if(program_type != undefined
            && program_type.configuration.sb.meeting_request.limit_spokes != undefined) {
                limit_spokes = program_type.configuration.sb.meeting_request.limit_spokes;
            }
        }

        let can_show =
            (
                this.dom.is(':visible')
                && this.dom.find("#max_spoke_programs").val() !== ''
            )
            ||
            (
                !(this.isHub()
                && limit_spokes)
                && app.pages['meetingrequest'].formCards.venue_card.dom.is(':visible')
                && app.pages['meetingrequest'].formCards.venue_card.filled_out
            );

        return can_show;
    }

}