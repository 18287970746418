export class Dropdown
{
    constructor(data)
    {
        this.data = data
        this.multipleOption = false;
        this.containerLabel = false;
        this.outerLabel = false;
        this.classes = [
            'form-select'
        ];
        this.isRequired = false;
        this.modalContainer = false;
    }

    label(label)
    {
        this.labelOption = label
        return this
    }

    setContainerLabel(labelText) {
        this.containerLabel = labelText;
        return this
    }

    all(all)
    {
        this.allOption = all
        return this
    }

    addClass(className)
    {
        let classNames = className.split(' ');
        this.classes.push(...classNames)

        return this;
    }

    required(required)
    {
        this.isRequired = required
        return this
    }

    multiple(multiple)
    {
        this.multipleOption = multiple;
        return this
    }

    isModalContainer(modalContainer)
    {
        this.modalContainer = modalContainer;
        return this
    }

    draw(container, keyName, valueName, ddName, dataNames, nameAsArray = false)
    {
        this.container = container;
        let name = '',
            id = '',
            multiple = '',
            required;


      if (ddName) {
        const nameSuffix = nameAsArray ? '[]' : '';
        name = `name = "${ddName}${nameSuffix}"`;
        id = `id = "${ddName}"`;
      }


      if(this.multipleOption) {
            multiple = ' multiple ';
        }

        if(this.isRequired)
        {
            required = ' required ';
        }

        let classes = this.classes.join(' ');
        let select = $('<select class = "' + classes + '" '+ name + id + multiple + required + '>')

        if(this.labelOption && !this.multipleOption)
        {
            select.append('<option selected disabled value="">' + this.labelOption + '</option>')
            select.append('<option value="" disabled>')
        }

        if(this.containerLabel) {
            this.container.prepend(`<label for="${ddName}">${this.containerLabel}</label>`);
        }

        if(this.allOption)
        {
            select.append('<option value="">- ALL -</option>')
        }

        this.data.forEach((element) => {
            let option = $('<option>').val(element[keyName]).text(element[valueName]);

            if(dataNames) {
                dataNames.forEach((dn) => {
                    option.data(dn, element[dn]);
                });
            }
            if(element[keyName] == '') {
                let skip = keyName == 'rdt'; // TODO: Figure out a cleaner solution

                if(!skip) {
                    option.prop('disabled', true).text('• ' + element[valueName]);
                }
            }

            select.append(option);
        })

        this.container.append(select)
        if(this.multipleOption) {
            this.refreshMultiSelect();
        }
    }

    refreshMultiSelect() {
        this.container.find('select').first()
            .select2({
                linkInfo: {
                    checkAll: {text: 'All', title: 'Check All foos'},
                    uncheckAll: {text: 'None', title: 'Un-check All foos'},
                    noneSelectedText: 'None'
                },
                dropdownParent: (this.modalContainer ? this.container.parents('.modal .modal-content') : $(document.body)),
                classes:"form-select input-group-text w-100",
                placeholder: this.labelOption,
                selectedText: (numChecked, numTotal, checkedItems) => {
                    let text = this.labelOption+': ';
                    if(numChecked < 2) {
                        text += checkedItems[0].nextElementSibling.innerText;
                    }
                    else {
                        text += numChecked + ' of ' + numTotal + ' selected';
                    }

                    return text;
                }
            })
    }
}
