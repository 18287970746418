import {Card} from "./Card";

var template = require('./faculty_prep_call_card.twig');
var questions_template = require('./faculty_prep_call_questions.twig');

export class FacultyPrepCallCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({data: template}).render());

        $(document).on('click', '.prep_call_assist', (event) => {
            let speakerId = $(event.target).data('speaker-id');
            if($(event.target).val() == 1) {
                this.dom.find('#prep_call_date_selections_'+speakerId).show();
            } else {
                this.dom.find('#prep_call_date_selections_'+speakerId).find('.prep_call_date').val('');
                this.dom.find('#prep_call_date_selections_'+speakerId).find('.prep_call_time_hr').val('');
                this.dom.find('#prep_call_date_selections_'+speakerId).hide();
            }
        });

        $(document).on('change', '.prep_call_date, .prep_call_time_hr', (event) => {
            let row = $(event.target).closest('.row');
            let meetingMoment = moment($("#meeting_date").val() + $("#time_dd").val(),"MM/DD/YYYYH:mm:a");
            if(moment($(row).find('.prep_call_date').val() + $(row).find('.prep_call_time_hr').val(),"MM/DD/YYYYH:mm:a").isSameOrAfter(
                meetingMoment))
            {
                $(row).find('.prep_call_date').val('');
                $(row).find('.prep_call_time_hr').val('');
                $(event.target).closest('.prep_call_selections').find('.prep-date-selection-error').show().fadeOut(5000);
            }
        });
    }

    getSpeakerOptions(speakerData) {
        let speakers = [];
        let prepTimes = [];
        $(speakerData).each( function(index, speaker) {
            if($.inArray(speaker.speaker_id,speakers.keys()) < 0 && $('#prep_call_yes_' + speaker.speaker_id).is(':checked'))
            {
                let questionsContainer = $('#prep_call_date_selections_' + speaker.speaker_id);
                let prefPrepMoment = moment(
                    $(questionsContainer).find('[name="pref_prep_call_date"]').val() +
                    $(questionsContainer).find('[name="pref_prep_call_time_hr_ampm"]').val(),"MM/DD/YYYYH:mm:a");
                let alt1PrepMoment = moment(
                    $(questionsContainer).find('[name="alt1_prep_call_date"]').val() +
                    $(questionsContainer).find('[name="alt1_prep_call_time_hr_ampm"]').val(),"MM/DD/YYYYH:mm:a");
                let alt2PrepMoment = moment(
                    $(questionsContainer).find('[name="alt2_prep_call_date"]').val() +
                    $(questionsContainer).find('[name="alt2_prep_call_time_hr_ampm"]').val(),"MM/DD/YYYYH:mm:a");
                speakers.push(speaker.speaker_id)
                prepTimes.push({
                    'speaker_id' : speaker.speaker_id,
                    'pref_prep_call_time' : prefPrepMoment.format("YYYY-MM-DD HH:mm:ss"),
                    'alt1_prep_call_time' : alt1PrepMoment.format("YYYY-MM-DD HH:mm:ss"),
                    'alt2_prep_call_time' : alt2PrepMoment.format("YYYY-MM-DD HH:mm:ss")
                })
            }

        });
        return prepTimes;
    }

    renderFacultyPrepQuestions(speakerData) {
        this.dom.find("#speaker_prep_call_container").html('');
        let speakers = [];
        $(speakerData).each( function(index, speaker) {
            if($.inArray(speaker.speaker_id,speakers) < 0)
            {
                speakers.push(speaker.speaker_id);

                $("#speaker_prep_call_container").append($(twig({data: questions_template}).render({
                    speaker: speaker,
                    timezone: $("#timezone_dd :selected").text()
                })));
            }

        });

        let options = {
            startDate :  moment().toDate(),
            endDate : $("#meeting_date").val()
        }

        this.dom.find('.prep_call_date').val('').datepicker('destroy').datepicker(options);
        this.drawPrepTimeDropDowns();
    }

    drawPrepTimeDropDowns() {
        this.dom.find(".prep_call_time_hr").each( function(index, time_dd) {
            let element = $(time_dd);
            element.empty().append("<option value=''></option>")

            for(let i = 6; i < 12; i++)
            {
                element.append("<option value='" + i + ":00:am'>" + i + ":00am</option>")
                element.append("<option value='" + i + ":15:am'>" + i + ":15am</option>")
                element.append("<option value='" + i + ":30:am'>" + i + ":30am</option>")
                element.append("<option value='" + i + ":45:am'>" + i + ":45am</option>")
            }

            element.append("<option value='12:00:pm'>12pm</option>")
            element.append("<option value='12:15:pm'>12:15pm</option>")
            element.append("<option value='12:30:pm'>12:30pm</option>")
            element.append("<option value='12:45:pm'>12:45pm</option>")
            for(let i = 1; i < 12; i++)
            {
                element.append("<option value='" + i + ":00:pm'>" + i + ":00pm</option>")
                element.append("<option value='" + i + ":15:pm'>" + i + ":15pm</option>")
                element.append("<option value='" + i + ":30:pm'>" + i + ":30pm</option>")
                element.append("<option value='" + i + ":45:pm'>" + i + ":45pm</option>")
            } 
        });
    }

    show() {
        super.show();
        $("#speaker_prep_call_container .prep_call_assist").addClass('required is-invalid').on('change', ()=>{
            this.radioChecked();
        });

        this.dom.trigger('UpdatePrep');
    }

    radioChecked(){
        super.cardFilledOutTriggers();
    }
}
