import {Card} from "./Card";
import { GoogleAddressAutocomplete } from "../../helpers/GoogleAddressAutocomplete"

let template = require('./manual_speaker_card.twig');

export class ManualSpeakerCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({data: template}).render());

        this.topic_dd = $(this.dom.find('.topic_dd')[0]);

        this.dom.find('input').on('keyup', () => this.cardFilledOutTriggers());
        this.dom.on('change', 'input, select, textarea', () => this.cardFilledOutTriggers());
    }

    show() {
        super.show();
        new GoogleAddressAutocomplete('manual_speaker_card');

        this.drawTopicDD();
        this.dom.find('input, select').attr('required', 'required').addClass('required is-invalid');
        this.dom.trigger('HideSpeakerSideCard');
    }

    getData() {
        let speaker_data = {};
        if(this.dom.is(':visible')) {
            this.dom.find('input, select').each((i, e) => { speaker_data[$(e).data('text')] = $(e).val() })
        }

        return speaker_data;
    }

    drawTopicDD() {
        this.topic_dd.empty();

        $.ajax({
            url     : 'api/meetingrequest/topics',
            method  : 'get',
            async   : false,
            data    : {
                meeting_date    : $('#meeting_date').val(),
                project_id      : $('#project_dd').val(),
                program_type_id : $('#program_type_dd').val(),
                hub_meeting_id  : $('#hub_meeting_id').val(),
                meeting_type_id : $('#meeting_type_dd').val()
            },
            success : (data) => {
                this.topics = data;

                if(Object.keys(this.topics).length !== 1) {
                    this.topic_dd.append("<option></option>");
                }

                for(let i in this.topics)
                {
                    let topic = this.topics[i];
                    this.topic_dd.append("<option value='" + topic.topic_id + "'>" + topic.topic_name + "</option>");
                }
            }
        })
    }
}
