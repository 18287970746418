import {Card} from "./Card";

var template = require('./oce_cart_questions_card.twig');

export class OceCartQuestionsCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({data: template}).render());
        this.dom.find('input').on('change', () => this.cardFilledOutTriggers());

        this.dom.on("click", "#oce_question_yes", () => {
            this.dom.find("#form-details").show();
            this.dom.find('#oce_firstname').prop('required', true).addClass('required is-invalid');
            this.dom.find('#oce_lastname').prop('required', true).addClass('required is-invalid');
        })

        this.dom.on("click", "#oce_question_no", () => {
            this.dom.find("#form-details").hide();
            this.dom.find('#oce_firstname').prop('required', false).removeClass('required is-invalid');
            this.dom.find('#oce_lastname').prop('required', false).removeClass('required is-invalid');
        })

        this.dom.on("click", "#cart_question_yes", () => {
            this.dom.find("#cart_form_detail").show();
            this.dom.find('#cart_address').prop('required', true).addClass('required is-invalid');
        })

        this.dom.on("click", "#cart_question_no", () => {
            this.dom.find("#cart_form_detail").hide();
            this.dom.find('#cart_address').prop('required', false).removeClass('required is-invalid');
            displayNotification({
                message: 'Unfortunately, your program cannot be submitted if it is not within 100 miles of an activated site.',
                type: false
            });
            this.dom.trigger('ClearVenueCard');
        })

        this.dom.on('change keydown keyup keypress', 'input.required, textarea.required', () => {
            this.cardFilledOutTriggers();
        });
    }
    show() {
        super.show();

        let project_id = $('#project_dd').val();
        let program_type_id = $('#program_type_dd').val();
        let program_type = app.appData.projects[project_id].program_types[program_type_id];
        let show_partnering_oce_question = program_type.configuration.sb.meeting_request.partnering_oce_question;
        let show_cart_site_question = program_type.configuration.sb.meeting_request.cart_site_question;

        if(show_partnering_oce_question) {
            this.dom.find("#oce_questions").show();
            this.dom.find('[name=oce_question]').attr('required', 'required').addClass('required is-invalid')
        } else {
            this.dom.find("#oce_questions").hide();
        }

        if(show_cart_site_question) {
            this.dom.find("#cart_questions").show();
            this.dom.find('[name=cart_question]').attr('required', 'required').addClass('required is-invalid')
        } else {
            this.dom.find("#cart_questions").hide();
        }
    }
    getdata() {
        let oce_cart_questions_data = [];
        let project_id = $('#project_dd').val();
        let program_type_id = $('#program_type_dd').val();
        if( program_type_id) {
            let program_type = app.appData.projects[project_id].program_types[program_type_id];
            let show_partnering_oce_question = program_type.configuration.sb.meeting_request.partnering_oce_question;
            let show_cart_site_question = program_type.configuration.sb.meeting_request.cart_site_question;
            if (show_partnering_oce_question) {
                if (this.dom.find('input[name=oce_question]:checked').val() == 1) {
                    let firstname = this.dom.find("#oce_firstname").val();
                    let lastname = this.dom.find("#oce_lastname").val();
                    oce_cart_questions_data.push({
                        type: "partnering_oce",
                        note: "Partnering OCE : " + firstname + " " + lastname + " added to meeting."
                    });
                }
            }
            if (show_cart_site_question) {
                if (this.dom.find('input[name=cart_question]:checked').val() == 1) {
                    let address = this.dom.find("#cart_address").val();
                    oce_cart_questions_data.push({
                        type: "cart_site",
                        note: "Offered in a CAR-T site: " + address + " added to meeting."
                    });
                }
            }
        }
        return oce_cart_questions_data;
    }
}
