
let template = require('./positions.twig');

export class Positions {
	constructor() {
		this.dom = '';
			this.dom = $(twig({ data: template }).render({appData: app.appData}));

			$(this.dom).find('.user_positions').on('click', (elem) => {
				let newpos = $(elem.target).data('position-id');
				$.ajax({
					url: "api/changePos?newPos="+newpos,
				}).then(() => {
					window.location.reload(false);
				});
			})

			$(this.dom).find('#pos-userbox-button').first().on('click', () => {
				if((app.appData.current_user.positions).length > 1) {
					$(this.dom).find('#pos-userbox-menu').toggle();
				}
			});
	}
}
