import AddressAutocomplete from 'google-address-autocomplete';

export class GoogleAddressAutocomplete
{
    constructor(containerElement) {
        let element = $('#'+containerElement);
        if(element.is(':visible')) {
            let address = new AddressAutocomplete('#'+containerElement+' .vs_address1', (results, rawResults) => {
                let cityName = results['cityName'] || rawResults['address_components'].find(component => 
                    component.types.includes("administrative_area_level_3") || component.types.includes("sublocality_level_1")
                )?.long_name;
                element.find('[id*=city], [name*=city]').val(cityName);
                element.find('[id*=zip], [name*=zip]').val(results['zipCode']);
                element.find('[id*=country], [name*=country]').val(results['countryAbbr']);
                element.find('[id*=state], [name*=state]').val(results['stateAbbr']);

                element.find('[id*=place_id], [name*=place_id]').val(rawResults['place_id']);
                element.find('[id*=lat], [name*=lat]').val(results['coordinates']['lat']);
                element.find('[id*=lng], [name*=lng]').val(results['coordinates']['lng']);

                let address1 = (results['streetNumber']+' '+results['streetName']).trim();
                if (!address1) {
                    address1 = rawResults['address_components'][0]?.long_name;
                }
                element.find('[id*=address1], [name*=address1]').val(address1);

                element.find('.vs_address1').trigger('change');
            });

            address.initializeAutocomplete();
        }
    }
}
