import { GoogleAddressAutocomplete } from "../../helpers/GoogleAddressAutocomplete"

var venue_search_template = require('./venue_search_modal.twig');

export class VenueSearch
{
    constructor() {
        let venue_search;
        if(app.appData.layout.meeting_request.venue_search) {
            venue_search = app.appData.layout.meeting_request.venue_search
        } else {
            venue_search = { national_account: false }
        }

        this.dom = $(twig({ data: venue_search_template }).render({venue_search: venue_search}))
        this.results_table = this.dom.find('.venue_search_results_container table').DataTable( {
            dom: "t<'row'<'col-md-4 col-sm-12'i><'col-md-8 col-sm-12 float-end'p>>",
            processing: true,
            lengthChange: false,
            info: true,
            sort: false,
            paginationType: "full_numbers",
            ajax: {
                url : 'api/venues',
                data: (d) => {
                    let params = this.getSearchParams();
                    for(let param in params) {
                        d[param] = params[param]
                    }
                }
            },
            language: {
                'loadingRecords': '&nbsp;',
                'processing': 'Loading...'
            },
            deferRender: true,
            sorting: [[ 1, "desc" ]],
            columns: [
                { "mDataProp": "venue" },
                { "mDataProp": "address1" },
                { "mDataProp": "city" },
                { "mDataProp": "state" },
                { "mDataProp": "zip" },
                { "mDataProp": "venue_id", searchable: false },
            ],
            rowCallback: ( nRow, aData ) => {
                let button = $('<button value="'+aData.venue_id+'" class="btn btn-primary btn-sm d-none d-md-block">Select</button>');
                button.on('click', (e) => {
                    this.applyVenue(aData);
                    e.stopPropagation();
                })

                $('td', nRow).eq(5).empty().append(button)

                if(app.isMobile()){
                    $('.venue_search_results_container tbody').on('click', 'tr', function (e) {
                        $(this).find(button).trigger("click");
                        e.stopPropagation();
                    });
                }
            }
        })

        this.dom.find('#national_accounts').on('click', () => {
            this.results_table.ajax.reload()
        })

        this.dom.find('.vs_filter_results').on('keyup', (event) => {
            this.results_table.search($(event.target).val()).draw();
        })

        this.dom.find('.add_manually').on('click', () => {
            this.modal.hide();
            let venue_slot = this.venue_slot.attr('id');
            $('#' + venue_slot + ' .location_clear_button').show();
            $('#' + venue_slot + ' .location_clear_button').click();
            new GoogleAddressAutocomplete(venue_slot);
            this.dom.trigger('VenueAddManually');
        })

        this.drawVenueTypeDD();

        this.showModal(this.dom);
    }

    showModal(dom){
        this.modal = new bootstrap.Modal(dom);
        this.modal.show();
    }

    getSearchParams() {
        let params = {}
        if(this.dom.find('#national_accounts').is(':checked')) {
            params.national_accounts = 1
        }
        if(app.appData.layout.meeting_request.meetingtype_venuetype_filter) {
            params.meeting_type_filter = app.appData.layout.meeting_request.meetingtype_venuetype_filter
            params.meeting_type = $('#meeting_type_dd').val();
        }
        return params;
    }

    applyVenue(data)
    {
        this.venue_slot.find('.location_clear_button').show();
        this.venue_slot.find('.location_clear_button').click();

        for(const [key, value] of Object.entries(data))
        {
            this.venue_slot.find('.vs_' + key).val(value);
        }

        this.dom.trigger('VenueApplied');
        this.modal.hide();
    }

    drawVenueTypeDD() {
        let dd = this.dom.find('.venue_type_dd');
        dd.empty().append($("<option></option>"))

        for(let i in app.appData.venueTypes)
        {
            let venueType = app.appData.venueTypes[i];
            dd.append("<option value='" + venueType.venue_type_id + "'>" + venueType.venue_type_name + "</option>");
        }
    }
}