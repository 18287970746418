import template from './approveException.twig'
import {CustomModal} from "../../../components/customModal";

export class ApproveException
{
    constructor(exception_id, exception_table) {

        this.exception_id = exception_id;
        this.exception_table = exception_table;

        this.dom = $(twig({data: template}).render({
        }));

        this.dom.find('#cancel').on('click', () => {
            this.close();
        })

        this.dom.find('#save').on('click', () => {
            $.ajax({
                url: "api/exceptions/approve",
                type: "post",
                data: {eid: this.exception_id,
                reason: $('#noteText').val().trim()},
                beforeSend: () => {
                    $("#noteText, #save").prop('disabled',true);
                }
                }).done(() => {
                    $("#noteText, #save").prop('disabled',false);
                this.close()
                });
        })

        this.dom.on('shown.bs.modal',  () => {
            this.dom.find('#noteText').focus()
        })

        this.modal = new CustomModal({
            title: 'Approve '+app.appData.labels.exception+' Reason',
            content: this.dom,
            buttons: []
        })

        this.modal.open();
    }

    close() {
        this.dom.find("#noteText").val("");
        this.exception_table.filterTrigger();
        this.modal.close();
    }
}
