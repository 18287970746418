let speaker_nominate_log = require('./speaker_nominate_log_modal.twig');

export class SpeakerNominateLog
{
    constructor(speaker_id, brand_id) {
        this.speaker_id = speaker_id;
        this.brand_id = brand_id;

        this.dom = $(twig({
            data: speaker_nominate_log,
            labels: app.appData.labels
        }).render());

        this.initializeTable();

        if(app.appData.layout.speaker_nominate.display_notes) {
            this.dom.find('.add_note_container').show();
        }

        this.dom.find('.add_note_btn').on('click', (event) => {
            event.preventDefault();
            this.dom.find('#note_container').show();
		});

        this.dom.find('#note_cancel').on('click', (event) => {
            event.preventDefault();
            this.dom.find('#note').html('');
            this.dom.find('#note_container').hide();
		});

        this.dom.find('#note_submit').on('click', (event) => {
            event.preventDefault();
            let data = {
                speaker_id: this.speaker_id,
                brand_id: this.brand_id,
                note: this.dom.find('#note').val()
            };
            $.ajax({
                url: "api/speakernominate/addnote",
                method: "POST",
                data: data
            }).then(response => {
                if(response.success) {
                    displayNotification({
                        title: 'Note added',
                        success: true
                    });
                    this.dom.find('#note_cancel').trigger('click');
                    this.notesDatatable.ajax.reload();
                }
                else {
                    displayNotification({
                        title: 'Note failed',
                        success: false
                    });
                }
            })
		});

        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false, show: false});
        this.modal.show();
    }

    initializeTable() {
        this.notesDatatable = this.dom.find('#datatable_notes').DataTable({
            "displayLength": 10,
            "sort": true,
            "paginate": true,
            "filter": true,
            "pagingType": $(window).width() < 768 ? "simple" : "full_numbers",
            "pageLength": $(window).width() < 768 ? 2 : 10,
            "sorting": [[ 1, "asc" ]],
            "ajax": {
                "url": "api/speakernominate/log",
                "data": (d) => {
                    d.speaker_id = this.speaker_id,
                    d.brand_id = this.brand_id,
                    d.include_notes = app.appData.layout.speaker_nominate.display_notes
                }
            },
            "dom": '<"row"<"col-md-6"l><"col-md-6"<"row"<"col-10"f><"col-2 text-end"<"w-100 float-start"B>>>>><t>ip',
            "columns": [
                {"mDataProp": "note"},
                {"mDataProp": "timestamp"},
                {"data": null, render: (data) => {
                    let name = `${data.user_firstname}  ${data.user_lastname}`
                    if(app.appData.layout.speaker_nominate.display_usertype_name && data.user_type_name) {
                        name += ` (${data.user_type_name})`
                    }

                    return name
                }}
            ],
            "language": {
                "search": "",
                "loadingRecords": "Please wait while we fetch your results.",
                "emptyTable": "No Results",
                "processing": "Please wait while we fetch your results."
            },
            "drawCallback": function() {
                app.convertDatatableToCard("datatable_notes");
            },
            "buttons": [{
                'extend' : 'csvHtml5',
                'className':'btn btn-success export',
                'text': '<i class="fas fa-file-excel"></i>',
                'title': 'Speaker Nomination Log'
            }]
        })
    }
}
