
var template = require('./contact.twig');

export class Contact {
	constructor() {
		app.appData.supportInfo.after_hours_time = moment(app.appData.supportInfo.after_hours_time, 'hh:mm:ss').format('h:mm A');
		this.dom = $(twig({ data: template }).render({
			supportInfo : app.appData.supportInfo
		}));

		$(this.dom).find('#close').on('click', (event) => {
			event.preventDefault();
			$('#contact_container').hide()
		})
	}
}