var template = require('./googlemap.twig');

export class GoogleMap
{
    constructor()
    {
        this.dom = $(template);
    }

    drawMap(dom, address, marker_title){
        if(typeof google == 'undefined')
        {
            setTimeout(() => { this.showGoogleMap(address, marker_title) }, 200)
            return
        }

        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            address: address
        }, (results) => {
            if (results.length === 0) {
                dom.find('.google_map').html('The address could not be found.');
            }
            else {
                let map_options = {
                    center: results[0].geometry.location,
                    zoom: 14,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                };
                let map = new google.maps.Map(dom.find('.google_map')[0], map_options);

                new google.maps.Marker({
                    position: results[0].geometry.location,
                    map: map,
                    title: marker_title
                });
            }
        });
    }

    showGoogleMap (address, marker_title) {
        this.drawMap(this.dom, address, marker_title);
        this.modal = new bootstrap.Modal(this.dom);
        this.modal.show();
    }

    displayGoogleMap (dom, address, marker_title) {
        this.drawMap(dom, address, marker_title);
    }
}
