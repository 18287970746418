import {Card} from "./Card";

let meeting_date_card_template = require('./meeting_date_card.twig');
let leadtime_warning_template = require('./leadtime_warning_modal.twig');

export class MeetingDateCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({data: meeting_date_card_template}).render({
            int_company_name: app.appData.supportInfo.int_company_name
        }));

        this.time_dd = this.dom.find('#time_dd');
        this.timezone_dd = this.dom.find('#timezone_dd');

        this.drawTimeDD(this.time_dd);
        this.drawTimezoneDD();

        this.dom.find('#time_dd, #timezone_dd').off('change').on('change', () => {
            this.dom.trigger('UpdatePrep');
        });

        this.short_lead_time = false;

        if(typeof app.appData.layout.meeting_request.show_arrival_time != 'undefined'
        && app.appData.layout.meeting_request.show_arrival_time)
        {
            this.dom.find('#arrival_time_container').show()
            this.arrival_time_dd = this.dom.find('#arrival_time_dd');
            this.drawTimeDD(this.arrival_time_dd);
            this.time_dd.on('change', () => {
                if(!this.arrival_time_dd.val()) {
                    this.arrival_time_dd.val(this.time_dd.val())
                }
            })
        }

        if(typeof app.appData.layout.meeting_request.show_end_time != 'undefined'
        && app.appData.layout.meeting_request.show_end_time)
        {
            this.dom.find('#end_time_container').show()
            this.end_time_dd = this.dom.find('#end_time_dd');
            this.drawTimeDD(this.end_time_dd);
            this.time_dd.on('change', function() {
                $.ajax({
                    url     : 'api/meetingrequest/endtime',
                    async   : false,
                    method  : 'get',
                    data    : {
                        start_time : $('#time_dd').val(),
                        project_id : $('#project_dd').val(),
                        pt_id      : $('#program_type_dd').val(),
                        mt_id      : $('#meeting_type_dd').val()
                    },
                    success : (data) => {
                        let etb = app.appData.layout.meeting_request.end_time_behavior ?? '';
                        if (data.result.length > 0) {
                            switch(etb) {
                                case 'lock':
                                    $('#end_time_dd').val(data.result);
                                    $('#end_time_dd').attr('disabled','disabled');
                                    break;
                                case 'floor':
                                    $('#end_time_dd').val(data.result);
                                    $("#end_time_dd > option").attr('disabled', false);
                                    $("#end_time_dd > option").each(function () {
                                        if ($(this).val() == data.result) {
                                            return false;
                                        }
                                        $(this).attr('disabled', 'disabled');
                                    });
                                    break;
                                default:
                                    $('#end_time_dd').val(data.result);
                                    break;
                            }
                        }
                    }
                })
            });
        }

        this.dom.on('change', 'input, select', () => this.cardFilledOutTriggers() );
    }

    drawMeetingDates()
    {
        moment.updateLocale('us', {
            holidays: app.appData.holidays.map(a => a.date),
            holidayFormat: 'YYYY-MM-DD'
        });

        let projProgTypeConf;
        if($('#program_type_dd').val()) {
            projProgTypeConf = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()].configuration;
        }

        let allow_short_lead = false;
        let short_lead_warning = '';
        if(projProgTypeConf) {
            allow_short_lead = projProgTypeConf.sb.meeting_request.allow_short_lead_pr;
            short_lead_warning = projProgTypeConf.sb.meeting_request.short_lead_warning ?? '';
        }

        let leadTime =
            (parseInt(app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()].lead_time)
            || -app.appData.projects[$('#project_dd').val()].mtg_date_allow_prior);
        let leadStartTime = (parseInt(projProgTypeConf && (projProgTypeConf.sb.meeting_request.short_lead_block_start_by_days)));

        let leadTimeStartRangeDate;
        let leadTimeStartRangeDateMoment;
        let leadTimeEndRangeDate;
        let leadTimeEndRangeDateMoment;

        if(app.appData.layout.meeting_request.useBusinessDays) {
            leadTimeStartRangeDateMoment = moment().businessAdd(leadStartTime);
            leadTimeStartRangeDate = leadTimeStartRangeDateMoment.toDate();
            leadTimeEndRangeDateMoment = moment().businessAdd(leadTime);
            leadTimeEndRangeDate = leadTimeEndRangeDateMoment.toDate();
        }
        else {
            leadTimeStartRangeDateMoment = moment().add(leadStartTime, 'd');
            leadTimeStartRangeDate = leadTimeStartRangeDateMoment.toDate();
            leadTimeEndRangeDateMoment = moment().add(leadTime, 'd');
            leadTimeEndRangeDate = leadTimeEndRangeDateMoment.toDate();
        }

        let leadTimeRequests = app.appData.layout.meeting_request.leadtime_requests;
        if(allow_short_lead) {
            leadTimeRequests = true;
        }

        let projectStartDate = moment(app.appData.projects[$('#project_dd').val()].mtg_date_start).toDate();
        let startDate = (projectStartDate > leadTimeEndRangeDate) ? projectStartDate : leadTimeEndRangeDate;
        let leadTimeStartDate = (projectStartDate > leadTimeStartRangeDate) ? projectStartDate : leadTimeStartRangeDate;

        let options = {
            startDate : leadTimeRequests ? leadTimeStartDate : startDate,
            endDate : moment(app.appData.projects[$('#project_dd').val()].mtg_date_end).toDate(),
            datesDisabled: app.appData.projects[$('#project_dd').val()].blackout_dates
        }

        this.dom.find('#meeting_date').val('').datepicker('destroy').datepicker(options);
        this.dom.find('#meeting_date2').val('').datepicker('destroy').datepicker(options);
        this.dom.find('#meeting_date3').val('').datepicker('destroy').datepicker(options);
        this.dom.find('#end_date').val('').datepicker('destroy').datepicker(options);

        this.dom.find('#meeting_date, #meeting_date2, #meeting_date3, #end_date').on('show', () => {
            $('.day').removeClass('alert-warning').removeClass('alert');

            $('.day').each((index, day) => {
                /* moment objects created from bootstrap-datepicker's unix timestamps need
                to be treated as utc to get the correct calendar date */
                let calendarDate = moment($(day).data('date')).utc().format('YYYY-MM-DD');
                let currentDate = moment(calendarDate, 'YYYY-MM-DD').local();

                if(currentDate.isBefore(leadTimeEndRangeDateMoment, 'day') || currentDate.isSame(leadTimeEndRangeDateMoment, 'day')) {
                    $(day).addClass('alert').addClass('alert-warning');
                }
            })
        })

        this.dom.find('#meeting_date').off('change').on('change', () => {
            let meetingDate = moment(this.dom.find('#meeting_date').val(), 'MM/DD/YYYY');

            if(meetingDate.isBefore(leadTimeEndRangeDateMoment, 'day')) {
                let leadtime_warning_dom = $(twig({ data: leadtime_warning_template }).render({
                    short_lead_warning: short_lead_warning,
                    int_company_name: app.appData.supportInfo.int_company_name
                }));
                let modal = new bootstrap.Modal(leadtime_warning_dom);
                modal.show();

                this.short_lead_time = true;
            } else {
                this.short_lead_time = false;
            }

            this.dom.trigger('ChangeMeetingDate');
            this.dom.trigger('RefreshTopics');

            if(allow_short_lead) {
                if(this.isFilledOut()) {
                    this.dom.trigger('CardFilledOut');
                }else{
                    this.dom.trigger('CardNotFilledOut');
                }
            }
        })

        this.dom.find('#mtg_date_allow_prior').text(leadTime);
        if(leadTime <= 0) {
            this.dom.find('.instruction-text').hide();
        } else if(this.isShow14DaysLeadTimeMessage()) {
            this.dom.find('.instruction-text').show();
        }
        this.dom.trigger('ChangeMeetingDate');
    }

    drawTimeDD(time_dd) {
        time_dd.empty().append("<option></option>")

        for(let i = 6; i < 12; i++)
        {
            time_dd.append("<option value='" + i + ":00:am'>" + i + ":00am</option>")
            time_dd.append("<option value='" + i + ":15:am'>" + i + ":15am</option>")
            time_dd.append("<option value='" + i + ":30:am'>" + i + ":30am</option>")
            time_dd.append("<option value='" + i + ":45:am'>" + i + ":45am</option>")
        }

        time_dd.append("<option value='12:00:pm'>12pm</option>")
        time_dd.append("<option value='12:15:pm'>12:15pm</option>")
        time_dd.append("<option value='12:30:pm'>12:30pm</option>")
        time_dd.append("<option value='12:45:pm'>12:45pm</option>")
        for(let i = 1; i < 12; i++)
        {
            time_dd.append("<option value='" + i + ":00:pm'>" + i + ":00pm</option>")
            time_dd.append("<option value='" + i + ":15:pm'>" + i + ":15pm</option>")
            time_dd.append("<option value='" + i + ":30:pm'>" + i + ":30pm</option>")
            time_dd.append("<option value='" + i + ":45:pm'>" + i + ":45pm</option>")
        }
    }

    drawTimezoneDD() {
        this.timezone_dd.empty().append("<option></option>")

        for(let i in app.appData.timezones)
        {
            let timezone = app.appData.timezones[i];
            this.timezone_dd.append("<option value='" + timezone.timezone_id + "'>" + timezone.timezone_name + "</option>")
        }
    }

    setDefaultTime(default_time) {
        this.default_values['time_dd'] = '';
        if(default_time){
            this.default_values['time_dd'] = default_time;
        }
    }

    setDefaultTimeZone(default_timezone) {
        this.default_values['timezone_dd'] = '';
        if(default_timezone){
            this.default_values['timezone_dd'] = default_timezone;
        }
    }

    isShow14DaysLeadTimeMessage() {
        $.ajax({
            url: "api/ref-config-by-key",
            type: "get",
            dataType: 'JSON',
            data: {
                key: 'SHOW_14_DAYS_LEAD_TIME_MESSAGE'
            }
        }).then((data) => {
            if (data.success && data.conf_data == 0) {
                this.dom.find('.instruction-text').hide();
                return false
            } else {
                this.dom.find('.instruction-text').show();
                return true;
            }
        })
    }
}
