import { Dropdown } from "./Dropdown";

var template = require('./program_filters.twig')

export class ProgramFilter {
    drawProjectCountWarning() {
        let num_selectable_projects = this.dom.find('#project_search_dd').find('option').length;
        let num_selected_projects = this.dom.find('#project_search_dd').find('option:selected').length;

        if(num_selectable_projects > app.appData.maximum_unfiltered_projects
            && (num_selected_projects > app.appData.maximum_unfiltered_projects || num_selected_projects == 0)
            && !this.dom.find('#program_id').val()
        ) {
            this.tooManyProjects = true;
            this.dom.find('#fy_project_warning').show();
            this.dom.find('#fy_project_count').text(num_selectable_projects);
            this.dom.find('#applyFilters').attr('disabled', 'disabled');
        } else {
            this.tooManyProjects = false;
            this.dom.find('#fy_project_warning').hide();
            this.dom.find('#fy_project_count').text(0);
            this.dom.find('#applyFilters').removeAttr('disabled');
        }
    }

    drawFiscalYearDD() {
        let year = new Date().getFullYear();
        let data = Object.values(app.appData.projects).
            map(x => { return {'year':x.proj_fy}}).
            filter((v, i, a) => a.map(mapObj => mapObj.year).indexOf(v.year) === i).sort((a, b) => b.year - a.year)
		let fiscalYearDD = new Dropdown(data)
		fiscalYearDD
            .all(false)
            .label("Year")
            .draw(this.dom.find('#fiscal_year_container'), 'year', 'year', 'fiscal_year')
            this.dom.find('#fiscal_year_container').find('select').on('change', (elem) => {
                this.drawProjectDD(elem.currentTarget.value);
                this.drawProjectCountWarning();
                this.drawBrandDD();
                this.drawDistrictDD();
            })

            if(this.dom.find("#fiscal_year_container select option[value='"+year+"']").length == 0) {
                year = this.dom.find('#fiscal_year_container select option:eq(2)').val()
            }
            this.dom.find('#fiscal_year_container').find('select').val(year).trigger('change');


    }

    drawTypesDD() {
        let currentValue = (this.dom.find('#projects_container > select').val());
        this.dom.find('#types_container').html('');
        let filter = this.getFilterConfig('types');
        let data = Object.values(app.appData.programTypes)
        if(currentValue.length >= 1) {
            let final_project_ids = {};
            currentValue.forEach(function(projectId){
                let batch_project_ids = data.filter(type => type.project_ids.split(",").includes(projectId));
                if(Object.keys(final_project_ids).length === 0){
                    final_project_ids = batch_project_ids;
                } else {
                    let proj_id_merge = new Set([...batch_project_ids,...final_project_ids]);
                    final_project_ids = [...proj_id_merge];
                }
            });
            data = final_project_ids;
        }

        this.typesDD = new Dropdown(data)
        this.typesDD
            .all(false)
            .multiple(true)
            .label(filter.label)
            .addClass('w-100')
            .draw(this.dom.find('#types_container').first(), 'program_type_id', 'program_name', 'program_type_id')
    }

    drawStatusDD(filter) {
        this.statusDD = new Dropdown(app.appData.programStatuses)
        this.statusDD
            .label(filter.label)
            .all(false)
            .multiple(true)
            .draw(this.dom.find('#status_container').first(), 'status_name', 'status_name', 'client_status_name')
    }

    drawStatesDD(filter) {
        this.stateDD = new Dropdown(app.appData.states)
        this.stateDD
            .label(filter.label)
            .all(true)
            .draw(this.dom.find('#venue_states_container').first(), 'alpha2', 'alpha2', 'venue_state')
    }

    drawProjectDD(currentValue) {
        this.dom.find('#projects_container').html('');
        let filter = this.getFilterConfig('projects');

        let projects = Object.values(app.appData.projects);
        let brand_ids = this.dom.find('#brand_search_dd').val()?.map(item => parseInt(item));

        if(currentValue) {
            projects = Object.values(projects).filter(project => String(project.proj_fy) === currentValue);
        }

        if(brand_ids?.length) {
            projects = projects.filter(project => {
                return brand_ids.includes(project.brand_id)
            });
        }

        projects.sort((a, b) => b.proj_fy - a.proj_fy || a.project_name.localeCompare(b.project_name))

        this.projectDD = new Dropdown(projects)
        this.projectDD
            .label(filter.label)
            .all(false)
            .multiple(true)
            .draw(this.dom.find('#projects_container').first(), 'project_id', 'project_name', 'project_search_dd')

        this.dom.find('#projects_container > select').on('change', () => {
            this.drawBrandDD();
            this.drawTopicDD();
            this.drawDistrictDD();
            this.drawMembersDD();
            this.drawTllDD();
            this.drawTypesDD();
            this.drawProjectCountWarning();
        })

        this.drawProjectCountWarning();
    }

    drawBrandDD() {
        this.dom.find('#brands_container').html('');
        let filter = this.getFilterConfig('brands');
        if(filter) {
            let data = [];
            let fiscal_year = this.dom.find('#fiscal_year').val();
            let project_ids = this.dom.find('#project_search_dd').val()?.map(item => parseInt(item));

            let project_brand_ids = [];
            Object.entries(app.appData.projects).forEach(project => {
                if(project_ids.includes(project[1].project_id)) {
                    project_brand_ids.push(project[1].brand_id);
                }
            });

            Object.entries(app.appData.brands).forEach(brand => {
                if ((fiscal_year == '' || fiscal_year == brand[1].fiscal_year)) {
                    data.push(brand[1]);
                }
            });

            this.brandDD = new Dropdown(data)
            this.brandDD
                .label(filter.label)
                .all(false)
                .multiple(true)
                .draw(this.dom.find('#brands_container').first(), 'brand_id', 'brand_name', 'brand_search_dd')

            this.dom.find('#brand_search_dd option').each((index, item) => {
                if(project_brand_ids.includes(parseInt(item.value))) {
                    $(item).attr('selected', 'selected');
                }
            });

            this.dom.find('#brands_container').removeClass('d-none');

            this.dom.find('#brands_container > select').on('change', () => {
                this.drawProjectCountWarning();
                this.drawProjectDD();
            })
        }
    }

    drawTopicDD() {
        let filter = this.getFilterConfig('topic');
        if(filter) {
            let project_ids = this.dom.find('#project_search_dd').val();
            $.ajax({
                url     : 'api/projects/topics',
                method  : 'get',
                data : { "projects": project_ids }
            }).then((data) => {
                this.buildTopicDD(filter, data);
            })
        }
    }

    buildTopicDD(filter, data) {
        this.dom.find('#topic_container').removeClass('d-none');
        this.dom.find('#topic_container').empty();
        this.topicDD = new Dropdown(data)
        this.topicDD
            .label(filter.label)
            .all(false)
            .multiple(true)
            .draw(this.dom.find('#topic_container'), 'topic_id', 'topic_name', 'topic_id')
    }

    drawDistrictDD() {
        let filter = this.getFilterConfig('district');
        if(filter) {
            this.dom.find('#district_container').removeClass('d-none');
            this.dom.find('#district_container').html('');
            $.ajax({
                url     : 'api/district',
                method  : 'get',
                data : {
                    "fiscal_year": this.dom.find('#fiscal_year').val(),
                    "projects": this.dom.find('#project_search_dd').val(),
                    "data": filter
                }
            }).then((data) => {
                this.districtDD = new Dropdown(data)
                this.districtDD
                    .label(filter.label)
                    .all(false)
                    .multiple(true)
                    .draw(this.dom.find('#district_container').first(), 'rdt', 'region_name', 'district')

                this.dom.find('#district_container > select').on('change', () => {
                    this.drawMembersDD();
                    this.drawTllDD()
                })
            })
        }
    }

    drawTllDD() {
        let filter = this.getFilterConfig('tll');
        if(filter) {
            this.dom.find('#tll_container').removeClass('d-none');
            this.dom.find('#tll_container').html('');
            $.ajax({
                url     : 'api/tll',
                method  : 'get',
                data : {
                    "fiscal_year": this.dom.find('#fiscal_year').val(),
                    "projects": this.dom.find('#project_search_dd').val(),
                    "district": this.dom.find('#district').val()
                }
            }).then((data) => {
                this.tllDD = new Dropdown(data)
                this.tllDD
                    .label(filter.label)
                    .all(true)
                    .draw(this.dom.find('#tll_container').first(), 'wwid', 'name', 'tll')
            })
        }
    }

    drawMembersDD() {
        let filter = this.getFilterConfig('members');
        if (filter) {
            this.dom.find('#members_container').html('');
            $.ajax({
                url: 'api/sfmember',
                method: 'get',
                data: {
                    "fiscal_year": this.dom.find('#fiscal_year').val(),
                    "projects": this.dom.find('#project_search_dd').val(),
                    "district": this.dom.find('#district').val()
                }
            }).then((data) => {
                this.memberDD = new Dropdown(data)
                this.memberDD
                    .label(filter.label)
                    .all(false)
                    .multiple(true)
                    .draw(this.dom.find('#members_container'), 'wwid', 'name', 'members')
            })
        }
    }

    buildProgramOptionsDD()
    {
        let filter = this.getFilterConfig('my_programs_options');

        if(filter) {
            this.dom.find('#my_programs_options_container').html('');
            let data = [
                {"segment":"territory","label":"My Programs"},
                {"segment":"district","label":"My Region"},
                {"segment":"region","label":"My Area"}
            ];
            let programOptionsDD = new Dropdown(data)
            programOptionsDD.draw(this.dom.find('#my_programs_options_container'), 'segment', 'label', 'my_programs_options')
        }
    }

    renderFiltersDD(filter) {
        switch(filter.field) {
            case 'types' :
                this.drawTypesDD()
                break;
            case 'status' :
                this.drawStatusDD(filter)
                break;
            case 'venue_states' :
                this.drawStatesDD(filter)
                break;
            case 'members' :
                this.drawMembersDD()
                break;
            case 'tll' :
                this.drawTllDD()
                break;
            case 'topic':
                this.buildTopicDD(filter, app.appData.topics)
                break;
            case 'my_programs_options':
                this.buildProgramOptionsDD()
        }
    }

    getFilterConfig(selectedKey) {
        return this.filters.find(field => field.field == selectedKey)
    }

    setFilterData() {
        let filterData = {
            others_hubs: true,
            too_many_projects: this.tooManyProjects
        };

        this.dom.find("input:not(:disabled), select").each((index ,elem) => {
            let name = $(elem).attr('name')
            let value = $(elem).val()

            if(name) {
                if($(elem).prop('type') != 'checkbox' || $(elem).is(':checked')) {
                    if(name == "district" && value.length > 0) {
                        var data = $(elem).select2('data')
                        filterData['district_name'] = [];
                        filterData['district'] = [];
                        data.forEach(district => {
                            filterData['district_name'].push(district.text);
                            filterData['district'].push(district.id);
                        })
                    } else if ($(elem).prop('type') == 'checkbox') {
                        filterData[name] = 1;
                    } else {
                        filterData[name] = value;
                    }
                } else if(name == 'my_programs_only') {
                    filterData[name] = false;  // we need this flag otherwise it will be set from config
                }
            }
        });

        return filterData;
    }

    showCalendarView() {
        this.dom.find('#program_id_container').hide();
        this.dom.find('#from_program_date').prop('disabled', true)
        this.dom.find('#from_program_date_container').hide();
        this.dom.find('#to_program_date_container').hide();
        this.dom.find('#to_program_date').prop('disabled', true);
    }

    showListView() {
        this.dom.find('#program_id_container').show();
        this.dom.find('#from_program_date').prop('disabled', false).show();
        this.dom.find('#to_program_date').prop('disabled', false).show();
        this.dom.find('#from_program_date_container').show();
        this.dom.find('#to_program_date_container').show();
    }

    constructor(filters) {
        this.dom = $(twig({ data: template }).render({
            labels:                      app.appData.labels,
            maximum_unfiltered_projects: app.appData.maximum_unfiltered_projects,
            speaker:                     app.appData.labels.speaker
        }));
        this.filters = filters.filter(filterObj => {
            return !filterObj.user_types || (filterObj.user_types && filterObj.user_types.includes(parseInt(app.appData.curPosition.user_type_id)))
        })

        this.drawFiscalYearDD()
        $.each(this.filters, (key, filter) => {
            this.filters.push(filter);
            this.dom.find('#'+filter.field+"_container").removeClass('d-none');
            if(filter.type == 'text' && typeof filter.label !== 'undefined') {
                this.dom.find('#'+filter.field).prop('placeholder', filter.label)
            }
            if(filter.field == 'my_programs_only') {
                this.dom.find('#'+filter.field).prop('checked', true)
                this.dom.find('#'+filter.field).prop('disabled', false)
            }
            if(filter.field == 'my_speakers_only') {
                this.dom.find('#'+filter.field).prop('disabled', false)
            }
            if(filter.type == 'dropdown') {
                this.renderFiltersDD(filter);
            }
        })

        this.dom.find('#from_program_date').datepicker().datepicker("update", new Date());
        this.dom.find('#to_program_date').datepicker();

        this.dom.find('button[type=reset]').on('click', (event) => {
            event.preventDefault();
            let formContainer = $('#form_filter_programs')[0];
            if(typeof formContainer !== 'undefined') {
                formContainer.reset();
            }

            this.dom.find('#program_type_id').val([]).trigger('change')
            this.dom.find('#status_type').val([]).trigger('change')
            this.dom.find('#client_status_name').val([]).trigger('change')
            this.dom.find('#project_search_dd').val([]).trigger('change')
            this.dom.find('#brand_search_dd').val([]).trigger('change')
            this.dom.find('#topic_id').val([]).trigger('change')

            $('#form_filter_programs input[type="checkbox"]:checked').prop('checked', false).val(1);
        })

        this.dom.find('#program_id').on('input', () => {
            this.drawProjectCountWarning();
        })

        this.dom.off('submit').on('submit', () => {
            let program_id = app.programFilter.dom.find('#program_id').val();
            if(program_id) {
                app.programFilter.dom.find('#program_id').val('');
                app.navigate('meeting', [program_id]);
                return;
            }
        })
    }

    ready() {
        if(this.typesDD) {
            this.typesDD.refreshMultiSelect();
        }

        if(this.statusDD) {
            this.statusDD.refreshMultiSelect();
        }

        if(this.projectDD) {
            this.projectDD.refreshMultiSelect();
        }

        if(this.brandDD) {
            this.brandDD.refreshMultiSelect();
        }

        if(this.topicDD) {
            this.topicDD.refreshMultiSelect();
        }

        if(this.districtDD) {
            this.districtDD.refreshMultiSelect();
        }

        if(this.memberDD) {
            this.memberDD.refreshMultiSelect();
        }

        // show join-able programs
        let joinable_programs_config = JSON.parse(app.appData.configs.JOINABLE_PROGRAM_ON_CALENDAR_REP_PORTAL ?? '{"is_show":false}')

        if (joinable_programs_config.is_show) {
            this.dom.find('#joinable_programs_container').show()

            if ((joinable_programs_config.is_on_default ?? false)) {
                this.dom.find('#joinable_programs').click()
            }
        } else {
            this.dom.find('#joinable_programs_container').remove()
        }

        this.drawProjectCountWarning();
    }
}
