import { Page } from './page.js'
import { ModalReasonForChange } from './marketingcontent/ModalReasonForChange.js'
import { Dropdown } from '../components/Dropdown'

let template = require('./marketingcontent/detail.twig');
let body_template = require('./marketingcontent/detail_body.twig');

export class MarketingContentDetail extends Page {
    constructor() {
        super();

        this.dom = $(twig({data: template}).render());

        this.dom.find('#marketing_list').on('click', () => {
            app.navigate('marketingcontent');
        });
    }

    drawProgramTypesDD(data, dom_element) {
        if(typeof data === 'object') data = Object.values(data)
        new Dropdown(data)
            .all(false)
            .required(true)
            .multiple(true)
            .draw(dom_element, 'program_type_id', 'program_name', 'program_type_id');
    }

    ready() {
        let request_id = window.location.toString().split("/").pop();

        if(this.dom.find('.card-body, .card-footer').length) {
            this.dom.find('.card-body, .card-footer').remove();
        }

        $.ajax({
            "url": "api/marketingcontent/getRequest",
            "data": {
                "request_id": request_id
            },
            "dataType": "json",
            "method": "get"
        }).then(
            (data) => {
                if(data.success) {
                    data.action = data.action[0].toUpperCase() + data.action.substr(1).toLowerCase();

                    if(data.audience_type) {
                        data.audience_type = data.audience_type[0].toUpperCase() + data.audience_type.substr(1).toLowerCase();
                    }

                    data.show_complete_button = app.appData.layout.marketing_content.show_complete_button.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id));

                    data.show_update_options = app.appData.companyKey == 'biogen' &&
                        (app.appData.curUserType.user_type_id == 5 || app.appData.curUserType.user_type_id == 12);

                    let detail_dom = $(twig({data: body_template}).render(data));

                    detail_dom.on('click', '#complete_btn', () => {
                        let spinner_html = [
                            '<div class="spinner-border text-success" role="status">',
                                '<span class="sr-only">Loading...</span>',
                            '</div>'
                        ];

                        detail_dom.find('#completed_by_section').html(spinner_html.join(''))

                        $.ajax({
                            "url": "api/marketingcontent/complete",
                            "data": {
                                "request_id": request_id
                            },
                            "dataType": "json",
                            "method": "post"
                        }).then(
                            (res) => {
                                let completed_by_html = [
                                    '<h4>Completed By:</h4>',
                                    '<h3>' + res.completed_by + '</h3>',
                                    '<h3>' + res.completed_datetime + '</h3>'
                                ];

                                detail_dom.find('#completed_by_section').html(completed_by_html.join(''));
                            },
                            () => {
                                let err_msg = $("<span>").text('Error').addClass('text-danger text-center');
                                detail_dom.find('#completed_by_section').html(err_msg);
                            }
                        );
                    });

                    // update actions on the page for biogen and user_type 12
                    if (data.show_update_options) {
                        data.program_type_ids = Object.values(data.program_type_ids.split(','));

                        // create program_type DD
                        this.drawProgramTypesDD(app.appData.programTypes, detail_dom.find('#program_type .action-input'));
                        data.program_type_ids.forEach((e) => {
                            detail_dom.find("#program_type option[value='" + e + "']").prop("selected", true).change()
                        });

                        // update actions
                        detail_dom.on('click', '.action-update', (e) => {
                            let parent = $(e.target).closest('div.action');
                            let parent_id = parent.attr('id');
                            parent.find('.action-update, h3').hide();
                            parent.find('.action-cancel, .action-input').show();
                            if (parent_id == 'go_live_date') {
                                let go_live_date = moment(data.go_live_date).format('L');
                                let today = moment().format('L');
                                let tomorrow = moment().add('days', 1).format('L');
                                let startDate = tomorrow;
                                let datesDisabled = [];
                                if (go_live_date < today) {
                                    startDate = go_live_date;
                                    let getDaysArray = function(start, end) {
                                        let arr = [];
                                        for(let dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)){
                                            arr.push(new Date(dt));
                                        }
                                        return arr;
                                    };
                                    datesDisabled = getDaysArray(
                                        new Date(new Date(go_live_date).getTime() + 86400000),
                                        new Date(today));
                                }
                                parent.find('input').datepicker({
                                    startDate : startDate,
                                    endDate: data.expiration_date,
                                    datesDisabled: datesDisabled,
                                });
                            }
                        });

                        detail_dom.on('change', '.action-input, #program_type_id', (e) => {
                            let parent = $(e.target).closest('div.action');
                            let parent_id = parent.attr('id');
                            if (parent_id == 'program_type') {
                                let new_program_type_ids = $('#program_type_id').val();
                                if (JSON.stringify(new_program_type_ids) != JSON.stringify(data.program_type_ids)) {
                                    data.new_program_type_ids = new_program_type_ids;
                                    parent.find('.action-save').show();
                                } else {
                                    parent.find('.action-save').hide();
                                    delete data.new_program_type_ids;
                                }
                            } else if (parent_id == 'go_live_date') {
                                let tmp_go_live_date = parent.find('input').val();
                                if (Number(new Date(data.go_live_date)) != Number(new Date(tmp_go_live_date))) {
                                    data.new_go_live_date = tmp_go_live_date;
                                    parent.find('.action-save').show();
                                } else {
                                    parent.find('.action-save').hide();
                                    delete data.new_go_live_date;
                                }
                            }
                        });

                        detail_dom.on('click', '.action-cancel', (e) => {
                            let parent = $(e.target).closest('div.action');
                            let parent_id = parent.attr('id');
                            parent.find('.action-cancel, .action-save, .action-input').hide();
                            parent.find('.action-update, h3').show();
                            if (parent_id == 'program_type') {
                                $("#program_type option:selected").prop("selected", false);
                                data.program_type_ids.forEach((e) => {
                                    detail_dom.find("#program_type option[value='" + e + "']").prop("selected", true).change();
                                });
                                delete data.new_program_type_ids;
                            } else if (parent_id == 'go_live_date') {
                                parent.find('input').datepicker('destroy').val(data.go_live_date);
                                delete data.new_go_live_date;
                            }
                        });
                        detail_dom.on('click', '.action-save', () => {
                            new ModalReasonForChange(data)
                        });
                    }

                    this.dom.append(detail_dom);
                }
                else {
                    let err_msg = $('<span>').text("Unable to retrieve marketing content request").addClass('text-center h6');
                    this.dom.append(err_msg);
                }
            },
            () => {
                let err_msg = $('<span>').text("Error retrieving marketing content request").addClass('text-center h6');
                this.dom.append(err_msg);
            }
        );
    }
}
