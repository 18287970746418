import { Page } from '../page.js'
import { ProgramTable } from '../../components/ProgramTable'
import { MeetingCards } from './meetingcards'
import { BudgetCards } from './budgetcards'
import { ProgramFilter } from "../../components/ProgramFilter";

let template = require('./home.twig');

export class Home extends Page{
    constructor() {
        super();
        this.dom = $(twig({ data: template }).render({
            labels: app.appData.labels,
            maximum_unfiltered_projects:              app.appData.maximum_unfiltered_projects,
            show_maximum_unfiltered_projects_warning: Object.entries(app.appData.projects).length > app.appData.maximum_unfiltered_projects
        }))

        this.initializedTable = false;

        this.today = moment().format('MM/DD/YYYY');

        if(!app.programFilter)
        {
            app.programFilter = new ProgramFilter(app.appData.layout.programs.filters);
            app.programFilter.dom.find('#from_program_date').datepicker("update", new Date());

            if(typeof app.appData.layout.programs.default_all_dates !== 'undefined'
                && app.appData.layout.programs.default_all_dates) {
                app.programFilter.dom.find('#from_program_date').val('');
            }

            if(app.appData.layout.programs.show_all_filters) {
                this.dom.find('#btn_show_filters').trigger('click');
            }
        }

        this.programList = new ProgramTable(this.dom.find('#upcoming_programs'));

        this.dom.find('#btn_show_filters').on('click',() => {
            $('#more_filters').removeClass('d-none');
            this.dom.find('#btn_hide_filters').removeClass('d-none');
            this.dom.find('#btn_show_filters').addClass('d-none');
        })

        this.dom.find('#btn_hide_filters').on('click',() => {
            $('#more_filters').addClass('d-none');
            this.dom.find('#btn_show_filters').removeClass('d-none');
            this.dom.find('#btn_hide_filters').addClass('d-none');
        })
    }

    loadMeetingCards() {
        $.ajax({
            url: "api/programs/list/mini",
            type: "post",
            data: {"data":{
                'status_key' :  'confirmed',
                "my_programs_only": 1
            }}
        }).then(data => {
            if(data.length > 0) {
                let cardView = new MeetingCards(data);
                this.dom.find('#meeting-card-views').html(cardView.dom);
            }
        })
    }

    loadBudgetCards() {
        this.budgetCard = new BudgetCards(app.appData);
        this.dom.find('#meeting-card-views').html(this.budgetCard.dom);
    }

    ready()
    {
        app.programFilter.showListView();
        app.programFilter.dom.find('#to_program_date').datepicker();
        app.programFilter.ready();

        if(!this.dom.find('#filter_container').html()) {
            this.dom.find('#filter_container').html(app.programFilter.dom);
        }

        if(!this.initializedTable) {
            this.programList.setCustomFilterData(app.programFilter.setFilterData());
            this.programList.initializeDataTable();
            this.initializedTable = true;

            this.dom.off('click', '#approval-card .card-body').on('click', '#approval-card .card-body', () => {
                let statuses = app.appData.programStatuses.filter(status => status.status_id == app.appData.curUserType.can_approve_status_id);
                let approvalStatus = statuses[0];

                this.programList.setCustomFilterData({
                    "status_key": [approvalStatus.status_key],
                    "my_programs_only": 1
                });
                this.programList.reloadDatatable();
            })

            if(app.checkPerms('budget_card')) {
                this.loadBudgetCards();
            } else {
                this.loadMeetingCards();
            }
        } else if (app.appData.refreshProgramList) {
            app.appData.refreshProgramList = false;
            this.programList.reloadDatatable();
        }

        this.programList.bindProgramFilter();
        if(typeof this.budgetCard !== 'undefined') {
            this.budgetCard.drawChart();
        }
    }
}
