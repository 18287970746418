var template = require('./hubsearchresults.twig');

export class HubSearchResults
{
    constructor(project_id = 0, program_id = 0) {
        this.dom = $(twig({ data: template }).render())

        // Restrict results to after required lead time
        let from_date = $('#hub_search_from').val();
        if(project_id != 0 && program_id != 0) {
            let block_short_lead = parseInt(app.appData.projects[project_id].program_types[program_id]
                .configuration.sb.meeting_request.short_lead_block_start_by_days);
            if(block_short_lead > 0){
                let ymd_lead_date = moment().add(block_short_lead, 'days').format('YYYY-MM-DD');
                if(app.appData.layout.meeting_request.useBusinessDays) {
                    ymd_lead_date = moment().businessAdd(block_short_lead, 'days').format('YYYY-MM-DD');
                }

                if(from_date != '') {
                    let ymd_date = moment(from_date).format('YYYY-MM-DD');
                    if(ymd_date < ymd_lead_date) {
                        from_date = ymd_lead_date;
                    }
                }else{
                    from_date = ymd_lead_date;
                }
            }
        }

        this.results_table = this.dom.find('.dataTable').DataTable({
            dom: "<'row'<'col-10'><'col-2 float-end'B>>t<'row'<'col-6'i><'col-6 float-end'p>>",
            language: {
                emptyTable: "No hub meetings",
                search: ""
            },
            processing: true,
            lengthChange: false,
            info: true,
            sort: true,
            paginationType: "full_numbers",
            ajax: {
                "url": 'api/programs/hubs' + (project_id ? '/' + project_id : ''), // This is a hotfix, don't add to the string
                "data": function(data) {
                    data.speaker_name = $('#hub_search_speaker_name').val();
                    data.rep_name = $('#hub_search_rep_name').val();
                    data.from_date = from_date;
                    data.to_date = $('#hub_search_to').val();
                    data.region = $('#hub_region').val();
                }
            },
            buttons: [ {
                'extend' : 'csvHtml5',
                'className':'btn btn-success',
                'text': '<i class="fas fa-file-excel"></i>',
                'title': 'Hub Meeting List' + (project_id ? ': Project ' + project_id : ''),
                'exportOptions': {
                    orthogonal: 'export',
                    columns: [0,1,2,3,4]
                }
            }],
            deferRender: true,
            sorting: [[ 1, "asc" ]],
            columns: [
                { "data": "meeting_id" },
                {
                    "data": "meeting_date",
                    render : (data, type, row) => {
                        return row.meeting_date + ' ' + row.start_time + ' ' + row.timezone_abbrv
                    }
                },
                { "data": "topic" },
                { "data": "speaker" },
                { "data": "mgr" },
                { "data": "meeting_id" }
            ],
            rowCallback: ( nRow, aData ) => {
                let button = $('<button value="'+aData.meeting_id+'" class="btn btn-primary btn-sm">Select</button>');

                if(aData.spoke_limit_reached) {
                    button.text(app.appData.labels.spoke + ' Limit Reached').prop('disabled', true);
                }
                else {
                    button.on('click', () => this.applyHub(aData))
                }

                $('td', nRow).eq(5).empty().append(button)
            }
        })

        this.results_table.draw();
    }

    applyHub(data) {
        this.dom.trigger($.Event('HubMeetingSelected', { hub_meeting_id: data.meeting_id }))
    }
}
