import {Card} from "./Card";

var edify_card_template = require('./edify_card.twig');

export class EdifyCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({data: edify_card_template}).render());
        this.enabled = false;
    }

    show() {
        super.show();
        $('#edify_card').hide();

        let project_id = $('#project_dd').val();
        let program_type_id = $('#program_type_dd').val();
        if(app.appData.projects[project_id].program_types[program_type_id].configuration.sb.meeting_request.edify_required){
            $('#edify_card').show();
            this.enabled = true;
        }else{
            this.clearAndHide();
        }
    }

    canShowNextCard() {
        let can_show = (this.dom.is(':visible') || app.pages['meetingrequest'].formCards.meeting_date_card.dom.is(':visible'))
        return can_show
    }

    getSelection() {
        return this.enabled ? $('#edify_checkbox').is(':checked') : null;
    }
}
