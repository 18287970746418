let template = require('./modal.approval.twig');

export class ModalApproval
{
    constructor(data) {
        this.dom = $(twig({data: template}).render(data))
        this.data = data;

        this.dom.find('#submit_all').on('click', (event) => {
            event.preventDefault();
            $.ajax({
                url: "api/speakernominate/changestatus",
                method: "POST",
                data: this.data
            }).then(response => {
                this.modal.hide();
                if(response.success) {
                    displayNotification({
                        title: 'Speaker nomination change submitted',
                        success: true
                    });
                    app.navigate('speakernominate');
                }
                else {
                    displayNotification({
                        title: 'Speaker nomination change failed',
                        success: false
                    });
                }
            })
        });

        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false, show: false});
    }
}
