import {Card} from "./Card";

let template = require('./business_justification_card.twig');

export class BusinessJustificationCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({data: template}).render());

        this.dom.find('textarea').on('keyup', () => this.cardFilledOutTriggers());
    }

    show() {
        super.show();
        $('#business_justification_note').addClass('required is-invalid');
    }
}
