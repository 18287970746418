import {Card} from "./Card";

var attendees_card_template = require('./attendees_card.twig');

export class AttendeesCard extends Card {
    constructor() {
        super();
        this.dom = $(twig({data: attendees_card_template}).render({
            injector_experiences : app.appData.injector_experiences,
            notice_msg: app.appData.layout.attendance.notice_msg ?? false,
            audience_types: app.appData.audience_types
        }));
        let that = this;

        this.dom.find('input').on('change', () => this.cardFilledOutTriggers());
        this.dom.find('select').on('change', () => this.cardFilledOutTriggers());

        this.dom.find('[name=est_att]').on('keyup', () => {
            if(this.shouldShowMinimumAttendeeRationaleInput()) {
                this.dom.find('#minimum_att_rationale_container').show();
                this.dom.find('#minimum_att_rationale').addClass('required');
            } else {
                this.dom.find('#minimum_att_rationale_container').hide();
                this.dom.find('#minimum_att_rationale').removeClass('required');
            }

            if(this.shouldShowMaximumAttendeeRationaleInput()) {
                this.dom.find('#maximum_att_rationale_container').show();
                this.dom.find('#maximum_att_rationale').addClass('required');
            } else {
                this.dom.find('#maximum_att_rationale_container').hide();
                this.dom.find('#maximum_att_rationale').val("").removeClass('required');
            }

            this.cardFilledOutTriggers();
        })

        this.dom.find('#audience_type').on('change', function() {
            let select_option_text = $('#audience_type option:selected')
                .toArray().map(item => item.text);

            if($.inArray('Other', select_option_text) >= 0){
                $('#audience_type_other_container').show();
                $('input[name="audience_type_other"]').addClass('required is-invalid').attr('required', 'required');
            }else{
                $('#audience_type_other_container').hide();
                $('input[name="audience_type_other"]').val('').removeClass('required is-invalid').removeAttr('required');
            }

            that.cardFilledOutTriggers();
        })
    }

    shouldShowMinimumAttendeeRationaleInput() {
        if(this.dom.find('[name=est_att]').val() > 1) {
            return false;
        }

        let project_id = $('#project_dd').val();
        let program_type_id = $('#program_type_dd').val();

        if(program_type_id == '') {
            return false;
        }

        let program_type = app.appData.projects[project_id].program_types[program_type_id];

        if(typeof program_type.configuration.sb.meeting_request.triggerMinimumAttendeeRationale == 'undefined') {
            return false;
        }

        let meeting_type_id = $('#meeting_type_dd').val();
        let meeting_type = app.appData.programtypeMeetingTypes[program_type_id][meeting_type_id];

        let setting_type = meeting_type.meeting_type_key.split(':')[0];

        return program_type.configuration.sb.meeting_request.triggerMinimumAttendeeRationale.indexOf(setting_type) >= 0;
    }

    shouldShowMaximumAttendeeRationaleInput() {
        let est_att = this.dom.find('[name=est_att]').val();
        if(est_att == 0) {
            return false;
        }

        let project_id = $('#project_dd').val();
        let program_type_id = $('#program_type_dd').val();

        if(program_type_id == '') {
            return false;
        }

        let program_type = app.appData.projects[project_id].program_types[program_type_id];

        if(typeof program_type.configuration.sb.meeting_request.estimated_max_attendee_approval == 'undefined'
            || program_type.configuration.sb.meeting_request.estimated_max_attendee_approval == 0
            || est_att <= program_type.configuration.sb.meeting_request.estimated_max_attendee_approval) {
            return false;
        }

        return true;
    }

    isFilledOut() {
        let filled_out = true;
        let in_range = false;
        let min_att = null;
        let max_att = null;
        let cur_att = this.dom.find('#estimated_attendees');

        if(    typeof app.appData.projects[$('#project_dd').val()] !== 'undefined'
            && typeof app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()] !== 'undefined') {
            min_att = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()]
                .configuration.sb.meeting_request.min_est_att;
            max_att = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()]
                .configuration.sb.meeting_request.max_est_att;
        }

        let range_msg = "Estimated attendees must be within the required range.";
        // "(Minimum of " + min_att;


        if(min_att != null && min_att != ''){
            range_msg = range_msg + " Minimum # of attendees: " + min_att + ".";
        } else {
            min_att = 0;
        }

        if(max_att != null && max_att != ''){
            range_msg = range_msg + " Maximum # of attendees: " + max_att + ".";
        } else {
            max_att = 99999;
        }


        let cur_att_int = parseInt(cur_att.val());
        if(min_att <= cur_att_int && cur_att_int <= max_att){
            in_range = true;
        }

        $(cur_att).removeClass('is-invalid');
        $('#est_att_range_error').hide();
        $('#est_att_range_error').html('');

        if(cur_att.val() == '' || !in_range)
        {
            if(!in_range && cur_att.val() != ''){
                //show range error
                $('#est_att_range_error').html(range_msg);
                $('#est_att_range_error').show();
            }

            $(cur_att).addClass('is-invalid');
            filled_out = false;
        }

        return filled_out && super.isFilledOut();
    }
    show() {
        super.show();

        let project_id = $('#project_dd').val();
        let program_type_id = $('#program_type_dd').val();

        let program_type = app.appData.projects[project_id].program_types[program_type_id];
        if(typeof program_type.configuration.sb.meeting_request.max_attendee !== 'undefined'
            && program_type.configuration.sb.meeting_request.max_attendee) {
            this.dom.find('#maximum_att_container').show();
        } else {
            this.dom.find('#maximum_att_container').hide();
        }

        if(typeof program_type.configuration.sb.meeting_request.show_injectors_experience !== 'undefined'
            && program_type.configuration.sb.meeting_request.show_injectors_experience) {
            this.dom.find('#injector_experience_container').show();
        } else {
            this.dom.find('#injector_experience_container').hide();
        }

        if(typeof program_type.configuration.sb.meeting_request.show_cannula_request !== 'undefined'
            && program_type.configuration.sb.meeting_request.show_cannula_request) {
            this.dom.find('#is_cannula_shipment_requested_container').show();
            this.dom.find('input[name="is_cannula_shipment_requested"]').attr('required', 'required');
        } else {
            this.dom.find('#is_cannula_shipment_requested_container').hide();
            this.dom.find('input[name="is_cannula_shipment_requested"]').removeAttr('required');
        }

        if(typeof program_type.configuration.sb.meeting_request.show_estimated_patients !== 'undefined'
            && program_type.configuration.sb.meeting_request.show_estimated_patients) {
            this.dom.find('#est_patients_container').show();
            this.dom.find('#est_patients_container input').attr('required', 'required');
        } else {
            this.dom.find('#est_patients_container').hide();
            this.dom.find('#est_patients_container input').removeAttr('required');
        }

        if(typeof program_type.configuration.sb.meeting_request.show_gov_employee_questions !== 'undefined'
            && program_type.configuration.sb.meeting_request.show_gov_employee_questions) {
            this.dom.find('#gov_employee_container').show();
            this.dom.find('#gov_employee_container select').addClass('required');
        } else {
            this.dom.find('#gov_employee_container').hide();
            this.dom.find('#gov_employee_container select').removeClass('required');
        }

        this.dom.find('[name=est_att]').attr('required', 'required').addClass('required is-invalid');

        let show_audience_type_field = app.appData.layout.meeting_request.show_audience_type_field ?? false;
        if(show_audience_type_field){
            this.audience_type = this.dom.find('#audience_type');
            this.audience_type.select2();
            this.dom.find('#audience_type_container').show();
            this.audience_type.addClass('required is-invalid').attr('required', 'required');
        }
    }
}
