import { Page } from './page.js'
import { TrainingList } from './prepostwork/trainingList'
import { SurveyEval } from "./prepostwork/surveyEval";
import { PatientModelInformation } from "./prepostwork/patientModelInformation";

let template = require('./prepostwork/prepostwork.twig');

export class PrePostWork extends Page{
    constructor() {
        super();
        this.attendeeId = '';
        this.activeWork = '';
        this.isWorkSelected = false;
    }
    
    getAttendees(async = true){
        return $.ajax({
            url     : 'api/prepostwork/meeting/' + this.meetingId + '/attendees',
            method  : 'get',
            async: async
        });
    }

    initializeTrainingTable(){
        this.trainingTable = new TrainingList(this.meetingId, this.attendeeId);
    }

    loadSurveyEval(){
        this.surveyEval = new SurveyEval(this.meetingId, this.attendeeId);
    }

    loadPatientModelInformation(){
        this.PatientModelInformation = new PatientModelInformation(this.meetingId, this.attendeeId);
        this.PatientModelInformation.ready();
    }

    isRequirementSet(){
        this.attendeeId = this.dom.find('#attendees').val();
        let valid = true;
        
        if(this.attendeeId === '' || this.attendeeId === null || !this.isWorkSelected){
            valid = false;
        }

        return valid;
    }
    
    showData(){
        if(this.isRequirementSet()){
            if(this.activeWork === 'training_tab'){
                this.initializeTrainingTable();
            }else if(this.activeWork === 'survey_eval_tab'){
                this.loadSurveyEval();
            }else{
                this.loadPatientModelInformation();
            }

            $('#missing_message').hide();
            $('#work-list').show();
            return;
        }

        $('#missing_message').show();
        $('#work-list').hide();
    }

    loadUIJs(){
        $('#meeting_details_button').on('click', () => app.navigate('meeting', [this.meetingId]));

        this.dom.find('#attendees').on("change", () => {
            this.showData();
        });

        this.dom.find('#pre_post_work_tab').find('.nav-link').on("click", (event) => {
            event.preventDefault();
            this.activeWork = event.target.id;
            this.isWorkSelected = true;
            this.showData();
        });

       $('.toggler').on('click', function(){
            let table_id = $(this).closest('.table_wrapper').attr('id');

            $('.inverted_header').find($('[data-controller-for=' + table_id + ']')).toggle();
            $('#' + table_id).toggle();
        });

        $('.inverted_header_toggler').on('click', function(){
            let table_id = $(this).parent().attr('data-controller-for');
            $('#' + table_id).toggle();
            $(this).parent().toggle();
        })
    }

    ready() {
        let pathArray = window.location.pathname.split('/');
        this.meetingId = pathArray[pathArray.length -1];
        this.attendees = {};
        this.trainingTable = {};
        this.surveyEval = {};
        this.patientModelInformation = {};
        
        this.getAttendees(false).done((data) => {
            this.attendees = data;
        });
        
        this.dom = $(twig({ data: template }).render({
            attendees: this.attendees,
            meeting_id: this.meetingId
        }));
        
        $('.content-body').html(this.dom).addClass('pre_post_work').promise().done(() => {
            if(!this.isRequirementSet()){
                $('#missing_message').show();
                $('#work-list').hide();
            }
        });

        this.loadUIJs();
        
    }
}
