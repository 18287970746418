import {Card} from "./Card";

let slt_card_template = require('./slt_questions_card.twig');
let questions_template = require('./slt_questions.twig');

export class SLTQuestionsCard extends Card {
    constructor() {
        super();

        this.dom = $(twig({data: slt_card_template}).render());

        this.dom.on('change keydown keyup keypress', 'input.required, textarea.required', () => {
            this.cardFilledOutTriggers();
        });

        this.dom.on('change keydown keyup keypress', 'textarea', (e) => {
            let char_count = $(e.target).val().length;
            let max_char_count = $(e.target).attr('maxlength');

            $(e.target).siblings('span.char_count').text(char_count + "/" + max_char_count);
        });
    }

    show() {
        let program_type;
        if($('#program_type_dd').val()) {
            program_type = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()];
        }

        if(program_type
        && program_type.configuration.sb.meeting_request.allow_short_lead_pr
        && app.pages['meetingrequest'].formCards.meeting_date_card.short_lead_time
        && app.appData.sltQuestions.length > 0) {
            let sltQuestions = app.appData.sltQuestions[0][0];
            if(app.appData.sltQuestions[$('#project_dd').val()] != undefined
            && app.appData.sltQuestions[$('#project_dd').val()][$('#program_type_dd').val()] != undefined) {
                sltQuestions = app.appData.sltQuestions[$('#project_dd').val()][$('#program_type_dd').val()];
            }

            twig({data: questions_template}).renderAsync({ "questions": sltQuestions }).then(
                (dom_html) => {
                    let questions_dom = $(dom_html);
                    this.dom.find('#slt_questions').empty().append(questions_dom);

                    this.dom.find('.has_dependent input.slt_question_answer').on('change', (e) => {
                        let question_id = $(e.target).attr('name').split('_').pop();

                        let dependent_id = sltQuestions[question_id].dependent_question_id;
                        let require_val = sltQuestions[question_id].require_dependent_question_val;

                        if(dependent_id) {
                            this.dom.find('[name="slt_question_' + dependent_id + '"]').removeClass('required is-invalid');

                            if($(e.target).val() == require_val) {
                                this.dom.find('[name="slt_question_' + dependent_id + '"]').addClass('required is-invalid');
                            }
                        }
                    });

                    let dependent_question_ids = [];
                    Object.entries(sltQuestions).values(val => {
                        if(val.dependent_question_id) {
                            dependent_question_ids.push(val.dependent_question_id);
                        }
                    });

                    this.dom.find('textarea.slt_question_answer').each((idx, el) => {
                        let elem = $(el);

                        let question_id = elem.attr('name').split('_').pop();
                        let days_to_mtg = moment($('#meeting_date').val(), 'MM/DD/YYYY').diff(moment(), 'days');
                        let require_question_days_to_mtg = sltQuestions[question_id].require_question_days_to_mtg || 0;

                        if(require_question_days_to_mtg > 0
                        && days_to_mtg <= require_question_days_to_mtg) {
                            elem.addClass('required is-invalid');
                        }
                        else if(require_question_days_to_mtg == 0
                        && !dependent_question_ids.includes(question_id)) {
                            elem.addClass('required is-invalid');
                        }
                    });

                    this.dom.fadeIn(100).promise().then(() => {
                        this.cardFilledOutTriggers();
                    });
                },
                () => {
                    this.dom.find('#slt_questions').empty();
                    this.filled_out = false;

                    this.clearAndHide();
                }
            );
        } else {
            this.dom.find('#slt_questions').empty();
            this.filled_out = false;

            this.clearAndHide();
        }
    }

    canShowNextCard()
    {
        let allow_slt = false;
        let program_type;

        if($('#program_type_dd').val()) {
            program_type = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()];
        }

        if(program_type
        && program_type.configuration.sb.meeting_request.allow_short_lead_pr) {
            allow_slt = true;
        }

        let can_show =
            (
                app.appData.sltQuestions.length == 0
            )
            ||
            (
                this.dom.is(':visible')
                && this.isFilledOut()
            )
            ||
            (
                (!allow_slt || !app.pages['meetingrequest'].formCards.meeting_date_card.short_lead_time)
                && app.pages['meetingrequest'].formCards.meeting_date_card.dom.is(':visible')
                && app.pages['meetingrequest'].formCards.meeting_date_card.filled_out
            );

        return can_show;
    }

    getAnswers() {
        if(!this.dom.is(":visible")) {
            return [];
        }

        let answers = {};

        this.dom.find('.slt_question_answer').each((idx, el) => {
            let elem = $(el);
            let name = elem.attr('name');
            let project_question_id = name.split('_').pop();
            let value;

            if(elem.is("input")
            && elem.attr('type') === 'radio') {
                let selected_radio = this.dom.find('input[name="' + name + '"]:checked');

                if(selected_radio.length) {
                    value = selected_radio.val() == '1' ? 'Yes' : 'No';
                }
            }
            else if(elem.is("textarea")
                || (
                    elem.is('input')
                    && elem.attr('type') === 'number'
                )
            ) {
                value = elem.val();
            }

            answers[project_question_id] = value;
        });

        return answers;
    }
}