import {Card} from "./Card";

var tour_card_template = require('./tour_card.twig');

export class TourCard extends Card {
    constructor() {
        super();
        this.dom = $(twig({data: tour_card_template}).render());
        this.tour_dd_populated = false;

        this.is_tour_dd = this.dom.find('#is_tour');
        this.tour_dd = this.dom.find('#tour_id');

        this.is_tour_dd.on('click', () => {
            if(this.is_tour_dd.val() == '1') {
                this.populate_tours();
                this.dom.find('#tour_id_container').show();
            } else {
                this.dom.find('#tour_id_container').hide()
            }
        })
    }

    populate_tours() {
        if(this.tour_dd_populated) {
            return;
        }

        $.ajax({
            url: 'api/meetingrequest/tours',
        }).then(data => {
            this.tour_dd_populated = true;
            for(let i in data) {
                let tour = data[i];
                this.tour_dd.append($('<option value="'+tour.tour_id+'">'+tour.name+'</option>'))
            }

            this.tour_dd.append('<option value="new">New Tour</option>')
        })
    }
}