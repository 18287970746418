import template from './reviewException.twig'
import {CustomModal} from "../../../components/customModal";
import { Dropdown } from '../../../components/Dropdown'

export class ReviewException
{
    constructor(exception_id, exception_table) {

        this.exception_id = exception_id;
        this.exception_table = exception_table;

        this.dom = $(twig({data: template}).render({
        }));

        this.dom.find('#cancel').on('click', () => {
            this.close();
        })

        this.dom.find('#save').on('click', () => {
            this.dom.find('.reviewer-alert').hide();
            let required_fields = [];
            if(this.dom.find('#noteText').val().trim() == '') {
                required_fields.push('Approval Reason');
            }

            if(this.dom.find('#reviewer_dd').val().length == 0) {
                required_fields.push('Reviewers');
            }

            if(required_fields.length > 0) {
                this.dom.find('.reviewer-alert')
                    .html("The following fields are required for review submission: <br>" + required_fields.join('<br>'))
                    .show();
            } else {
                this.review();
            }
        })

        this.dom.on('shown.bs.modal',  () => {
            this.dom.find('#noteText').focus()
        })

        this.modal = new CustomModal({
            title: 'Review '+app.appData.labels.exception,
            content: this.dom,
            buttons: []
        })
        this.populateReviewers();
        this.modal.open();
    }

    populateReviewers() {
        this.getReviewers().then((reviewers) => {
            let dropdown = new Dropdown(reviewers);
            dropdown
                .label('Select')
                .all(true)
                .multiple(true)
                .isModalContainer(true)
                .draw(this.dom.find('#reviewers'), 'wwid', 'full_name', 'reviewer_dd',['user_type_id','approver_id']);
        });
    }

    close() {
        this.dom.find("#noteText").val("");
        this.exception_table.filterTrigger();
        this.modal.close();
    }

    review() {
        let approvers = [];
        this.dom.find('#reviewer_dd :selected').each((ind,el) => {
            approvers.push({'wwid': $(el).val(),'approver_id': $(el).data('approver_id')});
        });
        return $.ajax({
            url: "api/exceptions/reviewers",
            type: "post",
            data: {
                eid: this.exception_id,
                approvers: approvers,
                reason: $('#noteText').val().trim()
            },
            beforeSend: () => {
                $("#noteText, #save").prop('disabled',true);
            }
            }).done(() => {
                $("#noteText, #save").prop('disabled',false);
            this.close()
            });
    }

    getReviewers() {
        return $.ajax({
            url: "api/exceptions/reviewers",
            type: "get",
            data: {
                eid: this.exception_id,
            }}
        );
    }
}
