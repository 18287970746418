import {Card} from "./Card";

var template = require('./supply_items_card.twig');

export class SupplyItemsCard extends Card
{
    constructor() {
        super();
        this.dom = $(twig({data: template}).render());

        for(let i in app.appData.supplyItems){
            let item = app.appData.supplyItems[i];
            this.dom.find('#supply_items_selections_container').append(
                $('<div class="col-6">' +
                    '<label>' + item.name + ' (' + item.description + ') </label><input class="form-control w-50" name="' + item.id + '"/>' +
                    '</div>')
            )
        }

        this.dom.on('change', 'select.required', () => {
            this.cardFilledOutTriggers();
            this.dom.trigger($.Event('NeedBudgetUpdate'));
        })
        this.dom.on('keyup', 'input.required', () => {
            this.cardFilledOutTriggers();
            this.dom.trigger($.Event('NeedBudgetUpdate'));
        })

    }

    getSupplyItems() {
        let supplyItems = [];
        this.dom.find('#supply_items_selections_container input').each((i, element) => {
            if($(element).val()) {
                supplyItems[$(element).attr('name')] = $(element).val();
            }
        });

        return supplyItems;
    }

}